import { ReactNode, useCallback, useEffect, useState } from "react"

import { Box, Divider, Heading, useMediaQuery } from "@chakra-ui/react"

import { useAppDispatch } from "app/hooks"
import ProtectedComponent from "components/PleaseLoginComp"
import DesktopSideMenu from "components/sidemenu/sidemenu"
import { MenuItem as MenuItemType } from "components/sidemenu/types"
import Tabs from "components/tabs/Tabs"
import useIsPermitted from "hooks/useIsPermittedHook/useIsPermitted"
import { setListingPageMenuItems } from "store/ui/uiSlice"

type HostListingLayoutProps = {
  children: ReactNode
}

export const propertiesSidebarMenuArr: MenuItemType[] = [
  {
    name: "Live Properties",
    depth: 1,
    isActive: true,
    id: "liveProperties",
    to: "/listings/properties/live-properties",
    childMenu: [],
  },
  {
    name: "Unpublished Properties",
    depth: 1,
    id: "unpublishProperties",
    to: "/listings/properties/unpublished-properties",
    childMenu: [],
  },
  {
    name: "Discount Template",
    depth: 1,
    id: "discountTemplate",
    to: "/listings/properties/discount-template",
    childMenu: [],
  },
  {
    name: "Contact Template",
    depth: 1,
    id: "contactTemplate",
    to: "/listings/properties/contact-template",
    childMenu: [],
  },
  {
    name: "Property Damage Protection",
    depth: 1,
    id: "propertyDamageProtection",
    to: "/listings/properties/property-damage-protection",
    childMenu: [],
  },
  {
    name: "Minimum Age Template",
    depth: 1,
    id: "minimumAgeTemplate",
    to: "/listings/properties/minimum-age-template",
    childMenu: [],
  },
  {
    name: "Availability Blocks",
    depth: 1,
    id: "availabilityBlocks",
    to: "/listings/properties/availability-blocks",
    childMenu: [],
  },
]

export const HOST_TAB_LIST = [
  {
    name: "Listings",
    path: "/listings/properties/live-properties",
    basePath: "/listings/properties",
  },
  {
    name: "Bookings",
    path: "/listings/bookings/all",
    basePath: "/listings/bookings",
  },
  {
    name: "Reports",
    path: "/listings/reports/earningProgress",
    basePath: "/listings/reports",
  },
]
const HostPropertiesLayout = (props: HostListingLayoutProps) => {
  const { children } = props
  const hasAccessTo = useIsPermitted()
  const dispatch = useAppDispatch()
  const [isMobile] = useMediaQuery("(max-width: 1024px)")
  const [menuItems, setMenuItems] = useState(propertiesSidebarMenuArr)

  const setMobileListingPageMenuItems = useCallback(
    (menu: MenuItemType[]) => {
      dispatch(setListingPageMenuItems(menu))
    },
    [dispatch],
  )

  useEffect(() => {
    if (hasAccessTo?.isAdmin || hasAccessTo?.viewArchivedPropertiesPage) {
      const tempMenu = [...propertiesSidebarMenuArr]
      tempMenu.splice(
        2,
        0,
        {
          name: "PMC Inactive Properties",
          depth: 1,
          id: "pmcInactiveProperties",
          to: "/listings/properties/pmc-inactive-properties",
          childMenu: [],
        },
        // {
        //   name: "Whimstay Inactive Properties",
        //   depth: 1,
        //   id: "whimstayInactiveProperties",
        //   to: "/listings/properties/whimstay-inactive-properties",
        //   childMenu: [],
        // },
        {
          name: "Archived Properties",
          depth: 1,
          id: "archivedProperties",
          to: "/listings/properties/archived-properties",
          childMenu: [],
        },
      )
      tempMenu.push({
        name: "Fee mapping",
        depth: 1,
        id: "feeMapping",
        to: "/listings/properties/fee-mapping",
        childMenu: [],
      })
      setMenuItems(tempMenu)
      setMobileListingPageMenuItems(tempMenu)
    } else {
      setMenuItems(propertiesSidebarMenuArr)
      setMobileListingPageMenuItems(propertiesSidebarMenuArr)
    }
  }, [hasAccessTo, hasAccessTo?.isAdmin, setMobileListingPageMenuItems])

  return (
    <Box
      maxW='100%'
      pl={{ base: "20px", lg: "30px", xl: "70px" }}
      pr={{ base: "20px", lg: "30px", xl: "30px" }}
      minW='100%'
    >
      <Box
        py={{ base: "0", md: "16px" }}
        mx={{ base: "-20px", md: "0" }}
        paddingTop={{ base: "16px", md: "16px" }}
      >
        <Tabs tabsArr={HOST_TAB_LIST} />
      </Box>
      <Divider borderColor='#e1e1e1' />
      <Box mt={{ base: 4, md: 8 }} display='flex' gap={{ base: 7, xl: 10 }}>
        {!isMobile && (
          <Box w='210px' flexGrow={1} flexShrink={0}>
            <Heading size='sm'>Properties</Heading>
            <DesktopSideMenu sidebarMenuArr={menuItems} />
          </Box>
        )}
        <Box pr={{ base: 0, xl: 10 }} w='full'>
          <ProtectedComponent>{children}</ProtectedComponent>
        </Box>
      </Box>
    </Box>
  )
}

export default HostPropertiesLayout
