import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
} from "@chakra-ui/react"

import MobileContentPage from "components/mobile-content-page"

type CustomModalPropsType = {
  children: React.ReactNode
  title?: string | undefined
  isOpen: boolean
  onClose: () => void
  closeOnOverlayClick?: boolean
  isFitWidth?: boolean
  maxH?: string
  titleSize?: string
  width?: string
}

const CustomModal = (props: CustomModalPropsType) => {
  /** Custom modal for different designs for mobile view and desktop view */
  const {
    children,
    title,
    isOpen,
    onClose,
    closeOnOverlayClick = true,
    isFitWidth = false,
    maxH = "500px",
    titleSize,
    width,
  } = props
  const [isMobile] = useMediaQuery("(max-width: 480px)")

  return (
    (!isMobile && (
      <Modal
        onClose={onClose}
        size='lg'
        isOpen={isOpen}
        closeOnOverlayClick={closeOnOverlayClick}
      >
        <ModalOverlay />
        <ModalContent
          width={width}
          borderRadius='22px'
          alignSelf='center'
          minW={!isFitWidth ? "auto" : "fit-content"}
          maxW='550px'
        >
          {title && (
            <>
              <ModalHeader fontSize='18px' fontWeight='bold'>
                {title}
              </ModalHeader>
              <ModalCloseButton top={2} size='lg' />
              <Divider borderColor='#E1E1E1' />
            </>
          )}
          <ModalBody maxH={maxH} pt='5'>
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    )) || (
      <MobileContentPage
        titleSize={titleSize}
        isOpen={isOpen}
        onClose={onClose}
        title={title}
      >
        <Box p='20px'>{children}</Box>
      </MobileContentPage>
    )
  )
}

export default CustomModal
