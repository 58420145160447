import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { ResponseData } from "./common"

export interface HostFullyRequestData {
  firstName: string
  lastName: string
  emailId: string
  mobileno1: string
  dialerCode: string
}

interface HostFullyUser {
  active: boolean
  userType: number
  isAdmin: boolean
  isHost: boolean
  isTraveler: boolean
  emailVerified: boolean
  mobileno1: string
  dialerCode: string
  emailId: string
  firstName: string
  lastName: string
  fullName: string
  isTrmsncndAccepted: boolean
  signinFrom: string
  travelerRatingUserCount: number
  travelerRatingStarSum: number
  notificationEmail: boolean
  notificationApp: boolean
  notificationSms: boolean
  hostFeePercentage: number
  pmsType: string
  channel: string
  listingStatus: boolean
  stateCode: string
}

export interface HostFullyRequest extends Partial<AxiosRequestConfig> {
  params: HostFullyRequestData
}

export interface HostFullyResponseData extends ResponseData {
  data: HostFullyUser
}

export interface HostFullyResponse extends AxiosResponse {
  data: HostFullyResponseData
}

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/user/save-user-state-id`

export function hostFullySubmit(
  {
    params,
    method = "post",
    url = `${defaultUrl}`,
    ...config
  }: HostFullyRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<HostFullyResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<HostFullyResponse>
}
