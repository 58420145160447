import { Icon, IconProps } from "@chakra-ui/react"

const FaqIcon = (props: IconProps) => (
  <Icon width='18px' height='18px' viewBox='0 0 20 20' fill='none' {...props}>
    <g clipPath='url(#clip0_468_12515)'>
      <path
        d='M19.25 10C19.25 15.1086 15.1086 19.25 10 19.25C4.89137 19.25 0.75 15.1086 0.75 10C0.75 4.89136 4.89136 0.75 10 0.75C15.1086 0.75 19.25 4.89137 19.25 10Z'
        stroke='#414141'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 6V10'
        stroke='#414141'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 14H10.01'
        stroke='#414141'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_468_12515'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </Icon>
)

export default FaqIcon
