import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "app/store"
import { AddRoomDetailsRequest, RoomDetailsRequest } from "services/bedroom"
import { apiClient } from "services/client"

import { InitialState, RoomsDetail } from "./types"

export const getDefaultBeds = createAsyncThunk(
  "bedrooms/getDefaultBeds",
  async () => {
    const response = await apiClient.getDefaultBeds()

    return response.data
  },
)

export const getRoomDetails = createAsyncThunk(
  "bedrooms/getRoomDetails",
  async (params: RoomDetailsRequest) => {
    const response = await apiClient.getRoomDetails(params)

    return response.data
  },
)

export const addRoomDetails = createAsyncThunk(
  "bedrooms/addRoomDetails",
  async (params: AddRoomDetailsRequest) => {
    await apiClient.addRoomDetails(params)
  },
)

const initialState: InitialState = {
  loading: false,
  defaultBeds: [],
  bedroomsDetails: {
    pmcId: "",
    pmsUnitId: "",
    bedRoomCount: 0,
    bedRooms: [],
  },
  selectedRoom: 0,
  error: null,
}

export const bedroomsSlice = createSlice({
  name: "bedrooms",
  initialState,
  reducers: {
    addRoom: (state, action: PayloadAction<{ bedRoomType: string }>) => {
      const roomsArr = state.bedroomsDetails.bedRooms.filter(
        (room) => room.bedRoomType === action.payload.bedRoomType,
      )

      const newRoom = {
        bedRoomType: action.payload.bedRoomType,
        bedRoomNumber: roomsArr.length,
        beds: [],
      }
      state.selectedRoom = state.bedroomsDetails.bedRooms.length
      state.bedroomsDetails = {
        ...state.bedroomsDetails,
        bedRoomCount: state.bedroomsDetails.bedRoomCount + 1,
        bedRooms: [...state.bedroomsDetails.bedRooms, newRoom],
      }
    },
    selectRoom: (state, action: PayloadAction<RoomsDetail>) => {
      const sellectedIndex = state.bedroomsDetails.bedRooms.findIndex(
        (i) =>
          i.bedRoomType === action.payload.bedRoomType &&
          i.bedRoomNumber === action.payload.bedRoomNumber,
      )
      state.selectedRoom = sellectedIndex || 0
    },
    addBeds: (
      state,
      action: PayloadAction<{ bedType: string; bedCount: number }>,
    ) => {
      const bedroomIndex = state.selectedRoom
      // const bedroomIndex = state.bedroomsDetails.bedRooms.findIndex(
      //   (room) =>
      //     room.bedRoomType === state.selectedRoom.bedRoomType &&
      //     room.bedRoomNumber === state.selectedRoom.bedRoomNumber,
      // )

      const tempBed = state.bedroomsDetails.bedRooms[
        bedroomIndex
      ].beds.findIndex((bed) => bed.badType === action.payload.bedType)

      let newBedsArray

      if (tempBed >= 0) {
        newBedsArray = state.bedroomsDetails.bedRooms[bedroomIndex].beds
        newBedsArray[tempBed].bedCount = action.payload.bedCount
      } else {
        newBedsArray = state.bedroomsDetails.bedRooms[bedroomIndex].beds
        newBedsArray.push({
          badType: action.payload.bedType,
          bedCount: action.payload.bedCount,
        })
      }
      state.bedroomsDetails.bedRooms[bedroomIndex].beds = newBedsArray
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRoomDetails.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getRoomDetails.fulfilled, (state, action) => {
      state.loading = false
      state.bedroomsDetails = action.payload.data
      state.selectedRoom = 0
      state.error = null
    })
    builder.addCase(getRoomDetails.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })

    builder.addCase(getDefaultBeds.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getDefaultBeds.fulfilled, (state, action) => {
      state.loading = false
      state.defaultBeds = action.payload.data
      state.error = null
    })
    builder.addCase(getDefaultBeds.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })

    builder.addCase(addRoomDetails.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(addRoomDetails.fulfilled, (state) => {
      state.loading = false
      state.error = null
    })
    builder.addCase(addRoomDetails.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })
  },
})

export const { addRoom, selectRoom, addBeds } = bedroomsSlice.actions

export const selectBedroomsDetails = (state: RootState) =>
  state.bedroomsReducer.bedroomsDetails

export const selectDefaultBeds = (state: RootState) =>
  state.bedroomsReducer.defaultBeds

export const selectSelectedRoom = (state: RootState) =>
  state.bedroomsReducer.selectedRoom

export default bedroomsSlice.reducer
