import {
  Box,
  Center,
  Editable,
  EditableInput,
  EditablePreview,
  Text,
  Tooltip,
} from "@chakra-ui/react"

import {
  DayStatus,
  NewDayObject,
  OriginalDayObject,
} from "pages/dummy/property-calendar"

type Properties = {
  originalDay: OriginalDayObject
  setMinLimit: (x: number, y: number) => void
  toggleArrival: (x: number) => void
  toggleDeparture: (x: number) => void
  toggleAvailable: (x: number) => void
  selectDate: (x: string) => void
  day: NewDayObject
  isSelected: boolean
  isToCheckArrival: boolean
}

export const Day = ({
  day,
  setMinLimit,
  toggleArrival,
  toggleDeparture,
  toggleAvailable,
  selectDate,
  originalDay,
  isSelected,
  isToCheckArrival,
}: Properties) => {
  const dayNumber = String(new Date(day.date).getDate())

  const isPastDate = false // day < new Date()

  return (
    <Box
      position='relative'
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDir='column'
      borderRight='1px solid #ddd'
      borderBottom='1px solid #ddd'
      height='130px'
      pointerEvents={isPastDate ? "none" : "auto"}
      cursor='pointer'
      bg={day.status === DayStatus.ENABLED ? "transparent" : "purple.200"}
    >
      <Center
        rounded='full'
        w={12}
        h={12}
        bg={isSelected ? "blue.300" : "gray.200"}
        onClick={() => {
          if (!day.isDisabled) {
            if (isSelected) {
              selectDate(" ")
            } else {
              selectDate(originalDay.date)
            }
          }
        }}
      >
        <Tooltip
          label={day.msg?.join(", ") || "lol"}
          colorScheme='black'
          placement='top'
          hasArrow
          fontSize='sm'
        >
          <Text
            display='flex'
            justifyContent='center'
            alignItems='center'
            fontSize='24px'
            fontWeight='600'
            height='37px'
            width='38px'
            mt='0px'
            color={isPastDate ? "gray.400" : "black"}
            borderRadius='5px'
            opacity={day.status === DayStatus.DISABLED ? "0.4" : 1}
            textDecoration={
              day.status === DayStatus.STRIKED ? "line-through" : ""
            }
          >
            {dayNumber}
          </Text>
        </Tooltip>
      </Center>
      <Center mt={1} minW={7} h={7} p={2} bg='blue.100' borderRadius='8px'>
        <Editable value={`${originalDay.minStayDays}`}>
          <EditablePreview />
          <EditableInput
            type='number'
            maxW='80px'
            _focus={{}}
            onChange={(e) =>
              setMinLimit(originalDay.calendarId, e.target.valueAsNumber)
            }
          />
        </Editable>
      </Center>
      {isToCheckArrival && (
        <>
          <Center
            position='absolute'
            top={4}
            right={4}
            w={7}
            h={7}
            bg={originalDay.arrival ? "green.300" : "red.300"}
            borderRadius='8px'
            onClick={() => toggleArrival(originalDay.calendarId)}
          >
            A
          </Center>
          <Center
            position='absolute'
            top={12}
            right={4}
            w={7}
            h={7}
            bg={originalDay.departure ? "green.300" : "red.300"}
            borderRadius='8px'
            onClick={() => toggleDeparture(originalDay.calendarId)}
          >
            D
          </Center>
        </>
      )}
      <Center
        position='absolute'
        top={20}
        right={4}
        w={7}
        h={7}
        bg={originalDay.isAvailable ? "green.300" : "red.300"}
        borderRadius='8px'
        onClick={() => toggleAvailable(originalDay.calendarId)}
      >
        B
      </Center>
      <Center
        position='absolute'
        top={4}
        left={4}
        minW={7}
        h={7}
        borderRadius='8px'
      >
        {day.status}
      </Center>
    </Box>
  )
}
