import { Icon, IconProps } from "@chakra-ui/react"

const ListingsIcon = (props: IconProps) => (
  <Icon width='24px' height='20px' viewBox='0 0 24 20' fill='none' {...props}>
    <g clipPath='url(#clip0_1322_379)'>
      <path
        d='M15 1H3C1.89543 1 1 1.71634 1 2.6V15.4C1 16.2837 1.89543 17 3 17H15C16.1046 17 17 16.2837 17 15.4V2.6C17 1.71634 16.1046 1 15 1Z'
        stroke='#414141'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 17V13H12V17'
        stroke='#414141'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 5H9.01'
        stroke='#414141'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 9H9.01'
        stroke='#414141'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13 5H13.01'
        stroke='#414141'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13 9H13.01'
        stroke='#414141'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 5H5.01'
        stroke='#414141'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 9H5.01'
        stroke='#414141'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1322_379'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </Icon>
)

export default ListingsIcon
