import { Icon } from "@chakra-ui/react"

const SignOutIcon = () => (
  <Icon width='24px' height='20px' viewBox='0 0 24 20' fill='none'>
    <g clipPath='url(#clip0_1319_5101)'>
      <mask
        id='mask0_1319_5101'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='18'
      >
        <path d='M18 0H0V18H18V0Z' fill='white' />
      </mask>
      <g mask='url(#mask0_1319_5101)'>
        <path
          d='M17.7771 8.56901L15.8511 7.21101C15.7721 7.15563 15.6794 7.12297 15.5831 7.11657C15.4869 7.11017 15.3907 7.13028 15.305 7.1747C15.2194 7.21913 15.1475 7.28617 15.0973 7.36856C15.0471 7.45094 15.0204 7.54552 15.0201 7.64201V8.47201H5.77311C5.6332 8.47201 5.49903 8.52758 5.40011 8.62651C5.30118 8.72543 5.24561 8.8596 5.24561 8.99951C5.24561 9.13941 5.30118 9.27358 5.40011 9.3725C5.49903 9.47143 5.6332 9.52701 5.77311 9.52701H15.0201V10.357C15.0204 10.4535 15.0471 10.5481 15.0973 10.6305C15.1475 10.7128 15.2194 10.7799 15.305 10.8243C15.3907 10.8687 15.4869 10.8888 15.5831 10.8824C15.6794 10.876 15.7721 10.8434 15.8511 10.788L17.7771 9.43001C17.8458 9.3812 17.9019 9.31665 17.9405 9.24176C17.9792 9.16686 17.9994 9.0838 17.9994 8.99951C17.9994 8.91521 17.9792 8.83215 17.9405 8.75725C17.9019 8.68236 17.8458 8.61781 17.7771 8.56901Z'
          fill='#414141'
        />
        <path
          d='M15.1342 12.386C15.0742 12.3512 15.008 12.3285 14.9393 12.3193C14.8706 12.3102 14.8008 12.3146 14.7338 12.3324C14.6669 12.3503 14.6041 12.3811 14.549 12.4233C14.494 12.4654 14.4478 12.518 14.4132 12.578C13.6254 13.9427 12.4093 15.0094 10.9535 15.6125C9.49772 16.2156 7.8836 16.3215 6.36151 15.9137C4.83941 15.5059 3.49441 14.6073 2.53512 13.3571C1.57583 12.107 1.05586 10.5753 1.05586 8.9995C1.05586 7.42373 1.57583 5.892 2.53512 4.64187C3.49441 3.39175 4.83941 2.49311 6.36151 2.08533C7.8836 1.67755 9.49772 1.78343 10.9535 2.38654C12.4093 2.98965 13.6254 4.05628 14.4132 5.421C14.4832 5.54207 14.5984 5.63037 14.7335 5.66647C14.8687 5.70257 15.0126 5.68352 15.1337 5.6135C15.2547 5.54348 15.343 5.42824 15.3791 5.29312C15.4152 5.158 15.3962 5.01407 15.3262 4.893C14.4222 3.32726 13.0268 2.10356 11.3565 1.41168C9.68613 0.719802 7.83417 0.598418 6.08781 1.06635C4.34145 1.53429 2.7983 2.56539 1.69768 3.99974C0.59706 5.4341 0.000488281 7.19154 0.000488281 8.9995C0.000488281 10.8075 0.59706 12.5649 1.69768 13.9993C2.7983 15.4336 4.34145 16.4647 6.08781 16.9327C7.83417 17.4006 9.68613 17.2792 11.3565 16.5873C13.0268 15.8954 14.4222 14.6717 15.3262 13.106C15.3961 12.9851 15.4152 12.8413 15.3792 12.7062C15.3432 12.5712 15.2551 12.456 15.1342 12.386Z'
          fill='#414141'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1319_5101'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </Icon>
)

export default SignOutIcon
