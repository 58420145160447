import { ChangeEvent, useCallback, useState } from "react"

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Checkbox,
  Link,
  Text,
  Button,
  Box,
} from "@chakra-ui/react"

import { useAppDispatch, useAppSelector } from "app/hooks"
import { apiClient } from "services/client"
import {
  selectInviteCode,
  selectIsTandCModalOpen,
  setInviteTerms,
  setIsTandCModalOpen,
} from "store/auth/authSlice"

const TandCModal = () => {
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(selectIsTandCModalOpen)
  const inviteCode = useAppSelector(selectInviteCode)

  const [isChecked, setIsChecked] = useState<boolean>(false)

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
  }, [])

  const handleClose = useCallback(() => {
    dispatch(setIsTandCModalOpen(false))
  }, [dispatch])

  const handleContinue = () => {
    if (inviteCode) {
      apiClient
        .acceptTermsConditions({
          invCode: inviteCode,
          isTrmsncndAccepted: true,
          // TODO :  make trmsncndAcceptedVersion dynamic in old host it is comming from .env file (discuss).
          trmsncndAcceptedVersion: "1.1",
        })
        .then(() => {
          dispatch(setInviteTerms(true))
          handleClose()
          return true
        })
        .catch((err) => console.error(err))
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody>
          <Checkbox
            alignItems='flex-start'
            isChecked={isChecked}
            onChange={handleChange}
          >
            <Text size='md' mt='-1'>
              I agree that I am 18 years of age or older and accept your&nbsp;
              <Link
                href='https://whimstay.com/terms'
                fontWeight='bold'
                color='#0D6F81'
                isExternal
              >
                Terms of Service
              </Link>
            </Text>
          </Checkbox>
          <Box w='100%' display='flex' justifyContent='center'>
            <Button
              disabled={!isChecked}
              onClick={handleContinue}
              mt='10px'
              alignSelf='center'
            >
              Continue
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TandCModal
