import { AxiosInstance, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "services/client"

export interface GetDeviceIdResponse extends AxiosResponse {
  data: string
}

const getDeviceIdURL = `${process.env.REACT_APP_BASE_API_URL || ""}/device-id`

export function getDeviceIdApi(
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetDeviceIdResponse> {
  return axiosInstance({
    method: "get",
    url: getDeviceIdURL,
  }) as Promise<GetDeviceIdResponse>
}
