import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import {
  GetLivePropertiesData,
  GetLivePropertiesRequestData,
  PropertyData,
  PublishPropertiesRequestData,
  ResponseData,
  DeletePropertiesRequestData,
  CheckPublishPropertiesStatusRequestData,
  CheckPublishPropertiesStatusData,
  PmcDefaults,
  InactivePropertiesRequestData,
  AddingTagRequestData,
  CompanyStatusRequestData,
  CompanyTagResponse,
  AddingCustomTagRequestData,
  GetMinimumAgePropertiesRequestData,
  GetMinimumAgePropertiesData,
} from "./common"

export interface PropertyRequest extends Partial<AxiosRequestConfig> {
  params: Partial<PropertyData>
}

export interface AutoPublishPropertyRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    pmcId: string
    isAutoPublish: boolean
  }
}

export interface PropertyResponseData extends ResponseData {
  data: PropertyData
}

export interface PropertyResponse extends AxiosResponse {
  data: PropertyResponseData
}

export interface GetLivePropertiesRequest extends Partial<AxiosRequestConfig> {
  params: GetLivePropertiesRequestData
}

export interface PublishPropertiesRequest extends Partial<AxiosRequestConfig> {
  data: PublishPropertiesRequestData
}
export interface CompanyStatusRequest extends Partial<AxiosRequestConfig> {
  params: CompanyStatusRequestData
}
export interface InactivePropertiesRequest extends Partial<AxiosRequestConfig> {
  data: InactivePropertiesRequestData
}
export interface GetMinimumAgePropertiesRequest
  extends Partial<AxiosRequestConfig> {
  params: GetMinimumAgePropertiesRequestData
}
export interface AddingTagRequest extends Partial<AxiosRequestConfig> {
  data: AddingTagRequestData
}

export interface AddingCustomTagRequest extends Partial<AxiosRequestConfig> {
  data: AddingCustomTagRequestData
}

export interface CheckPublishPropertiesStatusRequest
  extends Partial<AxiosRequestConfig> {
  data: CheckPublishPropertiesStatusRequestData
}
export interface DeletePropertiesRequest extends Partial<AxiosRequestConfig> {
  data: DeletePropertiesRequestData
}

export interface GetLivePropertiesResponseData extends ResponseData {
  data: GetLivePropertiesData
}

export interface GetLivePropertiesResponse extends AxiosResponse {
  data: GetLivePropertiesResponseData
}

export interface PublishPropertiesResponse extends AxiosResponse {
  data: ResponseData
}
export interface CompanyStatusResponse extends AxiosResponse {
  data: ResponseData
}
export interface InactivePropertiesResponse extends AxiosResponse {
  data: ResponseData
}
export interface GetMinimumAgePropertiesResponseData extends ResponseData {
  data: GetMinimumAgePropertiesData
}
export interface GetMinimumAgePropertiesResponse extends AxiosResponse {
  data: GetMinimumAgePropertiesResponseData
}
export interface AddingTagResponse extends AxiosResponse {
  data: ResponseData
}

export interface AddingCompanyTagResponse extends AxiosResponse {
  data: ResponseData
}

export interface CheckPublishPropertiesStatusResponseData extends ResponseData {
  data: CheckPublishPropertiesStatusData
}

export interface CheckPublishPropertiesStatusResponse extends AxiosResponse {
  data: CheckPublishPropertiesStatusResponseData
}

export interface DeletePropertiesResponse extends AxiosResponse {
  data: ResponseData
}

const defaultUrl = `${process.env.REACT_APP_BASE_API_URL || ""}/properties/all`

export function getLiveProperties(
  { params, method = "get", url = defaultUrl, ...config }: PropertyRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetLivePropertiesResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetLivePropertiesResponse>
}

const defaultPublishUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/properties/publish/all`

export function publishProperties(
  { method = "put", url = defaultPublishUrl, ...config },
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<PublishPropertiesResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<PublishPropertiesResponse>
}

const defaultAutoPublishUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/properties/auto-publish-property`

export function autoPublishProperties(
  {
    params,
    method = "get",
    url = `${defaultAutoPublishUrl}?pmcId=${
      params.pmcId
    }&isAutoPublished=${params.isAutoPublish.toString()}`,
    ...config
  }: AutoPublishPropertyRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<PublishPropertiesResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<PublishPropertiesResponse>
}

const defaultCompanyStatusUrl = `${process.env.REACT_APP_BASE_API_URL || ""}`

export function companyStatus(
  {
    params,
    method = "post",
    url = `${defaultCompanyStatusUrl}/host/company-status`,
    ...config
  }: CompanyStatusRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<CompanyStatusResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<CompanyStatusResponse>
}

const defaultInactiveUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/properties/hide/all`

export function inactiveProperties(
  { method = "post", url = defaultInactiveUrl, ...config },
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<PublishPropertiesResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<PublishPropertiesResponse>
}

const defaultMinimumAgeUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/minimum-age/all`

export function getMinimumAgeProperties(
  {
    params,
    method = "get",
    url = defaultMinimumAgeUrl,
    ...config
  }: PropertyRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetMinimumAgePropertiesResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetMinimumAgePropertiesResponse>
}

export interface UpdateMinimumAgeResponse extends AxiosResponse {
  data: {
    apiStatus: string
    message: string
  }
}

export interface UpdateMinimumAgeRequest extends Partial<AxiosRequestConfig> {
  params: {
    minAge: number
    minAgeTemplate: string
    isAllUpdate: boolean
    pmcId: string
    propertyDetails: {
      propertyId: string
      active: boolean
    }[]
    removeAll: boolean
  }
}

export function updateMinimumAge(
  {
    params,
    method = "put",
    url = `${
      process.env.REACT_APP_BASE_API_URL || ""
    }/minimum-age/update-min-age?minAge=${params.minAge}&minAgeTemplate=${
      params.minAgeTemplate
    }`,
    ...config
  }: UpdateMinimumAgeRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<UpdateMinimumAgeResponse> {
  const propertyDetailsPayload = params.propertyDetails.map(
    ({ propertyId, active }) => ({
      propertyId,
      active,
    }),
  )
  return axiosInstance({
    data: {
      isAllUpdate: params.isAllUpdate,
      pmcId: params.pmcId,
      propertyDetails: propertyDetailsPayload,
      removeAll: params.removeAll,
    },
    method,
    url,
    ...config,
  }) as Promise<UpdateMinimumAgeResponse>
}

const defaultAddingTagUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/properties/custom-tag`

export function addingTag(
  { method = "post", url = defaultAddingTagUrl, ...config },
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AddingTagResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<AddingTagResponse>
}

const defaultAddingCustomTagUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/admin/company`

export function addingCustomTag(
  { method = "post", url = defaultAddingCustomTagUrl, ...config },
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AddingCompanyTagResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<AddingCompanyTagResponse>
}

const defaultDeleteUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/properties`

export function deleteProperties(
  { method = "delete", url = defaultDeleteUrl, ...config },
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<DeletePropertiesResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<DeletePropertiesResponse>
}

const defaultCheckStatusUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/properties/publish/check`

export function checkPublishPropertiesStatus(
  { method = "post", url = defaultCheckStatusUrl, ...config },
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<CheckPublishPropertiesStatusResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<CheckPublishPropertiesStatusResponse>
}

export interface SyncPropertiesRequest extends Partial<AxiosRequestConfig> {
  params: {
    pmcId: string
    propertyIds: string[]
  }
}
export interface SyncPropertiesResponse extends AxiosResponse {
  data: ResponseData
}
export function syncProperties(
  {
    params,
    method = "post",
    url = `${
      process.env.REACT_APP_BASE_API_URL || ""
    }/properties/save-prod-to-stage`,
    ...config
  }: SyncPropertiesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<SyncPropertiesResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<SyncPropertiesResponse>
}

export interface PmcDefaultCheckRequest extends Partial<AxiosRequestConfig> {
  params: {
    pmcId: string
  }
}
export interface PmcDefaultCheckResponseData extends ResponseData {
  data: PmcDefaults
}
export interface PmcDefaultCheckResponse extends AxiosResponse {
  data: PmcDefaultCheckResponseData
}
// to check if current pmc have default contact template and bank account.
export function pmcDefaultCheck(
  {
    params,
    method = "get",
    url = `${process.env.REACT_APP_BASE_API_URL || ""}/host/pmc/tag`,
    ...config
  }: PmcDefaultCheckRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<PmcDefaultCheckResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<PmcDefaultCheckResponse>
}

export interface GetAdminCompanyRequest extends Partial<AxiosRequestConfig> {
  params: {
    pmcId: string
  }
}
export interface GetAdminCompanyResponseData extends ResponseData {
  data: CompanyTagResponse
}
export interface GetAdminCompanyResponse extends AxiosResponse {
  data: GetAdminCompanyResponseData
}

export function getCompany(
  {
    params,
    method = "get",
    url = `${process.env.REACT_APP_BASE_API_URL || ""}/admin/company`,
    ...config
  }: GetAdminCompanyRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetAdminCompanyResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetAdminCompanyResponse>
}

export interface GetStatesResponseData {
  apiStatus: string
  data: string[]
}

export interface GetStatesResponse extends AxiosResponse {
  data: GetStatesResponseData
}

export interface GetStatesRequest extends Partial<AxiosRequestConfig> {
  params: {
    pmcId: string
  }
}

export function getStates(
  {
    params,
    method = "post",
    url = `${
      process.env.REACT_APP_BASE_API_URL || ""
    }/properties/get-states?pmcId=${params.pmcId}`,
    ...config
  }: GetStatesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetStatesResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<GetStatesResponse>
}

export interface UpdateWeightResponse extends AxiosResponse {
  data: {
    apiStatus: string
    message: string
  }
}

export interface UpdateWeightRequest extends Partial<AxiosRequestConfig> {
  params: {
    pmcId: string
    weight: number
    pmsUnitIds: string[]
  }
}

export function updateWeight(
  {
    params,
    method = "post",
    url = `${
      process.env.REACT_APP_BASE_API_URL || ""
    }/properties/update-weight`,
    ...config
  }: UpdateWeightRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<UpdateWeightResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<UpdateWeightResponse>
}
