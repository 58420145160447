import { ReactNode } from "react"

import { TableRowProps, Tr } from "@chakra-ui/react"

type CustomTableRowProps = {
  children: ReactNode
  isActive?: boolean
  selectable?: boolean
}
const CustomTableRow = (props: TableRowProps & CustomTableRowProps) => {
  const { children, isActive, selectable, ...rest } = props
  return (
    <Tr
      _hover={selectable ? { bg: "#f6f6f6" } : {}}
      bg={isActive ? "#f0f0f0" : "initial"}
      cursor={selectable ? "pointer" : "auto"}
      {...rest}
    >
      {children}
    </Tr>
  )
}

export default CustomTableRow
