import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import {
  AttachmentData,
  AttachmentRequestData,
  GetAllAttachmentsRequestData,
  PropImages,
  RemoveAttachmentRequestData,
  ReorderAttachmentRequestData,
  ResponseData,
  UpdateAttachmentRequestData,
  UploadVideoRequestData,
} from "./common"

export interface AttachmentRequest extends Partial<AxiosRequestConfig> {
  params: AttachmentRequestData
}

export interface AttachmentResponseData extends ResponseData {
  data: AttachmentData
}

export interface AttachmentResponse extends AxiosResponse {
  data: AttachmentResponseData
}

export interface UpdateAttachmentRequest extends Partial<AxiosRequestConfig> {
  params: UpdateAttachmentRequestData
}

export interface RemoveAttachmentRequest extends Partial<AxiosRequestConfig> {
  params: RemoveAttachmentRequestData
}

export interface RemoveAttachmentResponseData extends ResponseData {
  data: string
}

export interface RemoveAttachmentResponse extends AxiosResponse {
  data: RemoveAttachmentResponseData
}

export interface ReorderAttachmentRequest extends Partial<AxiosRequestConfig> {
  params: ReorderAttachmentRequestData
}

export interface ReorderAttachmentResponseData extends ResponseData {
  data: string
}

export interface ReorderAttachmentResponse extends AxiosResponse {
  data: ReorderAttachmentResponseData
}

export interface GetAllAttachmentsRequest extends Partial<AxiosRequestConfig> {
  params: GetAllAttachmentsRequestData
}

export interface GetAllAttachmentsResponseData extends ResponseData {
  data: PropImages[]
}
export interface GetAllAttachmentsResponse extends AxiosResponse {
  data: GetAllAttachmentsResponseData
}
export interface UploadVideoRequest extends Partial<AxiosRequestConfig> {
  params: UploadVideoRequestData
  onUploadProgress: (progressEvent: { loaded: number; total: number }) => void
}
export interface UploadVideoResponseData extends ResponseData {
  data: {
    hostPopertyAttachmentId: number
    fileDescription: string
    fileName: string
    imgOrder: number
    isCoverImg: number
    pmsUnitId: string
    pmcId: string
    imageFullUrl: string
    imageThumbnailUrl: string
    video: boolean
    isHide: boolean
  }
}
export interface UploadVideoResponse extends AxiosResponse {
  data: UploadVideoResponseData
}

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/properties/attachments`

export function addAttachment(
  { params, method = "post", url = defaultUrl, ...config }: AttachmentRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AttachmentResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: 20000,
    ...config,
  }) as Promise<AttachmentResponse>
}

export function updateAttachment(
  {
    params,
    method = "put",
    url = defaultUrl,
    ...config
  }: UpdateAttachmentRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AttachmentResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: 20000,
    ...config,
  }) as Promise<AttachmentResponse>
}

export function removeAttachment(
  {
    params,
    method = "delete",
    url = defaultUrl,
    ...config
  }: RemoveAttachmentRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<RemoveAttachmentResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<RemoveAttachmentResponse>
}

export function reorderAttachment(
  {
    params,
    method = "post",
    url = `${defaultUrl}/order-change`,
    ...config
  }: ReorderAttachmentRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ReorderAttachmentResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<ReorderAttachmentResponse>
}

export function getAllAttachments(
  {
    params,
    method = "get",
    url = `${defaultUrl}/all`,
    ...config
  }: GetAllAttachmentsRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetAllAttachmentsResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetAllAttachmentsResponse>
}

export function uploadVideo(
  {
    params,
    onUploadProgress,
    method = "post",
    url = `${defaultUrl}/video`,
    ...config
  }: UploadVideoRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<UploadVideoResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
    timeout: 60 * 3 * 1000,
    ...config,
  }) as Promise<UploadVideoResponse>
}
