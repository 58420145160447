import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
} from "@chakra-ui/react"
import { format, parse } from "date-fns"

import CalendarAvailability from "pages/listing/components/calendar-availablity"
import CalendarPricing from "pages/listing/components/calendar-pricing"
import { CalendarDateListProps } from "pages/properties/types"

const getFormatedDate = (date: string) => {
  const temp = parse(date, "yyyy-MM-dd", new Date())
  const tempDate = format(temp, "dd MMM yyyy")
  return tempDate
}

// currently commented the things in this component which has possiblities to be used in future

const DateDetailsModal = ({
  multiples,
  selectedDateData,
  onClose,
  openDateModal,
}: {
  multiples: boolean
  selectedDateData: Array<CalendarDateListProps>
  onClose: () => void
  openDateModal: boolean
}) => {
  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  // const calendarCommonData = useAppSelector(getCalendarCommonData)
  // const currentPmc = useAppSelector(selectCurrentPmc)

  // const { properyId } = useParams()
  // const {
  //   register,
  //   formState: { errors, isValid },
  //   handleSubmit,

  //   reset,
  // } = useForm<CalendarSelectedDateValues>({
  //   mode: "all",
  //   reValidateMode: "onChange",
  //   defaultValues: useMemo(
  //     () => ({
  //       minstay: selectedDateData[0]?.minStayDays,
  //       price: selectedDateData[0]?.basePrice,
  //       discountPrice: selectedDateData[0]?.discountPrice,
  //       available: selectedDateData[0]?.available,
  //       arrival: selectedDateData[0]?.arrival,
  //       departure: selectedDateData[0]?.departure,
  //     }),
  //     [selectedDateData],
  //   ),
  // })
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   reset(selectedDateData[0])
  // }, [selectedDateData])

  // const getCalendarDetails = useCallback(() => {
  //   if (currentPmc?.pmc_id) {
  //     apiClient
  //       .getCalendarDates({
  //         data: {
  //           pmcId: currentPmc?.pmc_id?.toString(),
  //           pmsUnitId: properyId?.toString(),
  //           propertyId: properyId?.toString(),
  //           fromDate: getDateInYYYYMMddFormat(days[0]),
  //           toDate: getDateInYYYYMMddFormat(days[days.length - 1]),
  //         },
  //       })
  //       .then((res) => {
  //         const result = res.data.data.calendarList
  //         const BlockedDates = result
  //           .filter((item) => !item.available)
  //           .map((item) => item.date)
  //         dispatch(setCalendarDates(result))
  //         dispatch(setCalendarBlockedDates(BlockedDates))
  //         dispatch(
  //           setCalendarCommonData({
  //             ...calendarCommonData,
  //             basePrice: res.data.data.maximumPrice,
  //           }),
  //         )
  //         return true
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //   }
  // }, [])

  const designModalTitle = () => {
    if (multiples) {
      if (selectedDateData?.length <= 1) {
        return getFormatedDate(selectedDateData[0]?.date)
      }
      return `${getFormatedDate(
        selectedDateData[0]?.date,
      )} to ${getFormatedDate(
        selectedDateData[selectedDateData.length - 1]?.date,
      )}`
    }
    return getFormatedDate(selectedDateData[0]?.date)
  }

  // const onSubmit: SubmitHandler<CalendarSelectedDateValues> = (data) => {
  //   if (isValid && currentPmc?.pmc_id) {
  //     const reqData = selectedDateData.map((item) => ({
  //       date: item.date,
  //       basePrice: +data.price,
  //       discountPrice: item?.discountPrice || +data.discountPrice,
  //       available: data.available,
  //       arrival: data.arrival,
  //       departure: data.departure,
  //       minStayDays: +data.minstay,
  //     }))

  //     apiClient
  //       .updateCalendarDates({
  //         data: {
  //           pmcId: currentPmc?.pmc_id?.toString(),
  //           pmsUnitId: properyId?.toString(),
  //           propertyId: properyId?.toString(),
  //           fromDate: getDateInYYYYMMddFormat(days[0]),
  //           toDate: getDateInYYYYMMddFormat(days[days.length - 1]),
  //           calendarList: reqData,
  //         },
  //       })
  //       .then(() => {
  //         //  to get updatd data
  //         getCalendarDetails()
  //         setIsSubmitting(false)
  //         reset()
  //         onClose()
  //         return true
  //       })
  //       .catch((err) => {
  //         setIsSubmitting(false)
  //         reset()
  //         onClose()
  //         console.log(err)
  //       })
  //   }
  // }

  return (
    <Drawer
      isOpen={openDateModal}
      onClose={onClose}
      placement='right'
      size='md'
    >
      <DrawerContent>
        <DrawerHeader>{designModalTitle()}</DrawerHeader>
        <DrawerCloseButton onClick={onClose} />
        <DrawerBody>
          <Box>
            <CalendarPricing />
            <CalendarAvailability />
            {/* <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl display='flex' flexDir='column' gap={6}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Text size='lg3' fontWeight='500'>
                    Available
                  </Text>
                  <Switch size='md' id='available' {...register("available")} />
                </Box>
                <Box>
                  <Text size='lg3' fontWeight='500'>
                    Pricing
                  </Text>
                  <CalendarFormInput
                    icon='$'
                    id='price'
                    type='number'
                    placeholder='1'
                    defaultValue={selectedDateData[0]?.basePrice}
                    label='Nightly price'
                    error={errors.price}
                    {...register("price", {
                      validate: (x) => {
                        if (x < 1) {
                          return `Enter value greater than or equal to 1`
                        }
                        return undefined
                      },
                    })}
                    _placeholder={{ color: "#00000077" }}
                    _focus={{
                      outline: "none",
                      outlineColor: "transparent",
                      boxShadow: "none",
                    }}
                    _hover={{}}
                    outlineOffset='0px'
                    outlineColor='transparent'
                  />
                </Box>
                <Box>
                  <Text size='lg3' fontWeight='500'>
                    Minimum stay
                  </Text>
                  <CalendarFormInput
                    id='nights'
                    placeholder='1'
                    defaultValue={selectedDateData[0]?.minStayDays}
                    type='number'
                    label='Nights'
                    error={errors.minstay}
                    {...register("minstay", {
                      validate: (x) => {
                        if (x < 1) {
                          return `Enter value greater than or equal to 1`
                        }
                        return undefined
                      },
                    })}
                    _placeholder={{ color: "#00000077" }}
                    _focus={{
                      outline: "none",
                      outlineColor: "transparent",
                      boxShadow: "none",
                    }}
                    _hover={{}}
                    outlineOffset='0px'
                    outlineColor='transparent'
                  />
                </Box>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Text size='lg3' fontWeight='500'>
                    Arrival
                  </Text>
                  <Switch size='md' id='arrival' {...register("arrival")} />
                </Box>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Text size='lg3' fontWeight='500'>
                    Departure
                  </Text>
                  <Switch size='md' id='departure' {...register("departure")} />
                </Box>

                <Box display='flex' my='4' justifyContent='flex-end'>
                  <Button
                    colorScheme='blue'
                    type='submit'
                    onClick={() => {
                      handleSubmit(onSubmit)
                      setIsSubmitting(true)
                    }}
                  >
                    {isSubmitting ? <Spinner /> : "Save"}
                  </Button>
                </Box>
              </FormControl>
            </form> */}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default DateDetailsModal
