// import { EditIcon } from "@chakra-ui/icons"
import { Box, Text, useMediaQuery } from "@chakra-ui/react"
import { format } from "date-fns"
import { useDispatch } from "react-redux"

import { CalendarDateData } from "services/common"
import { setCalendarCommonData } from "store/calendar/calendarSlice"

const getFormatedDate = (date: Date) => format(date, "yyyy-MM-dd")

// Day: component to show day in calendar

export interface Event {
  id?: string
  color: string
  title: string
  from: string
  to: string
  description: string
}

interface Properties {
  day: Date
  backgroundColor?: string
  text?: number
  handleMultipleDateClick: (day: string, multiples: boolean) => void
  handleSingleDateClick: (day: string) => void
  handleBookedDateClick: (day: string) => void
  handleMouse: (day: string) => void
  multiples: boolean
  bgColor: string
  selectedDatesList: string[]
  setSelectedDatesList: React.Dispatch<React.SetStateAction<string[]>>
  setMultiples: React.Dispatch<React.SetStateAction<boolean>>
  isLeftJoin?: boolean
  isRightJoin?: boolean
  isBooked?: boolean
  isWhimstay: boolean
  canEditDates?: boolean
  dayData?: CalendarDateData
}
export const Day = ({
  day,
  dayData,
  backgroundColor,
  text,
  handleMultipleDateClick,
  handleMouse,
  bgColor,
  handleSingleDateClick,
  handleBookedDateClick,
  setSelectedDatesList,
  selectedDatesList,
  multiples,
  setMultiples,
  isLeftJoin = false,
  isRightJoin = false,
  isBooked = false,
  isWhimstay,
  canEditDates = false,
}: Properties) => {
  const [isMobile] = useMediaQuery("(max-width: 1024px)")
  const dayNumber = String(day.getDate())
  const isPastDate = day < new Date()
  // const [mulitpledates, setMulitpledates] = useState<boolean>(false)
  const updateSelectedDatesList = (dy: Date) => {
    const result = getFormatedDate(dy).split("/").reverse().join("-").toString()
    if (!selectedDatesList.includes(result)) {
      setSelectedDatesList([...selectedDatesList, result])
    }
  }

  const dispatch = useDispatch()

  return (
    <Box
      pos='relative'
      w='full'
      h='full'
      display='flex'
      justifyContent='flex-start'
      alignItems='center'
      flexDir='column'
      borderRight='1px solid #E1E1E1'
      borderBottom='1px solid #E1E1E1'
      height={{ base: "70px", md: "130px" }}
      opacity={isPastDate || !dayData?.available ? "0.5" : "1"}
      pointerEvents={isPastDate || !dayData?.available ? "none" : "auto"}
      cursor='pointer'
      bg={bgColor}
      onMouseEnter={() => {
        if (multiples && isWhimstay) {
          handleMouse(getFormatedDate(day))
        }
      }}
      onClick={(e) => {
        e.preventDefault()
        handleSingleDateClick(getFormatedDate(day))

        dispatch(
          setCalendarCommonData({
            date: getFormatedDate(day),
            basePrice: text || 0,
            arrival: dayData?.arrival || false,
            departure: dayData?.departure || false,
            available: dayData?.available || false,
            discountPrice: dayData?.djs_discount || 0,
            minStayDays: dayData?.min_nights || 0,
          }),
        )
        if (isWhimstay && canEditDates) {
          if (isBooked) {
            handleBookedDateClick(getFormatedDate(day))
            handleSingleDateClick(getFormatedDate(day))
          } else {
            handleMouse(getFormatedDate(day))
            updateSelectedDatesList(day)
            handleMultipleDateClick(getFormatedDate(day), multiples)
            handleSingleDateClick(getFormatedDate(day))
            setMultiples(true)
          }
        }
        e.stopPropagation()
      }}
      _hover={{ bg: "rgba(182, 227, 136,0.2)" }}
    >
      {/* <Box
        pos='absolute'
        w={{ base: "full", md: "2.5rem" }}
        h={{ base: "2rem", md: "2.5rem" }}
        top='0'
        right='0'
      >
        <IconButton
          aria-label='single-date-edit'
          variant='unstyled'
          pos='relative'
          display='flex'
          alignItems='center'
          justifyContent='center'
          w='full'
          h='full'
          // as='button'
          onClick={(e) => {
            e.preventDefault()
            if (isWhimstay && canEditDates) {
              if (isBooked) {
                handleBookedDateClick(getFormatedDate(day))
              } else {
                handleMouse(getFormatedDate(day))
                // edit single date
                handleSingleDateClick(getFormatedDate(day))
              }
            }
            e.stopPropagation()
          }}
        >
          <EditIcon fontSize={{ base: "1.1rem", md: "1.2rem" }} />
        </IconButton>
      </Box> */}
      <Box
        w='full'
        position='relative'
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        zIndex='1'
        onClick={() => handleSingleDateClick}
      >
        <Box
          w='full'
          position='absolute'
          mt={backgroundColor ? `${isMobile ? "0px" : "20px"}` : "0px"}
          display='flex'
          alignItems='flex-end'
        >
          {/* to extend the box on left */}
          <Box
            bg={isLeftJoin ? backgroundColor : ""}
            left={0}
            w={{ base: "40%", md: "50%" }}
            height={{ base: "36px", md: "37px" }}
          />
          {/* to extend the box on right */}
          <Box
            bg={isRightJoin ? backgroundColor : ""}
            position='absolute'
            right={0}
            w={{ base: "40%", md: "50%" }}
            height={{ base: "36px", md: "37px" }}
          />
        </Box>
        <Text
          display='flex'
          justifyContent='center'
          alignItems='center'
          size={{ base: "sm", sm: "md", md: "sm" }}
          fontWeight='500'
          height={{ base: "36px", md: "37px" }}
          width={{ base: "32px", md: "38px" }}
          bg={backgroundColor}
          mt={backgroundColor ? `${isMobile ? "0px" : "20px"}` : "0px"}
          color={backgroundColor ? "white" : ""}
          borderRadius='5px'
          zIndex={1000}
          top={0}
          textDecoration={dayData?.available ? "" : "line-through"}
        >
          {dayNumber}
        </Text>
      </Box>
      <Text
        fontSize={{ sm: "10px", md: "20px" }}
        fontWeight='500'
        mt={{ base: "10px", md: "45px" }}
        justifyContent='center'
      >
        ${text}
      </Text>
    </Box>
  )
}
