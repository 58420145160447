import { Suspense, SyntheticEvent, useCallback, useState } from "react"

import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react"

import { useAppDispatch, useAppSelector } from "app/hooks"
import loginLogo from "assets/images/login_logo.png"
import useToastHook from "hooks/useToastHook/useToastHook"
import {
  selectAuthError,
  selectForgotPasswordForm,
  setAuthError,
  setForgotPasswordForm,
  verifyResetPasswordLink,
} from "store/auth/authSlice"

const ForgotPasswordForm = () => {
  const dispatch = useAppDispatch()
  const displayToast = useToastHook()
  const isForgotPassword = useAppSelector(selectForgotPasswordForm)
  const authError = useAppSelector(selectAuthError)
  const [error, setError] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [isDisabled, setIsDisabled] = useState(true)
  const [IsSubmited, setIsSubmited] = useState<boolean>(false)

  const handleOnChange = (value: string) => {
    const emailRegex = /^[\w.%+-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/
    if (emailRegex.test(value)) {
      setEmail(value)
      setIsDisabled(false)
      setError("")
      dispatch(setAuthError(""))
      setIsSubmited(false)
    } else {
      setError("Email entered is invalid. Please try again.")
      setIsDisabled(true)
      setIsSubmited(true)
    }
  }

  const onClickResetPassword = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault()
      setIsSubmited(true)

      try {
        const res = await dispatch(
          verifyResetPasswordLink({
            params: { email },
          }),
        ).unwrap()

        if (res.apiStatus === "SUCCESS") {
          displayToast({
            message: res.message as string,
            variant: "success",
          })
        }
        setIsSubmited(false)
        dispatch(setForgotPasswordForm(false))
        // eslint-disable-next-line no-empty
      } catch (rejectedValueOrSerializedError) {}
    },
    [dispatch, displayToast, email],
  )

  return (
    <Modal
      isOpen={isForgotPassword}
      onClose={() => {
        dispatch(setForgotPasswordForm(false))
      }}
      size={{ base: "full", md: "md" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        minW={{ base: "auto", md: "650px" }}
        w='full'
        height={{ base: "100%", md: "auto" }}
        maxH={{ base: "100%", md: "550px" }}
        overflow={{ base: "auto", md: "hidden" }}
        borderRadius={{ sm: "0px", mxd: "42px !important" }}
      >
        <ModalCloseButton
          color='#006f80'
          size='24px'
          mt={{ base: "50px", md: "10px" }}
          mr={{ base: "50px", md: "10px" }}
          _hover={{ backgroundColor: "none", transform: "rotate(90deg)" }}
        />

        <HStack alignItems='center' h='550px' spacing={0} overflowY='auto'>
          <Box w='40%' h='inherit' display={{ base: "none", md: "block" }}>
            <Image
              src={`${
                process.env.REACT_APP_BASENAME || ""
              }/images/auth-banner.jpg`}
              w='full'
              h='full'
              alt='auth-banner'
              objectFit='cover'
            />
          </Box>

          <ModalBody w={{ base: "100%", md: "60%" }} h='full' paddingX='40px'>
            <Center w='full' h='full' mx='auto' flexDirection='column'>
              <Image src={loginLogo} alt='login-logo' />

              <Heading
                as='h3'
                fontWeight='normal'
                fontSize='16px'
                display='flex'
                pt='20px'
                alignItems='baseline'
                fontFamily='Montserrat,sans-serif'
                marginBottom='5px'
              >
                Welcome to the&nbsp;
                <Text fontWeight='semibold' color='#606161'>
                  Whimstay
                </Text>
              </Heading>

              <Text
                fontSize='15px'
                fontWeight={300}
                mb='15px'
                fontFamily='Montserrat,sans-serif'
              >
                Enter the email address associated with your account, and we’ll
                email you a link to reset your password.
              </Text>

              <Suspense
                fallback={
                  <Center p={4}>
                    <Spinner />
                  </Center>
                }
              >
                <Box>
                  <InputGroup size='md'>
                    <Input
                      type='email'
                      height='60px'
                      width='100%'
                      border='2px solid #006f80'
                      pr='4.5rem'
                      placeholder='Email'
                      onChange={(event) => handleOnChange(event.target.value)}
                    />
                  </InputGroup>
                  {error && (
                    <Text fontSize='13px' fontWeight='500' color='red'>
                      {error}
                    </Text>
                  )}
                  <Text
                    fontSize='12px'
                    my='25px'
                    fontFamily='Montserrat,sans-serif'
                  >
                    By creating an account, I agree to the Whimstay{" "}
                    <Link
                      href='https://whimstay.com/terms'
                      color='#0D6F81'
                      isExternal
                    >
                      Terms and Conditions{" "}
                    </Link>
                    and{" "}
                    <Link
                      href='https://whimstay.com/privacy'
                      color='#0D6F81'
                      isExternal
                    >
                      Privacy{" "}
                    </Link>
                    Statement and provide my consent to receive communications
                    from Whimstay.
                  </Text>
                  {authError && (
                    <Text fontSize='13px' fontWeight='500' color='red'>
                      {authError}
                    </Text>
                  )}
                  <Button
                    m='auto'
                    display='flex'
                    fontSize='18px'
                    height='45px'
                    type='submit'
                    disabled={isDisabled || IsSubmited}
                    onClick={onClickResetPassword}
                  >
                    Send reset link
                  </Button>
                </Box>
              </Suspense>
            </Center>
          </ModalBody>
        </HStack>
      </ModalContent>
    </Modal>
  )
}

export default ForgotPasswordForm
