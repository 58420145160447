import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "services/client"
import { ResponseData } from "services/common"

export interface ResetPasswordRequest extends Partial<AxiosRequestConfig> {
  params: {
    email: string
  }
}

export interface ResetPasswordResponseData extends ResponseData {
  data: {
    message: string
    status: number
  }
}
export interface ResetPasswordResponse extends AxiosResponse {
  data: ResetPasswordResponseData
}

export const defaultUrl = `${process.env.REACT_APP_BASE_API_URL || ""}`

export function resetPasswordLink(
  { params, method = "get", ...config }: ResetPasswordRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ResetPasswordResponse> {
  const url = `${defaultUrl}/auth/password/reset?email=${params.email}`
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<ResetPasswordResponse>
}
