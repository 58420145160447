import { useEffect, useState } from "react"

import {
  Box,
  Text,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Input,
} from "@chakra-ui/react"

import { useAppSelector } from "app/hooks"
import { apiClient } from "services/client"
import { selectCurrentPmc } from "store/auth/authSlice"
import { selectProperty } from "store/property/propertySlice"

const CalendarAvailability = () => {
  const property = useAppSelector(selectProperty)
  const currentPmc = useAppSelector(selectCurrentPmc)
  const [connectLink, setConnectLink] = useState(
    "https://whimstay.com/calendar/ica203e0.ics",
  )

  const [calendarImportLink, setCalendarImportLink] = useState("")
  const [calendarImportLinkName, setCalendarImportLinkName] = useState("")

  useEffect(() => {
    apiClient
      .getExportCalendarLink({
        params: {
          pmcId: "whimstay_test_pmc",
          pmsUnitId: 2,
        },
      })
      .then((resp) => {
        setCalendarImportLink(resp?.data?.data?.importCalendarLink)
        setCalendarImportLinkName(resp?.data?.data?.icalLinkPlatFormName)
        setConnectLink(resp?.data?.data?.exportCalendarLink)
        return true
      })
      .catch((e) => {
        console.log(e)
      })
  }, [currentPmc?.pmc_id, property?.pmsUnitId])

  const handleSaveImportLink = () => {
    apiClient
      .setImportCalendarLink({
        params: {
          pmcId: "whimstay_test_pmc",
          pmsUnitId: 2,
          calendarLink: calendarImportLink,
          icalLinkPlatformName: calendarImportLinkName,
        },
      })
      .then((resp) => {
        console.log(resp)
        return true
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <Box pt='23px'>
      <Text
        fontSize={{ base: "20px", xl: "21px" }}
        fontWeight='semibold'
        lineHeight='25.6px'
      >
        Availability
      </Text>
      <Text
        mb='11px'
        mt='18px'
        fontSize='lg'
        fontWeight='semibold'
        lineHeight='22px'
      >
        Connect calendars
      </Text>
      <Box
        border='1px solid #00000033'
        borderRadius='16px'
        px={{ base: 4, xl: 5 }}
        py={{ base: 5, xl: 6 }}
      >
        <Accordion allowMultiple defaultIndex={[0]}>
          <AccordionItem border={0}>
            <AccordionButton
              p={0}
              _hover={{}}
              position='relative'
              color='#000'
              alignItems='flex-start'
              textAlign='left'
              justifyContent='space-between'
            >
              <Box flexGrow={1}>
                Connect to another website
                <Text fontSize='15px' lineHeight='18.29px' mb={2.5} mt='30px'>
                  Add this link to Airbnb or other website
                </Text>
                <Input
                  value={connectLink}
                  onChange={(e) => setConnectLink(e.target.value)}
                  onClick={(e) => e.stopPropagation()} // Prevent click from bubbling up to AccordionButton
                  px={3}
                  borderColor='#9B9B9B'
                  textColor='#242424'
                  fontFamily='roboto'
                  fontSize={{ base: 14, xl: 16 }}
                  height={12}
                  _focus={{
                    outline: "none",
                    borderColor: "#9B9B9B",
                    boxShadow: "none",
                  }}
                />
              </Box>
              <AccordionIcon
                fontSize='32px'
                position='absolute'
                top='-5px'
                right='-8px'
              />
            </AccordionButton>
            <AccordionPanel p='0' pb={2}>
              <Box pt={6}>
                <Text fontSize='15px' lineHeight='18.29px' mb={2.5}>
                  Airbnb calendar
                </Text>
                <Box borderRadius='8px'>
                  <Box>
                    <Input
                      placeholder='Calendar Url'
                      value={calendarImportLink}
                      onChange={(e) => setCalendarImportLink(e.target.value)}
                      borderColor='#9B9B9B'
                      textColor='#242424'
                      fontFamily='roboto'
                      fontSize={{ base: 14, xl: 16 }}
                      height='46.75px'
                      _focus={{
                        outline: "none",
                        boxShadow: "none",
                      }}
                    />
                    <Input
                      placeholder='Calendar Name'
                      value={calendarImportLinkName}
                      onChange={(e) =>
                        setCalendarImportLinkName(e.target.value)
                      }
                      borderColor='#9B9B9B'
                      textColor='#242424'
                      fontFamily='roboto'
                      fontSize={{ base: 14, xl: 16 }}
                      height='46.75px'
                      _focus={{
                        outline: "none",
                        boxShadow: "none",
                      }}
                    />
                  </Box>
                </Box>
                <Button
                  variant='ghost'
                  mt='22px'
                  textColor='primaryColor'
                  p={0}
                  fontWeight={600}
                  fontSize='md'
                  height='19.5px'
                  onClick={handleSaveImportLink}
                  float='right'
                  disabled={!calendarImportLink || !calendarImportLinkName}
                >
                  save
                </Button>
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  )
}

export default CalendarAvailability
