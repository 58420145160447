import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
} from "@chakra-ui/react"
import { Link, useLocation, useParams } from "react-router-dom"

import { AccordianProps, MenuItem, SidebarTypeProps } from "./types"

const CustomAccordion = (props: AccordianProps) => {
  const { menuItem } = props
  const location = useLocation()
  const { properyId = " " } = useParams()

  return (
    <Accordion allowToggle allowMultiple defaultIndex={[0]}>
      <AccordionItem borderStyle='none'>
        <AccordionButton
          as={Button}
          p='0'
          pl={`${menuItem.depth * 10}px`}
          _hover={{}}
          my={1}
          variant='ghost'
          isActive={
            (menuItem.to && location.pathname.startsWith(menuItem.to)) ||
            location.hash === menuItem.to ||
            location.hash === `#${menuItem.id}`
          }
          w='full'
          h='22px'
          justifyContent='flex-start'
          fontSize='14px'
          textAlign='left'
          borderRadius='0px'
          borderLeft='3px solid transparent'
          borderColor={
            location.pathname.startsWith(
              menuItem.makeTo?.(properyId) || menuItem.to || " ",
            ) ||
            location.hash === menuItem.to ||
            location.hash === `#${menuItem.id}`
              ? "secondaryColor"
              : "transparent"
          }
        >
          <Box flex='1'>{menuItem.name}</Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel p='0' pb={2}>
          <Box w='full'>
            {menuItem.childMenu?.map((m: MenuItem) =>
              m?.childMenu && m.childMenu.length > 0 ? (
                <CustomAccordion key={m.name} menuItem={m} />
              ) : (
                <Button
                  as={Link}
                  to={m.makeTo?.(properyId) || m.to || " "}
                  key={m.name}
                  variant='ghost'
                  isActive={
                    location.pathname.startsWith(
                      m.makeTo?.(properyId) || m.to || " ",
                    ) ||
                    location.hash === m.to ||
                    location.hash === `#${m.id}`
                  }
                  w='full'
                  h='22px'
                  justifyContent='flex-start'
                  py={0}
                  pl={`${m.depth * 10}px`}
                  pr={0}
                  fontSize='14px'
                  textAlign='left'
                  borderRadius='0px'
                  borderLeft='3px solid transparent'
                  borderColor={
                    location.pathname.startsWith(
                      m.makeTo?.(properyId) || m.to || " ",
                    ) ||
                    location.hash === m.to ||
                    location.hash === `#${m.id}`
                      ? "secondaryColor"
                      : "transparent"
                  }
                >
                  {m.name}
                </Button>
              ),
            )}
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

const DesktopSideMenu = (props: SidebarTypeProps) => {
  const { sidebarMenuArr } = props
  const location = useLocation()
  const { properyId = " " } = useParams()

  return (
    <Box mt={4} w='full' borderLeft='1px solid #e1e1e1'>
      {sidebarMenuArr &&
        sidebarMenuArr.map((menuItem: MenuItem) =>
          menuItem.childMenu && menuItem.childMenu.length > 0 ? (
            <CustomAccordion key={menuItem.name} menuItem={menuItem} />
          ) : (
            <Button
              as={Link}
              to={menuItem.makeTo?.(properyId) || menuItem.to || " "}
              key={menuItem.name}
              my={1}
              variant='ghost'
              isActive={
                location.pathname.startsWith(
                  menuItem.makeTo?.(properyId) || menuItem.to || " ",
                ) ||
                location.hash === menuItem.to ||
                location.hash === `#${menuItem.id}`
              }
              w='full'
              h='22px'
              justifyContent='flex-start'
              py={0}
              pl={`${menuItem.depth * 10}px`}
              pr={0}
              fontSize='14px'
              textAlign='left'
              borderRadius='0px'
              borderLeft='3px solid transparent'
              borderColor={
                location.pathname.startsWith(
                  menuItem.makeTo?.(properyId) || menuItem.to || " ",
                ) ||
                location.hash === menuItem.to ||
                location.hash === `#${menuItem.id}`
                  ? "secondaryColor"
                  : "transparent"
              }
            >
              {menuItem.name}
            </Button>
          ),
        )}
    </Box>
  )
}

export default DesktopSideMenu
