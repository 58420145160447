import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { HouseRulesData, ResponseData } from "./common"

export interface HouseRulesRequest extends Partial<AxiosRequestConfig> {
  params: Partial<HouseRulesData>
}

export interface HouseRulesResponseData extends ResponseData {
  data: HouseRulesData
}

export interface HouseRulesResponse extends AxiosResponse {
  data: HouseRulesResponseData
}

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/properties/features`

export function addHouseRules(
  {
    params,
    method = "post",
    url = `${defaultUrl}/add-house-rules`,
    ...config
  }: HouseRulesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<HouseRulesResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<HouseRulesResponse>
}
