import { useEffect } from "react"

import { Box, Image, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "app/hooks"
import TryHostImage from "assets/images/try-host.png"
import SignUpModal from "components/signUp/signup-modal"
import { selectUserLoggedIn } from "store/auth/authSlice"
import { setIsSignupModalOpen } from "store/ui/uiSlice"
import { getAuthToken } from "utils/local-storage"

const mainTextSize = {
  base: "lg",
  md: "xxl",
  lg: "xxxl",
  xxxl: "xxxxl",
}

const SignUp = () => {
  const navigate = useNavigate()

  const isLoggedIn = useAppSelector(selectUserLoggedIn)

  const dispatch = useAppDispatch()
  const authToken = getAuthToken()

  useEffect(() => {
    if (!authToken) {
      dispatch(setIsSignupModalOpen(true))
    } else {
      navigate("/")
    }
  }, [isLoggedIn, navigate, dispatch, authToken])
  return (
    <Box as='main' width='full'>
      <Box position='relative'>
        <Box w='full' h='full'>
          <Image
            src={TryHostImage}
            w='full'
            h='full'
            alt='try host'
            objectFit='cover'
          />
        </Box>
        <Box
          position='absolute'
          left={{ base: "5%", sm: "10%" }}
          top='35%'
          textAlign='start'
        >
          <Text
            color='#FFFFFF'
            size={mainTextSize}
            fontWeight={600}
            as='h1'
            fontFamily='Montserrat,sans-serif'
          >
            Welcome to <br />
            Whimstay&apos;s Host Site
          </Text>

          {/* {!isLoggedIn && (
            <Button
              mt={{ base: "10px" }}
              size={{ base: "xs", md: "md", lg: "lg" }}
              onClick={() => dispatch(setIsLoginModalOpen(true))}
            >
              Try hosting
            </Button>
          )} */}
        </Box>
      </Box>
      {/* <Box textAlign='center' pt={{ base: "20px", md: "30px" }}>
        <Text variant='black' size={mainTextSize} fontWeight={600}>
          Find out what you <br />
          could earn as a Host
        </Text>

        <Box
          mt={8}
          p={5}
          mx='auto'
          maxW='1280px'
          display='grid'
          gridTemplateColumns={{
            base: "1fr",
            md: "1fr 1fr",
            xl: "1fr 1fr 1fr",
          }}
          gap={5}
        >
          <StatCard
            digits='$356'
            lead='Hosts in your area earn an average of*'
            tail='/month'
            fontColor='#0D6F81'
          />
          <StatCard digits='$562' lead='They earn' tail='/night' />
          <StatCard digits={12} lead="They're booked" tail='nights/month' />
        </Box>
      </Box> */}
      {/* <Box mt={14} textAlign='center'>
        <Text mb={8} variant='black' size='xxl' fontWeight={600}>
          Tell us about your place
        </Text>
        <Image
          src='/images/try_banner.jpg'
          height='500px'
          mx='auto'
          objectFit='cover'
          alt='google map'
        />
      </Box> */}
      <SignUpModal />
    </Box>
  )
}
export default SignUp
