import { format as formatDate } from "date-fns"
import isMobilePhone from "validator/lib/isMobilePhone"
import isURL from "validator/lib/isURL"

import { CurrencyType } from "services/common"

// function to get number with two decimal points
export const getFormattedNumber = (value: number) =>
  parseFloat(parseFloat(`${value}`).toFixed(2)).toLocaleString("en-IN", {
    useGrouping: true,
  })

export const getCurrencySymbol = (value?: CurrencyType) => {
  const SYMBOLS = {
    USD: "$", // US Dollar
    EUR: "€", // Euro
    CRC: "₡", // Costa Rican Colón
    GBP: "£", // British Pound Sterling
    ILS: "₪", // Israeli New Sheqel
    INR: "₹", // Indian Rupee
    JPY: "¥", // Japanese Yen
    KRW: "₩", // South Korean Won
    NGN: "₦", // Nigerian Naira
    PHP: "₱", // Philippine Peso
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    THB: "฿", // Thai Baht
    UAH: "₴", // Ukrainian Hryvnia
    VND: "₫", // Vietnamese Dong
  }
  return value ? SYMBOLS[value] : ""
}

export enum DownloadFormats {
  xlsx = ".xlsx",
  pdf = ".pdf",
  html = ".html",
}

type DownloadFileFromBlobDataType = {
  fileName: string
  format: string
  data: BlobPart
}

export const downloadFileFromBlobData = ({
  fileName,
  format,
  data,
}: DownloadFileFromBlobDataType) => {
  const urlll = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement("a")
  link.href = urlll
  link.setAttribute(
    "download",
    `${fileName}${DownloadFormats[format as keyof typeof DownloadFormats]}`,
  )
  document.body.appendChild(link)
  link.click()
}

export const isValidURL = (str: string) => {
  if (!isURL(str)) {
    return "Please enter valid link"
  }
  return true
}

export const isValidPhone = (str: string) => {
  if (!isMobilePhone(str)) {
    return "Please enter valid phone number"
  }
  return true
}

export const isValidDate = (date: string | Date) => {
  const eighteenYearsAgo = new Date()
  const minDate = new Date(
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18),
  )
  if (new Date(date) > minDate) {
    return `Date should not be after ${formatDate(minDate, "MM/dd/yyyy")}`
  }
  return true
}

export const isValidSsn = (ssn: string) => {
  if (ssn.length < 4 || Number.isNaN(+ssn)) {
    return `Please enter 4 digit SSN number`
  }
  return true
}

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const isOlderThen30 = (time: number) => {
  const pastTime = time
  const now = new Date()

  const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000

  const timeDiffInMs = now.getTime() - pastTime

  if (timeDiffInMs >= thirtyDaysInMs) {
    return true
  }
  return false
}

export enum UserRole {
  ADMIN = "Whimstay Admin",
  HOST_OWNER = "Owner",
  HOST_ADMIN = "Admin",
  HOST_ACCOUNTANT = "Accountant",
  HOST_RESERVATIONIST = "Reservationist",
  TRAVELLER = "Traveller",
}

export const customSlugify = (word: string) =>
  word
    .replace(/[^a-zA-Z\d ]/g, "")
    .trim()
    .replace(/ +/g, "-") || "Whimstay"

export const contactReason: { name: string; id: string }[] = [
  {
    name: "Pricing",
    id: "Pricing",
  },
  {
    name: "Payout",
    id: "Payout",
  },
  {
    name: "Integration",
    id: "Integration",
  },
  {
    name: "Bookings",
    id: "Bookings",
  },
  {
    name: "Host Site",
    id: "Host Site",
  },
  {
    name: "New listings",
    id: "New listings",
  },
  {
    name: "Other",
    id: "Other",
  },
]

export function formatCurrencySymbol(
  currencySymbol: string,
  amount: number | string,
) {
  return currencySymbol?.replace("@@amount@@", `${amount}`)
}
