import { Checkbox, CheckboxProps } from "@chakra-ui/react"

const CustomCheckBox = (props: CheckboxProps) => (
  <Checkbox
    aria-label='checkbox'
    iconColor='white'
    borderColor='#e1e1e1'
    sx={{
      "[data-checked]": {
        backgroundColor: "#0d6f81 !important",
        borderColor: "#0d6f81 !important",
      },
      "[data-checked]:hover": {
        backgroundColor: "#0d6f81 !important",
        borderColor: "#0d6f81 !important",
      },
      "[data-indeterminate]": {
        backgroundColor: "#0d6f81 !important",
        borderColor: "#0d6f81 !important",
      },
    }}
    _hover={{
      backgroundColor: "#transparent",
      borderColor: "#0d6f81",
    }}
    {...props}
  />
)

export default CustomCheckBox
