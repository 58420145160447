import { useCallback, useEffect, useRef, useState } from "react"

import {
  ChevronDownIcon,
  CloseIcon,
  HamburgerIcon,
  Search2Icon,
} from "@chakra-ui/icons"
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "app/hooks"
import ProfileMenu from "components/header/profile-menu"
import BookingsIcon from "components/icons/BookingsIcon"
import ContactUsIcon from "components/icons/contactUsIcon"
import FaqIcon from "components/icons/FaqIcon"
import ListingsIcon from "components/icons/ListingsIcon"
import PayoutSettingIcon from "components/icons/payoutSettingIcon"
import ProfileIcon from "components/icons/profileIcon"
import ReportsIcon from "components/icons/ReportsIcon"
import SignOutIcon from "components/icons/signOutIcon"
import Logo from "components/logo/logo"
import {
  logOut,
  selectCurrentPmc,
  selectPmc,
  selectUserLoggedIn,
  selectUserName,
  setCurrentActivePmc,
} from "store/auth/authSlice"
import { PmcState } from "store/auth/types"
import { setIsLoginModalOpen } from "store/ui/uiSlice"
import { getSelectedPMCId, setSelectedPMCId } from "utils/local-storage"

const SideDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isLoggedIn = useAppSelector(selectUserLoggedIn)
  const btnRef = useRef(null)
  const navigate = useNavigate()

  const handleNavigate = useCallback(
    (to: string) => {
      navigate(to)
      onClose()
    },
    [navigate, onClose],
  )

  const dispatch = useAppDispatch()

  // add reducer for currentActive PMSID and show that in dropdown

  const onLogout = useCallback(() => {
    dispatch(logOut())
    handleNavigate("/")
  }, [dispatch])

  return (
    <>
      <Button
        ref={btnRef}
        aria-label='side drawer'
        w={6}
        h={6}
        px={3}
        variant='ghost'
        onClick={onOpen}
      >
        {isOpen ? (
          <CloseIcon w={4} h={4} color='black' />
        ) : (
          <HamburgerIcon w={6} h={6} color='black' />
        )}
      </Button>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        preserveScrollBarGap
      >
        <DrawerOverlay />
        <DrawerContent shadow='none' bg='white'>
          <DrawerCloseButton />
          <DrawerBody py={5} px={0} pt='30px'>
            <Box display='flex' flexDir='column' p={2} gap={2}>
              {/* <a href='https://whimstay.com' target='_blank' rel='noreferrer'>
                <Button variant='ghost' justifyContent='left'>
                  <Text size='sm' variant='black' fontWeight='500'>
                    Switch to travelling
                  </Text>
                </Button>
              </a> */}
              {isLoggedIn ? (
                <>
                  {/** Note: Uncomments this to unable Create a new listing. */}
                  {/* <Button
                    variant='ghost'
                    justifyContent='left'
                    onClick={() => handleNavigate("/become-a-host/intro")}
                  >
                    <Text p={2} size='sm' variant='black' fontWeight='500'>
                      Create a new listing
                    </Text>
                  </Button>
                  <Button
                    variant='ghost'
                    justifyContent='left'
                    onClick={() => handleNavigate("/")}
                  >
                    <Text p={2} size='sm' variant='black' fontWeight='500'>
                      Today
                    </Text>
                  </Button> */}
                  {/* <Button
                    variant='ghost'
                    justifyContent='left'
                    onClick={() => handleNavigate("/inbox/all")}
                  >
                    <Text p={2} size='sm' variant='black' fontWeight='500'>
                      Inbox
                    </Text>
                  </Button> */}
                  <Button
                    paddingLeft='10px'
                    variant='ghost'
                    justifyContent='left'
                    onClick={() => handleNavigate("/profile/profile-details")}
                  >
                    <Text size='sm' variant='black' fontWeight='500'>
                      <ProfileIcon /> Profile
                    </Text>
                  </Button>
                  <Button
                    paddingLeft='10px'
                    variant='ghost'
                    justifyContent='left'
                    onClick={() =>
                      handleNavigate("/listings/properties/live-properties")
                    }
                  >
                    <Text size='sm' variant='black' fontWeight='500'>
                      <ListingsIcon /> Listings
                    </Text>
                  </Button>
                  <Button
                    paddingLeft='10px'
                    variant='ghost'
                    justifyContent='left'
                    onClick={() => handleNavigate("/listings/bookings/all")}
                  >
                    <Text size='sm' variant='black' fontWeight='500'>
                      <BookingsIcon /> Bookings
                    </Text>
                  </Button>
                  <Button
                    paddingLeft='10px'
                    variant='ghost'
                    justifyContent='left'
                    onClick={() =>
                      handleNavigate("/listings/reports/earningProgress")
                    }
                  >
                    <Text size='sm' variant='black' fontWeight='500'>
                      <ReportsIcon /> Reports
                    </Text>
                  </Button>
                  <Button
                    paddingLeft='10px'
                    variant='ghost'
                    justifyContent='left'
                    onClick={() => handleNavigate("/profile/payout-settings")}
                  >
                    <Text size='sm' variant='black' fontWeight='500'>
                      <PayoutSettingIcon /> Payout Settings
                    </Text>
                  </Button>
                  <Button
                    paddingLeft='10px'
                    variant='ghost'
                    justifyContent='left'
                    onClick={() => handleNavigate("/contact-us")}
                  >
                    <Text size='sm' variant='black' fontWeight='500'>
                      <ContactUsIcon /> Contact Us
                    </Text>
                  </Button>
                  <Button
                    paddingLeft='10px'
                    variant='ghost'
                    justifyContent='left'
                    onClick={() => handleNavigate("/faq")}
                  >
                    <Text size='sm' variant='black' fontWeight='500'>
                      <FaqIcon />
                      &nbsp; FAQs
                    </Text>
                  </Button>
                  <Button
                    paddingLeft='10px'
                    variant='ghost'
                    justifyContent='left'
                    onClick={onLogout}
                  >
                    <Text size='sm' variant='black' fontWeight='500'>
                      <SignOutIcon /> Sign Out
                    </Text>
                  </Button>
                </>
              ) : (
                <Button
                  variant='ghost'
                  justifyContent='left'
                  onClick={() => dispatch(setIsLoginModalOpen(true))}
                >
                  <Text size='sm' variant='black' fontWeight='500'>
                    Log In
                  </Text>
                </Button>
              )}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

const PageSize = 20
const PMCDropDown = () => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [noOfPages, setNoOfPages] = useState(1)
  const PMCs = useAppSelector(selectPmc)
  const selectedPMCId = getSelectedPMCId()
  const currentPmc = useAppSelector(selectCurrentPmc)

  const dispatch = useAppDispatch()

  const handlePMCSelection = useCallback(
    (pmcId: string) => {
      setSelectedPMCId(pmcId)
      dispatch(setCurrentActivePmc(pmcId))
      setSearchTerm("") // reset search term
    },
    [dispatch, setSearchTerm],
  )

  // on reload we will reset already selected pmc in redux
  useEffect(() => {
    if (PMCs && PMCs.find((item) => item.pmc_id === selectedPMCId)) {
      handlePMCSelection(selectedPMCId)
    }
  }, [PMCs])

  const firstFieldRef = useRef(null)

  const searchResults = PMCs.filter((x) =>
    `${x?.name || ""} ${x?.pmc_id}`
      ?.toLowerCase()
      .includes(searchTerm.trim().toLowerCase()),
  )

  return PMCs.length > 1 ? (
    <Popover initialFocusRef={firstFieldRef} onClose={() => setNoOfPages(1)}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              py={0}
              px={0}
              variant='ghost'
              border='1.5px solid #E1E1E1'
              transition='all 0.2s'
              display='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              <Box
                p={1.5}
                pr={{ base: "8px", xxl: "12px" }}
                borderRight='1px solid #e1e1e1'
              >
                <Text
                  size={{ base: "xs", md: "sm" }}
                  pl='5px'
                  fontWeight='500'
                  maxW='140px'
                  overflow='clip'
                  whiteSpace='nowrap'
                  wordBreak='keep-all'
                  width={{ base: "85px", sm: "auto" }}
                  textOverflow='ellipsis'
                >
                  {currentPmc?.name || "Whimstay"}
                </Text>
              </Box>
              <Box pr={1}>
                <ChevronDownIcon color='#000' ml='4px' w={8} h={8} />
              </Box>
            </Button>
          </PopoverTrigger>
          <PopoverContent maxH='50vh' overflow='auto' maxW='280px'>
            <PopoverHeader>
              <InputGroup>
                <Input
                  placeholder='Search...'
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value || " ")
                  }}
                  ref={firstFieldRef}
                />
                <InputRightElement>
                  <IconButton
                    aria-label='search button'
                    variant='ghost'
                    _focusVisible={{}}
                    icon={<Search2Icon color='green.500' />}
                  />
                </InputRightElement>
              </InputGroup>
            </PopoverHeader>

            <PopoverBody>
              {PMCs &&
                ((searchResults.length > 0 &&
                  searchResults
                    .slice(0, noOfPages * PageSize)
                    .map((i: PmcState) => {
                      const selectedPmc = currentPmc?.pmc_id
                      const isSelected = i.pmc_id === selectedPmc
                      return (
                        <Button
                          variant='menuItem'
                          key={i.pmc_id}
                          value={i.pmc_id || ""}
                          onClick={() => {
                            onClose()
                            handlePMCSelection(i.pmc_id)
                          }}
                          color={isSelected ? "white" : ""}
                          backgroundColor={
                            isSelected ? "secondaryColor" : "auto"
                          }
                          maxW='273px'
                          wordBreak='break-all'
                          display='flex'
                          alignItems='baseline'
                          justifyContent='flex-start'
                          textAlign='left'
                          h='auto'
                          whiteSpace='pre-wrap'
                          fontWeight={500}
                          px={3}
                        >
                          {i.name} {` (${i.pmc_id})`}
                        </Button>
                      )
                    })) || <Center p={4}>No Results</Center>)}
            </PopoverBody>
            {PMCs &&
              searchResults.length > PageSize &&
              searchResults.length > PageSize * noOfPages && (
                <PopoverFooter>
                  <Button
                    variant='secondary'
                    w='full'
                    onClick={() => setNoOfPages((x) => x + 1)}
                  >
                    Load More
                  </Button>
                </PopoverFooter>
              )}
          </PopoverContent>
        </>
      )}
    </Popover>
  ) : null
}

const HostHeaderContent = () => {
  const navigate = useNavigate()
  const [isLargerThan767] = useMediaQuery("(min-width: 767px)")
  // const [isLargerThan756] = useMediaQuery("(min-width: 756px)")
  const isLoggedIn = useAppSelector(selectUserLoggedIn)
  const username = useAppSelector(selectUserName)
  // const userProfileImage = useAppSelector(selectUserProfileImage)

  const dispatch = useAppDispatch()

  const onLogout = useCallback(() => {
    dispatch(logOut())
    navigate("/")
  }, [dispatch])

  return (
    <Flex
      w='full'
      h='full'
      justifyContent='space-between'
      alignItems='center'
      px={{ base: 3, lg2: 16, xl: "70px" }}
      gap={3}
    >
      <Box
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        gap={{ base: 3, lg2: 12 }}
      >
        <Logo />
        {isLoggedIn && <PMCDropDown />}
      </Box>

      <HStack spacing={{ base: 2, sm: 4 }}>
        {/* {isLargerThan767 && isLoggedIn && <NavList />} */}
        {/* {isLargerThan1280 && (
          <Button
            as='a'
            href='https://whimstay.com'
            target='_blank'
            rel='noreferrer'
            fontWeight='medium'
            size='md'
            fontSize='sm'
          >
            Switch to travelling
          </Button>
        )} */}

        {/* notification for mobile */}
        {/* {!isLargerThan756 && isLoggedIn && <NotificationDrawer />} */}
        {/* notification for big screen */}
        {/* {isLargerThan756 && isLoggedIn && <NotificationPopover />} */}

        {isLargerThan767 && isLoggedIn && (
          <ProfileMenu
            onLogout={onLogout}
            username={username}
            // userProfileImage={userProfileImage}
          />
        )}

        {isLargerThan767 && !isLoggedIn && (
          <Button
            fontFamily='Montserrat,sans-serif'
            size={{ base: "sm", md: "md" }}
            onClick={() => dispatch(setIsLoginModalOpen(true))}
          >
            Log In
          </Button>
        )}

        {/* sidedrawer for desktop */}
        {!isLargerThan767 && <SideDrawer />}
      </HStack>
    </Flex>
  )
}

const HostHeader = () => (
  <Box
    position={{ base: "fixed" }}
    top='0'
    w='full'
    h={{ base: "70px", lg2: "91px" }}
    display='flex'
    justifyContent='space-between'
    alignItems='center'
    maxWidth='100vw'
    zIndex='sticky'
    borderBottom='1px solid #e1e1e1'
    bgColor='white'
  >
    <HostHeaderContent />
  </Box>
)

export default HostHeader
