import { MenuItem, MenuItemProps } from "@chakra-ui/react"

// CustomMenuItem: component to show selected menu item
const CustomMenuItem = (
  props: MenuItemProps & {
    isSelected?: boolean
  },
) => {
  const { isSelected, children, ...rest } = props
  return (
    <MenuItem
      color={isSelected ? "white" : ""}
      backgroundColor={isSelected ? "secondaryColor" : "auto"}
      {...rest}
    >
      {children}
    </MenuItem>
  )
}

export default CustomMenuItem
