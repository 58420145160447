import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { BookingsData, GetBookingsData, ResponseData } from "./common"

export const defaultUrl = `${process.env.REACT_APP_BASE_API_URL || ""}/booking`

export interface GetBookingsRequestData {
  status: string
  pmcId: string
  pageNo: number
  noOfRecords: number
  keyword: string
  sortDirection: string
  sortBy: string
}

export interface GetBookingsRequest extends Partial<AxiosRequestConfig> {
  params: GetBookingsRequestData
}

export interface BookingsResponseData extends ResponseData {
  data: GetBookingsData
}

export interface BookingsResponse extends AxiosResponse {
  data: BookingsResponseData
}

export function getBookings(
  {
    params,
    method = "post",
    url = `${defaultUrl}/all`,
    ...config
  }: GetBookingsRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<BookingsResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<BookingsResponse>
}

export interface BookingsPropertyResponseData extends ResponseData {
  data: BookingsData
}

export interface BookingsPropertyResponse extends AxiosResponse {
  data: BookingsPropertyResponseData
}

export interface GetBookingPropertyRequest extends Partial<AxiosRequestConfig> {
  bookingNo: string
}

export function fetchSingleBookingDataApi(
  {
    bookingNo,
    method = "get",
    url = `${defaultUrl}/${bookingNo}`,
    ...config
  }: GetBookingPropertyRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<BookingsPropertyResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<BookingsPropertyResponse>
}
