import { useCallback, useEffect, useMemo, useState } from "react"

import { WarningIcon } from "@chakra-ui/icons"
import { Box, Button, Center, Text } from "@chakra-ui/react"

import { useAppDispatch, useAppSelector } from "app/hooks"
import FeeMappingTale, {
  FeeMappingItem,
} from "components/table/fee-mapping-table"
import PageSelector from "components/table/page-selector"
import Pagination from "components/table/pagination"
import TemplateHeader from "components/template/template-header"
import useToastHook from "hooks/useToastHook/useToastHook"
import { apiClient } from "services/client"
import { CategoryData } from "services/common"
import { UpdateCategoryResponse } from "services/fee-mapping"
import { selectCurrentPmc } from "store/auth/authSlice"
import { feeMappingCategories } from "store/features/featuresSlice"

type SortOrder = "asc" | "desc"

const FeeMapping = () => {
  const [pageSize, setPageSize] = useState<number>(5)
  const [pageStartIndex, setPageStartIndex] = useState<number>(0)
  const [updatedCategoryData, setUpdatedCategoryData] = useState<
    FeeMappingItem[]
  >([])

  const [categoryData, setCategoryData] = useState<CategoryData[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isUpdatedCategoryLoading, setIsUpdatedCategoryLoading] =
    useState<boolean>(false)
  const [sortConfig, setSortConfig] = useState<{
    key: keyof CategoryData | null
    order: SortOrder
  }>({ key: null, order: "asc" })

  const dispatch = useAppDispatch()
  const currentPmc = useAppSelector(selectCurrentPmc)
  const displayToast = useToastHook()

  useEffect(() => {
    setPageStartIndex(0)
  }, [currentPmc])

  const sortedData = useMemo(() => {
    if (!categoryData || !sortConfig.key) {
      return categoryData
    }

    setPageStartIndex(0)

    const sortedArray = [...categoryData].sort((a, b) => {
      if (a[sortConfig.key!] < b[sortConfig.key!]) {
        return sortConfig.order === "asc" ? -1 : 1
      }
      if (a[sortConfig.key!] > b[sortConfig.key!]) {
        return sortConfig.order === "asc" ? 1 : -1
      }
      return 0
    })

    return sortedArray
  }, [categoryData, sortConfig])

  // const startIndex = pageStartIndex
  // const endIndex = pageStartIndex + pageSize
  // const paginatedData = categoryData.slice(startIndex, endIndex)

  const paginatedData = useMemo(() => {
    const actualStartIndex = pageStartIndex * pageSize
    const endIndex = actualStartIndex + pageSize
    return sortedData.slice(actualStartIndex, endIndex)
  }, [pageStartIndex, pageSize, sortedData])

  const fetchFeeCategoryData = useCallback(async () => {
    setIsLoading(true)

    if (currentPmc?.pmc_id) {
      try {
        const response = await apiClient.getCategoryData({
          params: { pmcId: currentPmc.pmc_id },
        })
        setCategoryData(response.data.data)
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
  }, [currentPmc?.pmc_id])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchFeeCategoryData()
  }, [fetchFeeCategoryData])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(feeMappingCategories())
      .then(() => true)
      .catch()
  }, [dispatch])

  const updateCategoryHandler = async () => {
    if (currentPmc?.pmc_id) {
      setIsUpdatedCategoryLoading(true)
      try {
        await apiClient
          .updateFeeCategoryData({
            params: { pmcId: currentPmc?.pmc_id },
            data: updatedCategoryData,
          })
          .then((response: UpdateCategoryResponse) => {
            if (response?.data?.apiStatus.toLowerCase() !== "success") {
              displayToast({
                message: response?.data?.errorMessage || "",
                variant: "error",
              })
            }
            setIsUpdatedCategoryLoading(false)
            setUpdatedCategoryData([])
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            fetchFeeCategoryData()

            return true
          })
      } catch (error) {
        console.log(error)
        setIsUpdatedCategoryLoading(false)
      }
    }
  }

  return (
    <Box>
      <TemplateHeader text='Fee Mapping' />
      {!isLoading && categoryData?.length === 0 ? (
        <Center p={8} w='full' flexDir='column'>
          <WarningIcon color='primaryColor' w={16} h={16} />
          <Text size='lg' mt={8} mb={4}>
            No Fee yet.
          </Text>
        </Center>
      ) : (
        <Box>
          <FeeMappingTale
            categoryData={paginatedData}
            isLoading={isLoading}
            pageSize={pageSize}
            setUpdatedCategoryData={setUpdatedCategoryData}
            onSort={setSortConfig}
          />
          <Box display='flex' flexDirection='column' gap={4} mt={4}>
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              gap={4}
            >
              <PageSelector
                pageSize={pageSize}
                setPageSize={setPageSize}
                setPageStartIndex={setPageStartIndex}
              />

              <Pagination
                pageSize={pageSize}
                itemsLength={categoryData.length}
                pageStartIndex={pageStartIndex}
                setPageStartIndex={setPageStartIndex}
              />
            </Box>
            <Button
              width='10%'
              height='40px'
              alignSelf='flex-end'
              onClick={updateCategoryHandler}
              disabled={
                updatedCategoryData.length === 0 || isUpdatedCategoryLoading
              }
              isLoading={isUpdatedCategoryLoading}
              mb='20px'
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default FeeMapping
