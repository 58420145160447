import {
  AttachmentData,
  GetPropertyData,
  PropertyData,
  PropertyFeaturesKeyWords,
  PropertyFeaturesNewAmenities,
  PropImages,
} from "services/common"

export const newAttachementsResponseHelper = (PropImagesData: PropImages[]) => {
  const mappedAttachemetsObj: AttachmentData[] = []

  if (PropImagesData) {
    PropImagesData.forEach((item) => {
      mappedAttachemetsObj.push({
        hostPopertyAttachmentId: item.file_name,
        fileDescription: item.file_description,
        fileName: item.file_name,
        imgOrder: item.image_order,
        isCoverImg: item.is_cover_img,
        pmsUnitId: item.unit_d,
        pmcId: item.pmc_id,
        imageFullUrl: item.image_full_url,
        imageThumbnailUrl: "",
        video: item.is_video,
        isHide: item.is_hide,
      })
    })
  }

  return mappedAttachemetsObj
}

export const getPropertyDataResponseHelper = (
  propertyData: GetPropertyData,
) => {
  const { property } = propertyData

  const mappedAttachemetsObj: AttachmentData[] = newAttachementsResponseHelper(
    property?.propImages,
  )

  const updatedResponse: {
    property: Partial<PropertyData>
    propertyFeaturesKeyWords: PropertyFeaturesKeyWords[]
    propertyFeaturesNewAmenites: PropertyFeaturesNewAmenities[]
    hostPropertyAttachment: AttachmentData[]
  } = {
    property: {
      ...property.propData,
      propertyId: property.id,
      active: property.active,
      isHide: property.isHide,
      featureProperty: false,
      noOfUnits: property.propData.no_of_units,
      propertyDesc: property.propData.property_desc,
      propertyName: property.propData.property_name,
      pmsType: property.pmsSystem,
      pulledProperty: property.propData.pulled_property,
      pmcId: property.pmcId,
      pmsUnitId: property.unitId,
      propertyTypeDesc: property.propData.property_type_desc,
      propertySubTypeDesc: property.propData.property_sub_type_desc,
      propertyAccessType: "",
      addressFull: property.propData.address_full,
      addressline1: property.propData.addressline1,
      addressline2: property.propData.addressline2,
      city: property.propData.city,
      state: property.propData.state,
      countryCode: property.propData.country,
      postalCode: property.propData.postal_code,
      latitude: property.propData.latitude,
      longitude: property.propData.longitude,
      distance: 0,
      maxNoGuest: property.propData.max_no_guests,
      noOfBathroom: property.propData.no_of_bathrooms,
      noOfBedroom: property.propData.no_of_bedrooms,
      partyAllowed: property.propData.party_allowed,
      smokeAllowed: property.propData.smoke_allowed,
      suitableChild: property.propData.suitable_child,
      suitableInfant: property.propData.suitable_infant,
      suitablePet: property.propData.suitable_pet,
      wheelchairAccessable: property.propData.wheelchair_accessable,
      maxPrice: property.propData.max_price,
      addHouseRule: property.propData.addHouse_rule,
      propertyRatingUserCount: property.propData.property_rating_user_count,
      propertyRatingStarSum: property.propData.property_rating_star_sum,
      checkInTime: property.propData.check_in_time,
      checkInInstruction: property.propData.checkInInstruction,
      checkOutTime: property.propData.check_out_time,
      checkOutInstruction: property.propData.checkOutInstruction,
      cleaningFee: property.propData.cleaning_fee,
      depositAmount: 0,
      depositType: "",
      depositeBasicType: 0,
      discPer: property.propData.disc_per,
      minPrice: property.propData.min_price,
      nightlyTaxPer: property.propData.nightlyTaxPer,
      otherFee: property.propData.other_fee,
      otherFeeName: "",
      petFee: 0,
      serviceTaxCleaning: false,
      serviceTaxDeposite: false,
      serviceTaxOther: false,
      serviceTaxPet: false,
      serviceTaxPer: property.propData.service_tax_per,
      currency: property.propData.currency,
      contactMobileNo: property.propData.contact_mobile_no,
      contactEmailId: property.propData.contact_email_id,
      contactTemplateId: property.propData.contact_template_id,
      discountTemplateId: property.propData.discount_template_id,
      taxTemplateId: 0,
      unitNumber: property.propData.unit_number,
      pgHostAccId: property.propData.pg_host_acc_id,
      superhogListingId: 0,
      propertyUnitCalendarRate: {
        calendarId: 0,
        date: "",
        basePrice: 0,
        discPrice: 0,
        isAvailable: false,
        minStayDays: 0,
        pmsUnitId: "",
        pmcId: "",
        checkin: "",
        checkout: "",
      },
      roomsDetail: [],
      isManual: false,
      securityCamera: false,
      weapons: false,
      dangerousAnimals: false,
      status: property.status,
    },
    propertyFeaturesKeyWords: propertyData.propertyFeaturesKeyWords,
    propertyFeaturesNewAmenites: propertyData.propertyFeaturesNewAmenites || [],
    hostPropertyAttachment: mappedAttachemetsObj,
  }

  return updatedResponse
}
