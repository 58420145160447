import { combineReducers } from "@reduxjs/toolkit"

import amenitiesReducer from "store/amenities/amenitiesSlice"
import attachmentsReducer from "store/attachments/attachmentsSlice"
import authReducer from "store/auth/authSlice"
import bedroomsReducer from "store/bedrooms/bedroomsSlice"
import calendarReducer from "store/calendar/calendarSlice"
import houseRulesReducer from "store/features/featuresSlice"
import livePropertiesReducer from "store/liveProperties/livePropertiesSlice"
import modalReducer from "store/modal/modalSlice"
import payoutReducer from "store/payout/payoutSlice"
import propertyReducer from "store/property/propertySlice"
import uiReducer from "store/ui/uiSlice"

const rootReducer = combineReducers({
  propertyReducer,
  amenitiesReducer,
  attachmentsReducer,
  houseRulesReducer,
  authReducer,
  modalReducer,
  payoutReducer,
  uiReducer,
  livePropertiesReducer,
  bedroomsReducer,
  calendarReducer,
})

export default rootReducer
