import { ReactNode } from "react"

import { Container } from "@chakra-ui/react"

import Header from "components/header"
import ProtectedComponent from "components/PleaseLoginComp"

const StepLayout = ({ children }: { children: ReactNode }) => (
  <>
    <Header />
    <Container as='main' centerContent maxW='100vw' p={0} minH='100vh'>
      <ProtectedComponent>{children}</ProtectedComponent>
    </Container>
  </>
)

export default StepLayout
