import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { ResponseData, SignUpVerifyOtpRequestData } from "./common"
import { VerifyOtpResponse } from "./otp"
import { UserResponseData } from "./user"

interface UserData {
  appVersion: string
  browserType: string
  channel: string
  city: string
  country?: string
  dialerCode: string
  dobDt?: string
  emailId: string | null
  firstName: string
  ipAddress: string
  isTrmsncndAccepted: boolean
  lastName: string
  macId: string
  mobileno1: string
  password?: string
  signinFrom: string
  state: string
  trmsncndAcceptedDate: string
  trmsncndAcceptedVersion: string
  userType: number
  signupFrom?: string
}
export interface SaveUserRequest extends Partial<AxiosRequestConfig> {
  params: {
    user: UserData
  }
}

interface SaveUserResponseData extends ResponseData {
  message?: string
  user: UserResponseData
}

export interface SaveUserResponse extends AxiosResponse {
  data: SaveUserResponseData
}
interface SaveUserWithEmailResponseData extends ResponseData {
  firstName: string
  isEmailVerify: boolean
  message: string
  subject: string
  userId: number
}
export interface SaveUserWithEmailResponse extends AxiosResponse {
  data: SaveUserWithEmailResponseData
}

export interface SignupVerifyOtpRequest extends Partial<AxiosRequestConfig> {
  params: SignUpVerifyOtpRequestData
}

const defaultUrl = `${process.env.REACT_APP_BASE_API_URL || ""}/auth`

export function saveUserApi(
  { params }: SaveUserRequest,
  axiosInstance: AxiosInstance,
) {
  return axiosInstance({
    data: params.user,
    method: "post",
    url: `${defaultUrl}/sign-up`,
  }) as Promise<SaveUserResponse>
}

export function signUpVerifyOtp(
  {
    params,
    method = "get",
    url = `${defaultUrl}/mobile/verify`,
    ...config
  }: SignupVerifyOtpRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<VerifyOtpResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<VerifyOtpResponse>
}
