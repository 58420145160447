import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Button,
  Box,
} from "@chakra-ui/react"

import { useAppSelector } from "app/hooks"
import {
  tagDisplayTextMapping,
  tagOrder,
} from "components/add-tag-modal/AddTagModal"
import RoundedBadge from "components/table/rounded-badge"
import { CompanyTags } from "services/common"
import { selectCompanyTags } from "store/auth/authSlice"

const ViewTagMenu = () => {
  const companyTagData = useAppSelector(selectCompanyTags)
  const hasTrueValue = companyTagData
    ? Object.values(companyTagData).some((value) => value)
    : false
  return (
    <Menu autoSelect={false}>
      <MenuButton
        px='12px'
        h='30px'
        variant='secondary'
        as={Button}
        colorScheme='black'
        role='group'
        _focus={{}}
        _active={{}}
      >
        <Flex justifyContent='space-between' alignItems='center'>
          <Text
            fontWeight='500'
            fontSize='14px'
            lineHeight='10px'
            color='#006F80'
            _groupHover={{ color: "#fff" }}
          >
            View Tag
          </Text>
        </Flex>
      </MenuButton>

      <MenuList minWidth='200px'>
        {hasTrueValue ? (
          tagOrder.map(
            (key) =>
              companyTagData &&
              companyTagData[key as keyof CompanyTags] && (
                <Box
                  key={key}
                  display='block'
                  marginBottom={{ base: "3px", md: "10px" }}
                >
                  <RoundedBadge
                    marginRight='0px'
                    text={tagDisplayTextMapping[key]}
                  />
                </Box>
              ),
          )
        ) : (
          <Box fontWeight={600} textAlign='center'>
            No Tag List
          </Box>
        )}
      </MenuList>
    </Menu>
  )
}

export default ViewTagMenu
