import { useCallback, useEffect, useState } from "react"
import "react-phone-input-2/lib/bootstrap.css"

import {
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  Link,
  ModalCloseButton,
} from "@chakra-ui/react"
import { parsePhoneNumber } from "awesome-phonenumber"
import { useForm } from "react-hook-form"
import { CountryData } from "react-phone-input-2"
import { useNavigate } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "app/hooks"
import CustomPhoneInput from "components/custom-input/custom-phone-input/custom-phone-input"
import OtpForm from "components/login/otp-form"
import { useGoogleReCaptchaV3 } from "hooks/useGoogleReCaptcha"
import useToastHook from "hooks/useToastHook/useToastHook"
import { apiClient } from "services/client"
import { getIpAddress } from "services/ipAddress"
import {
  selectShowOtpForm,
  setIstermAccepted,
  setShowOtpForm,
} from "store/auth/authSlice"
import {
  selectIsSignupModalOpen,
  setIsLoginModalOpen,
  setIsSignupModalOpen,
} from "store/ui/uiSlice"
import { RECAPTCHA_KEY } from "utils/html-dom"
import { setReCaptchaToken, setTempAuthToken } from "utils/local-storage"

interface FormValues {
  phone: string
  dialerCode: string
  email: string
  firstName: string
  lastName: string
  city: string
  state: string
  zipCode: string
  airbnbHostUrl: string
  termsAndConditions: boolean
}

export default function HostSignUpModal() {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>()

  const { executeReCaptcha } = useGoogleReCaptchaV3({
    siteKey: RECAPTCHA_KEY,
  })

  const isOpen = useAppSelector(selectIsSignupModalOpen)
  const showOtpForm = useAppSelector(selectShowOtpForm)

  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const displayToast = useToastHook()

  // const [isSignupForm, setIsSignupForm] = useState<boolean>(true)

  const [isValid, setIsValid] = useState<boolean>(true)
  const [ipAddress, setIpAddress] = useState<{
    city?: string
    countryCode?: string
    ip?: string
    state?: string
    zipcode?: string
  }>()
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    getIpAddress()
      .then((resp) => {
        setIpAddress(resp)
        return true
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error)
      })
  }, [])

  const handlePhoneChange = (value: string, data: CountryData) => {
    setValue("phone", value.slice(data.dialCode.length))
    setValue("dialerCode", `+${data.dialCode}`)

    setIsDisabled(false)
    const pn = parsePhoneNumber(`+${value}`, { regionCode: data.countryCode })
    setIsValid(pn.valid)
    if (!pn.valid) {
      setError("phone", { type: "manual", message: "Invalid phone number" })
    } else {
      clearErrors("phone")
    }
  }

  const handleClose = useCallback(() => {
    dispatch(setIsSignupModalOpen(false))
  }, [dispatch])

  const onSubmit = async (data: FormValues) => {
    console.log(data, isValid)

    setIsLoading(true)

    const token = await executeReCaptcha("LoadReCaptchaOnClick")

    setReCaptchaToken(token)

    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      emailId: data.email,
      isTrmsncndAccepted: true,
      mobileno1: data?.phone || "",
      dialerCode: data?.dialerCode || "",
      userType: 2,
      channel: "web",
      signinFrom: "signup",
      trmsncndAcceptedVersion: "1.1",
      trmsncndAcceptedDate: new Date().toISOString(),
      macId: "",
      appVersion: "2.0.0",
      ipAddress: ipAddress?.ip || "",
      browserType: "NA",
      // country: countryCode || "",
      city: ipAddress?.city || "",
      state: ipAddress?.state || "",
      signupCountry: ipAddress?.countryCode || "",
      zipcode: ipAddress?.zipcode || "",
      // set value based on signup method.
      signupFrom: "MOBILE",
    }

    try {
      const response = apiClient.saveUser({ params: { user: userData } })
      setTempAuthToken((await response).headers.authorization)
      console.log(userData, response, "userdata")
      if ((await response).status === 201) {
        dispatch(setIstermAccepted(true))
        setIsLoading(false)
        dispatch(setShowOtpForm(true))
      }
    } catch (error: any) {
      setIsLoading(false)
      displayToast({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        message: error?.errorMessage,
        variant: "error",
      })

      console.log(error, "error")
    }

    // Reset form after submission if necessary
    // reset();
  }

  const loginHandler = () => {
    navigate("/")
    dispatch(setIsLoginModalOpen(true))
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size={{ base: "full", md: "md" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        padding='20px'
        minW={{ base: "auto", md: "650px" }}
        w='full'
        maxHeight={{ base: "300px", md: "650px", xxxl: "800px" }}
        maxH={{ base: "100%", md: "full" }}
        overflow={{ base: "auto", md: "auto" }}
        borderRadius='20px'
      >
        <ModalCloseButton onClick={handleClose} />
        <ModalBody padding='40px 0'>
          <Box
            w={{ base: "100%", md: "90%" }}
            margin={{ sm: "0px auto", md: "0px auto 20px" }}
            textAlign='center'
          >
            <Text
              as='h1'
              fontWeight={600}
              fontSize='24px'
              color='teal.500'
              lineHeight='30px'
            >
              Create your Whimstay account for individual Airbnb hosts
            </Text>
            <Text mb={4} mt={2}>
              Airbnb listing must be entire space and not a shared space or room
              to list on Whimstay.
            </Text>
          </Box>
          {!showOtpForm ? (
            <Box>
              <Box
                w={{ base: "100%", md: "60%" }}
                h='full'
                alignContent='center'
                margin={{ sm: "0px auto", md: "0px auto" }}
              >
                <Center w='full' h='full' mx='auto' flexDirection='column'>
                  <CustomPhoneInput
                    id='phone'
                    placeholder=''
                    label='Phone Number'
                    error={errors?.phone?.message}
                    country='us'
                    labelSize='16px'
                    onChange={handlePhoneChange}
                    value=''
                    dialerCode=''
                  />
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ width: "100%" }}
                  >
                    <FormControl mb={4} isRequired marginTop='15px'>
                      <FormLabel>Email</FormLabel>
                      <Input
                        type='email'
                        placeholder='Email'
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[\w.%+-]+@[A-Z\d.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors.email && (
                        <Text color='red.500'>{errors.email.message}</Text>
                      )}
                    </FormControl>
                    <FormControl mb={4} isRequired>
                      <FormLabel>First name</FormLabel>
                      <Input
                        placeholder='First name'
                        {...register("firstName", {
                          required: "First name is required",
                        })}
                      />
                      {errors.firstName && (
                        <Text color='red.500'>{errors.firstName.message}</Text>
                      )}
                    </FormControl>
                    <FormControl mb={4} isRequired>
                      <FormLabel>Last name</FormLabel>
                      <Input
                        placeholder='Last name'
                        {...register("lastName", {
                          required: "Last name is required",
                        })}
                      />
                      {errors.lastName && (
                        <Text color='red.500'>{errors.lastName.message}</Text>
                      )}
                    </FormControl>
                    <FormControl mb={4} isRequired>
                      <FormLabel>City</FormLabel>
                      <Input
                        placeholder='City'
                        {...register("city", { required: "City is required" })}
                      />
                      {errors.city && (
                        <Text color='red.500'>{errors.city.message}</Text>
                      )}
                    </FormControl>
                    <Flex>
                      <FormControl mb={4} isRequired mr={2}>
                        <FormLabel>State</FormLabel>
                        <Input
                          placeholder='State'
                          {...register("state", {
                            required: "State is required",
                          })}
                        />
                        {errors.state && (
                          <Text color='red.500'>{errors.state.message}</Text>
                        )}
                      </FormControl>
                      <FormControl mb={4} isRequired>
                        <FormLabel>Zip code</FormLabel>
                        <Input
                          placeholder='Zip code'
                          {...register("zipCode", {
                            required: "Zip code is required",
                          })}
                        />
                        {errors.zipCode && (
                          <Text color='red.500'>{errors.zipCode.message}</Text>
                        )}
                      </FormControl>
                    </Flex>
                    <FormControl mb={4}>
                      <FormLabel>Airbnb host url</FormLabel>
                      <Input
                        placeholder='Airbnb host url'
                        {...register("airbnbHostUrl", {
                          required: "Airbnb host URL is required",
                        })}
                      />
                      {errors.airbnbHostUrl && (
                        <Text color='red.500'>
                          {errors.airbnbHostUrl.message}
                        </Text>
                      )}
                    </FormControl>
                    <Box>
                      <Checkbox
                        alignItems='end'
                        borderColor='#464646'
                        {...register("termsAndConditions", {
                          required:
                            "You must agree to the terms and conditions",
                        })}
                      >
                        <Text fontSize={{ base: "14px", md: "16px" }}>
                          I agree to the terms and conditions
                        </Text>
                      </Checkbox>
                      {/* {errors.termsAndConditions && (
                                    <Text color="red.500">{errors.termsAndConditions.message}</Text>
                                )} */}
                      <Link
                        href='/'
                        mb={4}
                        ml={6}
                        color='teal.500'
                        as='u'
                        cursor='pointer'
                        display='block'
                        fontSize={{ base: "14px", md: "16px" }}
                      >
                        {" "}
                        Read Full Terms and Conditions
                      </Link>
                    </Box>
                    <Box display='flex' flexDir='column' mt={2}>
                      <Button
                        colorScheme='teal'
                        mr={3}
                        w='90%'
                        m='0 auto'
                        type='submit'
                        isDisabled={isDisabled || !isValid}
                        isLoading={isLoading}
                        p='10px'
                      >
                        Create individual host account
                      </Button>
                      <Button variant='ghost' onClick={loginHandler}>
                        Have an account? Go to login
                      </Button>
                    </Box>
                  </form>
                </Center>
              </Box>

              <Box display='flex' flexDir='column' w='100%' m='50px auto 0'>
                <Text
                  fontWeight={600}
                  textAlign='center'
                  w='full'
                  color='teal.500'
                  marginBottom={4}
                >
                  For property managers who use property management software.{" "}
                </Text>
                <Link
                  href='https://salespage.whimstay.com/contact_info_step_1'
                  m='0 auto'
                  isExternal
                >
                  <Button variant='tertiary' colorScheme='teal'>
                    Property managers account
                  </Button>
                </Link>
              </Box>
            </Box>
          ) : (
            <Box padding={{ md: "40px" }}>
              {" "}
              <OtpForm
                handleClose={handleClose}
                showEmailForm={false}
                isLoginFlow={false}
              />
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
