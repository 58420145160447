import { Text } from "@chakra-ui/react"

import { ToastVariant } from "hooks/useToastHook/types"

interface MessageProps {
  message: string
  variant: ToastVariant
}

const getMessageColors = (variant: ToastVariant) => {
  switch (variant) {
    case "success":
      return {
        backgroundColor: "secondaryColor",
        color: "white",
      }
    case "error":
      return {
        backgroundColor: "red",
        color: "white",
      }
    default:
      return {
        backgroundColor: "secondaryColor",
        color: "white",
      }
  }
}

const Toast = ({ message, variant }: MessageProps) => {
  const { backgroundColor, color } = getMessageColors(variant)
  return (
    <Text
      textAlign='center'
      display='flex'
      justifyContent='center'
      alignItems='center'
      marginBottom='10px'
      borderRadius='30px'
      color={color}
      p='10px'
      backgroundColor={backgroundColor}
    >
      {message}
    </Text>
  )
}

export default Toast
