import {
  addDays,
  addMonths,
  addYears,
  subDays,
  subMonths,
  subYears,
} from "date-fns"

const firstDayOfMonth = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), 1)
const lastDayOfMonth = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0)

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

const getDaysArray = (start: Date, end: Date): Date[] => {
  const arr: Date[] = []
  for (const dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt))
  }
  return arr
}

export const daysNames = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]

export const daysBefore: { [key: string]: number } = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
}

const daysMonth = (date: Date): Date[] =>
  getDaysArray(firstDayOfMonth(date), lastDayOfMonth(date))

const dayOfWeek = (date: Date): keyof typeof daysBefore =>
  date.toLocaleDateString("ISO", { weekday: "long" }).toLowerCase() // STRING DOES NOT RECOGNIZE KEYS AS VALID ONES

const dateMove = (
  date: Date,
  numberOfDays: number,
  direction: "back" | "forward",
): Date =>
  direction === "back"
    ? subDays(new Date(date), numberOfDays)
    : addDays(new Date(date), numberOfDays)

const daysMonthBefore = (date: Date): Date[] => {
  const firstDateMonth = firstDayOfMonth(date)
  const dayOfTheWeek = dayOfWeek(firstDateMonth)
  const dateBefore = dateMove(firstDateMonth, daysBefore[dayOfTheWeek], "back")
  const daysBack = getDaysArray(dateBefore, dateMove(firstDateMonth, 1, "back"))
  return daysBack
}

const daysMonthAfter = (date: Date): Date[] => {
  const daysBeforeCount = daysMonthBefore(date).length
  const daysOfTheMonth = lastDayOfMonth(date).getDate()
  const diff = 35 - daysBeforeCount - daysOfTheMonth
  const dateAfter = dateMove(lastDayOfMonth(date), diff, "forward")
  const firstDayOfNextMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    1,
  )
  const daysAfterMonth = getDaysArray(firstDayOfNextMonth, dateAfter)
  return daysAfterMonth
}

export const generateDates = (date: Date): Date[] => [
  ...daysMonthBefore(date),
  ...daysMonth(date),
  ...daysMonthAfter(date),
]

export const goYearBack = (
  date: Date,
  setDate: (date: Date) => void,
  setDays: (dates: Date[]) => void,
) => {
  const update = new Date(subYears(new Date(date), 1))
  setDate(update)
  setDays(generateDates(update))
}
export const goMonthBack = (
  date: Date,
  setDate: (date: Date) => void,
  setDays: (dates: Date[]) => void,
) => {
  const update = new Date(subMonths(new Date(date), 1))
  setDate(update)
  setDays(generateDates(update))
}
export const goMonthForward = (
  date: Date,
  setDate: (date: Date) => void,
  setDays: (dates: Date[]) => void,
) => {
  const update = new Date(addMonths(new Date(date), 1))
  setDate(update)
  setDays(generateDates(update))
}
export const goYearForward = (
  date: Date,
  setDate: (date: Date) => void,
  setDays: (dates: Date[]) => void,
) => {
  const update = new Date(addYears(new Date(date), 1))
  setDate(update)
  setDays(generateDates(update))
}
