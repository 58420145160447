import { Center, Image } from "@chakra-ui/react"
import { Link } from "react-router-dom"

import logo from "assets/images/logo.svg"

const Logo = () => (
  <Center>
    <Link to='/'>
      <Image
        h={{ base: "50px", md: "58px", xl: "58px", xxxl: "75px" }}
        w={{ base: "69", md: "80px", xl: "80px", xxxl: "103px" }}
        src={logo}
        alt='Whimstay Host'
      />
    </Link>
  </Center>
)

export default Logo
