import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { NotificationData, ResponseData } from "./common"

export interface GetNotificationRequestData {
  notificationStatus: string
}
export interface GetNotificationRequest extends Partial<AxiosRequestConfig> {
  params: GetNotificationRequestData
}
export interface GetNotificationResponseData extends ResponseData {
  data: NotificationData
}

export interface GetNotificationResponse extends AxiosResponse {
  data: GetNotificationResponseData
}

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/notificationlog`

export function getNotifications(
  {
    params,
    method = "get",
    url = `${defaultUrl}/notifications?notificationStatus=${
      params.notificationStatus
    }&pageNo=${0}&noOfRecords=${20}`, // TODO make pagination dynamic.
    ...config
  }: GetNotificationRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetNotificationResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<GetNotificationResponse>
}

export interface UpdateNotificationRequestData {
  notificationLogIds: number[]
  notificationStatus: string
}
export interface UpdateNotificationRequest extends Partial<AxiosRequestConfig> {
  params: UpdateNotificationRequestData
}
export interface UpdateNotificationResponse extends AxiosResponse {
  data: ResponseData
}

export function updateNotificationStatus(
  {
    params,
    method = "put",
    url = `${defaultUrl}/update-notifications`,
    ...config
  }: UpdateNotificationRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<UpdateNotificationResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<UpdateNotificationResponse>
}

export interface ReadNotificationRequestData {
  notificationId: number
  read: boolean
}
export interface ReadNotificationRequest extends Partial<AxiosRequestConfig> {
  params: ReadNotificationRequestData
}

export function readNotification(
  {
    params,
    method = "put",
    url = `${defaultUrl}/update-unread-notification`,
    ...config
  }: ReadNotificationRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<unknown> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<unknown>
}
