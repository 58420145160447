import { lazy, Suspense, useCallback, useEffect } from "react"

import {
  Box,
  Center,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { useLocation } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "app/hooks"
import loginLogo from "assets/images/login_logo.png"
import {
  // getAdminPmc,
  getHostPmc,
  getUser,
  selectCurrentPmc,
  selectEmailPasswordForm,
  selectShowOtpForm,
  selectUserLoggedIn,
  setAuthError,
  setEmailPasswordForm,
  setIsLoggedIn,
  setShowOtpForm,
} from "store/auth/authSlice"
import { setIsLoginModalOpen, selectIsLoginModalOpen } from "store/ui/uiSlice"
import { getAuthToken } from "utils/local-storage"

const OtpForm = lazy(() => import("./otp-form"))
const LoginForm = lazy(() => import("./login-form"))

const LoginModal = () => {
  const location = useLocation()
  const isLoggedIn = useAppSelector(selectUserLoggedIn)
  const isLoginModalOpen = useAppSelector(selectIsLoginModalOpen)
  const showOtpForm = useAppSelector(selectShowOtpForm)
  const showEmailForm = useAppSelector(selectEmailPasswordForm)
  const currentPmc = useAppSelector(selectCurrentPmc)
  const loginNotNeeded =
    location.pathname.startsWith("/invite") ||
    location.pathname.startsWith("/reset_password") ||
    location.pathname.startsWith("/link/hostfully") ||
    location.pathname.startsWith("/authorize/code") ||
    location.pathname.startsWith("/create-password")
  location.pathname.startsWith("/host-signup")
  const dispatch = useAppDispatch()

  const handleClose = useCallback(() => {
    dispatch(setAuthError(null))
    dispatch(setShowOtpForm(false))
    dispatch(setEmailPasswordForm(false))
    dispatch(setIsLoginModalOpen(false))
  }, [dispatch])

  const getUserData = useCallback(async () => {
    const authToken = getAuthToken()
    if (authToken) {
      if (currentPmc?.pmc_id) {
        await dispatch(
          getUser({
            params: {
              pmcId: currentPmc?.pmc_id,
            },
          }),
        )
      }
    }
  }, [currentPmc?.pmc_id, dispatch])

  useEffect(() => {
    const authToken = getAuthToken()
    if (authToken) {
      // assuming that user is logged in
      const getData = async () => {
        // if (response.data.isAdmin) {
        //   await dispatch(getAdminPmc()).unwrap()
        // } else {
        await dispatch(getHostPmc()).unwrap()
        // }
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getData()
    } else {
      // assuming that user is NOT logged in
      dispatch(setIsLoggedIn(false))
    }
  }, [dispatch, location.pathname])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getUserData()
  }, [currentPmc?.pmc_id, dispatch, getUserData])

  useEffect(() => {
    if (!isLoggedIn && !loginNotNeeded) {
      dispatch(setIsLoginModalOpen(true))
    }
  }, [isLoggedIn, dispatch, loginNotNeeded])

  return (
    <Modal
      isOpen={isLoginModalOpen}
      onClose={handleClose}
      size={{ base: "full", md: "md" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        minW={{ base: "auto", md: "650px" }}
        w='full'
        height={{ base: "100%", md: "auto" }}
        maxH={{ base: "100%", md: "550px" }}
        overflow={{ base: "auto", md: "hidden" }}
        borderRadius={{ sm: "0px", mxd: "42px !important" }}
      >
        <ModalCloseButton
          color='#006f80'
          size='24px'
          mt={{ base: "50px", md: "10px" }}
          mr={{ base: "50px", md: "10px" }}
          _hover={{ backgroundColor: "none", transform: "rotate(90deg)" }}
          onClick={handleClose}
        />

        <HStack alignItems='center' h='550px' spacing={0} overflowY='auto'>
          <Box w='40%' h='inherit' display={{ base: "none", md: "block" }}>
            <Image
              src={`${
                process.env.REACT_APP_BASENAME || ""
              }/images/auth-banner.jpg`}
              w='full'
              h='full'
              alt='auth-banner'
              objectFit='cover'
            />
          </Box>

          <ModalBody w={{ base: "100%", md: "60%" }} h='full'>
            <Center w='full' h='full' mx='auto' flexDirection='column'>
              <Image src={loginLogo} alt='login-logo' />

              <Heading
                as='h2'
                fontWeight='normal'
                fontSize='16px'
                display='flex'
                pt='20px'
                alignItems='baseline'
                fontFamily='Montserrat,sans-serif'
              >
                {" "}
                Log into your Whimstay
              </Heading>

              <Text
                fontSize='16px'
                fontWeight='normal'
                mb='15px'
                fontFamily='Montserrat,sans-serif'
                display='flex'
              >
                <Text fontWeight='semibold'> hosting&nbsp;</Text> account
              </Text>

              <Suspense
                fallback={
                  <Center p={4}>
                    <Spinner />
                  </Center>
                }
              >
                {showOtpForm ? (
                  <OtpForm
                    handleClose={handleClose}
                    showEmailForm={showEmailForm}
                  />
                ) : (
                  <LoginForm showEmailForm={showEmailForm} />
                )}
              </Suspense>
            </Center>
          </ModalBody>
        </HStack>
      </ModalContent>
    </Modal>
  )
}

export default LoginModal
