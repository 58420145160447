import { ReactNode } from "react"

import { IconButton, TableColumnHeaderProps, Th } from "@chakra-ui/react"

import SortingIcon from "components/icons/sorting_icon"

type CustomThProps = {
  children: ReactNode
  isSortable?: boolean
  selectable?: boolean
}
const CustomTh = (props: TableColumnHeaderProps & CustomThProps) => {
  const { children, isSortable, selectable, ...rest } = props
  return (
    <Th cursor={isSortable ? "pointer" : "auto"} {...rest}>
      {children}
      {isSortable && (
        <IconButton
          variant='ghost'
          h='20px'
          aria-label='sorting'
          icon={<SortingIcon />}
        />
      )}
    </Th>
  )
}

export default CustomTh
