import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "app/store"
import {
  AmenitiesRequest,
  CustomAmenitiesRequest,
  DeleteCustomAmenitiesType,
  GetCustomAmenitiesRequest,
} from "services/amenities"
import { apiClient } from "services/client"
import { GetPropertyRequest } from "services/properties"

import { AmenitiesInitialState, AmenityState } from "./types"

export const getDefaultAmenities = createAsyncThunk(
  "amenities/getDefaultAmenities",
  async () => {
    const response = await apiClient.getDefaultAmenities()

    return response.data
  },
)

export const getProperty = createAsyncThunk(
  "property/getProperty",
  async (params: GetPropertyRequest) => {
    const response = await apiClient.getProperty(params)

    return response.data
  },
)

export const getCustomAmenities = createAsyncThunk(
  "amenities/getCustomAmenities",
  async (params: GetCustomAmenitiesRequest) => {
    const response = await apiClient.getCustomAmenities(params)

    return response.data
  },
)

export const addSelectedAmenities = createAsyncThunk(
  "amenities/addAmenities",
  async (params: AmenitiesRequest) => {
    const response = await apiClient.addAmenities(params)

    return response.data
  },
)

export const addCustomAmenities = createAsyncThunk(
  "amenities/addCustomAmenities",
  async (params: CustomAmenitiesRequest) => {
    const response = await apiClient.addCustomAmenities(params)

    return response.data
  },
)

export const delCustomAmenities = createAsyncThunk(
  "amenities/delCustomAmenities",
  async (params: DeleteCustomAmenitiesType) => {
    const response = await apiClient.deleteCustomAmenity(params)

    return response.data
  },
)

const initialState: AmenitiesInitialState = {
  loading: false,
  defaultAmenities: [
    {
      id: 334,
      categoryName: "Features & Amenities",
      keyName: "Air conditioning",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Air_conditioning",
    },
    {
      id: 335,
      categoryName: "Features & Amenities",
      keyName: "Wifi",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Wifi",
    },
    {
      id: 336,
      categoryName: "Features & Amenities",
      keyName: "Smoke Detectors",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Smoke_Alarm",
    },
    {
      id: 203,
      categoryName: "Features & Amenities",
      keyName: "Smoke Detectors",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Smoke_Alarm",
    },
    {
      id: 353,
      categoryName: "Features & Amenities",
      keyName: "Iron and board",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Iron",
    },
    {
      id: 354,
      categoryName: "Features & Amenities",
      keyName: "High chair",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/High_chair",
    },
    {
      id: 358,
      categoryName: "Home safety",
      keyName: "Events Allowed",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Events_Allowed",
    },
    {
      id: 359,
      categoryName: "Home safety",
      keyName: "Events Not Allowed",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Events_Not_Allowed",
    },
    {
      id: 367,
      categoryName: "Features & Amenities",
      keyName: "Pool",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Pool",
    },
    {
      id: 371,
      categoryName: "Features & Amenities",
      keyName: "Wheelchair Accessible",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Wheelchair_Accessible",
    },
    {
      id: 398,
      categoryName: "Features & Amenities",
      keyName: "Parking available",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Parking",
    },
    {
      id: 399,
      categoryName: "Features & Amenities",
      keyName: "Gym",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Gym",
    },
    {
      id: 409,
      categoryName: "Features & Amenities",
      keyName: "Hot tub",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Hot_tub",
    },
    {
      id: 228,
      categoryName: "Features & Amenities",
      keyName: "Clean with disinfectan",
      keyIcon: null,
    },
    {
      id: 247,
      categoryName: "Features & Amenities",
      keyName: "Appartment or condo",
      keyIcon: null,
    },
    {
      id: 480,
      categoryName: "Features & Amenities",
      keyName: "Hair dryer",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Hair_dryer",
    },
    {
      id: 500,
      categoryName: "Home safety",
      keyName: "Smoking Allowed",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Smoking_Allowed",
    },
    {
      id: 542,
      categoryName: "Home safety",
      keyName: "Carbon monoxide alarm",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Carbon_monoxide_alarm",
    },
    {
      id: 543,
      categoryName: "Home safety",
      keyName: "Fire extinguisher",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Fire_extinguisher",
    },
    {
      id: 544,
      categoryName: "Home safety",
      keyName: "First aid kit",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/First_aid_kit",
    },
    {
      id: 545,
      categoryName: "Home safety",
      keyName: "Smoke alarm",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Smoke_Alarm",
    },
    {
      id: 569,
      categoryName: "Features & Amenities",
      keyName: "Washer",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Washer",
    },
    {
      id: 570,
      categoryName: "Features & Amenities",
      keyName: "Kitchen",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Kitchen",
    },
    {
      id: 571,
      categoryName: "Features & Amenities",
      keyName: "Indoor fireplace",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Indoor_fireplace",
    },
    {
      id: 584,
      categoryName: "Features & Amenities",
      keyName: "Dryer",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Dryer",
    },
    {
      id: 589,
      categoryName: "Features & Amenities",
      keyName: "Heating",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Heating",
    },
    {
      id: 608,
      categoryName: "Features & Amenities",
      keyName: "Microwave",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Microwave",
    },
    {
      id: 609,
      categoryName: "Features & Amenities",
      keyName: "Grill",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Barbecue_utensils",
    },
    {
      id: 610,
      categoryName: "Features & Amenities",
      keyName: "Carbon Monoxide Detector",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Carbon_monoxide_alarm",
    },
    {
      id: 622,
      categoryName: "Features & Amenities",
      keyName: "Dedicated workspace",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Dedicated_workspace",
    },
    {
      id: 628,
      categoryName: "Home safety",
      keyName: "Party Not Allowed",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/No_Party",
    },
    {
      id: 629,
      categoryName: "Home safety",
      keyName: "Party Allowed",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Party_Allowed",
    },
    {
      id: 107,
      categoryName: "Features & Amenities",
      keyName: "Air conditioning",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Air_conditioning",
    },
    {
      id: 177,
      categoryName: "Features & Amenities",
      keyName: "Wifi",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Wifi",
    },
    {
      id: 77,
      categoryName: "Features & Amenities",
      keyName: "Iron and board",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Iron",
    },
    {
      id: 102,
      categoryName: "Features & Amenities",
      keyName: "High chair",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/High_chair",
    },
    {
      id: 282,
      categoryName: "Home safety",
      keyName: "Events Allowed",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Events_Allowed",
    },
    {
      id: 283,
      categoryName: "Home safety",
      keyName: "Events Not Allowed",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Events_Not_Allowed",
    },
    {
      id: 165,
      categoryName: "Features & Amenities",
      keyName: "Pool",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Pool",
    },
    {
      id: 21,
      categoryName: "Features & Amenities",
      keyName: "Wheelchair Accessible",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Wheelchair_Accessible",
    },
    {
      id: 159,
      categoryName: "Features & Amenities",
      keyName: "Parking available",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Parking",
    },
    {
      id: 161,
      categoryName: "Features & Amenities",
      keyName: "Gym",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Gym",
    },
    {
      id: 162,
      categoryName: "Features & Amenities",
      keyName: "Hot tub",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Hot_tub",
    },
    {
      id: 65,
      categoryName: "Features & Amenities",
      keyName: "Hair dryer",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Hair_dryer",
    },
    {
      id: 294,
      categoryName: "Home safety",
      keyName: "Smoking Allowed",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Smoking_Allowed",
    },
    {
      id: 112,
      categoryName: "Home safety",
      keyName: "Carbon monoxide alarm",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Carbon_monoxide_alarm",
    },
    {
      id: 113,
      categoryName: "Home safety",
      keyName: "Fire extinguisher",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Fire_extinguisher",
    },
    {
      id: 114,
      categoryName: "Home safety",
      keyName: "First aid kit",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/First_aid_kit",
    },
    {
      id: 115,
      categoryName: "Home safety",
      keyName: "Smoke alarm",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Smoke_Alarm",
    },
    {
      id: 81,
      categoryName: "Features & Amenities",
      keyName: "Washer",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Washer",
    },
    {
      id: 128,
      categoryName: "Features & Amenities",
      keyName: "Kitchen",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Kitchen",
    },
    {
      id: 110,
      categoryName: "Features & Amenities",
      keyName: "Indoor fireplace",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Indoor_fireplace",
    },
    {
      id: 73,
      categoryName: "Features & Amenities",
      keyName: "Dryer",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Dryer",
    },
    {
      id: 109,
      categoryName: "Features & Amenities",
      keyName: "Heating",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Heating",
    },
    {
      id: 129,
      categoryName: "Features & Amenities",
      keyName: "Microwave",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Microwave",
    },
    {
      id: 146,
      categoryName: "Features & Amenities",
      keyName: "Grill",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Barbecue_utensils",
    },
    {
      id: 221,
      categoryName: "Features & Amenities",
      keyName: "Carbon Monoxide Detector",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Carbon_monoxide_alarm",
    },
    {
      id: 175,
      categoryName: "Features & Amenities",
      keyName: "Dedicated workspace",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Dedicated_workspace",
    },
    {
      id: 312,
      categoryName: "Home safety",
      keyName: "Party Not Allowed",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/No_Party",
    },
    {
      id: 313,
      categoryName: "Home safety",
      keyName: "Party Allowed",
      keyIcon:
        "https://whimstay-staging-bucket.s3.us-east-2.amazonaws.com/Whimstay/aminities-Icons/Party_Allowed",
    },
  ],
  selectedAmenities: [],
  customAmenities: [],
  error: null,
}

export const amenitiesSlice = createSlice({
  name: "amenities",
  initialState,
  reducers: {
    resetSelectedAmenities: (state) => {
      state.selectedAmenities = initialState.selectedAmenities
    },
    setSelectedAmenity: (state, action: PayloadAction<AmenityState>) => {
      state.selectedAmenities = [...state.selectedAmenities, action.payload]
    },
    removeSelectedAmenity: (state, action: PayloadAction<AmenityState>) => {
      const removeItem = (arr: AmenityState[], value: AmenityState) => {
        let i = 0
        while (i < arr.length) {
          if (arr[i].id === value.id) {
            arr.splice(i, 1)
          } else {
            // eslint-disable-next-line no-plusplus
            ++i
          }
        }
        return arr
      }
      state.selectedAmenities = removeItem(
        state.selectedAmenities,
        action.payload,
      )
    },
    setPropertyAmenity: (state, action: PayloadAction<AmenityState[]>) => {
      state.selectedAmenities = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addSelectedAmenities.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(addSelectedAmenities.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.selectedAmenities = [...action.payload.data]
      }
      state.loading = false
      state.error = null
    })

    builder.addCase(getProperty.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.selectedAmenities = [
          ...action.payload.data.propertyFeaturesKeyWords,
        ]
      }
      state.loading = false
      state.error = null
    })
    builder.addCase(addSelectedAmenities.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })
    builder.addCase(getDefaultAmenities.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getDefaultAmenities.fulfilled, (state, action) => {
      state.loading = false
      state.defaultAmenities = [...action.payload.data]
      state.error = null
    })
    builder.addCase(getCustomAmenities.fulfilled, (state, action) => {
      state.loading = false
      state.customAmenities = action.payload.data.newAmenities
      state.error = null
    })
    builder.addCase(getDefaultAmenities.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })
  },
})

export const { setSelectedAmenity } = amenitiesSlice.actions
export const { removeSelectedAmenity } = amenitiesSlice.actions
export const { setPropertyAmenity } = amenitiesSlice.actions
export const { resetSelectedAmenities } = amenitiesSlice.actions

export const selectDefaultAmenities = (state: RootState) =>
  state.amenitiesReducer.defaultAmenities
export const selectSelectedAmenities = (state: RootState) =>
  state.amenitiesReducer.selectedAmenities
export const selectCustAmenities = (state: RootState) =>
  state.amenitiesReducer.customAmenities
export default amenitiesSlice.reducer
