import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "services/client"
import { ResponseData, UserData } from "services/common"

export const defaultManageUsersUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/user/by-pmcid`
export const defaultManageUsersByCodeUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/user/by-invitation`

export interface GetUsersByPmcIdRequestData {
  pmcId: string
}

export interface GetUsersByPmcIdData {
  data: UserData[]
}

export interface GetUsersByPmcIdResponse extends AxiosResponse {
  data: GetUsersByPmcIdData
}

export interface GetUsersByPmcIdRequest extends Partial<AxiosRequestConfig> {
  params: GetUsersByPmcIdRequestData
}

export function getUsersByPmcId(
  {
    params,
    method = "get",
    url = defaultManageUsersUrl,
    ...config
  }: GetUsersByPmcIdRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetUsersByPmcIdResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetUsersByPmcIdResponse>
}

export interface UserResponseData extends ResponseData {
  data: UserData
}

export interface AddEditUserType {
  userId?: number | undefined
  firstName: string
  lastName: string
  emailId: string
  mobileno1?: string
  pmcId: string | undefined
  dialerCode?: string
  country?: string
}
export interface AddEditUserResponseData extends ResponseData {
  data: UserData
  message?: string
  status?: number
}

export interface AddEditUserResponse extends AxiosResponse {
  data: AddEditUserResponseData
}

export function addEditUser(
  data: AddEditUserType,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AddEditUserResponse> {
  return axiosInstance({
    method: data?.userId ? "put" : "post",
    data,
    params: { pmcId: data?.pmcId },
    url: defaultManageUsersUrl,
  }) as Promise<AddEditUserResponse>
}

type DeleteUserResponseData = {
  data: {
    deleted: boolean
    message: string
  }
}

export interface UserDeleteResponse extends AxiosResponse {
  data: DeleteUserResponseData
}

export interface DeleteUserType {
  userId?: number | undefined
  pmcId: string | undefined
}

export function deleteUser(
  data: DeleteUserType,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<UserDeleteResponse> {
  return axiosInstance({
    method: "delete",
    data,
    params: { pmcId: data?.pmcId, userId: data?.userId },
    url: defaultManageUsersUrl,
  }) as Promise<UserDeleteResponse>
}

// Manager users by invitation code APIs

export interface ManageUserByCodeRequestData {
  invCode: string
}
export interface ManageUserByCodeRequest extends Partial<AxiosRequestConfig> {
  params: ManageUserByCodeRequestData
}
export interface ManageUserByCodeResponseData {
  data: {
    token: string
  }
  status: number
  message: string
}
export interface ManageUserByCodeResponse extends AxiosResponse {
  data: ManageUserByCodeResponseData
}

export function manageUserByCode(
  {
    params,
    method = "get",
    url = `${process.env.REACT_APP_BASE_API_URL || ""}/user/invitation/${
      params.invCode
    }`,
    ...config
  }: ManageUserByCodeRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ManageUserByCodeResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<ManageUserByCodeResponse>
}
export interface GetUsersByCodeRequestData {
  invCode: string
}
export interface GetUsersByCodeRequest extends Partial<AxiosRequestConfig> {
  params: GetUsersByCodeRequestData
}
export function getUsersByCode(
  {
    params,
    method = "post",
    url = `${process.env.REACT_APP_BASE_API_URL || ""}/user/invitation`,
    ...config
  }: GetUsersByCodeRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetUsersByPmcIdResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<GetUsersByPmcIdResponse>
}
export interface AddEditUserByCodeType {
  userId?: number | undefined
  firstName: string
  lastName: string
  emailId: string
  mobileno1?: string
  dialerCode?: string
  country?: string
  invCode: string
}

export function addEditUserByCode(
  params: AddEditUserByCodeType,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AddEditUserResponse> {
  return axiosInstance({
    method: params?.userId ? "put" : "post",
    data: params,
    url: defaultManageUsersByCodeUrl,
  }) as Promise<AddEditUserResponse>
}

export interface DeleteUserByCodeType {
  userId: number
  invCode: string
}

export function deleteUserByCode(
  params: DeleteUserByCodeType,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<UserDeleteResponse> {
  return axiosInstance({
    method: "delete",
    data: params,
    url: defaultManageUsersByCodeUrl,
  }) as Promise<UserDeleteResponse>
}

export interface AcceptTermsConditionsRequest {
  isTrmsncndAccepted: boolean
  trmsncndAcceptedVersion: string
  invCode: string
}
export interface AcceptTermsConditionsResponseData {
  data: {
    accepted: boolean
  }
  apiStatus: string
}
export interface AcceptTermsConditionsResponse extends AxiosResponse {
  data: AcceptTermsConditionsResponseData
}

export function acceptTermsConditions(
  params: AcceptTermsConditionsRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AcceptTermsConditionsResponse> {
  return axiosInstance({
    method: "post",
    data: params,
    url: `${process.env.REACT_APP_BASE_API_URL || ""}/user/terms-conditions`,
  }) as Promise<AcceptTermsConditionsResponse>
}
