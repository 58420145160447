import { Button, HStack } from "@chakra-ui/react"
import { useLocation, useNavigate } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "app/hooks"
import { PropertyData } from "services/common"
import { resetSelectedAmenities } from "store/amenities/amenitiesSlice"
import { resetSelectedAttachments } from "store/attachments/attachmentsSlice"
import { selectCurrentPmc, selectUserLoggedIn } from "store/auth/authSlice"
import {
  createProperty,
  reset,
  selectProperty,
  selectPropertyStatus,
  setSaveAndExitStatus,
} from "store/property/propertySlice"
import { setIsLoginModalOpen } from "store/ui/uiSlice"

const HeaderStep = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectUserLoggedIn)
  const property = useAppSelector(selectProperty)
  const status = useAppSelector(selectPropertyStatus)
  const pmcId = useAppSelector(selectCurrentPmc)

  const navigate = useNavigate()

  const saveAndExit = async () => {
    dispatch(setSaveAndExitStatus(true))
    await dispatch(
      createProperty({
        params: {
          ...(property as PropertyData),
          pmcId: pmcId?.pmc_id,
          status,
          saveAndExit: true,
        },
      }),
    ).unwrap()
    dispatch(reset())
    dispatch(resetSelectedAmenities())
    dispatch(resetSelectedAttachments())
    navigate("/")
  }

  return (
    <HStack spacing={{ base: 3, md: 5 }}>
      {!isLoggedIn && (
        <Button
          size={{ base: "sm", md: "md" }}
          onClick={() => dispatch(setIsLoginModalOpen(true))}
        >
          Log In
        </Button>
      )}
      {location.pathname !== "/" && (
        <Button variant='tertiary' size={{ base: "sm", md: "md" }}>
          {/* // TODO make it function to forward help page */}
          Help
        </Button>
      )}
      <Button
        variant='tertiary'
        size={{ base: "sm", md: "md" }}
        onClick={saveAndExit}
      >
        {location.pathname === "/" ? "Exit" : "Save & Exit"}
      </Button>
    </HStack>
  )
}

export default HeaderStep
