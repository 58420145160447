import { AxiosInstance, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { ResponseData, PmcData } from "./common"

export interface HostPmcResponseData extends ResponseData {
  data: PmcData[]
}

export interface HostPmcResponse extends AxiosResponse {
  data: HostPmcResponseData
}

// eslint-disable-next-line @typescript-eslint/restrict-template-expressions

export const defaultUrl = `${process.env.REACT_APP_BASE_API_URL || ""}/host/pmc`

export function getHostPmc(
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<HostPmcResponse> {
  return axiosInstance({
    method: "get",
    url: defaultUrl,
  }) as Promise<HostPmcResponse>
}

const getAdminPmcURL = `${process.env.REACT_APP_BASE_API_URL || ""}/admin/pmc`

export function getAdminPmc(
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<HostPmcResponse> {
  return axiosInstance({
    method: "get",
    url: getAdminPmcURL,
  }) as Promise<HostPmcResponse>
}
