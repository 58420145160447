import { ReactNode, useCallback } from "react"

import { WarningTwoIcon } from "@chakra-ui/icons"
import { Box, Button, HStack, Text } from "@chakra-ui/react"

import { useAppDispatch, useAppSelector } from "app/hooks"
import { selectUserLoggedIn } from "store/auth/authSlice"
import { setIsLoginModalOpen } from "store/ui/uiSlice"

type ProtectedComponentProps = {
  children: ReactNode
}
const ProtectedComponent = ({ children }: ProtectedComponentProps) => {
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectUserLoggedIn)

  const handleOpenLoginModal = useCallback(() => {
    dispatch(setIsLoginModalOpen(true))
  }, [dispatch])

  return isLoggedIn ? (
    <>{children} </>
  ) : (
    <Box
      h='400px'
      w='full'
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDir='column'
    >
      <WarningTwoIcon color='primaryColor' w={28} h={28} />
      <Text size='xxl' mt={8} mb={4}>
        Please Login first
      </Text>
      <HStack>
        <Button size={{ base: "sm", md: "md" }} onClick={handleOpenLoginModal}>
          Log In
        </Button>
      </HStack>
    </Box>
  )
}
export default ProtectedComponent
