import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "app/store"
import { apiClient } from "services/client"
import { GetCountryByCodeRequest } from "services/country"
import {
  IndividualAccountRequest,
  IndividualBankAccountRequest,
} from "services/payout"

import { BankInfoDetails, PayoutInitialState } from "./types"

export const getCountries = createAsyncThunk(
  "whimstay/getCountries",
  async () => {
    const response = await apiClient.getCountries()

    return response.data
  },
)

export const getCountriesByCode = async (params: GetCountryByCodeRequest) => {
  const response = await apiClient.getCountryByCode(params)

  return response.data
}

export const setindividualAccount = async (
  params: IndividualAccountRequest,
) => {
  const response = await apiClient.setindividualAccount(params)

  return response.data
}

export const setindividualBankAccount = async (
  params: IndividualBankAccountRequest,
) => {
  const response = await apiClient.setindividualBankAccount(params)

  return response.data
}
const country = {
  id: "",
  defaultCurrency: "",
  supportedPaymentCurrencies: [],
  supportedPaymentMethods: [],
  supportedTransferCountries: [],
  companyFieldsMinimum: [],
  companyFieldsAdditional: [],
  individualFieldsMinimum: [],
  individualFieldsAdditional: [],
}
const bankInfo = {
  pmcId: "",
  pgHostAccId: undefined,
  accountHolderType: "individual",
  accountHolderName: "",
  country: "US",
}

const initialState: PayoutInitialState = {
  countries: { ...country },
  isRegisterScreenOpen: false,
  bankinfoDetails: { ...bankInfo },
}

export const payoutSlice = createSlice({
  name: "payout",
  initialState,
  reducers: {
    reset: () => initialState,
    setisRegisterScreenOpen: (state, action: PayloadAction<boolean>) => {
      state.isRegisterScreenOpen = action.payload
    },
    setisBankInfoDetails: (state, action: PayloadAction<BankInfoDetails>) => {
      state.bankinfoDetails.pmcId = action.payload.pmcId
      state.bankinfoDetails.country = action.payload.country
      state.bankinfoDetails.pgHostAccId = action.payload.pgHostAccId
      state.bankinfoDetails.accountHolderType = action.payload.accountHolderType
      state.bankinfoDetails.accountHolderName = action.payload.accountHolderName
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload.data
    })
  },
})

export const { setisRegisterScreenOpen, setisBankInfoDetails, reset } =
  payoutSlice.actions

export const selectisRegisterScreenOpen = (state: RootState) =>
  state.payoutReducer.isRegisterScreenOpen

export const selectBankInfoDetails = (state: RootState) =>
  state.payoutReducer.bankinfoDetails

export default payoutSlice.reducer
