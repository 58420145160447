import {
  addDays,
  addMonths,
  addYears,
  subDays,
  subMonths,
  subYears,
  format,
  add,
  parseISO,
} from "date-fns"

import { CalendarDateData } from "services/common"

import { SingleBookedDateProps } from "./type"

export const getDateInYYYYMMddFormat = (date: Date) =>
  format(date, "yyyy-MM-dd")

const firstDayOfMonth = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), 1)
const lastDayOfMonth = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0)

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

const getDaysArray = (start: Date, end: Date): Date[] => {
  const arr: Date[] = []
  for (const dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt))
  }
  return arr
}

export const daysNames = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]

export const daysBefore: { [key: string]: number } = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
}

const daysMonth = (date: Date): Date[] =>
  getDaysArray(firstDayOfMonth(date), lastDayOfMonth(date))

const dayOfWeek = (date: Date): keyof typeof daysBefore =>
  date.toLocaleDateString("ISO", { weekday: "long" }).toLowerCase() // STRING DOES NOT RECOGNIZE KEYS AS VALID ONES

const dateMove = (
  date: Date,
  numberOfDays: number,
  direction: "back" | "forward",
): Date =>
  direction === "back"
    ? subDays(new Date(date), numberOfDays)
    : addDays(new Date(date), numberOfDays)

const daysMonthBefore = (date: Date): Date[] => {
  const firstDateMonth = firstDayOfMonth(date)
  const dayOfTheWeek = dayOfWeek(firstDateMonth)
  const dateBefore = dateMove(firstDateMonth, daysBefore[dayOfTheWeek], "back")
  const daysBack = getDaysArray(dateBefore, dateMove(firstDateMonth, 1, "back"))
  return daysBack
}

const daysMonthAfter = (date: Date): Date[] => {
  const daysBeforeCount = daysMonthBefore(date).length
  const daysOfTheMonth = lastDayOfMonth(date).getDate()
  const diff = 35 - daysBeforeCount - daysOfTheMonth
  const dateAfter = dateMove(
    lastDayOfMonth(date),
    diff < 0 ? 7 + diff : diff,
    "forward",
  )
  const firstDayOfNextMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    1,
  )
  const daysAfterMonth = getDaysArray(firstDayOfNextMonth, dateAfter)
  return daysAfterMonth
}

export const generateDates = (date: Date): Date[] => [
  ...daysMonthBefore(date),
  ...daysMonth(date),
  ...daysMonthAfter(date),
]

export const goYearBack = (
  date: Date,
  setDate: (date: Date) => void,
  setDays: (dates: Date[]) => void,
) => {
  const update = new Date(subYears(new Date(date), 1))
  setDate(update)
  setDays(generateDates(update))
}
export const goMonthBack = (
  date: Date,
  setDate: (date: Date) => void,
  setDays: (dates: Date[]) => void,
) => {
  const update = new Date(subMonths(new Date(date), 1))
  setDate(update)
  setDays(generateDates(update))
}
export const goMonthForward = (
  date: Date,
  setDate: (date: Date) => void,
  setDays: (dates: Date[]) => void,
) => {
  const update = new Date(addMonths(new Date(date), 1))
  setDate(update)
  setDays(generateDates(update))
}
export const goYearForward = (
  date: Date,
  setDate: (date: Date) => void,
  setDays: (dates: Date[]) => void,
) => {
  const update = new Date(addYears(new Date(date), 1))
  setDate(update)
  setDays(generateDates(update))
}

export const generateIntermediateDates = (
  startDate: string,
  endDate: string,
  days: Date[],
) => {
  const result = days
    .filter(
      (day) =>
        (new Date(startDate) < new Date(getDateInYYYYMMddFormat(day)) &&
          new Date(getDateInYYYYMMddFormat(day)) < new Date(endDate)) ||
        (new Date(startDate) > new Date(getDateInYYYYMMddFormat(day)) &&
          new Date(getDateInYYYYMMddFormat(day)) > new Date(endDate)),
    )
    .map((day: Date) => getDateInYYYYMMddFormat(day))

  return result
}
export const generateRangeWithoutBookedDates = (
  startDate: string,
  endDate: string,
  days: Date[],
  bookedDatesList: string[],
) => {
  const result = days
    .filter(
      (day) =>
        new Date(startDate) < new Date(day) &&
        new Date(day) < new Date(endDate) &&
        !bookedDatesList.includes(getDateInYYYYMMddFormat(day)),
    )
    .map((day: Date) => getDateInYYYYMMddFormat(day))

  return result
}

export const getFormatedDate = (date: Date) =>
  date !== undefined ? getDateInYYYYMMddFormat(date) : ""

export const convertToLocalTimezone = (dateString: number) =>
  new Date(dateString).toLocaleDateString("en-US", {
    timeZone: "UTC",
    month: "short",
    day: "2-digit",
  })

export const convertDateToLocalTimeZoneFormatted = (dateString: number) => {
  const date = new Date(dateString)
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

export const compareTwoDatesWithGreater = (date1: string, date2: string) =>
  new Date(date1) > new Date(date2)

export const compareTwoDatesWithLesser = (date1: string, date2: string) =>
  new Date(date1) < new Date(date2)

export const CheckBookedDateRelatively = (
  bookedDateRange: SingleBookedDateProps,
  prev: string,
  curr: string,
  next: string,
) => {
  // current date is starting date of booked date range
  if (bookedDateRange.fromDate === curr) {
    if (
      new Date(bookedDateRange.fromDate) <= new Date(prev) &&
      new Date(prev) < new Date(bookedDateRange.toDate) &&
      new Date(bookedDateRange.fromDate) < new Date(next) &&
      new Date(next) <= new Date(bookedDateRange.toDate)
    ) {
      return { isBooked: true, leftJoin: true, rightJoin: true }
    }
    if (
      new Date(bookedDateRange.fromDate) < new Date(next) &&
      new Date(next) <= new Date(bookedDateRange.toDate)
    ) {
      return { isBooked: true, leftJoin: false, rightJoin: true }
    }
  }
  // current date is lie in the range of booked date
  if (
    new Date(bookedDateRange.fromDate) < new Date(curr) &&
    new Date(curr) < new Date(bookedDateRange.toDate)
  ) {
    if (
      new Date(bookedDateRange.fromDate) <= new Date(prev) &&
      new Date(prev) < new Date(bookedDateRange.toDate) &&
      new Date(bookedDateRange.fromDate) < new Date(next) &&
      new Date(next) <= new Date(bookedDateRange.toDate)
    ) {
      return { isBooked: true, leftJoin: true, rightJoin: true }
    }
  }
  // current date is last date of the booked date range
  if (bookedDateRange.toDate === curr) {
    if (
      new Date(bookedDateRange.fromDate) <= new Date(prev) &&
      new Date(prev) < new Date(bookedDateRange.toDate)
    ) {
      return { isBooked: true, leftJoin: true, rightJoin: false }
    }
    return { isBooked: true, leftJoin: false, rightJoin: false }
  }
  return { isBooked: false, leftJoin: false, rightJoin: false }
}

export const isCheckBookedDate = (
  prevDay: string,
  currDay: string,
  nextDay: string,
  bookedDates: SingleBookedDateProps[],
) => {
  const result = bookedDates.map((item) => {
    // only single booked date available
    if (item.fromDate === currDay && item.toDate === currDay) {
      return { isBooked: true, leftJoin: false, rightJoin: false }
    }

    // prevday undefined
    if (prevDay.length <= 0) {
      const yesterday = getFormatedDate(add(new Date(currDay), { days: -1 }))
      const dataObj = CheckBookedDateRelatively(
        item,
        yesterday,
        currDay,
        nextDay,
      )

      return dataObj
    }

    // nextday undefinde
    if (nextDay.length <= 0) {
      const tommorrow = getFormatedDate(add(new Date(currDay), { days: 1 }))
      const dataObj = CheckBookedDateRelatively(
        item,
        prevDay,
        currDay,
        tommorrow,
      )

      return dataObj
    }

    // current date is neither last date nor first date of month
    const dataObj = CheckBookedDateRelatively(item, prevDay, currDay, nextDay)

    return dataObj
  })

  const [obj] = result.filter((item) => item?.isBooked)
  if (obj) {
    return obj
  }
  return { isBooked: false, leftJoin: false, rightJoin: false }
}

export const fillinMissingDateInCalendar = (
  tempCalendarRates: CalendarDateData[],
  coverage: number,
) => {
  if (tempCalendarRates && tempCalendarRates.length >= 2) {
    const startDate = parseISO(format(new Date(), "yyyy-MM-dd"))
    let endDate = parseISO(format(new Date(), "yyyy-MM-dd"))
    endDate.setDate(endDate.getDate() + coverage)
    endDate = new Date(endDate)

    const filterData = tempCalendarRates.filter(
      (singleDate) =>
        new Date(singleDate.valid_from) <= endDate &&
        new Date(singleDate.valid_from) >= startDate,
    )

    if (filterData.length <= coverage) {
      filterData.push({
        valid_from: format(endDate, "yyyy-MM-dd"),
        base_price: 0,
        available: false,
        min_nights: tempCalendarRates[0].min_nights,
        max_nights: tempCalendarRates[0].max_nights || 365,
        arrival: false,
        departure: true,
        updateDt: 0,
        pm_base_price: null,
        weekly_base_price: null,
        max_rent: 0,
        whimstay_availability: false,
        djs_discount: 0,
        weekly_arrival: null,
        weekly_departure: null,
        priceTotal: 0,
        isAvailableForCheckIn: false,
        other_fee_Data: undefined,
        los: undefined,
        date: 0,
      })
    }
    // setup missiing date
    const missingDates = filterData
      .sort((a, b) => Date.parse(a.valid_from) - Date.parse(b.valid_from))
      .reduce(
        ((hash: { prev: number }) =>
          function (acc: Date[], c) {
            const missingDaysNo =
              (Date.parse(c.valid_from) - hash.prev) / (1000 * 3600 * 24)
            if (hash.prev && missingDaysNo > 1) {
              // eslint-disable-next-line no-plusplus
              for (let i = 1; i < missingDaysNo; i++)
                acc.push(
                  parseISO(
                    format(
                      new Date(hash.prev + i * (1000 * 3600 * 24)),
                      "yyyy-MM-dd",
                    ),
                  ),
                )
            }
            // eslint-disable-next-line no-param-reassign
            hash.prev = Date.parse(c.valid_from)
            return acc
          })(Object.create(null) as { prev: number }),
        [],
      )

    missingDates.forEach((res) =>
      filterData.push({
        valid_from: format(res, "yyyy-MM-dd"),
        base_price: 0,
        available: false,
        min_nights: filterData[0].min_nights,
        max_nights: filterData[0].max_nights || 365,
        arrival: false,
        departure: true,
        updateDt: 0,
        pm_base_price: null,
        weekly_base_price: null,
        max_rent: 0,
        whimstay_availability: false,
        djs_discount: 0,
        weekly_arrival: null,
        weekly_departure: null,
        priceTotal: 0,
        isAvailableForCheckIn: false,
        other_fee_Data: undefined,
        los: undefined,
        date: 0,
      }),
    )

    filterData.sort(
      (a, b) => Date.parse(a.valid_from) - Date.parse(b.valid_from),
    )

    return filterData
  }
  return tempCalendarRates
}
