import { useEffect, useState } from "react"

import {
  Box,
  Button,
  Text,
  RadioGroup,
  Stack,
  Radio,
  Spinner,
  Link,
} from "@chakra-ui/react"
import { AxiosError } from "axios"

import { useAppDispatch, useAppSelector } from "app/hooks"
import LearnMoreModal from "components/learn-modal-modal/learn-more-modal"
import TemplateHeader from "components/template/template-header"
import useToastHook from "hooks/useToastHook/useToastHook"
import { PDPType } from "services/common"
import { updateCompanyPdp } from "services/properties/contact-templates"
import { selectCurrentPmc, getHostPmc } from "store/auth/authSlice"

const PropertyDamageProtection = () => {
  const dispatch = useAppDispatch()
  const displayToast = useToastHook() // to display toast message
  const currentPmc = useAppSelector(selectCurrentPmc)

  const [pdpPlan, setPdpPlan] = useState("")
  const [isDisabledPdp, setIsDisabledPdp] = useState(true)
  const [isPdpLoading, setIsPdpLoading] = useState(false)

  useEffect(() => {
    setPdpPlan(currentPmc?.pdp || "")
  }, [currentPmc?.pdp])

  // handle pdp value onchange
  const handlePdpOnChange = (value: string) => {
    setPdpPlan(value)
    setIsDisabledPdp(false)
  }

  // update PDP plan
  const handleUpdatePdpPlan = async () => {
    setIsDisabledPdp(true)
    setIsPdpLoading(true)
    const newData = {
      pmcId: currentPmc?.pmc_id || "",
      pdp: pdpPlan,
    }

    await updateCompanyPdp({ params: newData })
      .then(async (response) => {
        if (response.data.apiStatus === "SUCCESS") {
          displayToast({
            message: response.data.message,
          })
          setIsPdpLoading(false)
          await dispatch(getHostPmc()).unwrap()
        } else {
          displayToast({
            message: response.data.message,
          })
          setIsPdpLoading(false)
          setIsDisabledPdp(false)
        }
        return true
      })
      .catch((e: AxiosError) => {
        displayToast({
          message: e.message,
          variant: "error",
        })
      })
  }

  return (
    <Box pb={{ base: "0px", md: "167px" }}>
      <TemplateHeader text='Select Your Property Damage Protection (PDP) Plan' />
      <Box
        border='1px solid'
        borderColor='silverColor'
        my={6}
        borderRadius='10px'
        maxW={{ base: "full", xl: "629px" }}
      >
        <Box py={6} px={{ base: 6, md: 10 }}>
          <Text mb={6} fontSize={14}>
            Please choose a preferred protection plan for your listed
            properties:
          </Text>
          <RadioGroup onChange={(e) => handlePdpOnChange(e)} value={pdpPlan}>
            <Stack>
              <Radio value={PDPType.Existing}>Use the PDP from my PMS</Radio>
              <Radio value={PDPType.Whimstay}>Use Whimstay PDP</Radio>
            </Stack>
          </RadioGroup>
          {/* Render the LearnMoreModal component */}
          <LearnMoreModal
            title='Property Damage Protection'
            width={{ base: "280px", xsm: "300px", sm: "410px", md: "600px" }}
            ml={6}
          >
            <Box>
              <Text fontSize={{ base: "14px", sm: "16px" }} mb={5}>
                Whimstay’s PDP costs $3.15 per night, which is paid by the
                traveler and included as part of the service fees included in
                their booking total.
              </Text>
              <Text fontSize={{ base: "14px", sm: "16px" }}>
                Whimstay’s PDP applies to bookings up to 29 nights (no
                protection on 29+ nights). Property managers can only have one
                coverage plan – either through Whimstay or through their own PDP
                plan provided by the PMS.{" "}
              </Text>
              <Link
                href='https://www.whimstay.com/blog/property-damage-protection-from-whimstay'
                target='_blank'
                textDecoration='underline'
                fontSize={{ base: "14px", sm: "16px" }}
              >
                View the knowledge base article for more information
              </Link>
            </Box>
          </LearnMoreModal>
        </Box>
        <Box
          py={6}
          px={{ base: 6, md: 10 }}
          borderTop='1px'
          borderColor='silverColor'
        >
          <Button
            w='100%'
            height='50px'
            isDisabled={isDisabledPdp}
            onClick={() => handleUpdatePdpPlan()}
          >
            {isPdpLoading ? <Spinner size='sm' /> : "Save"}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default PropertyDamageProtection
