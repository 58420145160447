import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import {
  AmenityData,
  AmenityRequestData,
  CustomAmenityData,
  CustomAmenityRequestData,
  GetCustomAmenityData,
  GetCustomAmenityRequestData,
  ResponseData,
} from "./common"

export interface AmenitiesResponseData extends ResponseData {
  data: AmenityData[]
}

export interface CustomAmenitiesResponseData extends ResponseData {
  data: CustomAmenityData[]
}

export interface GetCustomAmenitiesResponseData extends ResponseData {
  data: GetCustomAmenityData
}

export interface CustomAmenitiesResponse extends AxiosResponse {
  data: CustomAmenitiesResponseData
}
export interface AmenitiesResponse extends AxiosResponse {
  data: AmenitiesResponseData
}

export interface GetCustomAmenitiesResponse extends AxiosResponse {
  data: GetCustomAmenitiesResponseData
}

export interface AmenitiesRequest extends Partial<AxiosRequestConfig> {
  params: AmenityRequestData
}

export interface CustomAmenitiesRequest extends Partial<AxiosRequestConfig> {
  params: CustomAmenityRequestData
}

export interface GetCustomAmenitiesRequest extends Partial<AxiosRequestConfig> {
  params: GetCustomAmenityRequestData
}

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/properties/features`

export function getDefaultAmenities(
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AmenitiesResponse> {
  return axiosInstance({
    method: "get",
    url: `${defaultUrl}/default-amenities`,
  }) as Promise<AmenitiesResponse>
}

export function getCustomAmenities(
  {
    params,
    method = "get",
    url = `${process.env.REACT_APP_BASE_API_URL || ""}/properties/feature`,
    ...config
  }: GetCustomAmenitiesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetCustomAmenitiesResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetCustomAmenitiesResponse>
}

export function addAmenities(
  { params, method = "post", url = defaultUrl, ...config }: AmenitiesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AmenitiesResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<AmenitiesResponse>
}

export function addCustomAmenities(
  {
    params,
    method = "post",
    url = `${defaultUrl}/add-custom-amenities`,
    ...config
  }: CustomAmenitiesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AmenitiesResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<AmenitiesResponse>
}

export interface DeleteCustomAmenitiesType {
  propertyFeatureId: string
}

export function deleteCustomAmenity(
  data: DeleteCustomAmenitiesType,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AmenitiesResponse> {
  return axiosInstance({
    method: "delete",
    data,
    params: { propertyFeatureId: data?.propertyFeatureId },
    url: `${defaultUrl}/delete-custom-amenities`,
  }) as Promise<AmenitiesResponse>
}
