import { Box, Text, useMediaQuery } from "@chakra-ui/react"

import { useAppSelector } from "app/hooks"
import MobileSideMenu from "components/sidemenu/mobilesidemenu"
import { selectListingsPageMenuItems } from "store/ui/uiSlice"

const TemplateHeader = ({ text }: { text: string }) => {
  const [isMobile] = useMediaQuery("(max-width: 1024px)")
  const listingPageMenuItems = useAppSelector(selectListingsPageMenuItems)
  return (
    <Box
      mb={{ base: "0px", lg2: "12px" }}
      display='flex'
      justifyContent='space-between'
      gap='16px'
    >
      {/* title and create button */}
      <Text
        size={{
          base: "lg2",
          md: "xl",
        }}
        fontWeight={{
          base: 600,
          md: 700,
        }}
        variant='black'
      >
        {text}
      </Text>
      {isMobile && <MobileSideMenu sidebarMenuArr={listingPageMenuItems} />}
    </Box>
  )
}
export default TemplateHeader
