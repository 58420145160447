import { ReactNode } from "react"

import { ChakraProvider } from "@chakra-ui/react"
import { Provider as ReduxProvider } from "react-redux"

import { store } from "app/store"
import theme from "theme"

type ProviderProps = {
  children: ReactNode
}

const Providers = (props: ProviderProps) => {
  const { children } = props

  return (
    <ReduxProvider store={store}>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </ReduxProvider>
  )
}

export default Providers
