import { BrowserRouter } from "react-router-dom"

import Layout from "layouts"
import Providers from "providers"
import Router from "router"
import "./index.css"

export const App = () => (
  <Providers>
    <BrowserRouter basename={`${process.env.REACT_APP_BASENAME || "/"}`}>
      <Layout>
        <Router />
      </Layout>
    </BrowserRouter>
  </Providers>
)
