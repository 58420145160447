import { Icon } from "@chakra-ui/react"

const ProfileIcon = (props: any) => (
  <Icon width='56px' height='56px' viewBox='0 0 56 56' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.70694 49.1988C9.09158 47.7992 8.75 46.252 8.75 44.625V42C8.75 37.1675 12.6675 33.25 17.5 33.25H38.5C43.3325 33.25 47.25 37.1675 47.25 42V44.625C47.25 46.252 46.9084 47.7992 46.2931 49.1988C41.3862 53.4368 34.9924 56 28 56C21.0076 56 14.6138 53.4368 9.70694 49.1988Z'
      fill='#C4C4C4'
    />
    <circle cx='28' cy='28' r='27.125' stroke='#C4C4C4' strokeWidth='1.75' />
    <path
      d='M28 29.75C32.8325 29.75 36.75 25.8325 36.75 21C36.75 16.1675 32.8325 12.25 28 12.25C23.1675 12.25 19.25 16.1675 19.25 21C19.25 25.8325 23.1675 29.75 28 29.75Z'
      fill='#C4C4C4'
    />
  </Icon>
)

export default ProfileIcon
