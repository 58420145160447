/* eslint-disable no-param-reassign */

import { HttpsAgent } from "agentkeepalive"
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios"
import axiosRetry, { IAxiosRetryConfig } from "axios-retry"

import {
  authorizeCodeApi,
  AuthorizeCodeRequest,
  AuthorizeCodeResponse,
} from "services/authorize-code"
import { getDeviceIdApi, GetDeviceIdResponse } from "services/deviceId"
import {
  resetPasswordLink,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from "services/forgot-password"
import {
  HostFullyRequest,
  HostFullyResponse,
  hostFullySubmit,
} from "services/hostfully-user"
import {
  addEditAvailabilityBlocks,
  AddEditAvailabilityBlocksResponse,
  AvailabilityBlocksDeleteResponse,
  AvailabilityBlockType,
  deleteAvailabilityBlocks,
  DeleteAvailabilityBlocksRequestType,
  getAvailabilityBlocksByPmcId,
  GetAvailabilityBlocksByPmcIdRequest,
  GetAvailabilityBlocksByPmcIdResponse,
} from "services/properties/availability-blocks"
import {
  VerifyResetLinkResponse,
  verifyResetLink,
  VerifyResetLinkRequest,
  newPasswordSetup,
  NewPasswordSetupRequest,
  NewPasswordSetupResponse,
} from "services/reset-password"
import {
  getAuthToken,
  getInviteAuth,
  getReCaptchaToken,
  getTempAuthToken,
  removeAuthToken,
  removeReCaptchaToken,
  removeTempAuthToken,
} from "utils/local-storage"

import { customAdapter } from "./adapter"
import {
  getDefaultAmenities,
  AmenitiesResponse,
  addAmenities,
  AmenitiesRequest,
  addCustomAmenities,
  CustomAmenitiesRequest,
  getCustomAmenities,
  GetCustomAmenitiesRequest,
  deleteCustomAmenity,
  DeleteCustomAmenitiesType,
  GetCustomAmenitiesResponse,
} from "./amenities"
import {
  addAttachment,
  AttachmentRequest,
  AttachmentResponse,
  removeAttachment,
  RemoveAttachmentRequest,
  RemoveAttachmentResponse,
  ReorderAttachmentResponse,
  reorderAttachment,
  ReorderAttachmentRequest,
  updateAttachment,
  UpdateAttachmentRequest,
  getAllAttachments,
  GetAllAttachmentsRequest,
  GetAllAttachmentsResponse,
  uploadVideo,
  UploadVideoResponse,
  UploadVideoRequest,
} from "./attachments"
import { AuthRequest, AuthResponse, createPassword, login } from "./auth"
import {
  addRoomDetails,
  AddRoomDetailsRequest,
  AddRoomDetailsResponse,
  DefaultBedsResponse,
  getDefaultBeds,
  getRoomDetails,
  RoomDetailsRequest,
  RoomDetailsResponse,
} from "./bedroom"
import {
  BookingsPropertyResponse,
  BookingsResponse,
  fetchSingleBookingDataApi,
  GetBookingPropertyRequest,
  getBookings,
  GetBookingsRequest,
} from "./bookings"
import {
  exportCalendar,
  ExportCalendarRequest,
  ExportCalendarResponse,
  getCalendarBookedDates,
  GetCalendarBookedDatesRequest,
  GetCalendarBookedDatesResponse,
  getCalendarData,
  getCalendarDates,
  GetCalendarDatesRequest,
  GetCalendarDatesResponse,
  GetCalendarExportLinkResponse,
  GetCalendarRequest,
  GetCalendarResponse,
  getExportCalendarLink,
  importCalendar,
  ImportCalendarRequest,
  ImportCalendarResponse,
  SetCalendarImportLinkRequest,
  setImportCalendarLink,
  updateCalendarData,
  UpdateCalendarDataRequest,
  updateCalendarDates,
  UpdateCalendarDatesRequest,
  UpdateCalendarDatesResponse,
  updateCalendarWeekendPrice,
  UpdateCalendarWeekendPriceRequest,
} from "./calendar"
import {
  ContactUsRequest,
  ContactUsResponse,
  contactUsSubmit,
} from "./contact-us"
import {
  CountryResponse,
  getCountries,
  getCountriesByCode,
  GetCountryByCodeRequest,
} from "./country"
import {
  getErrorLogs,
  GetErrorLogsRequest,
  GetErrorLogsResponse,
} from "./errorLogs"
import { getEvents, GetEventsRequest, GetEventsResponse } from "./events"
import {
  HouseRulesRequest,
  addHouseRules,
  HouseRulesResponse,
} from "./features"
import {
  FeeCategoriesResponse,
  FeeCategoryResponse,
  getCategoryData,
  getFeeCategories,
  GetFeeCategoryDataRequest,
  UpdateCategoryDataRequest,
  UpdateCategoryResponse,
  updateFeeCategoryData,
} from "./fee-mapping"
import { getAdminPmc, getHostPmc, HostPmcResponse } from "./host"
import {
  getLiveProperties,
  GetLivePropertiesRequest,
  GetLivePropertiesResponse,
  publishProperties,
  companyStatus,
  inactiveProperties,
  PublishPropertiesRequest,
  PublishPropertiesResponse,
  deleteProperties,
  DeletePropertiesRequest,
  DeletePropertiesResponse,
  CheckPublishPropertiesStatusResponse,
  checkPublishPropertiesStatus,
  CheckPublishPropertiesStatusRequest,
  SyncPropertiesRequest,
  SyncPropertiesResponse,
  syncProperties,
  PmcDefaultCheckRequest,
  PmcDefaultCheckResponse,
  pmcDefaultCheck,
  InactivePropertiesRequest,
  InactivePropertiesResponse,
  AddingTagResponse,
  AddingTagRequest,
  addingTag,
  CompanyStatusRequest,
  CompanyStatusResponse,
  GetAdminCompanyRequest,
  GetAdminCompanyResponse,
  addingCustomTag,
  AddingCustomTagRequest,
  getCompany,
  GetStatesRequest,
  GetStatesResponse,
  getStates,
  updateWeight,
  UpdateWeightResponse,
  UpdateWeightRequest,
  GetMinimumAgePropertiesRequest,
  GetMinimumAgePropertiesResponse,
  getMinimumAgeProperties,
  UpdateMinimumAgeRequest,
  UpdateMinimumAgeResponse,
  updateMinimumAge,
  autoPublishProperties,
  AutoPublishPropertyRequest,
} from "./liveProperties"
import {
  GetNotificationRequest,
  GetNotificationResponse,
  getNotifications,
  readNotification,
  ReadNotificationRequest,
  UpdateNotificationRequest,
  UpdateNotificationResponse,
  updateNotificationStatus,
} from "./notification"
import {
  VerifyOtpRequest,
  VerifyOtpResponse,
  verifyOtp,
  VerifyPasswordRequest,
  verifyPassword,
  VerifyPasswordResponse,
} from "./otp"
import {
  deletePayoutAccount,
  DeletePayoutAccountRequest,
  DeletePayoutAccountResponse,
  IndividualAccountRequest,
  IndividualAccountResponse,
  IndividualBankAccountRequest,
  makeDefaultPayoutAccount,
  MakeDefaultPayoutAccountRequest,
  MakeDefaultPayoutAccountResponse,
  setindividualAccount,
  setindividualBankAccount,
} from "./payout"
import { getPMSLogs, GetPMSLogsRequest, GetPMSLogsResponse } from "./pmslogs"
import {
  addEditUser,
  deleteUser,
  AddEditUserType,
  getUsersByPmcId,
  GetUsersByPmcIdRequest,
  GetUsersByPmcIdResponse,
  UserDeleteResponse,
  DeleteUserType,
  GetUsersByCodeRequest,
  getUsersByCode,
  AddEditUserByCodeType,
  addEditUserByCode,
  DeleteUserByCodeType,
  deleteUserByCode,
  ManageUserByCodeRequest,
  ManageUserByCodeResponse,
  manageUserByCode,
  AcceptTermsConditionsRequest,
  acceptTermsConditions,
  AcceptTermsConditionsResponse,
  AddEditUserResponse,
} from "./profile/manage-users"
import {
  applyPayoutRulesOnProperties,
  ApplyPayoutRulesOnPropertiesRequest,
  ApplyPayoutRulesOnPropertiesResponse,
  GetAccountsByPmcIdRequest,
  GetAccountsByPmcIdResponse,
  getHostAccountList,
  getPayoutProperties,
  PayoutPropertiesRequest,
  PayoutPropertyResponse,
} from "./profile/payout-settings"
import {
  createProperty,
  getPMCDescription,
  GetPmcDescriptionRequest,
  GetPMCDescriptionResponse,
  getProperty,
  GetPropertyRequest,
  GetPropertyResponse,
  hidePropertyImage,
  HidePropertyImageRequest,
  HidePropertyImageResponse,
  PropertUpdateRequest,
  PropertyRequest,
  PropertyResponse,
  PropertyUpdateResponse,
  updateProperty,
  updatePropertyDescription,
} from "./properties"
import {
  addEditContactTemplate,
  AddEditContactTemplateResponse,
  deleteContactTemplate,
  DeleteContactTemplateRequestType,
  ContactTemplateDeleteResponse,
  ContactTemplateType,
  getContactTemplatesByPmcId,
  GetContactTemplatesByPmcIdRequest,
  GetContactTemplatesByPmcIdResponse,
  updateCompanyPdp,
  UpdateCompanyPdpRequest,
  UpdateCompanyPdpResponse,
} from "./properties/contact-templates"
import {
  addEditDiscountTemplate,
  AddEditDiscountTemplateResponse,
  applyTemplateOnProperties,
  ApplyTemplateOnPropertiesRequest,
  ApplyTemplateOnPropertiesResponse,
  deleteDiscountTemplate,
  DeleteDiscountTemplateRequestType,
  DiscountTemplateDeleteResponse,
  DiscountTemplateType,
  getDiscountTemplatesByPmcId,
  GetDiscountTemplatesByPmcIdRequest,
  GetDiscountTemplatesByPmcIdResponse,
  getTemplateProperties,
  TemplatePropertiesRequest,
  TemplatePropertyResponse,
} from "./properties/discount-templates"
import {
  ActivePropertiesReportRequest,
  ActivePropertiesReportResponse,
  EarningReportsResponse,
  getActivePropertiesReport,
  getEarningReportData,
  getPayoutReportData,
  GetPayoutReportRequest,
  getReports,
  GetReportsRequest,
  PayoutReportResponse,
  ReportsResponse,
} from "./reports"
import {
  addReply,
  AddReplyRequest,
  AddReplyResponse,
  addReview,
  AddReviewRequest,
  AddReviewResponse,
  deleteReply,
  DeleteReplyRequest,
  DeleteReplyResponse,
  deleteReview,
  DeleteReviewRequest,
  DeleteReviewResponse,
  getReviewsAndRatings,
  GetReviewsAndRatingsRequest,
  ReviewsAndRatingsResponse,
} from "./reviewsAndRatings"
import {
  saveUserApi,
  SaveUserRequest,
  SaveUserResponse,
  signUpVerifyOtp,
  SignupVerifyOtpRequest,
} from "./signup"
import {
  getUser,
  updateUser,
  ImageUploadRequest,
  ImageUploadResponse,
  uploadProfileImage,
  UserResponse,
  UpdateUserType,
  GetUserRequest,
} from "./user"

export const defaultHttpsAgent = new HttpsAgent({ keepAlive: true })
export const defaultTimeout = 40000

const defaultConfig: AxiosRequestConfig = {
  timeout: defaultTimeout,
  httpsAgent: defaultHttpsAgent,
  adapter: customAdapter,
  headers: {},
}

export const defaultAxiosInstance = axios.create(defaultConfig)
axiosRetry(defaultAxiosInstance)

defaultAxiosInstance.interceptors.request.use(
  (request) => {
    const authToken = getAuthToken()
    const inviteAuthToken = getInviteAuth()
    const tempAuthToken = getTempAuthToken()
    // eslint-disable-next-line no-console
    console.log("API call to", request.url)

    const reCaptchaToken = getReCaptchaToken()
    removeReCaptchaToken()

    if (reCaptchaToken) {
      removeTempAuthToken()
      removeAuthToken()
      return {
        ...request,
        headers: {
          ...request.headers,
          "whimstay-auth": `whimstay ${reCaptchaToken}`,
          app: "host",
        },
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if ((request.data?.invCode || request.params?.invCode) && inviteAuthToken) {
      // For manage user by invitation code APIs we need to set different AuthToken.
      return {
        ...request,
        headers: {
          ...request.headers,
          Authorization: inviteAuthToken,
          app: "host",
        },
      }
    }

    if (tempAuthToken) {
      removeAuthToken()
      return {
        ...request,
        headers: {
          ...request.headers,
          Authorization: tempAuthToken,
          app: "host",
        },
      }
    }

    if (authToken) {
      return {
        ...request,
        headers: { ...request.headers, Authorization: authToken, app: "host" },
      }
    }

    return {
      ...request,
      headers: { ...request.headers, app: "host" },
    }
  },
  // eslint-disable-next-line promise/no-promise-in-callback
  (error: AxiosError) => Promise.reject(error),
)

defaultAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (error?.response?.status === 401) {
      removeAuthToken()
      window.location.href = "/"
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return Promise.reject(error.response ? error.response.data : error)
  },
)

export type Config = {
  raxConfig?: IAxiosRetryConfig
} & AxiosRequestConfig

export interface ClientOptions {
  axiosInstance?: AxiosInstance
  config?: Config
}

export class Client {
  private axiosInstance: AxiosInstance

  constructor({ axiosInstance, config }: ClientOptions = {}) {
    if (axiosInstance && config) {
      throw new Error("Provide one of axiosInstance or config.")
    }

    if (axiosInstance) {
      this.axiosInstance = axiosInstance
      this.axiosInstance.defaults.headers = {
        ...defaultConfig.headers,
        ...this.axiosInstance.defaults.headers,
      }
    } else if (config) {
      config = { ...defaultConfig, ...config }
      config.headers = { ...defaultConfig.headers, ...(config.headers || {}) }
      this.axiosInstance = axios.create(config)
      axiosRetry(this.axiosInstance)
    } else {
      this.axiosInstance = defaultAxiosInstance
    }
  }

  // logout(request: AuthRequest): Promise<AuthResponse> {
  //   return logout(request, this.axiosInstance)
  // }

  login(request: AuthRequest): Promise<AuthResponse> {
    return login(request, this.axiosInstance)
  }

  verifyOtp(request: VerifyOtpRequest): Promise<VerifyOtpResponse> {
    return verifyOtp(request, this.axiosInstance)
  }

  signUpVerifyOtp(request: SignupVerifyOtpRequest): Promise<VerifyOtpResponse> {
    return signUpVerifyOtp(request, this.axiosInstance)
  }

  verifyPassword(
    request: VerifyPasswordRequest,
  ): Promise<VerifyPasswordResponse> {
    return verifyPassword(request, this.axiosInstance)
  }

  newPasswordSetup(
    request: NewPasswordSetupRequest,
  ): Promise<NewPasswordSetupResponse> {
    return newPasswordSetup(request, this.axiosInstance)
  }

  createPassword(
    request: NewPasswordSetupRequest,
  ): Promise<NewPasswordSetupResponse> {
    return createPassword(request, this.axiosInstance)
  }

  resetPasswordLink(
    request: ResetPasswordRequest,
  ): Promise<ResetPasswordResponse> {
    return resetPasswordLink(request, this.axiosInstance)
  }

  verifyResetLink(
    request: VerifyResetLinkRequest,
  ): Promise<VerifyResetLinkResponse> {
    // const tempInstance = this.axiosInstance
    // if (request.token) {
    //   // Set the Authorization header
    //   tempInstance.defaults.headers.common.authorization = `Bearer ${request.token}`
    // }
    return verifyResetLink(request, this.axiosInstance)
  }

  getUser(request: GetUserRequest): Promise<UserResponse> {
    return getUser(request, this.axiosInstance)
  }

  updateUser(request: UpdateUserType): Promise<UserResponse> {
    return updateUser(request, this.axiosInstance)
  }

  uploadProfileImage(
    request: ImageUploadRequest,
  ): Promise<ImageUploadResponse> {
    return uploadProfileImage(request, this.axiosInstance)
  }

  getHostPmc(): Promise<HostPmcResponse> {
    return getHostPmc(this.axiosInstance)
  }

  getAdminPmc(): Promise<HostPmcResponse> {
    return getAdminPmc(this.axiosInstance)
  }

  getDeviceIdApi(): Promise<GetDeviceIdResponse> {
    return getDeviceIdApi(this.axiosInstance)
  }

  createProperty(request: PropertyRequest): Promise<PropertyResponse> {
    return createProperty(request, this.axiosInstance)
  }

  updateProperty(request: PropertyRequest): Promise<PropertyResponse> {
    return updateProperty(request, this.axiosInstance)
  }

  updatePropertyDescription(
    request: PropertUpdateRequest,
  ): Promise<PropertyUpdateResponse> {
    return updatePropertyDescription(request, this.axiosInstance)
  }

  hidePropertyImage(
    request: HidePropertyImageRequest,
  ): Promise<HidePropertyImageResponse> {
    return hidePropertyImage(request, this.axiosInstance)
  }

  getProperty(request: GetPropertyRequest): Promise<GetPropertyResponse> {
    return getProperty(request, this.axiosInstance)
  }

  getPMCDescription(
    request: GetPmcDescriptionRequest,
  ): Promise<GetPMCDescriptionResponse> {
    return getPMCDescription(request, this.axiosInstance)
  }

  getLiveProperties(
    request: GetLivePropertiesRequest,
  ): Promise<GetLivePropertiesResponse> {
    return getLiveProperties(request, this.axiosInstance)
  }

  getMinimumAgeProperties(
    request: GetMinimumAgePropertiesRequest,
  ): Promise<GetMinimumAgePropertiesResponse> {
    return getMinimumAgeProperties(request, this.axiosInstance)
  }

  updateMinimumAge(
    request: UpdateMinimumAgeRequest,
  ): Promise<UpdateMinimumAgeResponse> {
    return updateMinimumAge(request, this.axiosInstance)
  }

  getEvents(request: GetEventsRequest): Promise<GetEventsResponse> {
    return getEvents(request, this.axiosInstance)
  }

  getPMSLogs(request: GetPMSLogsRequest): Promise<GetPMSLogsResponse> {
    return getPMSLogs(request, this.axiosInstance)
  }

  getErrorLogs(request: GetErrorLogsRequest): Promise<GetErrorLogsResponse> {
    return getErrorLogs(request, this.axiosInstance)
  }

  publishProperties(
    request: PublishPropertiesRequest,
  ): Promise<PublishPropertiesResponse> {
    return publishProperties(request, this.axiosInstance)
  }

  autoPublishProperties(
    request: AutoPublishPropertyRequest,
  ): Promise<PublishPropertiesResponse> {
    return autoPublishProperties(request, this.axiosInstance)
  }

  companyStatus(request: CompanyStatusRequest): Promise<CompanyStatusResponse> {
    return companyStatus(request, this.axiosInstance)
  }

  updateFeeCategoryData(
    request: UpdateCategoryDataRequest,
  ): Promise<UpdateCategoryResponse> {
    return updateFeeCategoryData(request, this.axiosInstance)
  }

  inactiveProperties(
    request: InactivePropertiesRequest,
  ): Promise<InactivePropertiesResponse> {
    return inactiveProperties(request, this.axiosInstance)
  }

  addingTag(request: AddingTagRequest): Promise<AddingTagResponse> {
    return addingTag(request, this.axiosInstance)
  }

  addingCustomTag(request: AddingCustomTagRequest): Promise<AddingTagResponse> {
    return addingCustomTag(request, this.axiosInstance)
  }

  checkPublishPropertiesStatus(
    request: CheckPublishPropertiesStatusRequest,
  ): Promise<CheckPublishPropertiesStatusResponse> {
    return checkPublishPropertiesStatus(request, this.axiosInstance)
  }

  deleteProperties(
    request: DeletePropertiesRequest,
  ): Promise<DeletePropertiesResponse> {
    return deleteProperties(request, this.axiosInstance)
  }

  getCalendarDates(
    request: GetCalendarDatesRequest,
  ): Promise<GetCalendarDatesResponse> {
    return getCalendarDates(request, this.axiosInstance)
  }

  updateCalendarDates(
    request: UpdateCalendarDatesRequest,
  ): Promise<UpdateCalendarDatesResponse> {
    return updateCalendarDates(request, this.axiosInstance)
  }

  getCalendarBookedDates(
    request: GetCalendarBookedDatesRequest,
  ): Promise<GetCalendarBookedDatesResponse> {
    return getCalendarBookedDates(request, this.axiosInstance)
  }

  importCalendar(
    request: ImportCalendarRequest,
  ): Promise<ImportCalendarResponse> {
    return importCalendar(request, this.axiosInstance)
  }

  updateCalendarData(
    request: UpdateCalendarDataRequest,
  ): Promise<UpdateCalendarDatesResponse> {
    return updateCalendarData(request, this.axiosInstance)
  }

  getExportCalendarLink(
    request: GetCalendarRequest,
  ): Promise<GetCalendarExportLinkResponse> {
    return getExportCalendarLink(request, this.axiosInstance)
  }

  setImportCalendarLink(
    request: SetCalendarImportLinkRequest,
  ): Promise<GetCalendarExportLinkResponse> {
    return setImportCalendarLink(request, this.axiosInstance)
  }

  updateCalendarWeekendPrice(
    request: UpdateCalendarWeekendPriceRequest,
  ): Promise<UpdateCalendarDatesResponse> {
    return updateCalendarWeekendPrice(request, this.axiosInstance)
  }

  exportCalendar(
    request: ExportCalendarRequest,
  ): Promise<ExportCalendarResponse> {
    return exportCalendar(request, this.axiosInstance)
  }

  getCalendarData(request: GetCalendarRequest): Promise<GetCalendarResponse> {
    return getCalendarData(request, this.axiosInstance)
  }

  getDefaultAmenities(): Promise<AmenitiesResponse> {
    return getDefaultAmenities(this.axiosInstance)
  }

  getCustomAmenities(
    request: GetCustomAmenitiesRequest,
  ): Promise<GetCustomAmenitiesResponse> {
    return getCustomAmenities(request, this.axiosInstance)
  }

  getCountryByCode(request: GetCountryByCodeRequest): Promise<CountryResponse> {
    return getCountriesByCode(request, this.axiosInstance)
  }

  getCountries(): Promise<CountryResponse> {
    return getCountries(this.axiosInstance)
  }

  addAmenities(request: AmenitiesRequest): Promise<AmenitiesResponse> {
    return addAmenities(request, this.axiosInstance)
  }

  addCustomAmenities(
    request: CustomAmenitiesRequest,
  ): Promise<AmenitiesResponse> {
    return addCustomAmenities(request, this.axiosInstance)
  }

  addAttachment(request: AttachmentRequest): Promise<AttachmentResponse> {
    return addAttachment(request, this.axiosInstance)
  }

  updateAttachment(
    request: UpdateAttachmentRequest,
  ): Promise<AttachmentResponse> {
    return updateAttachment(request, this.axiosInstance)
  }

  removeAttachment(
    request: RemoveAttachmentRequest,
  ): Promise<RemoveAttachmentResponse> {
    return removeAttachment(request, this.axiosInstance)
  }

  reorderAttachment(
    request: ReorderAttachmentRequest,
  ): Promise<ReorderAttachmentResponse> {
    return reorderAttachment(request, this.axiosInstance)
  }

  addHouseRules(request: HouseRulesRequest): Promise<HouseRulesResponse> {
    return addHouseRules(request, this.axiosInstance)
  }

  getFeeCategories(): Promise<FeeCategoriesResponse> {
    return getFeeCategories(this.axiosInstance)
  }

  getCategoryData(
    request: GetFeeCategoryDataRequest,
  ): Promise<FeeCategoryResponse> {
    return getCategoryData(request, this.axiosInstance)
  }

  setindividualAccount(
    request: IndividualAccountRequest,
  ): Promise<IndividualAccountResponse> {
    return setindividualAccount(request, this.axiosInstance)
  }

  setindividualBankAccount(
    request: IndividualBankAccountRequest,
  ): Promise<IndividualAccountResponse> {
    return setindividualBankAccount(request, this.axiosInstance)
  }

  getAllAttachments(
    request: GetAllAttachmentsRequest,
  ): Promise<GetAllAttachmentsResponse> {
    return getAllAttachments(request, this.axiosInstance)
  }

  getDefaultBeds(): Promise<DefaultBedsResponse> {
    return getDefaultBeds(this.axiosInstance)
  }

  getRoomDetails(request: RoomDetailsRequest): Promise<RoomDetailsResponse> {
    return getRoomDetails(request, this.axiosInstance)
  }

  addRoomDetails(
    request: AddRoomDetailsRequest,
  ): Promise<AddRoomDetailsResponse> {
    return addRoomDetails(request, this.axiosInstance)
  }

  getBookings(request: GetBookingsRequest): Promise<BookingsResponse> {
    return getBookings(request, this.axiosInstance)
  }

  fetchBookingData(
    request: GetBookingPropertyRequest,
  ): Promise<BookingsPropertyResponse> {
    return fetchSingleBookingDataApi(request, this.axiosInstance)
  }

  getUsersByPmcId(
    request: GetUsersByPmcIdRequest,
  ): Promise<GetUsersByPmcIdResponse> {
    return getUsersByPmcId(request, this.axiosInstance)
  }

  addEditUser(request: AddEditUserType): Promise<AddEditUserResponse> {
    return addEditUser(request, this.axiosInstance)
  }

  deleteUser(request: DeleteUserType): Promise<UserDeleteResponse> {
    return deleteUser(request, this.axiosInstance)
  }

  deleteCustomAmenity(
    request: DeleteCustomAmenitiesType,
  ): Promise<AmenitiesResponse> {
    return deleteCustomAmenity(request, this.axiosInstance)
  }

  getHostAccountList(
    request: GetAccountsByPmcIdRequest,
  ): Promise<GetAccountsByPmcIdResponse> {
    return getHostAccountList(request, this.axiosInstance)
  }

  getPayoutProperties(
    request: PayoutPropertiesRequest,
  ): Promise<PayoutPropertyResponse> {
    return getPayoutProperties(request, this.axiosInstance)
  }

  applyPayoutRulesOnProperties(
    request: ApplyPayoutRulesOnPropertiesRequest,
  ): Promise<ApplyPayoutRulesOnPropertiesResponse> {
    return applyPayoutRulesOnProperties(request, this.axiosInstance)
  }

  getDiscountTemplatesByPmcId(
    request: GetDiscountTemplatesByPmcIdRequest,
  ): Promise<GetDiscountTemplatesByPmcIdResponse> {
    return getDiscountTemplatesByPmcId(request, this.axiosInstance)
  }

  addEditDiscountTemplate(
    request: DiscountTemplateType,
  ): Promise<AddEditDiscountTemplateResponse> {
    return addEditDiscountTemplate(request, this.axiosInstance)
  }

  deleteDiscountTemplate(
    request: DeleteDiscountTemplateRequestType,
  ): Promise<DiscountTemplateDeleteResponse> {
    return deleteDiscountTemplate(request, this.axiosInstance)
  }

  getContactTemplatesByPmcId(
    request: GetContactTemplatesByPmcIdRequest,
  ): Promise<GetContactTemplatesByPmcIdResponse> {
    return getContactTemplatesByPmcId(request, this.axiosInstance)
  }

  addEditContactTemplate(
    request: ContactTemplateType,
  ): Promise<AddEditContactTemplateResponse> {
    return addEditContactTemplate(request, this.axiosInstance)
  }

  deleteContactTemplate(
    request: DeleteContactTemplateRequestType,
  ): Promise<ContactTemplateDeleteResponse> {
    return deleteContactTemplate(request, this.axiosInstance)
  }

  getAvailabilityBlocksByPmcId(
    request: GetAvailabilityBlocksByPmcIdRequest,
  ): Promise<GetAvailabilityBlocksByPmcIdResponse> {
    return getAvailabilityBlocksByPmcId(request, this.axiosInstance)
  }

  addEditAvailabilityBlocks(
    request: AvailabilityBlockType,
  ): Promise<AddEditAvailabilityBlocksResponse> {
    return addEditAvailabilityBlocks(request, this.axiosInstance)
  }

  deleteAvailabilityBlocks(
    request: DeleteAvailabilityBlocksRequestType,
  ): Promise<AvailabilityBlocksDeleteResponse> {
    return deleteAvailabilityBlocks(request, this.axiosInstance)
  }

  getTemplateProperties(
    request: TemplatePropertiesRequest,
  ): Promise<TemplatePropertyResponse> {
    return getTemplateProperties(request, this.axiosInstance)
  }

  applyTemplateOnProperties(
    request: ApplyTemplateOnPropertiesRequest,
  ): Promise<ApplyTemplateOnPropertiesResponse> {
    return applyTemplateOnProperties(request, this.axiosInstance)
  }

  updateCompanyPdp(
    request: UpdateCompanyPdpRequest,
  ): Promise<UpdateCompanyPdpResponse> {
    return updateCompanyPdp(request, this.axiosInstance)
  }

  getReports(request: GetReportsRequest): Promise<ReportsResponse> {
    const tempInstance = this.axiosInstance
    if (!(request.params.format === "JSON")) {
      tempInstance.interceptors.request.use((req) => ({
        ...req,
        timeout: 180000,
      }))
    }
    return getReports(request, tempInstance)
  }

  getEarningReportData(
    request: GetReportsRequest,
  ): Promise<EarningReportsResponse> {
    const tempInstance = this.axiosInstance
    if (!(request.params.format === "JSON")) {
      tempInstance.interceptors.request.use((req) => ({
        ...req,
        timeout: 180000,
      }))
    }
    return getEarningReportData(request, tempInstance)
  }

  getPayoutReportData(
    request: GetPayoutReportRequest,
  ): Promise<PayoutReportResponse> {
    const tempInstance = this.axiosInstance
    if (!(request.params.format === "JSON")) {
      tempInstance.interceptors.request.use((req) => ({
        ...req,
        timeout: 180000,
      }))
    }
    return getPayoutReportData(request, tempInstance)
  }

  uploadVideo(request: UploadVideoRequest): Promise<UploadVideoResponse> {
    return uploadVideo(request, this.axiosInstance)
  }

  getReviewsAndRatings(
    request: GetReviewsAndRatingsRequest,
  ): Promise<ReviewsAndRatingsResponse> {
    return getReviewsAndRatings(request, this.axiosInstance)
  }

  addReview(request: AddReviewRequest): Promise<AddReviewResponse> {
    return addReview(request, this.axiosInstance)
  }

  addReply(request: AddReplyRequest): Promise<AddReplyResponse> {
    return addReply(request, this.axiosInstance)
  }

  deleteReply(request: DeleteReplyRequest): Promise<DeleteReplyResponse> {
    return deleteReply(request, this.axiosInstance)
  }

  deleteReview(request: DeleteReviewRequest): Promise<DeleteReviewResponse> {
    return deleteReview(request, this.axiosInstance)
  }

  contactUsSubmit(request: ContactUsRequest): Promise<ContactUsResponse> {
    return contactUsSubmit(request, this.axiosInstance)
  }

  hostFullySubmit(request: HostFullyRequest): Promise<HostFullyResponse> {
    return hostFullySubmit(request, this.axiosInstance)
  }

  authorizeCodeApi(
    request: AuthorizeCodeRequest,
  ): Promise<AuthorizeCodeResponse> {
    return authorizeCodeApi(request, this.axiosInstance)
  }

  getNotifications(
    request: GetNotificationRequest,
  ): Promise<GetNotificationResponse> {
    return getNotifications(request, this.axiosInstance)
  }

  updateNotificationStatus(
    request: UpdateNotificationRequest,
  ): Promise<UpdateNotificationResponse> {
    return updateNotificationStatus(request, this.axiosInstance)
  }

  readNotification(request: ReadNotificationRequest): Promise<unknown> {
    return readNotification(request, this.axiosInstance)
  }

  manageUserByCode(
    request: ManageUserByCodeRequest,
  ): Promise<ManageUserByCodeResponse> {
    return manageUserByCode(request, this.axiosInstance)
  }

  getUsersByCode(
    request: GetUsersByCodeRequest,
  ): Promise<GetUsersByPmcIdResponse> {
    return getUsersByCode(request, this.axiosInstance)
  }

  addEditUserByCode(
    request: AddEditUserByCodeType,
  ): Promise<AddEditUserResponse> {
    return addEditUserByCode(request, this.axiosInstance)
  }

  deleteUserByCode(request: DeleteUserByCodeType): Promise<UserDeleteResponse> {
    return deleteUserByCode(request, this.axiosInstance)
  }

  acceptTermsConditions(
    request: AcceptTermsConditionsRequest,
  ): Promise<AcceptTermsConditionsResponse> {
    return acceptTermsConditions(request, this.axiosInstance)
  }

  getActivePropertiesReport(
    request: ActivePropertiesReportRequest,
  ): Promise<ActivePropertiesReportResponse> {
    const tempInstance = this.axiosInstance
    if (!(request.params.format === "JSON")) {
      tempInstance.interceptors.request.use((req) => ({
        ...req,
        timeout: 600000,
      }))
    }
    return getActivePropertiesReport(request, tempInstance)
  }

  syncProperties(
    request: SyncPropertiesRequest,
  ): Promise<SyncPropertiesResponse> {
    return syncProperties(request, this.axiosInstance)
  }

  deletePayoutAccount(
    request: DeletePayoutAccountRequest,
  ): Promise<DeletePayoutAccountResponse> {
    return deletePayoutAccount(request, this.axiosInstance)
  }

  makeDefaultPayoutAccount(
    request: MakeDefaultPayoutAccountRequest,
  ): Promise<MakeDefaultPayoutAccountResponse> {
    return makeDefaultPayoutAccount(request, this.axiosInstance)
  }

  pmcDefaultCheck(
    request: PmcDefaultCheckRequest,
  ): Promise<PmcDefaultCheckResponse> {
    return pmcDefaultCheck(request, this.axiosInstance)
  }

  saveUser(request: SaveUserRequest): Promise<SaveUserResponse> {
    return saveUserApi(request, this.axiosInstance)
  }

  getCompany(
    request: GetAdminCompanyRequest,
  ): Promise<GetAdminCompanyResponse> {
    return getCompany(request, this.axiosInstance)
  }

  getStates(request: GetStatesRequest): Promise<GetStatesResponse> {
    return getStates(request, this.axiosInstance)
  }

  updateWeight(request: UpdateWeightRequest): Promise<UpdateWeightResponse> {
    return updateWeight(request, this.axiosInstance)
  }
}

export const apiClient = new Client()
