import { Icon, IconProps } from "@chakra-ui/react"

const BackIcon = (props: IconProps) => (
  <Icon width='30px' height='30px' viewBox='0 0 30 30' fill='none' {...props}>
    <circle
      cx='15'
      cy='15'
      r='14.75'
      fill='white'
      stroke='black'
      strokeWidth='0.5'
    />
    <path
      d='M17.25 19.5L12.75 15L17.25 10.5'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
)

export default BackIcon
