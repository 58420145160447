import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Divider,
} from "@chakra-ui/react"
import { format, parse } from "date-fns"

import ProfileIcon from "components/icons/profile_icon"
import { BookedDatesProps } from "store/calendar/types"

const getFormatedDate = (date: string) => {
  const temp = parse(date, "yyyy-MM-dd", new Date())
  const tempDate = format(temp, "dd MMM yyyy")
  return tempDate
}

const BookedDateDetailsModal = ({
  onClose,
  openBookedDateModal,
  selectedBookedDateData,
}: {
  onClose: () => void
  openBookedDateModal: boolean
  selectedBookedDateData: BookedDatesProps
}) => (
  <Modal isOpen={openBookedDateModal} onClose={onClose} size='xl'>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Booked Date Details</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box
          w='full'
          display='flex'
          alignItems='flex-start'
          flexDir='column'
          gap={4}
          mb='4'
        >
          {/* status */}
          <Box display='flex' alignItems='center' w='full'>
            <Text w='110px' size={{ base: "sm", sm: "md" }} fontWeight='600'>
              Status
            </Text>
            <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
              {selectedBookedDateData[0]?.status}
            </Text>
          </Box>
          {/* checkin checkout date */}
          <Box display='flex' flexDir='column' gap={4}>
            <Box display='flex' alignItems='center'>
              <Text w='110px' size={{ base: "sm", sm: "md" }} fontWeight='600'>
                Check In{" "}
              </Text>
              <Text fontWeight='500'>
                {getFormatedDate(selectedBookedDateData[0]?.fromDate)}
              </Text>
            </Box>
            <Box display='flex'>
              <Text w='110px' size={{ base: "sm", sm: "md" }} fontWeight='600'>
                Check Out
              </Text>
              <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                {getFormatedDate(selectedBookedDateData[0]?.toDate)}
              </Text>
            </Box>
          </Box>

          {/* proprtyname */}
          <Box display='flex' alignItems='flex-start' w='full'>
            <Text w='140px' size={{ base: "sm", sm: "md" }} fontWeight='600'>
              Property
            </Text>
            <Text
              size={{ base: "sm", sm: "md" }}
              fontWeight='500'
              variant='secondary'
              display='flex'
              wordBreak='break-word'
              w={{ base: "90%", sm: "100%" }}
              noOfLines={2}
            >
              {/* The Shangri-La of La Jolla */}
              {selectedBookedDateData[0]?.propertyName}
            </Text>
          </Box>
          <Box display='flex' w='full'>
            <Text w='140px' />
            <Divider borderColor='#E1E1E1' />
          </Box>

          {/* payout */}

          <Box w='full' display='flex' flexDir={{ base: "column", sm: "row" }}>
            <Text
              w='110px'
              size={{ base: "sm", sm: "md" }}
              fontWeight='600'
              mb={{ base: "2", sm: "0" }}
            >
              Payout:
            </Text>

            <Box
              display='flex'
              flexDir='column'
              justifyContent='space-between'
              w={{ base: "100%", sm: "80%" }}
              gap={4}
            >
              {/* noght count */}
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                w='full'
              >
                <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                  {selectedBookedDateData[0]?.nightCount} Night
                </Text>
                <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                  $ {selectedBookedDateData[0]?.rentAmount}
                </Text>
              </Box>
              {/* host fee */}
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                w='full'
              >
                <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                  Host Fee
                </Text>
                <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                  $ {selectedBookedDateData[0]?.hostFee}
                </Text>
              </Box>
              {/* optional fee */}
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                w='full'
              >
                <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                  Optional Fee
                </Text>
                <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                  {" "}
                  $ {selectedBookedDateData[0]?.optionalFeeAmount}
                </Text>
              </Box>
              {/* whimstay service fee */}
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                w='full'
              >
                <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                  Whimstay Service Fee
                </Text>
                <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                  $ {selectedBookedDateData[0]?.hostFee}
                </Text>
              </Box>
              {/* Taxes */}
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                w='full'
              >
                <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                  Taxes
                </Text>
                <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                  $ {selectedBookedDateData[0]?.taxAmount}
                </Text>
              </Box>
              {/* Total */}
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                w='full'
              >
                <Text size={{ base: "sm", sm: "md" }} fontWeight='600'>
                  Total
                </Text>
                <Text size={{ base: "sm", sm: "md" }} fontWeight='600'>
                  $ {selectedBookedDateData[0]?.totalPaidAmt}
                </Text>
              </Box>
              <Divider />
            </Box>
          </Box>

          {/* guest  */}
          <Box display='flex' alignItems='center' gap={4}>
            <Box w='95px'>
              <ProfileIcon />
            </Box>
            <Box
              display='flex'
              flexDir='column'
              alignItems='flex-start'
              gap={4}
            >
              <Text size={{ base: "sm", sm: "md" }} fontWeight='600'>
                {selectedBookedDateData[0]?.guestName}
              </Text>
              <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                Phone: {selectedBookedDateData[0]?.travMobileNo}
              </Text>
              <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                Email: {selectedBookedDateData[0]?.travelerEmail}
              </Text>
            </Box>
          </Box>

          <Box display='flex' w='full'>
            <Text w='140px' />
            <Divider borderColor='#E1E1E1' />
          </Box>

          {/* payment */}
          <Box w='full' display='flex' flexDir={{ base: "column", sm: "row" }}>
            <Text w='110px' size={{ base: "sm", sm: "md" }} fontWeight='600'>
              Payment:
            </Text>

            <Box
              display='flex'
              flexDir='column'
              justifyContent='space-between'
              w={{ base: "100%", sm: "80%" }}
              gap={4}
            >
              {/* amount */}
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                w='full'
              >
                <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                  Amount paid (USD)
                </Text>
                <Text size={{ base: "sm", sm: "md" }} fontWeight='500'>
                  ${selectedBookedDateData[0]?.totalPaidAmt}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </ModalBody>
    </ModalContent>
  </Modal>
)

export default BookedDateDetailsModal
