const colors = {
  primaryColor: "#006F80", // Teal color
  secondaryColor: "#0D6F81", // Blue color
  tertiaryColor: "#F7F7F7", // Gray color
  textColor: "#000000", // Black color for text
  silverColor: "#E1E1E1",
  glacierColor: "#86B7C0",
  whimstay: {
    50: "#fffadc",
    100: "#fcefb1",
    200: "#f8e484",
    300: "#f5d954",
    400: "#f2ce26",
    500: "#F1C911",
    600: "#a98c05",
    700: "#786401",
    800: "#483c00",
    900: "#1a1400",
  },
  black: {
    50: "#f2f2f2",
    100: "#d9d9d9",
    200: "#bfbfbf",
    300: "#a6a6a6",
    400: "#8c8c8c",
    500: "#000000",
    600: "#595959",
    700: "#404040",
    800: "#262626",
    900: "#0d0d0d",
  },
}

export default colors
