import { Dispatch, SetStateAction, useEffect, useState } from "react"

import {
  Box,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { format } from "date-fns"

import { useAppSelector } from "app/hooks"
import CustomCheckBox from "components/customChakra/CustomCheckBox"
import CustomTableRow from "components/customChakra/CustomTableRow"
import CustomTh from "components/customChakra/CustomTh"
import CustomMenuItem from "components/menudropdown/dropdown-menu-item"
import MenuDropDown from "components/menudropdown/menudropdown"
import { CategoryData } from "services/common"
import { feeCategories } from "store/features/featuresSlice"

export interface FeeMappingItem {
  feeName: string
  feeCategory: string
  blocked: boolean
  feeAmount: number
}

interface FeeMappingTableTypeProps {
  categoryData: CategoryData[] | undefined
  isLoading: boolean
  pageSize: number
  setUpdatedCategoryData: Dispatch<SetStateAction<FeeMappingItem[]>>
  onSort: Dispatch<
    SetStateAction<{ key: keyof CategoryData | null; order: SortOrder }>
  >
}

type SortOrder = "asc" | "desc"

const SkeletonTableRow = () => (
  <Tr>
    <Td>
      <SkeletonText noOfLines={1} w='200px' />
    </Td>
    <Td>
      <SkeletonText noOfLines={1} w='200px' />
    </Td>
    <Td>
      <SkeletonText noOfLines={1} w='100px' />
    </Td>
    <Td>
      <SkeletonText noOfLines={1} w='100px' />
    </Td>
    <Td>
      <SkeletonText noOfLines={1} w='20px' />
    </Td>
    <Td>
      <SkeletonText noOfLines={1} w='200px' />
    </Td>
  </Tr>
)
const FeeMappingTale = (props: FeeMappingTableTypeProps) => {
  const { categoryData, isLoading, pageSize, setUpdatedCategoryData, onSort } =
    props
  const [selectedCategories, setSelectedCategories] = useState<{
    [key: string]: string
  }>({})
  const [checkedItems, setCheckedItems] = useState<string[]>([])
  const getFeeCategories = useAppSelector(feeCategories)

  const handleCategoryChange = (id: string, category: string) => {
    setSelectedCategories((prev: { [key: string]: string }) => ({
      ...prev,
      [id]: category,
    }))
    // setEditedItems((prev) => new Set(prev).add(id))
  }

  const handleSort = (key: keyof CategoryData) => {
    onSort((prevConfig) => {
      if (prevConfig.key === key) {
        return { key, order: prevConfig.order === "asc" ? "desc" : "asc" }
      }
      return { key, order: "asc" }
    })
  }

  useEffect(() => {
    if (categoryData) {
      // setCategories(initialCategories)

      const initiallyCheckedItems = categoryData
        .filter((data) => data.blocked)
        .map((data) => data.feeName)
      setCheckedItems(initiallyCheckedItems)
    }
  }, [categoryData])

  useEffect(() => {
    const generateFeeMappingData = () => {
      const mappingData = (categoryData || []).reduce(
        (accumulator: FeeMappingItem[], data) => {
          const originalCategory = data.feeCategory
          const currentCategory =
            selectedCategories[data.id] || data.feeCategory
          const originallyBlocked = data.blocked
          const currentlyBlocked = checkedItems.includes(String(data.id))

          // Check if the feeCategory or blocked status has changed
          const categoryChanged = currentCategory !== originalCategory
          const blockedStatusChanged = originallyBlocked !== currentlyBlocked

          if (categoryChanged || blockedStatusChanged) {
            accumulator.push({
              feeName: data.feeName,
              feeCategory: currentCategory,
              blocked: currentlyBlocked,
              feeAmount: data.feeAmount,
            })
          }

          return accumulator
        },
        [],
      )

      return mappingData
    }

    const newMappingData = generateFeeMappingData()

    setUpdatedCategoryData((prevData) => {
      // If there are changes, update the state with the new data
      if (JSON.stringify(prevData) !== JSON.stringify(newMappingData)) {
        return newMappingData
      }
      return prevData
    })
  }, [selectedCategories, checkedItems, categoryData, setUpdatedCategoryData])

  useEffect(() => {
    if (categoryData) {
      const initiallyCheckedItems = categoryData
        .filter((data) => data.blocked)
        .map((data) => data.id)
      setCheckedItems(initiallyCheckedItems)
    }
  }, [categoryData])

  const formatFeeAmount = (amount: number): string => {
    const [integerPart, decimalPart] = amount.toString().split(".")

    if (decimalPart && decimalPart.length > 4) {
      // Trim the decimal part if it has more than 4 digits
      return `${integerPart}.${decimalPart.slice(0, 4)}`
    }

    // Return the amount as is if there are 4 or fewer decimal digits
    return amount.toString()
  }

  return (
    <Box
      display='flex'
      flexDir='column'
      gap='20px'
      mr={{ base: "-20px", md: "0" }}
      mb={4}
    >
      <Box
        w={{
          base: "100vw",
          lg2: "calc(100vw - 316px)",
          xl: "calc(100vw - 410px)",
        }}
        maxWidth='full'
        overflow='auto'
        position='relative'
      >
        <TableContainer
          borderBottom='1px solid #e1e1e1'
          pr={{ base: "20px", md: "0" }}
          overflowY='auto'
          maxHeight={{
            base: "55vh",
            sm: "60vh",
            md: "70vh",
            lg2: "50vh",
            xxl: "60vh",
          }}
        >
          <Table>
            <Thead position='sticky' top={0} zIndex={1} background='white'>
              <CustomTableRow>
                {/* <Th minW='230px'>Name</Th> */}
                <CustomTh isSortable onClick={() => handleSort("feeName")}>
                  Your fees
                </CustomTh>
                <CustomTh isSortable onClick={() => handleSort("feeCategory")}>
                  Whimstay category
                </CustomTh>
                <CustomTh isSortable onClick={() => handleSort("feeType")}>
                  Amount Type
                </CustomTh>
                <CustomTh isSortable onClick={() => handleSort("feeAmount")}>
                  Fee Amount
                </CustomTh>
                <CustomTh>Block fee</CustomTh>
                <CustomTh
                  isSortable
                  display={{ base: "none", md: "block" }}
                  onClick={() => handleSort("modifiedDate")}
                >
                  Date Modified
                </CustomTh>
              </CustomTableRow>
            </Thead>
            <Tbody>
              {!isLoading &&
                categoryData?.map((data, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <CustomTableRow key={index}>
                    <Td fontWeight={500}>{data.feeName}</Td>
                    <Td fontWeight={500}>
                      <MenuDropDown
                        maxH={{ base: "300px", xxxl: "400px" }}
                        title={
                          selectedCategories[data.id] ||
                          data?.feeCategory ||
                          "Unmapped"
                        }
                        backgroundColor={
                          selectedCategories[data.feeName] || data?.feeCategory
                            ? "transparent"
                            : "#fff2f2"
                        }
                        borderRadius='5px'
                        buttonWidth='200px'
                      >
                        {getFeeCategories.map((category: string) => (
                          <CustomMenuItem
                            key={category}
                            value={category}
                            onClick={() =>
                              handleCategoryChange(String(data.id), category)
                            }
                            isSelected={
                              category === selectedCategories[data.id]
                            }
                          >
                            {category}
                          </CustomMenuItem>
                        ))}
                      </MenuDropDown>
                    </Td>
                    <Td fontWeight={500}>{data.feeType.replace("_", " ")}</Td>

                    <Td fontWeight={500}>
                      {data.feeType === "PERCENT"
                        ? `${formatFeeAmount(Number(data.feeAmount) * 100)}%`
                        : `$${formatFeeAmount(Number(data.feeAmount))}`}
                    </Td>
                    <Td fontWeight={500}>
                      <CustomCheckBox
                        isDisabled={
                          data.feeCategory === null &&
                          !selectedCategories[data.id]
                        }
                        isChecked={checkedItems.includes(String(data.id))}
                        onChange={(e) => {
                          e.preventDefault()
                          e.stopPropagation()

                          setCheckedItems((ids) => {
                            const updatedIds = ids.includes(String(data.id))
                              ? ids.filter((x) => x !== String(data.id))
                              : [...ids, String(data.id)]

                            return updatedIds
                          })
                        }}
                        borderColor='#464646'
                      />
                    </Td>
                    <Td
                      fontWeight={500}
                      display={{ base: "none", md: "block" }}
                    >
                      {data.modifiedDate ? (
                        format(new Date(data.modifiedDate), "MM/dd/yyyy")
                      ) : (
                        <span>-</span>
                      )}
                    </Td>
                  </CustomTableRow>
                ))}

              {isLoading &&
                Array(pageSize)
                  .fill(1)
                  .map((x: number, ind) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <SkeletonTableRow key={`skeleton${x}${ind}`} />
                  ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default FeeMappingTale
