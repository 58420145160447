import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { EventsData, GetEventsRequestData, ResponseData } from "./common"

export interface GetEventsRequest extends Partial<AxiosRequestConfig> {
  params: GetEventsRequestData
}

export interface GetEventsResponseData extends ResponseData {
  data: {
    content: EventsData[]
    pageable: {
      sort: {
        empty: boolean
        sorted: boolean
        unsorted: boolean
      }
      offset: number
      pageNumber: number
      pageSize: number
      paged: boolean
      unpaged: boolean
    }
    last: boolean
    totalPages: number
    totalElements: number
    number: number
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    size: number
    first: boolean
    numberOfElements: number
    empty: boolean
  }
}

export interface GetEventsResponse extends AxiosResponse {
  data: GetEventsResponseData
}

const defaultUrl = `${process.env.REACT_APP_BASE_API_URL || ""}/eventlog`

export function getEvents(
  { params, method = "get", url = defaultUrl, ...config }: GetEventsRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetEventsResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetEventsResponse>
}
