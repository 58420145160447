import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { ResponseData, UserData } from "./common"

export interface UserResponseData extends ResponseData {
  data: UserData
}

export interface UserResponse extends AxiosResponse {
  data: UserResponseData
}
export interface GetUserRequestData {
  pmcId: string
}
export interface GetUserRequest extends Partial<AxiosRequestConfig> {
  params: GetUserRequestData
}

export const defaultUrl = `${process.env.REACT_APP_BASE_API_URL || ""}/user`

export function getUser(
  { params }: GetUserRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<UserResponse> {
  return axiosInstance({
    params,
    method: "get",
    url: `${defaultUrl}`,
  }) as Promise<UserResponse>
}

export interface UpdateUserType {
  userId: number
  firstName: string
  lastName: string
  emailId: string
  hostWebsite?: string
  country?: string
  mobileno1?: string
}

export function updateUser(
  data: UpdateUserType,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<UserResponse> {
  return axiosInstance({
    method: "put",
    data,
    url: defaultUrl,
  }) as Promise<UserResponse>
}

export interface ImageUploadType {
  file: File
}

export interface ImageUploadRequest extends Partial<AxiosRequestConfig> {
  params: ImageUploadType
}

export interface ImageUploadResponse extends AxiosResponse {
  data: {
    apiStatus: string
  }
}

export function uploadProfileImage(
  { params, method = "post", ...config }: ImageUploadRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ImageUploadResponse> {
  return axiosInstance({
    data: params,
    method,
    url: `${defaultUrl}/profile/image`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    ...config,
  }) as Promise<ImageUploadResponse>
}
