import { lazy, ReactNode } from "react"

import { Container, LightMode } from "@chakra-ui/react"
import { SkipNavContent, SkipNavLink } from "@chakra-ui/skip-nav"

import ForgotPasswordForm from "components/forgot-password/forgot-password"
import LoginModal from "components/login/login-modal"
import TandCModal from "components/terms-condition-modal/t-and-c-modal"

const ErrorModal = lazy(() => import("components/error-modal/error-modal"))

type LayoutProps = {
  children: ReactNode
}

const Layout = (props: LayoutProps) => {
  const { children } = props

  return (
    <LightMode>
      <SkipNavLink id='content' zIndex='skipLink'>
        Skip to Content
      </SkipNavLink>
      <Container id='content' maxW='100vw' p={0}>
        <SkipNavContent />
        {children}
        <LoginModal key='loginModal' />
        <ForgotPasswordForm />
        <ErrorModal />
        <TandCModal />
      </Container>
    </LightMode>
  )
}

export default Layout
