import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { DiscountType } from "pages/discount-templates/DiscountTemplates"
import { defaultAxiosInstance } from "services/client"
import {
  ApplyTemplateOnPropertiesRequestData,
  GetTemplatePropertiesRequestData,
  ResponseData,
} from "services/common"

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/templates/discount`

export interface DiscountTemplate {
  id: number
  active: boolean
  discountPercent: number
  discountTemplateName: string
  regularDiscountPercent: number
  lastMinuteDays: number
  pmcId: string
  tiered: boolean
  min_stay: string
  max_stay: string
  tieredDiscountList: DiscountType[]
  isDefault: boolean
}

export interface GetDiscountTemplatesByPmcIdData {
  data: DiscountTemplate[]
}

export interface GetDiscountTemplatesByPmcIdResponse extends AxiosResponse {
  data: GetDiscountTemplatesByPmcIdData
}

export interface GetDiscountTemplatesByPmcIdRequestData {
  pmcId: string
}

export interface GetDiscountTemplatesByPmcIdRequest
  extends Partial<AxiosRequestConfig> {
  params: GetDiscountTemplatesByPmcIdRequestData
}

export function getDiscountTemplatesByPmcId(
  {
    params,
    method = "get",
    url = defaultUrl,
    ...config
  }: GetDiscountTemplatesByPmcIdRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetDiscountTemplatesByPmcIdResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetDiscountTemplatesByPmcIdResponse>
}

export interface DiscountTemplateType {
  id: number | undefined
  pmcId: string
  discountTemplateName: string
  discountPercent: number
  regularDiscountPercent?: number
  lastMinuteDays?: number
  active?: boolean
  tiered: boolean
  tieredDiscountList: DiscountType[]
  isDefault: boolean
}

export interface AddEditDiscountTemplateData extends ResponseData {
  data: DiscountTemplateType
}

export interface AddEditDiscountTemplateResponse extends AxiosResponse {
  data: AddEditDiscountTemplateData
}

export function addEditDiscountTemplate(
  data: DiscountTemplateType,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AddEditDiscountTemplateResponse> {
  return axiosInstance({
    method: data?.id ? "put" : "post",
    data,
    // params: { pmcId: data?.pmcId },
    url: defaultUrl,
  }) as Promise<AddEditDiscountTemplateResponse>
}

export interface DiscountTemplateDeleteResponseData extends ResponseData {
  data: unknown
}

export interface DiscountTemplateDeleteResponse extends AxiosResponse {
  data: DiscountTemplateDeleteResponseData
}

export interface DeleteDiscountTemplateRequestType {
  id: number
  pmcId: string
}

export function deleteDiscountTemplate(
  data: DeleteDiscountTemplateRequestType,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<DiscountTemplateDeleteResponse> {
  return axiosInstance({
    method: "delete",
    params: { templateId: data?.id, pmcId: data.pmcId },
    url: defaultUrl,
  }) as Promise<DiscountTemplateDeleteResponse>
}

export interface TemplatePropertyResponseData extends ResponseData {
  data: {
    content: {
      pmcId: string
      pmsUnitId: string
      propertyName: string
      address: string
      templateId: number
      propertyId: number
    }[]
    pageable: {
      sort: {
        empty: boolean
        sorted: boolean
        unsorted: boolean
      }
      offset: number
      pageNumber: number
      pageSize: number
      paged: boolean
      unpaged: boolean
    }
    last: boolean
    totalPages: number
    totalElements: number
    number: number
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    size: number
    first: boolean
    numberOfElements: number
    empty: boolean
  }
}

export interface TemplatePropertyResponse extends AxiosResponse {
  data: TemplatePropertyResponseData
}

export interface TemplatePropertiesRequest extends Partial<AxiosRequestConfig> {
  params: GetTemplatePropertiesRequestData
}

export function getTemplateProperties(
  {
    params,
    method = "post",
    url = "discount",
    ...config
  }: TemplatePropertiesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<TemplatePropertyResponse> {
  return axiosInstance({
    data: params,
    method,
    url: `${
      process.env.REACT_APP_BASE_API_URL || ""
    }/templates/${url}/properties/by-pmcId`,
    ...config,
  }) as Promise<TemplatePropertyResponse>
}

export interface ApplyTemplateOnPropertiesResponseData extends ResponseData {
  data: unknown
}

export interface ApplyTemplateOnPropertiesResponse extends AxiosResponse {
  data: ApplyTemplateOnPropertiesResponseData
}

export interface ApplyTemplateOnPropertiesRequest
  extends Partial<AxiosRequestConfig> {
  data: ApplyTemplateOnPropertiesRequestData
}

export function applyTemplateOnProperties(
  {
    data,
    method = "put",
    url = `discount`,
    ...config
  }: ApplyTemplateOnPropertiesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ApplyTemplateOnPropertiesResponse> {
  return axiosInstance({
    data,
    method,
    url: `${
      process.env.REACT_APP_BASE_API_URL || ""
    }/templates/${url}/property/by-templateId`,
    ...config,
  }) as Promise<ApplyTemplateOnPropertiesResponse>
}
