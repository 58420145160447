import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Button, ButtonGroup, IconButton, Text } from "@chakra-ui/react"
import { format } from "date-fns"

import { goMonthBack, goMonthForward } from "../utils"

interface Ṕroperties {
  date: Date
  setDate: (date: Date) => void
  setDays: (dates: Date[]) => void
}

export const CalendarHeader = ({ date, setDate, setDays }: Ṕroperties) => (
  <ButtonGroup isAttached variant='outline'>
    <IconButton
      aria-label='previous month'
      icon={<ChevronLeftIcon h={7} w={7} />}
      onClick={() => {
        goMonthBack(date, setDate, setDays)
      }}
    />
    <Button
      variant='outline'
      px={{ base: 2, xxl: 3 }}
      _hover={{}}
      cursor='auto'
      minW={{ base: "100px", md: "130px" }}
    >
      <Text fontSize={{ base: "12px", md: "14px" }}>
        {format(date, "MMMM yyyy")}
      </Text>
    </Button>
    <IconButton
      aria-label='next month'
      icon={<ChevronRightIcon h={7} w={7} />}
      onClick={() => {
        goMonthForward(date, setDate, setDays)
      }}
    />
  </ButtonGroup>
)
