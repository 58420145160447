import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "app/store"
import { MenuItem } from "components/sidemenu/types"

import { UIInitialState } from "./types"

const initialState: UIInitialState = {
  isLoginModalOpen: false,
  listingsPageMenuItems: [],
  profilePageMenuItems: [],
  isSignupModalOpen: false,
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setIsLoginModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isLoginModalOpen = action.payload
    },
    setListingPageMenuItems: (state, action: PayloadAction<MenuItem[]>) => {
      state.listingsPageMenuItems = action.payload
    },
    setProfilePageMenuItems: (state, action: PayloadAction<MenuItem[]>) => {
      state.profilePageMenuItems = action.payload
    },
    setIsSignupModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isSignupModalOpen = action.payload
    },
  },
})

export const {
  setIsLoginModalOpen,
  setListingPageMenuItems,
  setProfilePageMenuItems,
  setIsSignupModalOpen,
} = uiSlice.actions

export const selectIsLoginModalOpen = (state: RootState) =>
  state.uiReducer.isLoginModalOpen

export const selectListingsPageMenuItems = (state: RootState) =>
  state.uiReducer.listingsPageMenuItems

export const selectProfilePageMenuItems = (state: RootState) =>
  state.uiReducer.profilePageMenuItems

export const selectIsSignupModalOpen = (state: RootState) =>
  state.uiReducer.isSignupModalOpen

export default uiSlice.reducer
