import { Icon, IconProps } from "@chakra-ui/react"

const ReportsIcon = (props: IconProps) => (
  <Icon width='24px' height='20px' viewBox='0 0 24 20' fill='none' {...props}>
    <path
      d='M10.5312 1H3.625C3.19402 1 2.7807 1.16857 2.47595 1.46863C2.1712 1.76869 2 2.17565 2 2.6V15.4C2 15.8243 2.1712 16.2313 2.47595 16.5314C2.7807 16.8314 3.19402 17 3.625 17H13.375C13.806 17 14.2193 16.8314 14.524 16.5314C14.8288 16.2313 15 15.8243 15 15.4V5.4L10.5312 1Z'
      stroke='#414141'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 1V5.5H14.5'
      stroke='#414141'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 10H5'
      stroke='#414141'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 13H5'
      stroke='#414141'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7 7H5'
      stroke='#414141'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
)

export default ReportsIcon
