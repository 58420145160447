import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "services/client"
import {
  ApplyTemplateOnPropertiesRequestData,
  GetTemplatePropertiesRequestData,
  ResponseData,
} from "services/common"

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/templates/contact`

export interface ContactTemplate {
  id: number
  active: boolean
  contactNo: string
  emailId: string
  contactTemplateName: string
  pmcId: string
  confirmationEmail: string
  payoutConfirmationEmail: string
  contactEmailIds: string
  confirmationEmailIds: string
  payoutConfirmationEmailIds: string
  extensionNumber: string
  isDefault?: boolean
  dialerCode?: string
}

export interface GetContactTemplatesByPmcIdData {
  data: ContactTemplate[]
}

export interface GetContactTemplatesByPmcIdResponse extends AxiosResponse {
  data: GetContactTemplatesByPmcIdData
}

export interface GetContactTemplatesByPmcIdRequestData {
  pmcId: string
}

export interface GetContactTemplatesByPmcIdRequest
  extends Partial<AxiosRequestConfig> {
  params: GetContactTemplatesByPmcIdRequestData
}

export function getContactTemplatesByPmcId(
  {
    params,
    method = "get",
    url = defaultUrl,
    ...config
  }: GetContactTemplatesByPmcIdRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetContactTemplatesByPmcIdResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetContactTemplatesByPmcIdResponse>
}

export interface ContactTemplateType {
  id: number | undefined
  pmcId: string
  contactTemplateName: string
  active?: boolean
  contactNo: string
  emailId: string
  confirmationEmail: string
  payoutConfirmationEmail: string
  contactEmailIds: string
  confirmationEmailIds: string
  payoutConfirmationEmailIds: string
  extensionNumber: string
  isDefault: boolean
  dialerCode?: string
}

export interface AddEditContactTemplateData extends ResponseData {
  data: ContactTemplateType
}

export interface AddEditContactTemplateResponse extends AxiosResponse {
  data: AddEditContactTemplateData
}

export function addEditContactTemplate(
  data: ContactTemplateType,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AddEditContactTemplateResponse> {
  return axiosInstance({
    method: data?.id ? "put" : "post",
    data,
    params: { pmcId: data?.pmcId },
    url: defaultUrl,
  }) as Promise<AddEditContactTemplateResponse>
}

export interface ContactTemplateDeleteResponseData extends ResponseData {
  data: unknown
}

export interface ContactTemplateDeleteResponse extends AxiosResponse {
  data: ContactTemplateDeleteResponseData
}

export interface DeleteContactTemplateRequestType {
  id: number
  pmcId: string
}

export function deleteContactTemplate(
  data: DeleteContactTemplateRequestType,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ContactTemplateDeleteResponse> {
  return axiosInstance({
    method: "delete",
    params: { templateId: data?.id, pmcId: data.pmcId },
    url: defaultUrl,
  }) as Promise<ContactTemplateDeleteResponse>
}

export interface TemplatePropertyResponseData extends ResponseData {
  data: {
    content: {
      pmcId: string
      pmsUnitId: string
      propertyName: string
      address: string
      templateId: number
      propertyId: number
    }[]
    pageable: {
      sort: {
        empty: boolean
        sorted: boolean
        unsorted: boolean
      }
      offset: number
      pageNumber: number
      pageSize: number
      paged: boolean
      unpaged: boolean
    }
    last: boolean
    totalPages: number
    totalElements: number
    number: number
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    size: number
    first: boolean
    numberOfElements: number
    empty: boolean
  }
}

export interface TemplatePropertyResponse extends AxiosResponse {
  data: TemplatePropertyResponseData
}

export interface TemplatePropertiesRequest extends Partial<AxiosRequestConfig> {
  params: GetTemplatePropertiesRequestData
}

export function getTemplateProperties(
  {
    params,
    method = "post",
    url = `${defaultUrl}/properties/by-pmcId`,
    ...config
  }: TemplatePropertiesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<TemplatePropertyResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<TemplatePropertyResponse>
}

export interface ApplyTemplateOnPropertiesResponseData extends ResponseData {
  data: unknown
}

export interface ApplyTemplateOnPropertiesResponse extends AxiosResponse {
  data: ApplyTemplateOnPropertiesResponseData
}

export interface ApplyTemplateOnPropertiesRequest
  extends Partial<AxiosRequestConfig> {
  data: ApplyTemplateOnPropertiesRequestData
}

export function applyTemplateOnProperties(
  {
    data,
    method = "put",
    url = `${defaultUrl}/property/by-templateId`,
    ...config
  }: ApplyTemplateOnPropertiesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ApplyTemplateOnPropertiesResponse> {
  return axiosInstance({
    data,
    method,
    url,
    ...config,
  }) as Promise<ApplyTemplateOnPropertiesResponse>
}

export interface UpdateCompanyPdpResponseData {
  apiStatus: string
  message: string
}

export interface UpdateCompanyPdpResponse extends AxiosResponse {
  data: UpdateCompanyPdpResponseData
}

export interface UpdateCompanyPdpRequest extends Partial<AxiosRequestConfig> {
  params: {
    pmcId: string
    pdp: string
  }
}

export function updateCompanyPdp(
  {
    params,
    method = "post",
    url = `${
      process.env.REACT_APP_BASE_API_URL || ""
    }/admin/update-company-pdp?pmcId=${params.pmcId}&pdp=${params.pdp}`,
    ...config
  }: UpdateCompanyPdpRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<UpdateCompanyPdpResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<UpdateCompanyPdpResponse>
}
