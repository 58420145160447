import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import {
  CategoryData,
  ResponseData,
  UpdateCategoryDataRequestType,
} from "./common"

export interface FeeCategoriesData extends ResponseData {
  data: string[]
}

export interface FeeCategoriesResponse extends AxiosResponse {
  data: FeeCategoriesData
}

export interface GetFeeCategoryRequestData {
  pmcId: string
}

export interface UpdateCategoryRequestData {
  pmcId: string
  data: UpdateCategoryDataRequestType[]
}
export interface GetFeeCategoryDataRequest extends Partial<AxiosRequestConfig> {
  params: GetFeeCategoryRequestData
}

export interface UpdateCategoryDataRequest extends Partial<AxiosRequestConfig> {
  params: GetFeeCategoryRequestData
  data: UpdateCategoryDataRequestType[]
}

export interface FeeCategoryResponseData extends ResponseData {
  data: CategoryData[]
}

export interface FeeCategoryResponse extends AxiosResponse {
  data: FeeCategoryResponseData
}

export interface UpdateCategoryResponseData extends ResponseData {
  data: unknown
}

export interface UpdateCategoryResponse extends AxiosResponse {
  data: UpdateCategoryResponseData
}

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/fee-category`

export function getFeeCategories(
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<FeeCategoriesResponse> {
  return axiosInstance({
    method: "get",
    url: `${defaultUrl}/all-categories`,
  }) as Promise<FeeCategoriesResponse>
}

export function getCategoryData(
  {
    params,
    method = "get",
    url = `${defaultUrl}/category-data`,
    ...config
  }: GetFeeCategoryDataRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<FeeCategoryResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<FeeCategoryResponse>
}

export function updateFeeCategoryData(
  {
    params,
    method = "post",
    url = `${defaultUrl}`,
    ...config
  }: UpdateCategoryDataRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<UpdateCategoryResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<UpdateCategoryResponse>
}
