import axios from "axios"

export interface ProIpAddressData {
  city: string
  countryCode: string
  query: string
  region: string
  status: string
  zip: string
}

export interface ProIpAddressResponse {
  data: ProIpAddressData
}
export interface IpAddressData {
  city: string
  ip: string
  region_code: string
}

export interface IpAddressResponse {
  data: IpAddressData
}

export const getIpAddress = async () => {
  const response: ProIpAddressResponse = await axios.get(
    "https://pro.ip-api.com/json/?key=bSgurLmm6Xq8EgE",
  )
  if (response?.data?.status === "success") {
    return {
      ip: response?.data.query,
      city: response?.data?.city || "",
      state: response?.data?.region || "",
      zipcode: response?.data?.zip || "",
      countryCode: response?.data?.countryCode || "",
    }
  }
  const resIP: IpAddressResponse = await axios.get("https://ipapi.co/json")
  return {
    ip: resIP?.data?.ip,
    city: resIP?.data?.city || "",
    state: resIP?.data?.region_code || "",
  }
}
