import { ReactNode } from "react"

import { Box } from "@chakra-ui/react"

import HostHeader from "components/hostHeader"

const HostLayout = ({ children }: { children: ReactNode }) => (
  <>
    <HostHeader />
    <Box as='main' pt={{ base: "70px", lg2: "91px" }} maxW='100vw' minH='100vh'>
      {children}
    </Box>
  </>
)

export default HostLayout
