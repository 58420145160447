import { useCallback, useEffect } from "react"

import { Button, HStack } from "@chakra-ui/react"
import { useLocation, useNavigate } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "app/hooks"
import AddTagModal from "components/add-tag-modal/AddTagModal"
import ViewTagMenu from "components/view-tag-menu/ViewTagMenu"
import useIsPermitted from "hooks/useIsPermittedHook/useIsPermitted"
import { getCompany, selectCurrentPmc } from "store/auth/authSlice"

import { TabsTypeProps } from "./types"

const Tabs = (props: TabsTypeProps) => {
  const { tabsArr } = props
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const currentPmc = useAppSelector(selectCurrentPmc)
  const hasAccessTo = useIsPermitted()

  const getAdminCompanyTag = useCallback(async () => {
    if (currentPmc?.pmc_id) {
      await dispatch(
        getCompany({
          params: {
            pmcId: currentPmc?.pmc_id,
          },
        }),
      ).unwrap()
    }
  }, [currentPmc?.pmc_id, dispatch])

  useEffect(() => {
    // eslint-disable-next-line no-void
    void getAdminCompanyTag()
  }, [currentPmc?.pmc_id, getAdminCompanyTag])

  return (
    <HStack
      spacing={{ base: 0, md: 4 }}
      display={{ base: "block", md: "flex" }}
      justifyContent='space-between'
    >
      <HStack>
        {tabsArr &&
          tabsArr.map((tab) => (
            <Button
              key={tab?.name}
              size='sm'
              variant={
                location.pathname.startsWith(tab?.basePath)
                  ? "primary_nav_active"
                  : "primary_nav"
              }
              w={{ base: "full", md: "auto" }}
              h={{ base: "48px", md: "auto" }}
              textTransform={{ base: "uppercase", md: "initial" }}
              fontSize='sm'
              onClick={() => navigate(tab?.path, { replace: true })}
            >
              {tab?.name}
            </Button>
          ))}
      </HStack>
      <HStack
        paddingRight={{ xsm: "15px", lg2: "35px" }}
        justifyContent={{ base: "end", md: "space-between" }}
        paddingY={{ base: "10px", md: "0px" }}
      >
        {hasAccessTo?.isAdmin && (
          <>
            <ViewTagMenu />
            <AddTagModal getCompany={getAdminCompanyTag} />
          </>
        )}
      </HStack>
    </HStack>
  )
}
export default Tabs
