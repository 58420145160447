import { ReactNode, useCallback, useEffect, useState } from "react"

import { Box, Heading, useMediaQuery } from "@chakra-ui/react"

import { useAppDispatch } from "app/hooks"
import ProtectedComponent from "components/PleaseLoginComp"
import DesktopSideMenu from "components/sidemenu/sidemenu"
import { MenuItem as MenuItemType } from "components/sidemenu/types"
import useIsPermitted from "hooks/useIsPermittedHook/useIsPermitted"
import { setProfilePageMenuItems } from "store/ui/uiSlice"

type HostProfileLayoutProps = {
  children: ReactNode
}

export const profileSidebarMenuArr: MenuItemType[] = [
  {
    name: "Profile Details",
    depth: 1,
    isActive: true,
    id: "profileDetails",
    to: "/profile/profile-details",
    childMenu: [],
  },
  {
    name: "Payout Settings",
    depth: 1,
    id: "payoutSettings",
    to: "/profile/payout-settings",
    childMenu: [],
  },
  {
    name: "Manage Users",
    depth: 1,
    id: "manageUsers",
    to: "/profile/manage-users",
    childMenu: [],
  },
  // {
  //   name: "PMS Logs",
  //   depth: 1,
  //   id: "pmsLogs",
  //   to: "/profile/pms-logs",
  //   childMenu: [],
  // },
  // {
  //   name: "Error Logs",
  //   depth: 1,
  //   id: "errorLogs",
  //   to: "/profile/error-logs",
  //   childMenu: [],
  // },
  // {
  //   name: "Events",
  //   depth: 1,
  //   id: "events",
  //   to: "/profile/events",
  //   childMenu: [],
  // },
]

const HostProfileLayout = (props: HostProfileLayoutProps) => {
  const { children } = props
  const hasAccessTo = useIsPermitted()
  const dispatch = useAppDispatch()
  const [isMobile] = useMediaQuery("(max-width: 1023px)")
  const [menuItems, setMenuItems] = useState(profileSidebarMenuArr)

  const setMobileProfilePageMenuItems = useCallback(
    (menu: MenuItemType[]) => {
      dispatch(setProfilePageMenuItems(menu))
    },
    [dispatch],
  )

  useEffect(() => {
    let tempArr = profileSidebarMenuArr
    if (hasAccessTo?.isAdmin) {
      tempArr = [
        ...tempArr,
        {
          name: "Events",
          depth: 1,
          id: "events",
          to: "/profile/events",
          childMenu: [],
        },
        {
          name: "Error Logs",
          depth: 1,
          id: "errorLogs",
          to: "/profile/error-logs",
          childMenu: [],
        },
      ]
    }
    setMenuItems(tempArr)
    setMobileProfilePageMenuItems(tempArr)
  }, [hasAccessTo, hasAccessTo?.isAdmin, setMobileProfilePageMenuItems])

  return (
    <Box
      maxW='100%'
      pl={{ base: "20px", lg: "30px", xl: "70px" }}
      pr={{ base: "20px", lg: "30px", xl: "30px" }}
      minW='100%'
    >
      <Box mt={{ base: 4, md: 6 }} display='flex' gap={{ base: 7, xl: 10 }}>
        {!isMobile && (
          <Box w='210px' flexGrow={1} flexShrink={0}>
            <Heading size='sm'>Profile</Heading>
            <DesktopSideMenu sidebarMenuArr={menuItems} />
          </Box>
        )}
        <Box pr={{ base: 0, xl: 10 }} w='full'>
          <ProtectedComponent>{children}</ProtectedComponent>
        </Box>
      </Box>
    </Box>
  )
}

export default HostProfileLayout
