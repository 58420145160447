import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { ErrorLogsData, GetErrorLogsRequestData, ResponseData } from "./common"

export interface GetErrorLogsRequest extends Partial<AxiosRequestConfig> {
  params: GetErrorLogsRequestData
}
export interface GetErrorLogsResponseData extends ResponseData {
  data: ErrorLogsData
}

export interface GetErrorLogsResponse extends AxiosResponse {
  data: GetErrorLogsResponseData
}

export function getErrorLogs(
  {
    params,
    method = "get",
    url = `${process.env.REACT_APP_BASE_API_URL || ""}/properties/error`,
    ...config
  }: GetErrorLogsRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetErrorLogsResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetErrorLogsResponse>
}
