/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable promise/always-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosPromise,
  AxiosError,
} from "axios"
// import * as settle from "axios/lib/core/settle"
import * as transformData from "axios/lib/core/transformData"
import * as defaults from "axios/lib/defaults"

import { ApiStatus, Status } from "./common"

export function statusToCode(status: Status): number {
  switch (status) {
    case Status.OK:
    case Status.ZERO_RESULTS: {
      return 200
    }
    case Status.INVALID_REQUEST: {
      return 400
    }
    case Status.REQUEST_DENIED: {
      return 403
    }
    case Status.NOT_FOUND: {
      return 404
    }
    case Status.UNKNOWN_ERROR: {
      return 500
    }
    default: {
      return 200
    }
  }
}

export function apiStatusToCode(apiStatus: ApiStatus): number {
  switch (apiStatus) {
    case ApiStatus.SUCCESS: {
      return 200
    }
    case ApiStatus.ACCEPTED: {
      return 201
    }
    case ApiStatus.FAILED: {
      return 400
    }
    case ApiStatus.ERROR: {
      return 404
    }
    default: {
      return 200
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function settle(resolve: any, reject: any, response: any) {
  const { validateStatus } = response.config

  if (!response.status || !validateStatus || validateStatus(response.status)) {
    resolve(response)
  } else {
    reject(
      new AxiosError(
        response.data.errorMessage || response?.data?.data?.message,
        response.data.errorCode,
        response.config,
        response.request,
        response,
      ),
    )
  }
}

export const customAdapter = (
  config: AxiosRequestConfig,
): AxiosPromise<unknown> =>
  new Promise((resolve, reject) => {
    defaults
      .adapter(config)
      .then((r: AxiosResponse) => {
        r.data = transformData(r.data, r.headers, config.transformResponse)

        if (r.status === 200 && r.data.status) {
          r.status = statusToCode(r.data.status)
        }

        if (r.status === 200 && r.data.apiStatus) {
          r.status = apiStatusToCode(r.data.apiStatus)
        }

        settle(resolve, reject, r)
      })
      .catch(reject)
  })
