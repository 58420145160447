import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { AuthData, AuthRequestData, ResponseData } from "./common"
import {
  NewPasswordSetupRequest,
  NewPasswordSetupResponse,
} from "./reset-password"

export interface AuthRequest extends Partial<AxiosRequestConfig> {
  params: AuthRequestData
}

export interface AuthResponseData extends ResponseData {
  data: AuthData
}

export interface AuthResponse extends AxiosResponse {
  data: AuthResponseData
}

export const defaultUrl = `${process.env.REACT_APP_BASE_API_URL || ""}`

export function login(
  { params, ...config }: AuthRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AuthResponse> {
  let url
  if (!params.emailOption) {
    url = `${defaultUrl}/auth/login`
  } else {
    url = `${defaultUrl}/auth/mobile/${params.email}/signin`
  }
  return axiosInstance({
    data: !params.emailOption
      ? {
          code: params.code,
          mobileNum: params.mobileNum,
        }
      : {
          email: params.email,
        },
    method: !params.emailOption ? "post" : "get",
    url,
    ...config,
  }) as Promise<AuthResponse>
}

export const defaultCreatePasswordUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/auth/password/change`

export function createPassword(
  {
    data,
    method = "post",
    url = defaultCreatePasswordUrl,
  }: NewPasswordSetupRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<NewPasswordSetupResponse> {
  return axiosInstance({
    data: {
      password: data.password,
    },
    method,
    url,
    headers: {
      authorization: `Bearer ${data.token || ""}`,
    },
  }) as Promise<NewPasswordSetupResponse>
}
