import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "app/store"

import { ModalInitialState } from "./types"

const initialState: ModalInitialState = {
  isOpen: false,
  error: null,
}

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    setModalError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
  },
})

export const { setModalIsOpen, setModalError } = modalSlice.actions

export const selectModalError = (state: RootState) => state.modalReducer.error
export const selectModalIsOpen = (state: RootState) => state.modalReducer.isOpen

export default modalSlice.reducer
