import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { ResponseData } from "./common"

interface AuthorizeCodeUser {
  code: string
  status: string
  state: string
}

export interface AuthorizeCodeResponseData extends ResponseData {
  data: AuthorizeCodeUser
}

export interface AuthorizeCodeResponse extends AxiosResponse {
  data: AuthorizeCodeResponseData
}

export interface AuthorizeCodeRequest extends Partial<AxiosRequestConfig> {
  params: {
    state: string | null
    code: string | null
    status: string | null
  }
}
export function authorizeCodeApi(
  {
    params,
    method = "get",
    url = `https://hostfully.whimstay.com/auth/redirect?status=${
      params.status || "SUCCESS"
    }&state=${params.state || ""}&code=${params.code || ""}`,
    ...config
  }: AuthorizeCodeRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AuthorizeCodeResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<AuthorizeCodeResponse>
}
