import React from "react"

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react"

const LearnMoreModal = ({
  title,
  width,
  ml = 0,
  titleFontSize = { base: "lg", sm: "20px" },
  btnFontSize = "14px",
  btnFontFamily = "Montserrat,sans-serif",
  iconTop = "4px",
  iconRight = "8px",
  iconColor = "currentColor",
  children,
}: {
  title: string
  width: { base: string; xsm: string; sm: string; md: string; lg?: string }
  ml?: number
  titleFontSize?: { base: string; sm: string }
  btnFontSize?: string
  btnFontFamily?: string
  iconTop?: string
  iconRight?: string
  iconColor?: string
  children: React.ReactNode
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  return (
    <Popover isOpen={isOpen} onClose={onClose} placement='bottom-start'>
      <PopoverTrigger>
        <Button
          variant='link'
          fontSize={btnFontSize}
          fontWeight={500}
          ml={ml}
          color='black'
          fontFamily={btnFontFamily}
          onClick={onToggle}
        >
          Learn more
        </Button>
      </PopoverTrigger>
      <PopoverContent
        width={width}
        padding={{ base: 2, sm: 6 }}
        borderRadius='25px'
        boxShadow='-1px 4px 12px rgba(0, 0, 0, 0.16)'
      >
        <PopoverHeader
          borderBottomWidth='0px'
          pb={2}
          fontSize={titleFontSize}
          fontWeight={500}
        >
          {title}
        </PopoverHeader>
        <PopoverCloseButton
          fontSize={{ base: 12, sm: 14 }}
          padding={{ base: 3, sm: 5 }}
          top={iconTop}
          right={iconRight}
          color={iconColor}
        />
        <PopoverBody>{children}</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default LearnMoreModal
