import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { RootState } from "app/store"
import { apiClient } from "services/client"
import { GetLivePropertiesRequest } from "services/liveProperties"

import { LivePropertyInitialState } from "./types"

export const getLiveProperties = createAsyncThunk(
  "liveProperty/getLiveProperties",
  async (params: GetLivePropertiesRequest) => {
    const response = await apiClient.getLiveProperties(params)

    return response.data
  },
)

const initialState: LivePropertyInitialState = {
  loading: false,
  error: null,
  liveProperties: [],
  pagination: {
    last: true,
    totalPages: 0,
    totalElements: 0,
    number: 0,
    sort: {
      empty: false,
      sorted: false,
      unsorted: true,
    },
    size: 0,
    first: true,
    numberOfElements: 0,
    empty: true,
  },
}

export const livePropertySlice = createSlice({
  name: "liveProperty",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLiveProperties.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getLiveProperties.fulfilled, (state, action) => {
      state.loading = false
      state.error = null
      state.liveProperties = action.payload.data.content
      state.pagination = {
        last: action.payload.data.last,
        totalPages: action.payload.data.totalPages,
        totalElements: action.payload.data.totalElements,
        number: action.payload.data.number,
        sort: {
          empty: action.payload.data.sort.empty,
          sorted: action.payload.data.sort.sorted,
          unsorted: action.payload.data.sort.unsorted,
        },
        size: action.payload.data.size,
        first: action.payload.data.first,
        numberOfElements: action.payload.data.numberOfElements,
        empty: action.payload.data.empty,
      }
    })
    builder.addCase(getLiveProperties.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })
  },
})

// export const {} = livePropertySlice.actions

export const selectLiveProperties = (state: RootState) =>
  state.livePropertiesReducer.liveProperties
export const selectIsLoadingLiveProperties = (state: RootState) =>
  state.livePropertiesReducer.loading
export const selectLivePropertiesPagination = (state: RootState) =>
  state.livePropertiesReducer.pagination
export default livePropertySlice.reducer
