import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "app/store"
import { AuthRequest } from "services/auth"
import { apiClient } from "services/client"
import { ResetPasswordRequest } from "services/forgot-password"
import { GetAdminCompanyRequest } from "services/liveProperties"
import { VerifyOtpRequest, VerifyPasswordRequest } from "services/otp"
import {
  NewPasswordSetupRequest,
  VerifyResetLinkRequest,
} from "services/reset-password"
import {
  GetUserRequest,
  ImageUploadRequest,
  UpdateUserType,
} from "services/user"
import {
  getSelectedPMCId,
  removeAuthToken,
  removeTempAuthToken,
  setAuthToken,
  setTempAuthToken,
  setUserCookie,
} from "utils/local-storage"

import { AuthInitialState } from "./types"

export const login = createAsyncThunk(
  "auth/login",
  async (params: AuthRequest, thunkAPI) => {
    try {
      const response = await apiClient.login(params)

      // store token in cookies
      setTempAuthToken(response.headers.authorization)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (params: VerifyOtpRequest, thunkAPI) => {
    try {
      const response = await apiClient.verifyOtp(params)

      // remove temporary auth token of login API
      removeTempAuthToken()
      // store token in cookies
      setAuthToken(response.headers.authorization)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const verifyPassword = createAsyncThunk(
  "auth/password/verify",
  async (params: VerifyPasswordRequest, thunkAPI) => {
    try {
      const response = await apiClient.verifyPassword(params)

      // remove temporary auth token of login API
      removeTempAuthToken()
      // store token in cookies
      setAuthToken(response.headers.authorization)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const verifyResetPasswordLink = createAsyncThunk(
  "/auth/password/reset",
  async (params: ResetPasswordRequest, thunkAPI) => {
    try {
      const response = await apiClient.resetPasswordLink(params)

      // remove temporary auth token of login API
      removeTempAuthToken()
      // store token in cookies
      setAuthToken(response.headers.authorization)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const verifyResetLink = createAsyncThunk(
  `/auth/verify/reset-link`,
  async (params: VerifyResetLinkRequest, thunkAPI) => {
    try {
      const response = await apiClient.verifyResetLink(params)

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const newPasswordSetup = createAsyncThunk(
  "auth/password/change",
  async (params: NewPasswordSetupRequest, thunkAPI) => {
    try {
      const response = await apiClient.newPasswordSetup(params)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getCompany = createAsyncThunk(
  "auth/getCompany",
  async (params: GetAdminCompanyRequest, thunkAPI) => {
    try {
      const response = await apiClient.getCompany(params)

      return response.data.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getUser = createAsyncThunk(
  "auth/getUser",
  async (params: GetUserRequest) => {
    const response = await apiClient.getUser(params)

    return response.data
  },
)

export const uploadProfileImage = async (request: ImageUploadRequest) => {
  const response = await apiClient.uploadProfileImage(request)
  return response.data
}

export const updateUser = async (request: UpdateUserType) => {
  const response = await apiClient.updateUser(request)
  return response.data
}

export const getHostPmc = createAsyncThunk("auth/getHostPmc", async () => {
  const response = await apiClient.getHostPmc()

  return response.data
})

export const getAdminPmc = createAsyncThunk("auth/getAdminPmc", async () => {
  const response = await apiClient.getAdminPmc()

  return response.data
})

export const getDeviceId = createAsyncThunk("auth/getDeviceId", async () => {
  const response = await apiClient.getDeviceIdApi()

  return response.data
})

const initialState: AuthInitialState = {
  loading: false,
  dialCode: "",
  mobileNumber: "",
  email: "",
  userExists: false,
  isTermAccepted: false,
  showOtpForm: false,
  forgotPasswordForm: false,
  showEmailForm: false,
  isLoggedIn: true,
  currentPmc: null,
  user: {
    userId: 0,
    // pmcId: "",
    password: "",
    active: false,
    userType: 0,
    isAdmin: false,
    isHost: false,
    isTraveler: false,
    emailVerified: false,
    mobileno1: "",
    mobileno2: "",
    phNo: "",
    deactiveDt: "",
    dialerCode: "",
    dobDt: "",
    emailId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    fullName: "",
    gender: "",
    about: "",
    city: "",
    country: "",
    currency: "",
    identificationId: "",
    language: "",
    location: "",
    profileImg: "",
    state: "",
    zipcode: "",
    appVersion: "",
    browserType: "",
    ipAddress: "",
    isTrmsncndAccepted: false,
    macId: "",
    trmsncndAcceptedDate: "",
    trmsncndAcceptedVersion: "",
    signinFrom: "",
    travelerRatingUserCount: 0,
    travelerRatingStarSum: 0,
    notificationEmail: false,
    notificationApp: false,
    notificationSms: false,
    hostWebsite: "",
    profileImageFullPath: "",
    hostFeePercentage: 0,
    servicefeeforhostId: 0,
    pmsType: "",
    channel: "",
    signinProvider: "",
    signinProviderId: "",
    socialProfileImageURL: "",
    superhogUserId: "",
    invCode: "",
    listingStatus: false,
    userRole: "",
  },
  pmc: [],
  isPmcWhimstay: null,
  error: null,
  inviteCode: null,
  isTandCModalOpen: false,
  isInviteTermAccepted: false,
  companyData: null,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
    },
    logOut: (state) => {
      removeAuthToken()
      state.isLoggedIn = false
      state.currentPmc = null
      state.isPmcWhimstay = null
      state.user = null
    },
    setCurrentActivePmc: (state, action: PayloadAction<string>) => {
      const pmcId = action.payload
      if (pmcId !== state.currentPmc?.pmc_id) {
        // means new pmc selected
        // set newpmc
        state.currentPmc = state.pmc.find((x) => x.pmc_id === pmcId) || null
        state.isPmcWhimstay = state.currentPmc?.pms_system === "whimstay"
      }
    },
    setDialCode: (state, action: PayloadAction<string>) => {
      state.dialCode = action.payload
    },
    setMobileNumber: (state, action: PayloadAction<string>) => {
      state.mobileNumber = action.payload
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setForgotPasswordForm: (state, action: PayloadAction<boolean>) => {
      state.forgotPasswordForm = action.payload
    },
    setShowOtpForm: (state, action: PayloadAction<boolean>) => {
      state.showOtpForm = action.payload
    },
    setEmailPasswordForm: (state, action: PayloadAction<boolean>) => {
      state.showEmailForm = action.payload
    },
    setAuthError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
    setIsTandCModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isTandCModalOpen = action.payload
    },
    setInviteCode: (state, action: PayloadAction<string>) => {
      state.inviteCode = action.payload
    },
    setInviteTerms: (state, action: PayloadAction<boolean>) => {
      state.isInviteTermAccepted = action.payload
    },
    setIstermAccepted: (state, action: PayloadAction<boolean>) => {
      state.isTermAccepted = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true
      state.isLoggedIn = false
      state.error = null
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false
      state.showOtpForm = true
      state.userExists = action.payload.data?.userExists || false
      state.isTermAccepted = action.payload.data?.isTermAccepted || false
      state.error = null
    })
    builder.addCase(login.rejected, (state, action) => {
      const payload = action.payload as { errorMessage?: string }
      state.loading = false
      state.isLoggedIn = false
      state.error = payload?.errorMessage || action.error?.message || ""
    })
    builder.addCase(verifyOtp.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(verifyOtp.fulfilled, (state) => {
      state.loading = false
      // state.isLoggedIn = true
      state.error = null
    })
    builder.addCase(verifyOtp.rejected, (state, action) => {
      const payload = action.payload as { errorMessage?: string }
      state.loading = false
      state.isLoggedIn = false
      state.error =
        payload?.errorMessage || action.error?.message || "Otp does not match."
    })
    builder.addCase(verifyPassword.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(verifyPassword.fulfilled, (state) => {
      state.loading = false
      state.error = null
    })
    builder.addCase(verifyPassword.rejected, (state, action) => {
      const payload = action.payload as { errorMessage?: string }
      state.loading = false
      state.isLoggedIn = false
      state.error =
        payload?.errorMessage ||
        action.error?.message ||
        "Password does not match"
    })
    builder.addCase(verifyResetPasswordLink.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(verifyResetPasswordLink.fulfilled, (state) => {
      state.loading = false
      state.error = null
    })
    builder.addCase(verifyResetPasswordLink.rejected, (state, action) => {
      const payload = action.payload as { errorMessage: string }
      state.loading = false
      state.isLoggedIn = false
      state.error =
        payload.errorMessage ||
        action.error.message ||
        "Reset Password link does not send"
    })
    builder.addCase(getUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.isLoggedIn = true
      state.user = { ...state.user, ...action.payload.data }
      state.loading = false
      const user = {
        userId: action.payload.data.userId,
        firstName: action.payload.data.firstName,
        pmcId: "",
      }
      // Store user inside cookies for WebV4.
      setUserCookie(user)
      state.error = null
    })
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })
    builder.addCase(getCompany.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.companyData = action.payload
      state.loading = false
      state.error = null
    })
    builder.addCase(getCompany.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })
    builder.addCase(getHostPmc.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getHostPmc.fulfilled, (state, action) => {
      const selectedPMCId = getSelectedPMCId()
      state.pmc = action.payload.data
      state.currentPmc =
        action.payload.data.find((item) => item.pmc_id === selectedPMCId) ||
        action.payload.data.at(0) ||
        null
      state.isPmcWhimstay = state.currentPmc?.pms_system === "whimstay"
      state.loading = false
      state.error = null
    })
    builder.addCase(getHostPmc.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })
    builder.addCase(getAdminPmc.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getAdminPmc.fulfilled, (state, action) => {
      state.loading = false
      state.pmc = action.payload.data
      state.currentPmc = action.payload.data.at(0) || null
      state.isPmcWhimstay = state.currentPmc?.pms_system === "whimstay"
      state.error = null
    })
    builder.addCase(getAdminPmc.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })
  },
})

export const {
  setDialCode,
  setMobileNumber,
  setEmail,
  setShowOtpForm,
  setForgotPasswordForm,
  setEmailPasswordForm,
  setAuthError,
  setIsLoggedIn,
  logOut,
  setCurrentActivePmc,
  setIsTandCModalOpen,
  setInviteCode,
  setInviteTerms,
  setIstermAccepted,
} = authSlice.actions

export const selectDialCode = (state: RootState) => state.authReducer.dialCode
export const selectMobileNumber = (state: RootState) =>
  state.authReducer.mobileNumber
export const selectCompanyTags = (state: RootState) =>
  state.authReducer.companyData?.companyCustomTags
export const selectMinimumAgeTemplate = (state: RootState) =>
  state.authReducer.companyData?.minimumAgeTemplate
export const selectIsAutoPublished = (state: RootState) =>
  state.authReducer.companyData?.isAutoPublished
export const selectEmail = (state: RootState) => state.authReducer.email
export const selectIsUserExists = (state: RootState) =>
  state.authReducer.userExists
export const selectIsTermAccepted = (state: RootState) =>
  state.authReducer.isTermAccepted
export const selectShowOtpForm = (state: RootState) =>
  state.authReducer.showOtpForm
export const selectForgotPasswordForm = (state: RootState) =>
  state.authReducer.forgotPasswordForm
export const selectEmailPasswordForm = (state: RootState) =>
  state.authReducer.showEmailForm
export const selectAuthLoading = (state: RootState) => state.authReducer.loading
export const selectAuthError = (state: RootState) => state.authReducer.error
export const selectUser = (state: RootState) => state.authReducer.user
export const selectUserName = (state: RootState) =>
  state.authReducer.user?.firstName
export const selectUserProfileImage = (state: RootState) =>
  state.authReducer.user?.profileImageFullPath
export const selectPmc = (state: RootState) => state.authReducer.pmc
export const selectCurrentPmc = (state: RootState) =>
  state.authReducer.currentPmc
export const selectUserLoggedIn = (state: RootState) =>
  state.authReducer.isLoggedIn
export const selectIsPmcWhimstay = (state: RootState) =>
  state.authReducer.isPmcWhimstay

export const selectIsTandCModalOpen = (state: RootState) =>
  state.authReducer.isTandCModalOpen

export const selectInviteCode = (state: RootState) =>
  state.authReducer.inviteCode

export const selectIsInviteTermAccepted = (state: RootState) =>
  state.authReducer.isInviteTermAccepted

export const selectUserRole = (state: RootState) =>
  state.authReducer.user?.userRole

export default authSlice.reducer
