import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Center,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuList,
  Text,
  Portal,
} from "@chakra-ui/react"

import { MenuTypeProps } from "./types"

const MenuDropDown = (props: MenuTypeProps & MenuButtonProps) => {
  const {
    title,
    children,
    width = "auto",
    maxW,
    maxH = "400px",
    backgroundColor = "transparent",
    borderRadius = "full",
    buttonWidth = "auto",
  } = props
  return (
    <Menu isLazy matchWidth autoSelect={false}>
      <MenuButton
        as={Button}
        variant='outline'
        px={{ base: 2, xxl: 3 }}
        py={1.5}
        border='1.5px solid #E1E1E1'
        transition='all 0.2s'
        borderRadius={borderRadius}
        overflow='hidden'
        width={buttonWidth}
        backgroundColor={backgroundColor}
      >
        <Box alignItems='center' display='flex' justifyContent='space-between'>
          <Box mr={{ base: "8px", xxl: "12px" }}>
            <Text
              size={{ base: "xs", md: "sm" }}
              pl='5px'
              fontWeight='500'
              maxW='140px'
              overflow='clip'
              whiteSpace='nowrap'
              wordBreak='keep-all'
              width={width}
              textOverflow='ellipsis'
            >
              {title}
            </Text>
          </Box>
          <Center borderLeft='1.5px solid #E1E1E1'>
            <ChevronDownIcon ml='4px' w={6} h={6} />
          </Center>
        </Box>
      </MenuButton>
      <Portal>
        <MenuList
          maxH={maxH}
          overflowY='auto'
          minW='auto'
          maxW={maxW}
          zIndex='popover'
        >
          {children}
        </MenuList>
      </Portal>
    </Menu>
  )
}

export default MenuDropDown
