import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as R from "ramda"

import { RootState } from "app/store"
import { newAttachementsResponseHelper } from "components/calendar/newApiResponseHelper"
import {
  AttachmentRequest,
  GetAllAttachmentsRequest,
  RemoveAttachmentRequest,
  ReorderAttachmentRequest,
  UpdateAttachmentRequest,
  UploadVideoRequest,
} from "services/attachments"
import { apiClient } from "services/client"
import { HidePropertyImageRequest } from "services/properties"

import { FileState, AttachmentInitialState } from "./types"

export const addAttachment = createAsyncThunk(
  "attachments/addAttachment",
  async (params: AttachmentRequest) => {
    const response = await apiClient.addAttachment(params)

    return response.data
  },
)

export const updateAttachment = createAsyncThunk(
  "attachments/updateAttachment",
  async (params: UpdateAttachmentRequest) => {
    const response = await apiClient.updateAttachment(params)

    return response.data
  },
)

export const removeAttachment = createAsyncThunk(
  "attachments/removeAttachment",
  async (
    arg: {
      params: RemoveAttachmentRequest
      index: number
    },
    thunkAPI,
  ) => {
    const { params, index } = arg
    const response = await apiClient.removeAttachment(params)

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    thunkAPI.dispatch(removeImage({ index }))

    return response.data
  },
)

export const reorderAttachment = createAsyncThunk(
  "attachments/reorderAttachment",
  async (params: ReorderAttachmentRequest) => {
    const response = await apiClient.reorderAttachment(params)

    return response.data
  },
)

export const getAllAttachments = createAsyncThunk(
  "attachments/getAllAttachments",
  async (params: GetAllAttachmentsRequest) => {
    const response = await apiClient.getAllAttachments(params)

    return response.data
  },
)

export const uploadVideo = createAsyncThunk(
  "attachments/uploadVideo",
  async (params: UploadVideoRequest) => {
    const response = await apiClient.uploadVideo(params)

    return response.data
  },
)

export const hidePropertyImage = createAsyncThunk(
  "property/hide-property-Image",
  async (params: HidePropertyImageRequest) => {
    const response = await apiClient.hidePropertyImage(params)

    return response.data
  },
)

const initialState: AttachmentInitialState = {
  loading: false,
  editImageIndex: null,
  images: [],
  attachment: {
    hostPopertyAttachmentId: 0,
    fileDescription: "",
    fileName: "",
    imgOrder: 0,
    isCoverImg: 0,
    pmsUnitId: "",
    pmcId: "",
    imageFullUrl: "",
    imageThumbnailUrl: "",
    video: false,
    isHide: false,
  },
  attachments: [],
  error: null,
}

export const attachmentsSlice = createSlice({
  name: "attachments",
  initialState,
  reducers: {
    resetSelectedAttachments: (state) => {
      state.images = initialState.images
    },
    setEditImageIndex: (state, action: PayloadAction<number | null>) => {
      state.editImageIndex = action.payload
    },
    addImage: (state, action: PayloadAction<FileState>) => {
      state.images = [...state.images, action.payload]
    },
    updateImage: (
      state,
      action: PayloadAction<{ editImageIndex: number; newImage: FileState }>,
    ) => {
      state.images = R.update(
        action.payload.editImageIndex,
        action.payload.newImage,
        state.images,
      )
    },
    replaceImage: (
      state,
      action: PayloadAction<{ dragIndex: number; hoverIndex: number }>,
    ) => {
      state.images = R.move(
        action.payload.dragIndex,
        action.payload.hoverIndex,
        state.images,
      )
      state.attachments = R.move(
        action.payload.dragIndex,
        action.payload.hoverIndex,
        state.attachments,
      )
    },
    removeImage: (state, action: PayloadAction<{ index: number }>) => {
      state.images = R.remove(action.payload.index, 1, state.images)
      state.attachments = R.remove(action.payload.index, 1, state.attachments)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addAttachment.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addAttachment.fulfilled, (state, action) => {
      state.loading = false
      state.attachments = [...state.attachments, action.payload.data]
        .slice()
        .sort((a, b) => a.imgOrder - b.imgOrder)
      state.error = null
    })
    builder.addCase(addAttachment.rejected, (state, action) => {
      state.loading = false
      state.attachments = []
      state.error = action.error.message as string
    })
    builder.addCase(updateAttachment.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateAttachment.fulfilled, (state, action) => {
      state.loading = false
      // Todo: to test editImageIndex
      state.attachments = R.update(
        state.editImageIndex as number,
        action.payload.data,
        state.attachments,
      )
      // Todo: to test editImageIndex
      state.editImageIndex = null
      state.error = null
    })
    builder.addCase(updateAttachment.rejected, (state, action) => {
      state.loading = false
      state.attachments = []
      // Todo: to test editImageIndex
      state.editImageIndex = null
      state.error = action.error.message as string
    })
    builder.addCase(removeAttachment.pending, (state) => {
      state.loading = true
    })
    builder.addCase(removeAttachment.fulfilled, (state) => {
      state.loading = false
      state.error = null
    })
    builder.addCase(removeAttachment.rejected, (state, action) => {
      state.loading = false
      state.attachment = null
      state.error = action.error.message as string
    })
    builder.addCase(reorderAttachment.pending, (state) => {
      state.loading = true
    })
    builder.addCase(reorderAttachment.fulfilled, (state) => {
      state.loading = false
      state.error = null
    })
    builder.addCase(reorderAttachment.rejected, (state, action) => {
      state.loading = false
      state.attachment = null
      state.error = action.error.message as string
    })
    builder.addCase(getAllAttachments.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllAttachments.fulfilled, (state, action) => {
      state.loading = false
      state.attachments = newAttachementsResponseHelper([
        ...action.payload.data,
      ])
        .slice()
        .sort((a, b) => a.imgOrder - b.imgOrder)
      state.error = null
    })
    builder.addCase(getAllAttachments.rejected, (state, action) => {
      state.loading = false
      state.attachments = []
      state.error = action.error.message as string
    })
    builder.addCase(uploadVideo.pending, (state) => {
      state.loading = true
    })
    builder.addCase(uploadVideo.fulfilled, (state, action) => {
      state.loading = false
      state.attachments = [...state.attachments, action.payload.data]
        .slice()
        .sort((a, b) => a.imgOrder - b.imgOrder)
      state.error = null
    })
    builder.addCase(uploadVideo.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })
    builder.addCase(hidePropertyImage.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(hidePropertyImage.fulfilled, (state, action) => {
      state.loading = false
      // update current state attechment array when api return object
      const Index = state.attachments.findIndex(
        (item) => item.fileName === action.meta.arg.params.description,
      )
      if (Index > -1) {
        state.attachments[Index].isHide = action.meta.arg.params.remove
      }
      state.error = null
    })
    builder.addCase(hidePropertyImage.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })
  },
})

export const {
  addImage,
  updateImage,
  replaceImage,
  removeImage,
  setEditImageIndex,
  resetSelectedAttachments,
} = attachmentsSlice.actions

export const selectEditImageIndex = (state: RootState) =>
  state.attachmentsReducer.editImageIndex
export const selectImages = (state: RootState) =>
  state.attachmentsReducer.images
export const selectAttachment = (state: RootState) =>
  state.attachmentsReducer.attachment
export const selectAttachments = (state: RootState) =>
  state.attachmentsReducer.attachments
// .slice()
// .sort((a, b) => a.imgOrder - b.imgOrder)
export const selectAttachmentError = (state: RootState) =>
  state.attachmentsReducer.error

export default attachmentsSlice.reducer
