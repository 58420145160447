import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "services/client"
import {
  ApplyPayoutRulesOnPropertiesRequestData,
  GetPayoutPropertiesRequestData,
  PayoutBankResponseData,
  ResponseData,
} from "services/common"

export const defaultPayoutSettingsUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/payment/account/host`

export interface GetAccountsByPmcIdData extends ResponseData {
  data: {
    content: PayoutBankResponseData[]
    pageable: {
      sort: {
        empty: boolean
        sorted: boolean
        unsorted: boolean
      }
      offset: number
      pageNumber: number
      pageSize: number
      paged: boolean
      unpaged: boolean
    }
    last: boolean
    totalPages: number
    totalElements: number
    number: number
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    size: number
    first: boolean
    numberOfElements: number
    empty: boolean
  }
}

export interface GetAccountsByPmcIdResponse extends AxiosResponse {
  data: GetAccountsByPmcIdData
}

export interface GetAccountsByPmcIdRequestData {
  pmcId: string
}

export interface GetAccountsByPmcIdRequest extends Partial<AxiosRequestConfig> {
  params: GetAccountsByPmcIdRequestData
}

export function getHostAccountList(
  {
    params,
    method = "post",
    url = `${defaultPayoutSettingsUrl}/accountlist/by-pmcid`,
    ...config
  }: GetAccountsByPmcIdRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetAccountsByPmcIdResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<GetAccountsByPmcIdResponse>
}

export type PayoutProperty = {
  pmcId: string
  pmsUnitId: string
  propertyName: string
  address: string
  pgHostAccId: number
}

export interface PayoutPropertyResponseData extends ResponseData {
  data: {
    content: PayoutProperty[]
    pageable: {
      sort: {
        empty: boolean
        sorted: boolean
        unsorted: boolean
      }
      offset: number
      pageNumber: number
      pageSize: number
      paged: boolean
      unpaged: boolean
    }
    last: boolean
    totalPages: number
    totalElements: number
    number: number
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    size: number
    first: boolean
    numberOfElements: number
    empty: boolean
  }
}

export interface PayoutPropertyResponse extends AxiosResponse {
  data: PayoutPropertyResponseData
}

export interface PayoutPropertiesRequest extends Partial<AxiosRequestConfig> {
  params: GetPayoutPropertiesRequestData
}

export function getPayoutProperties(
  {
    params,
    method = "post",
    url = `${defaultPayoutSettingsUrl}/properties/by-pghostaccid`,
    ...config
  }: PayoutPropertiesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<PayoutPropertyResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<PayoutPropertyResponse>
}

export interface ApplyPayoutRulesOnPropertiesResponseData extends ResponseData {
  data: unknown
}

export interface ApplyPayoutRulesOnPropertiesResponse extends AxiosResponse {
  data: ApplyPayoutRulesOnPropertiesResponseData
}

export interface ApplyPayoutRulesOnPropertiesRequest
  extends Partial<AxiosRequestConfig> {
  isAllUpdate: boolean
  isRemoveAll?: boolean
  data: ApplyPayoutRulesOnPropertiesRequestData
}

export function applyPayoutRulesOnProperties(
  {
    isAllUpdate,
    isRemoveAll = false,
    data,
    method = "put",
    url = `${defaultPayoutSettingsUrl}/properties`,
    ...config
  }: ApplyPayoutRulesOnPropertiesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ApplyPayoutRulesOnPropertiesResponse> {
  return axiosInstance({
    data: {
      isAllUpdate,
      isRemoveAll,
      property: data,
    },
    method,
    url,
    ...config,
  }) as Promise<ApplyPayoutRulesOnPropertiesResponse>
}
