import { useEffect, useState } from "react"

import { EditIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  IconButton,
  Input,
  Link,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react"
import { useDispatch } from "react-redux"

import { useAppSelector } from "app/hooks"
import LearnMoreModal from "components/learn-modal-modal/learn-more-modal"
import { apiClient } from "services/client"
import { selectCurrentPmc } from "store/auth/authSlice"
import {
  getCalendarCommonData,
  setCalendarLoading,
} from "store/calendar/calendarSlice"
import { selectProperty } from "store/property/propertySlice"

const CalendarPricing = () => {
  const [isSyncEnabled, setIsSyncEnabled] = useState(true)

  const [perNightPrice, setPerNightPrice] = useState("")
  const [isEditingPerNight, setIsEditingPerNight] = useState(false)

  const [customWeekendPrice, setCustomWeekendPrice] = useState("")
  const [isEditingWeekend, setIsEditingWeekend] = useState(false)

  const calendarCommondata = useAppSelector(getCalendarCommonData)
  const isPerNightPriceChanged =
    perNightPrice !== calendarCommondata?.basePrice?.toString()
  const isWeekendPriceChanged = customWeekendPrice !== ""
  const dispatch = useDispatch()

  useEffect(() => {
    setPerNightPrice(calendarCommondata?.basePrice?.toString())
  }, [calendarCommondata])

  const property = useAppSelector(selectProperty)
  const currentPmc = useAppSelector(selectCurrentPmc)

  const hasChanges = () => {
    if (isEditingPerNight && isEditingWeekend) {
      return isPerNightPriceChanged && isWeekendPriceChanged
    }
    if (isEditingPerNight) {
      return isPerNightPriceChanged
    }
    if (isEditingWeekend) {
      return isWeekendPriceChanged
    }
    return false
  }

  const handleCancel = () => {
    setPerNightPrice("239")
    setCustomWeekendPrice("")
    setIsEditingPerNight(false)
    setIsEditingWeekend(false)
  }

  const handleSyncToggle = () => {
    if (!isSyncEnabled) {
      handleCancel() // Reset all state values when turning on the sync
    }
    setIsSyncEnabled(!isSyncEnabled)
  }

  const updateCalendarData = () => {
    dispatch(setCalendarLoading(true))
    apiClient
      .updateCalendarData({
        params: {
          pmcId: currentPmc?.pmc_id?.toString() || "",
          pmsUnitId: property?.pmsUnitId,
          date: calendarCommondata?.date,
          basePrice: Number(perNightPrice) || calendarCommondata?.basePrice,
          discountPrice: calendarCommondata?.discountPrice,
          available: calendarCommondata?.available,
          arrival: calendarCommondata?.arrival,
          departure: true,
          minStayDays: 50,
        },
      })
      .then((resp) => {
        //  to get updatd data
        console.log(resp, "resp")
        dispatch(setCalendarLoading(false))
        return true
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const updateCalendarWeekendPrice = () => {
    dispatch(setCalendarLoading(true))
    apiClient
      .updateCalendarWeekendPrice({
        params: {
          pmcId: currentPmc?.pmc_id?.toString() || "",
          pmsUnitId: property?.pmsUnitId,
          weekendPrice: Number(customWeekendPrice),
        },
      })
      .then((resp) => {
        //  to get updatd data
        console.log(resp, "resp")
        dispatch(setCalendarLoading(false))
        return true
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSave = () => {
    setIsEditingPerNight(false)
    setIsEditingWeekend(false)
    if (isEditingPerNight) {
      updateCalendarData()
    }
    if (isEditingWeekend) {
      updateCalendarWeekendPrice()
    }
  }

  return (
    <Box>
      <Text
        mt={{ md: 20 }}
        mb='14px'
        fontSize={{ base: "20px", xl: "21px" }}
        fontWeight='semibold'
        lineHeight='25.6px'
      >
        Pricing
      </Text>
      <Box
        border='1px solid #00000033'
        borderRadius='16px'
        px={{ base: 4, xl: 5 }}
        py={{ base: 5, xl: 6 }}
      >
        <Stack direction='row' align='center' justify='space-between'>
          <Text
            fontSize='lg'
            fontWeight='semibold'
            color='primaryColor'
            lineHeight='22px'
          >
            Sync Airbnb pricing
          </Text>
          <Switch
            colorScheme='teal'
            size='lg'
            isChecked={isSyncEnabled}
            onChange={handleSyncToggle}
          />
        </Stack>
        <Text mt={5} fontSize='lg' fontWeight={500}>
          Per night
        </Text>
        {(isSyncEnabled && (
          <Box pt='11px'>
            <Text
              fontSize={{ base: "32px", xl: "42px" }}
              fontWeight={500}
              lineHeight='51px'
            >
              Auto
            </Text>
            <Text mt={9} fontSize='md'>
              Per night rate does not include taxes, fees or discounts
            </Text>
            <Text mt={2.5} mb={2} fontSize='md'>
              <Text as='span' fontWeight='semibold'>
                Sync Airbnb pricing
              </Text>{" "}
              automatically imports your rates from Airbnb.
            </Text>
            <LearnMoreModal
              title='Sync Airbnb pricing'
              width={{
                base: "280px",
                xsm: "300px",
                sm: "410px",
                md: "500px",
                lg: "624px",
              }}
              titleFontSize={{ base: "lg", sm: "21px" }}
              btnFontSize='15px'
              btnFontFamily='roboto'
              iconTop='16px'
              iconRight='18px'
              iconColor='#00000080'
            >
              <Text fontSize='md' fontWeight={500} lineHeight='22px' mt={4}>
                We make it simple so you don’t need to update your rate in
                Whimstay. We will automatically check your Airbnb rates to
                calculate your base nightly rate for every available night in
                your calendar. If you are using Airbnb Smart Pricing, or Custom
                weekend price Whimstay will automatically sync your rates. Some
                nightly rates will be averaged. Updates are performed hourly.
              </Text>
              <Link
                href='/'
                textDecoration='underline'
                fontSize='md'
                fontWeight={500}
                lineHeight='22px'
              >
                Learn more here.
              </Link>
            </LearnMoreModal>
          </Box>
        )) || (
          <Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              mt={3}
            >
              {isEditingPerNight ? (
                <>
                  <Input
                    value='$'
                    readOnly
                    height='49px'
                    fontSize='35px'
                    border={0}
                    paddingLeft={2.5}
                    borderRightRadius={0}
                    paddingRight={0}
                    width='32px'
                    minW='32px'
                    background='#F0F0F0'
                    _focus={{
                      outline: "none",
                      boxShadow: "none",
                    }}
                  />
                  <Input
                    value={perNightPrice}
                    onChange={(e) => setPerNightPrice(e.target.value)}
                    fontSize='35px'
                    background='#F0F0F0'
                    height='49px'
                    paddingLeft={0}
                    border={0}
                    borderLeftRadius={0}
                    autoComplete='off'
                    _focus={{
                      outline: "none",
                      boxShadow: "none",
                    }}
                  />
                </>
              ) : (
                <>
                  <Text
                    fontSize={{ base: "32px", xl: "42px" }}
                    fontWeight={500}
                    lineHeight='51px'
                  >
                    ${perNightPrice}
                  </Text>
                  <IconButton
                    aria-label='Edit'
                    icon={<EditIcon fontSize='18px' />}
                    size='sm'
                    variant='ghost'
                    onClick={() => setIsEditingPerNight(true)}
                  />
                </>
              )}
            </Box>
            <Box mt={10}>
              <Text fontSize='lg' fontWeight={500} lineHeight='22px'>
                Custom weekend price
              </Text>
              <Box
                mb={9}
                mt={2.5}
                display='flex'
                alignItems='center'
                justifyContent='space-between'
              >
                {isEditingWeekend ? (
                  <Box
                    display='flex'
                    alignItems='center'
                    w='100%'
                    mt={2.5}
                    mb={5}
                  >
                    <Input
                      value='$'
                      readOnly
                      height='49px'
                      fontSize='35px'
                      border={0}
                      paddingLeft={2.5}
                      borderRightRadius={0}
                      paddingRight={0}
                      width='32px'
                      minW='32px'
                      background='#F0F0F0'
                      _focus={{
                        outline: "none",
                        boxShadow: "none",
                      }}
                    />
                    <Input
                      value={customWeekendPrice}
                      onChange={(e) => setCustomWeekendPrice(e.target.value)}
                      fontSize='35px'
                      background='#F0F0F0'
                      height='49px'
                      paddingLeft={0}
                      border={0}
                      borderLeftRadius={0}
                      autoComplete='off'
                      _focus={{
                        outline: "none",
                        boxShadow: "none",
                      }}
                    />
                  </Box>
                ) : (
                  <>
                    {customWeekendPrice !== "" ? (
                      <Text
                        fontSize={{ base: "32px", xl: "42px" }}
                        fontWeight={500}
                        lineHeight='51px'
                      >
                        ${customWeekendPrice}
                      </Text>
                    ) : (
                      <Button
                        fontSize='lg'
                        fontWeight={500}
                        variant='ghost'
                        textColor='primaryColor'
                        p={0}
                        height='22px'
                        textDecoration='underline'
                        onClick={() => setIsEditingWeekend(true)}
                      >
                        Add
                      </Button>
                    )}
                    <IconButton
                      aria-label='Edit'
                      icon={<EditIcon fontSize='18px' />}
                      size='sm'
                      variant='ghost'
                      onClick={() => setIsEditingWeekend(true)}
                    />
                  </>
                )}
              </Box>
            </Box>

            <Text fontSize='md' mb={7}>
              Per night rate does not include taxes, fees or discounts.
            </Text>
            {(isEditingPerNight || isEditingWeekend) && (
              <Box
                display='flex'
                alignItems='center'
                justifyContent='flex-end'
                gap={6}
                pt={1.5}
              >
                <Button
                  variant='ghost'
                  fontSize='15px'
                  height='42px'
                  p={0}
                  disabled={!hasChanges()}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  fontSize='md'
                  minW='116px'
                  fontWeight={400}
                  height='42px'
                  disabled={!hasChanges()}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CalendarPricing
