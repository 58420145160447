import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "app/store"
import { getPropertyDataResponseHelper } from "components/calendar/newApiResponseHelper"
import { GetCustomAmenitiesRequest } from "services/amenities"
import { apiClient } from "services/client"
import { PropertyStatus } from "services/common"
import {
  GetPmcDescriptionRequest,
  GetPropertyRequest,
  // HidePropertyImageRequest,
  PropertUpdateRequest,
  PropertyRequest,
} from "services/properties"

import { PropertyInitialState, PropertyState } from "./types"

export const createProperty = createAsyncThunk(
  "property/createProperty",
  async (params: PropertyRequest) => {
    const response = await apiClient.createProperty(params)

    return response.data
  },
)

export const updateProperty = createAsyncThunk(
  "property/updateProperty",
  async (params: PropertyRequest) => {
    const response = await apiClient.updateProperty(params)

    return response.data
  },
)

export const updatePropertyDescription = createAsyncThunk(
  "property/edit-property-decription",
  async (params: PropertUpdateRequest) => {
    const response = await apiClient.updatePropertyDescription(params)

    return response.data
  },
)

export const getProperty = createAsyncThunk(
  "property/getProperty",
  async (params: GetPropertyRequest) => {
    const response = await apiClient.getProperty(params)

    return response.data
  },
)

export const getPMCDescription = createAsyncThunk(
  "property/getPMCDescription",
  async (params: GetPmcDescriptionRequest) => {
    const response = await apiClient.getPMCDescription(params)

    return response.data
  },
)

export const getCustomAmenities = createAsyncThunk(
  "amenities/getCustomAmenities",
  async (params: GetCustomAmenitiesRequest) => {
    const response = await apiClient.getCustomAmenities(params)

    return response.data
  },
)

const initialState: PropertyInitialState = {
  loading: false,
  property: {
    id: "",
    addedDesc: "",
    propertyId: "",
    active: false,
    isHide: false,
    featureProperty: false,
    noOfUnits: 0,
    propertyDesc: "",
    propertyName: "",
    pmsType: "",
    pulledProperty: false,
    pmcId: "",
    pmsUnitId: "",
    propertyTypeDesc: "",
    propertySubTypeDesc: "",
    propertyAccessType: "",
    addressFull: "",
    addressline1: "",
    addressline2: "",
    city: "",
    state: "",
    countryCode: "",
    postalCode: "",
    latitude: "37.842449",
    longitude: "-122.001647",
    distance: 0,
    maxNoGuest: 1,
    noOfBathroom: 1,
    noOfBedroom: 1,
    partyAllowed: false,
    smokeAllowed: false,
    suitableChild: false,
    suitableInfant: false,
    suitablePet: false,
    wheelchairAccessable: false,
    maxPrice: 0,
    addHouseRule: "",
    propertyRatingUserCount: 0,
    propertyRatingStarSum: 0,
    checkInTime: "",
    checkInInstruction: "",
    checkOutTime: "",
    checkOutInstruction: "",
    cleaningFee: 0,
    depositAmount: 0,
    depositType: "",
    depositeBasicType: 0,
    discPer: 0,
    minPrice: 0,
    nightlyTaxPer: 0,
    otherFee: 0,
    otherFeeName: "",
    petFee: 0,
    serviceTaxCleaning: false,
    serviceTaxDeposite: false,
    serviceTaxOther: false,
    serviceTaxPet: false,
    serviceTaxPer: 0,
    currency: "",
    contactMobileNo: "",
    contactEmailId: "",
    contactTemplateId: 0,
    discountTemplateId: 0,
    taxTemplateId: 0,
    unitNumber: "",
    pgHostAccId: 0,
    superhogListingId: 0,
    propertyUnitCalendarRate: {
      calendarId: 0,
      date: "",
      basePrice: 0,
      discPrice: 0,
      isAvailable: false,
      minStayDays: 0,
      pmsUnitId: "",
      pmcId: "",
      checkin: "",
      checkout: "",
    },
    roomsDetail: [],
    isManual: false,
    securityCamera: false,
    weapons: false,
    dangerousAnimals: false,
    propertyStatus: PropertyStatus.IN_PROGRESS,
    status: "",
    saveAndExit: false,
  },

  hostPropertyAttachment: [],
  propertyFeaturesKeyWords: [],
  propertyFeaturesNewAmenities: [],
  error: null,
}

export const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    reset: (state) => {
      state.property = initialState.property
      state.hostPropertyAttachment = initialState.hostPropertyAttachment
    },
    setProperty: (state, action: PayloadAction<PropertyState>) => {
      state.property = action.payload
    },
    sethouserules: (state, action: PayloadAction<string>) => {
      state.property.addHouseRule = action.payload
    },
    setaddressempty: (state, action: PayloadAction<string>) => {
      state.property.addressFull = action.payload
    },
    setCheckoutInstruction: (state, action: PayloadAction<string>) => {
      state.property.checkOutInstruction = action.payload
    },
    setCheckinInstruction: (state, action: PayloadAction<string>) => {
      state.property.checkInInstruction = action.payload
    },
    setCheckOutTime: (state, action: PayloadAction<string>) => {
      state.property.checkOutTime = action.payload
    },
    setCheckinTime: (state, action: PayloadAction<string>) => {
      state.property.checkInTime = action.payload
    },
    setPartyAllowed: (state, action: PayloadAction<boolean>) => {
      state.property.partyAllowed = action.payload
    },
    setPetAllowed: (state, action: PayloadAction<boolean>) => {
      state.property.suitablePet = action.payload
    },
    setChildAllowed: (state, action: PayloadAction<boolean>) => {
      state.property.suitableChild = action.payload
    },

    setSmokeAllowed: (state, action: PayloadAction<boolean>) => {
      state.property.smokeAllowed = action.payload
    },
    setInfantAllowed: (state, action: PayloadAction<boolean>) => {
      state.property.suitableInfant = action.payload
    },
    setWheelChairAllowed: (state, action: PayloadAction<boolean>) => {
      state.property.wheelchairAccessable = action.payload
    },
    setPropertyStatus: (state, action: PayloadAction<string>) => {
      state.property.status = action.payload
    },
    setSaveAndExitStatus: (state, action: PayloadAction<boolean>) => {
      state.property.saveAndExit = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createProperty.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createProperty.fulfilled, (state, action) => {
      state.loading = false
      state.property = { ...state.property, ...action.payload.data }
      state.error = null
    })
    builder.addCase(createProperty.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })
    builder.addCase(updateProperty.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateProperty.fulfilled, (state, action) => {
      state.loading = false
      state.property = { ...state.property, ...action.payload.data }
      state.error = null
    })
    builder.addCase(updateProperty.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })
    builder.addCase(updatePropertyDescription.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updatePropertyDescription.fulfilled, (state) => {
      state.loading = false
      // state.property = { ...state.property, ...action. .data }
      state.error = null
    })
    builder.addCase(updatePropertyDescription.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })

    // builder.addCase(getPMCDescription.pending, (state) => {
    //   // state.loading = true
    //   state.error = null
    // })
    // builder.addCase(getPMCDescription.fulfilled, (state) => {
    //   state.loading = false
    //   // state.property = { ...state.property, ...action.payload.data }
    //   state.error = null
    // })
    // builder.addCase(getPMCDescription.rejected, (state, action) => {
    //   // state.loading = false
    //   state.error = action.error.message as string
    // })
    builder.addCase(getProperty.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getProperty.fulfilled, (state, action) => {
      const mappedPropertyresponse = getPropertyDataResponseHelper(
        action.payload.data,
      )
      state.property = { ...state.property, ...mappedPropertyresponse.property }
      state.hostPropertyAttachment =
        mappedPropertyresponse.hostPropertyAttachment
      state.propertyFeaturesKeyWords =
        mappedPropertyresponse.propertyFeaturesKeyWords
      state.propertyFeaturesNewAmenities =
        mappedPropertyresponse.propertyFeaturesNewAmenites
      state.error = null
      state.loading = false
    })
    builder.addCase(getProperty.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message as string
    })
    builder.addCase(getCustomAmenities.fulfilled, (state, action) => {
      state.loading = false
      state.propertyFeaturesNewAmenities = action.payload.data.newAmenities
    })
  },
})

export const {
  setProperty,
  setPartyAllowed,
  setPetAllowed,
  setSmokeAllowed,
  setInfantAllowed,
  setWheelChairAllowed,
  setCheckOutTime,
  setCheckinTime,
  setCheckinInstruction,
  setCheckoutInstruction,
  sethouserules,
  setChildAllowed,
  setPropertyStatus,
  setSaveAndExitStatus,
  setaddressempty,
  reset,
} = propertySlice.actions

export const selectProperty = (state: RootState) =>
  state.propertyReducer.property

export const selectPropertyName = (state: RootState) =>
  state.propertyReducer.property?.propertyName

export const selectPropertyAttachments = (state: RootState) =>
  state.propertyReducer.hostPropertyAttachment

export const selectPropertyAmenities = (state: RootState) =>
  state.propertyReducer.propertyFeaturesKeyWords

export const selectPropertyCustomAmenities = (state: RootState) =>
  state.propertyReducer.propertyFeaturesNewAmenities

export const selectPropertyStatus = (state: RootState) =>
  state.propertyReducer.property.status

export const selectSaveAndExit = (state: RootState) =>
  state.propertyReducer.property.saveAndExit

export const selectIsLoading = (state: RootState) =>
  state.propertyReducer.loading

export const selectPropertyTypeDesc = (state: RootState) =>
  state.propertyReducer.property?.propertyTypeDesc

export default propertySlice.reducer
