import { ReactNode } from "react"

import { Box, Divider, Heading, useMediaQuery } from "@chakra-ui/react"

import ProtectedComponent from "components/PleaseLoginComp"
import DesktopSideMenu from "components/sidemenu/sidemenu"
import { MenuItem as MenuItemType } from "components/sidemenu/types"
import Tabs from "components/tabs/Tabs"
import { HOST_TAB_LIST } from "layouts/host-properties-layout"

type HostListingLayoutProps = {
  children: ReactNode
}

export const reportsSidebarMenuArr: MenuItemType[] = [
  {
    name: "Earning Progress",
    depth: 1,
    isActive: true,
    id: "earningProgress",
    to: "/listings/reports/earningProgress",
    childMenu: [],
  },
  {
    name: "Reservation",
    depth: 1,
    id: "reservation",
    to: "/listings/reports/reservation",
    childMenu: [
      // {
      //   name: "All",
      //   depth: 2,
      //   id: "all",
      //   to: "/listings/reports/reservation/all",
      //   childMenu: [],
      // },
      // {
      //   name: "Currently Hosting",
      //   depth: 2,
      //   id: "currentlyHosting",
      //   to: "/listings/reports/reservation/currentlyHosting",
      //   childMenu: [],
      // },
      // {
      //   name: "Arriving Soon",
      //   depth: 2,
      //   id: "arrivingSoon",
      //   to: "/listings/reports/reservation/arrivingSoon",
      //   childMenu: [],
      // },
      // {
      //   name: "Completed",
      //   depth: 2,
      //   id: "completed",
      //   to: "/listings/reports/reservation/completed",
      //   childMenu: [],
      // },
    ],
  },
  {
    name: "Payout",
    depth: 1,
    id: "payout",
    to: "/listings/reports/payout",
    childMenu: [],
  },
  {
    name: "Active Properties",
    depth: 1,
    id: "activeProperties",
    to: "/listings/reports/active-properties",
    childMenu: [],
  },
]

const HostReportsLayout = (props: HostListingLayoutProps) => {
  const { children } = props
  const [isMobile] = useMediaQuery("(max-width: 1023px)")

  return (
    <Box maxW='100%' px={{ base: "20px", lg: "30px", xl: "70px" }} minW='100%'>
      <Box py={{ base: "0", md: "16px" }} mx={{ base: "-20px", md: "0" }}>
        <Tabs tabsArr={HOST_TAB_LIST} />
      </Box>
      <Divider borderColor='#e1e1e1' />
      <Box mt={{ base: 4, md: 8 }} display='flex' gap={{ base: 7, xl: 10 }}>
        {!isMobile && (
          <Box w='210px' flexGrow={1} flexShrink={0}>
            <Heading size='sm'>Reports</Heading>
            <DesktopSideMenu sidebarMenuArr={reportsSidebarMenuArr} />
          </Box>
        )}
        <Box pr={{ base: 0, xl: 10 }} w='full'>
          <ProtectedComponent>{children}</ProtectedComponent>
        </Box>
      </Box>
    </Box>
  )
}

export default HostReportsLayout
