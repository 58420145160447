import { Flex, Box } from "@chakra-ui/react"

import Logo from "components/logo/logo"

import HeaderStep from "./step"
import "./index.css"

const Header = () => (
  <Box
    position={{ base: "fixed" }}
    top='0'
    w='full'
    h={{ base: "70px", lg2: "91px" }}
    display='flex'
    justifyContent='space-between'
    alignItems='center'
    maxWidth='100vw'
    zIndex='sticky'
    bgColor={{ base: "white", md: "transparent" }}
  >
    <Flex
      w='full'
      h='full'
      justifyContent='space-between'
      alignItems='center'
      px={{ base: 5, md: 16, lg: 24, xl: 70 }}
      py={{ base: 3 }}
    >
      <Logo />
      <HeaderStep />
    </Flex>
  </Box>
)

export default Header
