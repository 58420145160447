import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import {
  AddedReply,
  AddedReview,
  ResponseData,
  ReviewsAndRatingsData,
} from "./common"

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/review-and-rating`

export interface GetReviewsAndRatingsRequestData {
  pmcId: string
  pmsUnitId: string
  pageNo: string
  numberOfRecords: string
}

export interface GetReviewsAndRatingsRequest
  extends Partial<AxiosRequestConfig> {
  params: GetReviewsAndRatingsRequestData
}

export interface ReviewsAndRatingsResponseData extends ResponseData {
  data: ReviewsAndRatingsData
}

export interface ReviewsAndRatingsResponse extends AxiosResponse {
  data: ReviewsAndRatingsResponseData
}
export interface AddReviewRequestData {
  pmcId: string
  pmsUnitId: string
  payload: {
    id?: number
    reviewerName: string
    reviewTitle: string
    publishDate?: string
    rating: number
    reviewText: string
  }
}
export interface AddReviewRequest extends Partial<AxiosRequestConfig> {
  params: AddReviewRequestData
}
export interface AddReviewResponseData extends ResponseData {
  data: AddedReview
}

export interface AddReviewResponse extends AxiosResponse {
  data: AddReviewResponseData
}
export interface AddReplyRequestData {
  pmcId: string
  pmsUnitId: string
  payload: {
    id?: number
    userReviewId: number
    replyText: string
  }
}
export interface AddReplyRequest extends Partial<AxiosRequestConfig> {
  params: AddReplyRequestData
}
export interface AddReplyResponseData extends ResponseData {
  data: AddedReply
}

export interface AddReplyResponse extends AxiosResponse {
  data: AddReplyResponseData
}
export interface DeleteReplyRequestData {
  pmcId: string
  pmsUnitId: string
  userReplyId: string | number
}
export interface DeleteReplyRequest extends Partial<AxiosRequestConfig> {
  params: DeleteReplyRequestData
}
export interface DeleteReplyResponse extends AxiosResponse {
  data: unknown
}
export interface DeleteReviewRequestData {
  pmcId: string
  pmsUnitId: string
  userReviewId: string | number
}
export interface DeleteReviewRequest extends Partial<AxiosRequestConfig> {
  params: DeleteReviewRequestData
}
export interface DeleteReviewResponse extends AxiosResponse {
  data: unknown
}

export function getReviewsAndRatings(
  {
    params,
    method = "get",
    url = defaultUrl,
    ...config
  }: GetReviewsAndRatingsRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ReviewsAndRatingsResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<ReviewsAndRatingsResponse>
}

export function addReview(
  {
    params,
    method = "post",
    url = `${defaultUrl}?pmcId=${encodeURIComponent(params.pmcId)}&pmsUnitId=${
      params.pmsUnitId
    }`,
    ...config
  }: AddReviewRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AddReviewResponse> {
  return axiosInstance({
    data: params.payload,
    method,
    url,
    ...config,
  }) as Promise<AddReviewResponse>
}

export function addReply(
  {
    params,
    method = "post",
    url = `${defaultUrl}/reply?pmcId=${encodeURIComponent(
      params.pmcId,
    )}&pmsUnitId=${params.pmsUnitId}`,
    ...config
  }: AddReplyRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AddReplyResponse> {
  return axiosInstance({
    data: params.payload,
    method,
    url,
    ...config,
  }) as Promise<AddReplyResponse>
}

export function deleteReply(
  {
    params,
    method = "delete",
    url = `${defaultUrl}/reply`,
    ...config
  }: DeleteReplyRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<DeleteReplyResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<DeleteReplyResponse>
}
export function deleteReview(
  {
    params,
    method = "delete",
    url = `${defaultUrl}`,
    ...config
  }: DeleteReviewRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<DeleteReviewResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<DeleteReviewResponse>
}
