import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "app/store"
import { CalendarDateListProps } from "pages/properties/types"
import { GetCalendarDatesRequest } from "services/calendar"
import { apiClient } from "services/client"

import {
  BookedDatesProps,
  CalendarCommonData,
  CalendarInitialState,
} from "./types"

export const getCalendarDates = createAsyncThunk(
  "property/getCalendarDates",
  async (params: GetCalendarDatesRequest) => {
    const response = await apiClient.getCalendarDates(params)

    return response.data
  },
)

const initialState: CalendarInitialState = {
  loading: false,
  error: null,
  calendarList: [],
  dates: [],
  blockedDates: [],
  bookedDates: [],
  bookedDatesList: [[]],
  // TODO.jay update the default value after discussion
  commondata: {
    basePrice: 0,
    date: "",
    arrival: true,
    available: true,
    departure: true,
    discountPrice: 0,
    minStayDays: 1,
  },
}

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    reset: (state) => {
      state.calendarList = initialState.calendarList
      state.commondata = initialState.commondata
    },
    setCalendarDates: (
      state,
      action: PayloadAction<Array<CalendarDateListProps>>,
    ) => {
      state.calendarList = action.payload
    },
    setCalendarBlockedDates: (state, action: PayloadAction<Array<string>>) => {
      state.blockedDates = action.payload
    },
    setCalendarBookedDates: (
      state,
      action: PayloadAction<BookedDatesProps>,
    ) => {
      state.bookedDates = action.payload
    },
    setCalendarBookedDatesList: (
      state,
      action: PayloadAction<Array<Array<string>>>,
    ) => {
      state.bookedDatesList = action.payload
    },
    setCalendarCommonData: (
      state,
      action: PayloadAction<CalendarCommonData>,
    ) => {
      state.commondata = action.payload
    },
    setCalendarLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCalendarDates.fulfilled, (state, action) => {
      state.loading = false
      state.calendarList = action.payload.data.calendarList
    })
  },
})

export const {
  setCalendarDates,
  setCalendarBlockedDates,
  setCalendarBookedDates,
  setCalendarBookedDatesList,
  setCalendarCommonData,
  setCalendarLoading,
} = calendarSlice.actions

export const getCalendarDate = (state: RootState) =>
  state.calendarReducer?.calendarList

export const getCalendarDatesOnly = (state: RootState) =>
  state.calendarReducer?.dates

export const getBlockedDates = (state: RootState) =>
  state.calendarReducer?.blockedDates
export const getBookedDates = (state: RootState) =>
  state.calendarReducer?.bookedDates
export const getBookedDatesList = (state: RootState) =>
  state.calendarReducer?.bookedDatesList

export const getCalendarCommonData = (state: RootState) =>
  state.calendarReducer?.commondata

export const getCalendarLoading = (state: RootState) =>
  state.calendarReducer?.loading

export default calendarSlice.reducer
