import { ChevronDownIcon } from "@chakra-ui/icons"
import { Box, Button, Menu, MenuButton, MenuList } from "@chakra-ui/react"
import { Link, useLocation, useParams } from "react-router-dom"

import CustomMenuItem from "components/menudropdown/dropdown-menu-item"

import { AccordianProps, SidebarTypeProps } from "./types"

const CustomMenu = (props: AccordianProps) => {
  const { menuItem, isSelected } = props
  const location = useLocation()
  const { properyId = " " } = useParams()
  return (
    <Menu autoSelect={false}>
      <CustomMenuItem
        isSelected={isSelected}
        closeOnSelect={!(menuItem.childMenu && menuItem.childMenu.length > 0)}
        as={MenuButton}
        _hover={{ color: "white", bg: "secondaryColor" }}
        _expanded={{ color: "white", bg: "secondaryColor" }}
        _focus={{ color: "white", bg: "secondaryColor" }}
      >
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {menuItem.name}
          <ChevronDownIcon />
        </Box>
      </CustomMenuItem>
      <MenuList pb={4}>
        {menuItem.childMenu?.map((m) => {
          const isSubItemSelected =
            location.pathname.startsWith(
              m.makeTo?.(properyId) || m.to || " ",
            ) ||
            location.hash === m.to ||
            location.hash === `#${m.id}`

          return m?.childMenu && m.childMenu.length > 0 ? (
            <CustomMenu
              key={m.id}
              menuItem={m}
              isSelected={isSubItemSelected}
            />
          ) : (
            <Link key={m.id} to={m.makeTo?.(properyId) || m.to || " "}>
              <CustomMenuItem isSelected={isSubItemSelected}>
                {m.name}
              </CustomMenuItem>
            </Link>
          )
        })}
      </MenuList>
    </Menu>
  )
}

const MobileSideMenu = (props: SidebarTypeProps) => {
  const { sidebarMenuArr } = props
  const location = useLocation()
  const { properyId = " " } = useParams()
  return (
    <Menu autoSelect={false}>
      <MenuButton
        variant='ghost'
        as={Button}
        px={4}
        py={2}
        border='1px solid #e1e1e1'
        borderRadius='5px'
        fontSize='14px'
        minW='92px'
      >
        Menu <ChevronDownIcon />
      </MenuButton>
      <MenuList zIndex='999' pb={4}>
        {sidebarMenuArr &&
          sidebarMenuArr.map((menuItem) => {
            // check if item path matched then show that as selected
            const isSelected =
              location.pathname.startsWith(
                menuItem.makeTo?.(properyId) || menuItem.to || " ",
              ) ||
              location.hash === menuItem.to ||
              location.hash === `#${menuItem.id}`

            return menuItem.childMenu && menuItem.childMenu.length > 0 ? (
              // check if sub item id matched then show that as selected
              <CustomMenu
                key={menuItem.id}
                menuItem={menuItem}
                isSelected={isSelected}
              />
            ) : (
              (menuItem.onMenuItemClick && (
                <CustomMenuItem
                  onClick={menuItem.onMenuItemClick}
                  isSelected={isSelected}
                >
                  {menuItem.name}
                </CustomMenuItem>
              )) || (
                <Link
                  key={menuItem.id}
                  to={menuItem.makeTo?.(properyId) || menuItem.to || " "}
                >
                  <CustomMenuItem isSelected={isSelected}>
                    {menuItem.name}
                  </CustomMenuItem>
                </Link>
              )
            )
          })}
      </MenuList>
    </Menu>
  )
}

export default MobileSideMenu
