import { Icon, IconProps } from "@chakra-ui/react"

const BookingsIcon = (props: IconProps) => (
  <Icon width='24px' height='20px' viewBox='0 0 24 20' fill='none' {...props}>
    <g clipPath='url(#clip0_1319_5093)'>
      <mask
        id='mask0_1319_5093'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='18'
      >
        <path d='M18 0H0V18H18V0Z' fill='white' />
      </mask>
      <g mask='url(#mask0_1319_5093)'>
        <path
          d='M14.448 5.506C14.4044 5.49353 14.3593 5.48681 14.314 5.486C14.2851 5.48672 14.2564 5.48973 14.228 5.495C14.171 5.495 14.115 5.486 14.057 5.486H13.177C13.1966 5.34385 13.2063 5.2005 13.206 5.057C13.1449 4.06989 12.6964 3.14686 11.9582 2.48876C11.2199 1.83066 10.2516 1.49075 9.264 1.543H8.736C7.74822 1.49048 6.77967 1.83026 6.0412 2.48839C5.30272 3.14652 4.8541 4.06971 4.793 5.057C4.79304 5.20052 4.80306 5.34387 4.823 5.486H3.943C2.89758 5.48706 1.89527 5.90282 1.15605 6.64205C0.416821 7.38127 0.00105893 8.38358 0 9.429L0 12.515C0.00105893 13.5604 0.416821 14.5627 1.15605 15.302C1.89527 16.0412 2.89758 16.4569 3.943 16.458H14.057C14.115 16.458 14.171 16.451 14.228 16.449C14.2564 16.4543 14.2851 16.4573 14.314 16.458C14.3594 16.4575 14.4045 16.4508 14.448 16.438C15.4209 16.3399 16.3227 15.8843 16.9789 15.1594C17.6351 14.4345 17.999 13.4918 18 12.514V9.429C17.9987 8.45136 17.6348 7.50896 16.9786 6.78425C16.3224 6.05954 15.4207 5.60406 14.448 5.506ZM8.736 2.571H9.264C9.97885 2.51973 10.6852 2.75173 11.2304 3.21688C11.7757 3.68203 12.116 4.343 12.178 5.057C12.177 5.19374 12.1626 5.33006 12.135 5.464H5.864C5.83645 5.33006 5.82205 5.19374 5.821 5.057C5.88298 4.34281 6.22349 3.68169 6.76894 3.21651C7.31439 2.75133 8.02098 2.51946 8.736 2.571ZM5.483 6.514C5.53075 6.51365 5.57822 6.50658 5.624 6.493H12.377C12.4225 6.50651 12.4696 6.51358 12.517 6.514H13.8V15.428H4.457V6.514H5.483ZM1.029 12.514V9.429C1.03013 8.74586 1.27099 8.08479 1.70958 7.56103C2.14817 7.03728 2.75668 6.68408 3.429 6.563V15.381C2.75659 15.2597 2.14807 14.9063 1.70949 14.3824C1.27091 13.8585 1.03009 13.1973 1.029 12.514ZM16.972 12.514C16.9708 13.1518 16.7606 13.7717 16.3736 14.2787C15.9865 14.7856 15.444 15.1518 14.829 15.321V6.621C15.444 6.79024 15.9865 7.15638 16.3736 7.66335C16.7606 8.17032 16.9708 8.79018 16.972 9.428V12.514Z'
          fill='#414141'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1319_5093'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </Icon>
)

export default BookingsIcon
