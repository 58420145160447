import { useEffect, useState } from "react"

import { Box, Center, Spinner, useMediaQuery } from "@chakra-ui/react"

import { useAppSelector } from "app/hooks"
import { Calendar } from "components/calendar"
import { CalendarHeader } from "components/calendar/calendar-header"
import useIsPermitted from "hooks/useIsPermittedHook/useIsPermitted"
import { generateDates } from "pages/dummy/helpingFunctions"
import { apiClient } from "services/client"
import { CalendarDateData } from "services/common"
import { selectCurrentPmc } from "store/auth/authSlice"
import { getCalendarLoading } from "store/calendar/calendarSlice"
import { selectProperty } from "store/property/propertySlice"

import CalendarAvailability from "./components/calendar-availablity"
import CalendarPricing from "./components/calendar-pricing"

export function generateCalendarDays(
  year: number,
  month: number,
  calendarData: CalendarDateData[],
) {
  // Get the weekday (0-6) of the first day of the month, with Monday as the first day of the week
  const firstDayOfMonth = (new Date(year, month, 1).getDay() + 6) % 7
  const daysInMonth = new Date(year, month + 1, 0).getDate() // Get total days in the current month
  const daysInPrevMonth = new Date(year, month, 0).getDate() // Get total days in the previous month

  const totalTiles = 35 // 5 weeks * 7 days

  // Default values for CalendarDateData
  const defaultData: CalendarDateData = {
    updateDt: 0,
    valid_from: "",
    min_nights: 0,
    max_nights: null,
    base_price: 0,
    pm_base_price: null,
    weekly_base_price: null,
    max_rent: 0,
    available: false,
    whimstay_availability: false,
    djs_discount: 0,
    arrival: false,
    departure: false,
    weekly_arrival: null,
    weekly_departure: null,
    priceTotal: 0,
    isAvailableForCheckIn: false,
    other_fee_Data: null,
    los: null,
    date: 0, // Ensure date is a timestamp (or use appropriate type)
  }

  // Create array for previous month's days
  const prevMonthDays = Array.from({ length: firstDayOfMonth }).map((_, i) => {
    const date = new Date(
      year,
      month - 1,
      daysInPrevMonth - firstDayOfMonth + i + 1,
    )
    const matchedData = calendarData.find(
      (data) =>
        new Date(data.date).getDate() === date.getDate() &&
        new Date(data.date).getMonth() === month - 1,
    )
    return {
      ...defaultData,
      ...matchedData,
      date: date.getTime(),
    }
  })

  // Create array for current month's days
  const currentMonthDays = Array.from({ length: daysInMonth }).map((_, i) => {
    const date = new Date(year, month, i + 1)
    const matchedData = calendarData.find(
      (data) =>
        new Date(data.date).getDate() === date.getDate() &&
        new Date(data.date).getMonth() === month,
    )
    return {
      ...defaultData,
      ...matchedData,
      date: date.getTime(),
    }
  })

  // Calculate remaining tiles for the next month's days
  const remainingTiles =
    totalTiles - prevMonthDays.length - currentMonthDays.length
  const nextMonthDays = Array.from({ length: remainingTiles }).map((_, i) => {
    const date = new Date(year, month + 1, i + 1)
    const matchedData = calendarData.find(
      (data) =>
        new Date(data.date).getDate() === date.getDate() &&
        new Date(data.date).getMonth() === month + 1,
    )
    return {
      ...defaultData,
      ...matchedData,
      date: date.getTime(),
    }
  })

  // Return the combined array of days
  return [...prevMonthDays, ...currentMonthDays, ...nextMonthDays]
}

const PropertyListingCalendar = () => {
  const [isMobile] = useMediaQuery("(max-width: 1024px)")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [date, setDate] = useState(new Date())
  const [isWhimstay, selectIsWhimstay] = useState<boolean>(false)
  const [days, setDays] = useState(generateDates(date))
  const hasAccessTo = useIsPermitted()
  const property = useAppSelector(selectProperty)
  const currentPmc = useAppSelector(selectCurrentPmc)
  const isCalendarLoading = useAppSelector(getCalendarLoading)
  const [calendarData, setcalendarData] = useState<
    CalendarDateData[] | undefined
  >()

  console.log(
    setDate,
    selectIsWhimstay,
    setDays,
    property,
    currentPmc,
    date,
    "pmc",
  )

  useEffect(() => {
    const year = date.getFullYear()
    const month = date.getMonth()
    setIsLoading(true)

    apiClient
      .getCalendarData({
        params: {
          pmcId: currentPmc?.pmc_id || "",
          pmsUnitId: property?.pmsUnitId,
        },
      })
      .then((resp) => {
        const processedData = generateCalendarDays(
          year,
          month,
          resp?.data?.data,
        )

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, promise/always-return
        setcalendarData(processedData)
        setIsLoading(false)
        return true
      })
      .catch((e) => {
        console.log(e)
      })
  }, [currentPmc?.pmc_id, property?.pmsUnitId, date, isCalendarLoading])

  return (
    <Box display={{ md: "flex" }} w='100%' mb={6}>
      <Box flexGrow={1}>
        <CalendarHeader date={date} setDate={setDate} setDays={setDays} />
        {calendarData && !isLoading ? (
          <Calendar
            canEditDates={hasAccessTo.editDates}
            days={days}
            isWhimstay={isWhimstay}
            calendarData={calendarData}
          />
        ) : (
          <Center p={4} mt={10}>
            <Spinner />
          </Center>
        )}
      </Box>
      <Box
        w={{ md: "330px", xl: "415px" }}
        minW={{ md: "330px", xl: "415px" }}
        pl={{ md: 4, xl: 5 }}
      >
        {!isMobile && <CalendarPricing />}
        {!isMobile && <CalendarAvailability />}
      </Box>
    </Box>
  )
}

export default PropertyListingCalendar
