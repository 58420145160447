import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import {
  IndividualAccountRequestData,
  IndividualBankAccountRequestData,
  ResponseData,
} from "./common"

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/payment/account`

export interface IndividualAccountRequest extends Partial<AxiosRequestConfig> {
  params: Partial<IndividualAccountRequestData>
}
export interface IndividualBankAccountRequest
  extends Partial<AxiosRequestConfig> {
  params: IndividualBankAccountRequestData
}

export interface IndividualAccountResponseData extends ResponseData {
  data: {
    pgHostAccId: number
    message?: string
    stripeRedirectUrl?: string
  }
}

export interface IndividualAccountResponse extends AxiosResponse {
  data: IndividualAccountResponseData
}

export function setindividualAccount(
  {
    params,
    method = "post",
    url = `${defaultUrl}/host`,
    ...config
  }: IndividualAccountRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<IndividualAccountResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<IndividualAccountResponse>
}

export function setindividualBankAccount(
  {
    params,
    method = "put",
    url = `${defaultUrl}/host`,
    ...config
  }: IndividualBankAccountRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<IndividualAccountResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<IndividualAccountResponse>
}

export interface DeletePayoutAccountRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    pgHostAccId: number
  }
}

export interface MakeDefaultPayoutAccountRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    pgHostAccId: number
    pmcId: string
  }
}
export interface DeletePayoutAccountResponseData extends ResponseData {
  data: {
    pgHostAccId: number
    message?: string
  }
}

export interface MakeDefaultPayoutAccountResponseData extends ResponseData {
  data: {
    pgHostAccId: number
    message?: string
  }
}

export interface DeletePayoutAccountResponse extends AxiosResponse {
  data: DeletePayoutAccountResponseData
}

export interface MakeDefaultPayoutAccountResponse extends AxiosResponse {
  data: MakeDefaultPayoutAccountResponseData
}

export function deletePayoutAccount(
  {
    params,
    method = "delete",
    url = `${defaultUrl}/host`,
    ...config
  }: DeletePayoutAccountRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<DeletePayoutAccountResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<DeletePayoutAccountResponse>
}

export function makeDefaultPayoutAccount(
  {
    params,
    method = "put",
    url = `${defaultUrl}/host/makedefault?pmcId=${params.pmcId}`,
    ...config
  }: MakeDefaultPayoutAccountRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<MakeDefaultPayoutAccountResponse> {
  return axiosInstance({
    data: { pgHostAccId: params.pgHostAccId },
    method,
    url,
    ...config,
  }) as Promise<MakeDefaultPayoutAccountResponse>
}
