import React from "react"

import { Box, ModalContent, Text, useBreakpointValue } from "@chakra-ui/react"

import BackIcon from "components/icons/back_icon"
import Popup from "components/popup/Popup"

import { MobileContentPageProps } from "./types"

const MobileContentPage: React.FC<MobileContentPageProps> = ({
  isOpen,
  onClose,
  title,
  isShowCloseButton = true,
  children,
  isShowMobileContent = false,
  overflowY = "auto",
  overflowX,
  titleSize = "lg2",
}) => {
  const isMobileView = useBreakpointValue({ base: true, lg2: true, xl: false })
  if (!isOpen) return <div />
  if (isShowMobileContent || isMobileView)
    return (
      <Popup isOpen={isOpen} onClose={onClose} isCloseOnOverlayClick>
        <ModalContent
          alignSelf='flex-end'
          borderRadius='20px'
          borderBottomRadius='0px'
          marginBottom='0px'
          width='100%'
          maxHeight='calc(100vh - 120px)'
          maxW='100%'
          bg='white'
          overflowY={overflowY}
          overflowX={overflowX}
        >
          {(title || isShowCloseButton) && (
            <Box
              position='relative'
              paddingX='32px'
              paddingTop='32px'
              paddingBottom='16px'
            >
              {isShowCloseButton && (
                <BackIcon
                  position='absolute'
                  top='28px'
                  color='#006f80'
                  cursor='pointer'
                  onClick={onClose}
                />
              )}
              <Text
                w='249px'
                m='0 auto'
                flex='5'
                textAlign='center'
                size={titleSize}
                fontWeight='600'
              >
                {title}
              </Text>
            </Box>
          )}
          {children}
        </ModalContent>
      </Popup>
    )
  return (
    <>
      {children}
      <div />
    </>
  )
}

export default MobileContentPage
