import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { BedroomsDetails } from "store/bedrooms/types"

import { defaultAxiosInstance } from "./client"
import { ResponseData } from "./common"

export interface RoomDetailsRequestData {
  pmcId: string
  pmsUnitId: string
}

export interface BedsData {
  key: string
  value: string
}

export interface DefaultBedsResponseData extends ResponseData {
  data: BedsData[]
}

export interface DefaultBedsResponse extends AxiosResponse {
  data: DefaultBedsResponseData
}

export interface RoomDetailsRequest extends Partial<AxiosRequestConfig> {
  params: RoomDetailsRequestData
}

export interface RoomDetailsResponseData extends ResponseData {
  data: BedroomsDetails
}

export interface RoomDetailsResponse extends AxiosResponse {
  data: RoomDetailsResponseData
}

export interface AddRoomDetailsRequest extends Partial<AxiosRequestConfig> {
  params: BedroomsDetails
}

export interface AddRoomDetailsResponse extends AxiosResponse {
  data: ResponseData
}

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/properties`

export function getDefaultBeds(
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<DefaultBedsResponse> {
  return axiosInstance({
    method: "get",
    url: `${defaultUrl}/get-default-beds`,
  }) as Promise<DefaultBedsResponse>
}

export function getRoomDetails(
  {
    params,
    method = "get",
    url = `${defaultUrl}/get-room-details`,
    ...config
  }: RoomDetailsRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<RoomDetailsResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<RoomDetailsResponse>
}

export function addRoomDetails(
  {
    params,
    method = "post",
    url = `${defaultUrl}/add-room-details`,
    ...config
  }: AddRoomDetailsRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AddRoomDetailsResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<AddRoomDetailsResponse>
}
