import { Dispatch, SetStateAction } from "react"

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Button, ButtonGroup, IconButton, Text } from "@chakra-ui/react"

import { setPageCount } from "utils/local-storage"

type PaginationProps = {
  pageStartIndex: number
  pageSize: number
  setPageStartIndex: Dispatch<SetStateAction<number>>
  itemsLength: number
}
const Pagination = ({
  pageSize,
  pageStartIndex,
  setPageStartIndex,
  itemsLength,
}: PaginationProps) => (
  <ButtonGroup isAttached variant='outline'>
    <IconButton
      aria-label='previous page'
      icon={<ChevronLeftIcon h={7} w={7} />}
      onClick={() => {
        setPageStartIndex((x) => (x > 0 ? x - 1 : 0))
        setPageCount(`${pageStartIndex - 1}`)
      }}
      isDisabled={pageStartIndex <= 0}
    />
    <Button
      variant='outline'
      px={{ base: 2, xxl: 3 }}
      _hover={{}}
      cursor='auto'
      tabIndex={-1}
    >
      <Text fontSize={{ base: "12px", xl: "14px", xxl: "16px" }}>
        {`${pageStartIndex ? pageStartIndex * pageSize + 1 : 0} - ${Math.min(
          pageStartIndex * pageSize + pageSize,
          itemsLength,
        )} of ${itemsLength}`}
      </Text>
    </Button>
    <IconButton
      aria-label='next page'
      icon={<ChevronRightIcon h={7} w={7} />}
      onClick={() => {
        setPageStartIndex((x) =>
          x < Math.floor(itemsLength / pageSize) ? x + 1 : x,
        )
        setPageCount(`${pageStartIndex + 1}`)
      }}
      isDisabled={
        Math.min(pageStartIndex * pageSize + pageSize, itemsLength) ===
        itemsLength
      }
    />
  </ButtonGroup>
)

export default Pagination
