import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import {
  ResponseData,
  CountryData,
  GetCountryByCodeRequestData,
} from "./common"

export interface CountryResponseData extends ResponseData {
  data: CountryData
}

export interface GetCountryByCodeRequest extends Partial<AxiosRequestConfig> {
  params: GetCountryByCodeRequestData
}

export interface CountryResponse extends AxiosResponse {
  data: CountryResponseData
}

export const defaultUrl = `${process.env.REACT_APP_BASE_API_URL || ""}/stripe`

export function getCountries(
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<CountryResponse> {
  return axiosInstance({
    method: "get",
    url: `${defaultUrl}/country/all`,
  }) as Promise<CountryResponse>
}

export function getCountriesByCode(
  {
    params,
    method = "get",
    url = `${defaultUrl}/country/by-code`,
    ...config
  }: GetCountryByCodeRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<CountryResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<CountryResponse>
}
