import { useCallback, useEffect, useRef, useState } from "react"

import {
  Box,
  Button,
  Checkbox,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"

import { useAppSelector } from "app/hooks"
import CustomModal from "components/custom-modal/custom-modal"
import useToastHook from "hooks/useToastHook/useToastHook"
import { apiClient } from "services/client"
import { CompanyTags } from "services/common"
import { selectCompanyTags, selectCurrentPmc } from "store/auth/authSlice"

export const companyCustomTags = {
  onboardingPending: false,
  testBookingPending: false,
  awaitingPMTestBookingApproval: false,
  inDataValidation: false,
  engineeringFixingDataIssues: false,
  awaitingPMSSupport: false,
  awaitingPMCSupport: false,
  readyToPublish: false,
}

export const tagDisplayTextMapping: Record<string, string> = {
  onboardingPending: "Pending Onboarding",
  testBookingPending: "Pending Test Booking",
  awaitingPMTestBookingApproval: "Awaiting PM Test Booking Approval",
  inDataValidation: "In Data Validation",
  engineeringFixingDataIssues: "Engineering Fixing Data Issue",
  awaitingPMSSupport: "Awaiting PMS Support",
  awaitingPMCSupport: "Awaiting PMC Support",
  readyToPublish: "Ready to Publish",
}

export const tagOrder = [
  "onboardingPending",
  "testBookingPending",
  "awaitingPMTestBookingApproval",
  "inDataValidation",
  "engineeringFixingDataIssues",
  "awaitingPMSSupport",
  "awaitingPMCSupport",
  "readyToPublish",
]

const AddTagModal = (props: { getCompany: () => void }) => {
  const { getCompany } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const displayToast = useToastHook()
  const companyTagData = useAppSelector(selectCompanyTags)
  const [checkedItems, setCheckedItems] =
    useState<CompanyTags>(companyCustomTags)
  const currentPmc = useAppSelector(selectCurrentPmc)
  const toast = useToast()
  const toastIdRef = useRef<number | string>("")
  const addingCustomTag = useCallback(
    (companyTag: CompanyTags) => {
      toastIdRef.current = toast({
        description: "Company Tag Change Successfully",
        status: "loading",
        position: "top-right",
        duration: 60 * 3 * 1000,
      })

      const newValues = {
        pmcId: currentPmc?.pmc_id,
        companyCustomTags: companyTag,
      }
      apiClient
        .addingCustomTag({
          data: newValues,
        })
        .then(() => {
          getCompany()
          if (toastIdRef.current) {
            toast.update(toastIdRef.current, {
              description: "Company Tag Change Successfully",
              status: "success",
              position: "top-right",
              duration: 3000,
            })
          }
          return true
        })
        .catch(() => {
          if (toastIdRef.current) {
            toast.update(toastIdRef.current, {
              description: `Faild to add tag to the Properties`,
              status: "error",
              position: "top-right",
              duration: 3000,
            })
          }
          displayToast({
            message: "Something went wrong! Can not add tag to the property.",
          })
        })
    },
    [currentPmc?.pmc_id, displayToast, getCompany, toast],
  )

  const onSubmit = async () => {
    addingCustomTag(checkedItems)
    onClose()
  }

  useEffect(() => {
    if (companyTagData) {
      setCheckedItems(companyTagData)
    }
  }, [currentPmc?.pmc_id, companyTagData])

  const handleCheckboxChange = (key: string) => {
    setCheckedItems((prevState: CompanyTags) => ({
      ...prevState,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [key]: !prevState[key],
    }))
  }

  return (
    <>
      <Button
        variant='secondary'
        onClick={() => {
          onOpen()
        }}
        h='30px'
        px='12px'
      >
        Add Tags
      </Button>

      <CustomModal isOpen={isOpen} onClose={onClose} width='400px'>
        <Box>
          <Text
            size={{
              base: "lg",
              md: "lg2",
            }}
            fontWeight={{
              base: 600,
              md: 700,
            }}
            variant='black'
            marginBottom='10px'
          >
            Company Tags
          </Text>
          <Box p='10px'>
            {tagOrder.map((key) => (
              <Box key={key} display='flex' marginBottom='10px'>
                <Checkbox
                  defaultChecked={checkedItems[key as keyof CompanyTags]}
                  onChange={() => handleCheckboxChange(key)}
                  marginRight='10px'
                />
                <Text>{tagDisplayTextMapping[key]}</Text>
              </Box>
            ))}
          </Box>
          <Box
            px='10px'
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Button
              width='100%'
              height='50px'
              marginY={{ base: "0px", sm: "16px" }}
              type='submit'
              onClick={() => onSubmit()}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </>
  )
}

export default AddTagModal
