import { useToast } from "@chakra-ui/react"

import Toast from "components/toast/Toast"

import { DisplayToastProps } from "./types"

const useToastHook = () => {
  const toast = useToast()
  const displayToast = ({
    variant = "success",
    message,
    duration = 4000,
  }: DisplayToastProps) =>
    toast({
      render: () => <Toast message={message} variant={variant} />,
      duration,
    })
  return displayToast
}

export default useToastHook
