import { ChangeEvent, useCallback, useEffect, useState } from "react"

import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  PinInput,
  PinInputField,
  Text,
  VStack,
} from "@chakra-ui/react"
import { AxiosResponse } from "axios"
import { useNavigate } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "app/hooks"
import { useGoogleReCaptchaV3 } from "hooks/useGoogleReCaptcha"
import { apiClient } from "services/client"
import {
  getDeviceId,
  // getAdminPmc,
  getHostPmc,
  getUser,
  login,
  selectAuthError,
  selectCurrentPmc,
  selectDialCode,
  selectEmail,
  selectIsTermAccepted,
  selectMobileNumber,
  setForgotPasswordForm,
  setIsLoggedIn,
  setShowOtpForm,
  verifyOtp,
  verifyPassword,
} from "store/auth/authSlice"
import { RECAPTCHA_KEY } from "utils/html-dom"
import {
  removeAuthToken,
  removeTempAuthToken,
  setAuthToken,
  setReCaptchaToken,
} from "utils/local-storage"

type OtpFormProps = {
  handleClose: () => void
  showEmailForm: boolean
  isLoginFlow?: boolean
}

const OtpForm = (props: OtpFormProps) => {
  const { handleClose, showEmailForm, isLoginFlow = true } = props

  const dispatch = useAppDispatch()
  const dialCode = useAppSelector(selectDialCode)
  const mobileNumber = useAppSelector(selectMobileNumber)
  const emailId = useAppSelector(selectEmail)
  const isTermAccepted = useAppSelector(selectIsTermAccepted)
  const authError = useAppSelector(selectAuthError)
  const currentPmc = useAppSelector(selectCurrentPmc)

  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [time, setTime] = useState<number | null>(null)
  const [otp, setOtp] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [isDisable, setIsDisable] = useState(false)
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)
  const { executeReCaptcha } = useGoogleReCaptchaV3({
    siteKey: RECAPTCHA_KEY,
  })

  const navigate = useNavigate()

  const tick = useCallback(() => {
    if ((time as number) > 0) {
      setTime((preValue) => (preValue as number) - 1)
    } else {
      setTime(null)
    }
  }, [time])

  useEffect(() => {
    let timerId: NodeJS.Timeout

    if ((time as number) >= 0) {
      timerId = setInterval(() => tick(), 1000)
    }

    return () => clearInterval(timerId)
  }, [tick, time])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
  }, [])

  // otp form handle submit
  const handleComplete = useCallback(
    async (value: string) => {
      try {
        if (!isLoginFlow) {
          await apiClient
            .signUpVerifyOtp({
              params: {
                otp: value,
              },
            })
            .then((resp: AxiosResponse) => {
              removeTempAuthToken()
              setAuthToken(resp.headers.authorization)
              dispatch(setIsLoggedIn(true))
              dispatch(setShowOtpForm(false))
              navigate("/")
              return true
            })
        } else {
          await dispatch(
            verifyOtp({
              params: {
                otp: value,
                isTermAccepted: true,
              },
            }),
          ).unwrap()
        }

        await dispatch(getHostPmc()).unwrap()
        await dispatch(getDeviceId()).unwrap()

        // if (response.data.isAdmin) {
        //   await dispatch(getAdminPmc()).unwrap()
        // } else {
        // }

        if (currentPmc?.pmc_id) {
          await dispatch(
            getUser({
              params: {
                pmcId: currentPmc?.pmc_id,
              },
            }),
          )
        }
        handleClose()

        // eslint-disable-next-line no-empty
      } catch (rejectedValueOrSerializedError) {}
    },
    [currentPmc?.pmc_id, dispatch, handleClose],
  )

  const handleTryAgain = useCallback(async () => {
    setOtp("")
    removeAuthToken()

    const token = await executeReCaptcha("LoadReCaptchaOnClick")

    setReCaptchaToken(token)

    await dispatch(
      login({
        params: {
          code: dialCode as string,
          mobileNum: mobileNumber as string,
          email: emailId as string,
          emailOption: showEmailForm,
        },
      }),
    )

    setTime(60)
  }, [
    dialCode,
    dispatch,
    emailId,
    executeReCaptcha,
    mobileNumber,
    showEmailForm,
  ])

  // Verify password onsubmit function
  const handleVerifyPassword = useCallback(
    async (value: string) => {
      setIsDisable(true)
      try {
        await dispatch(verifyPassword({ data: { password: value } })).unwrap()
        setIsDisable(false)
        await dispatch(getHostPmc()).unwrap()
        if (currentPmc?.pmc_id) {
          await dispatch(
            getUser({
              params: {
                pmcId: currentPmc?.pmc_id,
              },
            }),
          )
        }
        await dispatch(getDeviceId()).unwrap()
        handleClose()

        // eslint-disable-next-line no-empty
      } catch (rejectedValueOrSerializedError) {}
    },
    [currentPmc?.pmc_id, dispatch, handleClose],
  )

  return (
    <Box width='100%'>
      {(!showEmailForm && (isTermAccepted || isChecked) && (
        <VStack spacing={4}>
          <Text fontSize={{ base: "sm", md: "md" }}>
            {`Enter the code we've send via SMS`}
          </Text>

          <HStack>
            <PinInput
              size={{ base: "md", md: "lg" }}
              autoFocus
              value={otp}
              onChange={(value: string) => setOtp(value)}
              onComplete={handleComplete}
              isInvalid={!!authError}
            >
              <PinInputField autoComplete='off' data-test='pin1' />
              <PinInputField autoComplete='off' data-test='pin2' />
              <PinInputField autoComplete='off' data-test='pin3' />
              <PinInputField autoComplete='off' data-test='pin4' />
              <PinInputField autoComplete='off' data-test='pin5' />
              <PinInputField autoComplete='off' data-test='pin6' />
            </PinInput>
          </HStack>
        </VStack>
      )) ||
        (showEmailForm && (
          <InputGroup size='md'>
            <Input
              height='60px'
              width='100%'
              border='2px solid #006f80'
              pr='4.5rem'
              type={show ? "text" : "password"}
              placeholder='Enter password'
              onChange={(e) => {
                if (e.target.value.length > 7) setIsDisable(false)
                setPassword(e.target.value)
              }}
            />
            <InputRightElement width='4.5rem' top='10px'>
              <Button
                h='1.75rem'
                size='sm'
                onClick={handleClick}
                variant='ghost'
              >
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
        ))}

      {authError && (
        <Text fontWeight='bold' color='red.700'>
          {authError}
        </Text>
      )}

      {!showEmailForm && (isTermAccepted || isChecked) && time && (
        <Text>
          {`Didn't get a code? Wait for ${time} seconds before trying again.`}
        </Text>
      )}

      {(!showEmailForm && (isTermAccepted || isChecked) && !time && (
        <Text marginY='10px' textAlign='center'>
          {`Did't get a code?`}{" "}
          <Button
            variant='link'
            fontWeight='bold'
            color='#0D6F81'
            onClick={handleTryAgain}
          >
            Try again
          </Button>
        </Text>
      )) ||
        (showEmailForm && (
          <>
            <Button
              marginTop='20px'
              fontSize='18px'
              height='45px'
              width='100%'
              type='submit'
              disabled={password.length <= 7 || isDisable}
              onClick={() => handleVerifyPassword(password)}
            >
              Submit
            </Button>
            <Text
              cursor='pointer'
              marginTop='20px'
              textDecoration='underline'
              color='#414141'
              fontWeight={600}
              marginRight='auto'
              fontSize='14px'
              display='flex'
              onClick={() => {
                handleClose()
                dispatch(setForgotPasswordForm(true))
              }}
            >
              Forgot password?
            </Text>
          </>
        ))}

      {!showEmailForm && !isTermAccepted && (
        <Checkbox
          alignItems='baseline'
          size='lg'
          isChecked={isChecked}
          onChange={handleChange}
        >
          I agree that I am 18 years of age or older and accept your&nbsp;
          <Link
            href='https://whimstay.com/terms'
            fontWeight='bold'
            color='#0D6F81'
            isExternal
          >
            Terms of Service
          </Link>
        </Checkbox>
      )}
    </Box>
  )
}

export default OtpForm
