import { ReactNode, useCallback, useEffect } from "react"

import { ArrowBackIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Divider,
  IconButton,
  Text,
  useMediaQuery,
} from "@chakra-ui/react"
import { useNavigate, useParams } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "app/hooks"
import ProtectedComponent from "components/PleaseLoginComp"
import MobileSideMenu from "components/sidemenu/mobilesidemenu"
import DesktopSideMenu from "components/sidemenu/sidemenu"
import { MenuItem as MenuItemType } from "components/sidemenu/types"
import Tabs from "components/tabs/Tabs"
import useIsPermitted from "hooks/useIsPermittedHook/useIsPermitted"
import { HOST_TAB_LIST } from "layouts/host-properties-layout"
import { selectCurrentPmc } from "store/auth/authSlice"
import {
  getProperty,
  selectProperty,
  selectPropertyName,
  selectPropertyTypeDesc,
} from "store/property/propertySlice"

type HostListingLayoutProps = {
  children: ReactNode
}
export const sidebarMenuArr: MenuItemType[] = [
  // Note: Uncomments this to unable calendar.
  // {
  //   name: "Calendar",
  //   depth: 1,
  //   id: "calendar",
  //   makeTo: (propertyId) => `/listings/properties/calendar/${propertyId}`,
  //   childMenu: [],
  // },
  {
    name: "Property details",
    depth: 1,
    id: "Property details",
    makeTo: (propertyId) =>
      `/listings/properties/details/${propertyId}#propertyDetails`,
    childMenu: [
      // {
      //   name: "PMC Description",
      //   depth: 2,
      //   id: "propertyDetails",
      //   makeTo: (propertyId) =>
      //     `/listings/properties/details/${propertyId}#propertyDetails`,

      //   childMenu: [],
      //   isActive: true,
      // },
      // {
      //   name: "Whimstay Description",
      //   depth: 2,
      //   id: "whimstayDescription",
      //   makeTo: (propertyId) =>
      //     `/listings/properties/details/${propertyId}#whimstayDescription`,

      //   childMenu: [],
      //   isActive: true,
      // },
      // {
      //   name: "Videos and Pictures",
      //   depth: 2,
      //   id: "videosAndPictures",
      //   makeTo: (propertyId) =>
      //     `/listings/properties/details/${propertyId}#videosAndPictures`,
      //   childMenu: [],
      // },
      {
        name: "Property Details",
        depth: 2,
        id: "propertyDetails",
        makeTo: (propertyId) =>
          `/listings/properties/details/${propertyId}#videosAndPictures`,
        childMenu: [],
      },
      {
        name: "Calendar",
        depth: 2,
        id: "calendar",
        makeTo: (propertyId) =>
          `/listings/properties/calendar/${propertyId}#videosAndPictures`,
        childMenu: [],
      },
      // {
      //   name: "Amenities",
      //   depth: 2,
      //   id: "amenities",
      //   makeTo: (propertyId) =>
      //     `/listings/properties/details/${propertyId}#amenities`,
      //   childMenu: [],
      // },
      // {
      //   name: "House Rules",
      //   depth: 2,
      //   id: "houserules",
      //   makeTo: (propertyId) =>
      //     `/listings/properties/details/${propertyId}#houserules`,
      //   childMenu: [],
      // },
      // {
      //   name: "Reviews and Ratings",
      //   depth: 2,
      //   id: "reviewAndRatings",
      //   makeTo: (propertyId) =>
      //     `/listings/properties/details/${propertyId}#reviewAndRatings`,
      //   childMenu: [],
      // },
    ],
  },
]
const HostListingLayout = (props: HostListingLayoutProps) => {
  const { children } = props
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery("(max-width: 1024px)")
  const propertyName = useAppSelector(selectPropertyName)
  const property = useAppSelector(selectProperty)
  const hasAccessTo = useIsPermitted()
  const propertyTypeDesc = useAppSelector(selectPropertyTypeDesc)
  const { properyId } = useParams()

  // const [isCalendar, selectIsCalendar] = useState<boolean>(false)
  // const [isPropertyDetail, setIsPropertyDetail] = useState<boolean>(false)

  const currentPmc = useAppSelector(selectCurrentPmc)

  const params = useParams()

  const dispatch = useAppDispatch()

  console.log(property, params, "property")

  useEffect(() => {
    if (currentPmc?.pmc_id && properyId) {
      dispatch(
        getProperty({
          params: {
            pmcId: currentPmc?.pmc_id,
            pmsUnitId: properyId,
          },
        }),
      )
        .then(() => true)
        .catch(() => {})
    }
  }, [dispatch, currentPmc?.pmc_id, properyId])

  const goBack = () => {
    navigate(-1) // This will navigate back to the previous page.
  }

  const openListing = useCallback(() => {
    if (property && propertyName)
      window.open(
        `${process.env.REACT_APP_WHIMSTAY_WEB_URL || ""}/detail/${propertyName
          ?.toLowerCase()
          .replace(/ /g, "-")}/${property.propertyId}`,
        "_blank",
      )
  }, [property, propertyName])

  const openPropetyDetail = useCallback(() => {
    // console.log(property,"property?.id")
    navigate(`/listings/properties/details/${property?.pmsUnitId}`)
  }, [navigate, property?.pmsUnitId])

  const openCalendar = useCallback(() => {
    // console.log(property,"property?.id")
    navigate(`/listings/properties/calendar/${property?.pmsUnitId}`)
  }, [navigate, property?.pmsUnitId])

  return (
    <Box maxW='100%' px={{ base: "20px", lg: "30px", xl: "70px" }} minW='100%'>
      <Box py={{ base: "0", md: "16px" }} mx={{ base: "-20px", md: "0" }}>
        <Tabs tabsArr={HOST_TAB_LIST} />
      </Box>
      <Divider borderColor='#e1e1e1' />
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        gap='16px'
        p={3}
      >
        {/* title and create button */}
        <Box display='flex' alignItems='center'>
          <IconButton
            size='sm'
            variant='outline'
            aria-label='Subtract'
            icon={<ArrowBackIcon h='30px' w='30px' />}
            data-testid='back'
            onClick={goBack}
          />
          {/* {propertyName && ( */}
          <Text
            pl='10px'
            size={{ base: "sm", sm: "md", md: "xl" }}
            variant='black'
            fontWeight='700'
            display='webkit-box'
            maxW={{ base: "150px", md: "full" }}
            noOfLines={{ base: 2 }}
          >
            {propertyName || propertyTypeDesc || "UNNAMED_PROPERTY"}
          </Text>
          {/* )} */}
        </Box>
        {(isMobile && (
          <MobileSideMenu
            sidebarMenuArr={[
              ...sidebarMenuArr,
              {
                name: "Listing",
                depth: 1,
                id: "Listing",
                onMenuItemClick: openListing,
                childMenu: [],
              },
            ]}
          />
        )) || (
          <Box>
            <Button
              m={1}
              size='sm'
              onClick={openPropetyDetail}
              disabled={!hasAccessTo.listingPropertiesDetails}
              variant='primary'
            >
              Property Details
            </Button>
            <Button
              m={1}
              size='sm'
              onClick={openCalendar}
              disabled={!hasAccessTo.listingPropertiesDetails}
              variant='tertiary'
            >
              Calendar
            </Button>
            <Button
              m={1}
              size='sm'
              onClick={openListing}
              disabled={!hasAccessTo.listingPropertiesDetails}
              variant='tertiary'
            >
              Listing
            </Button>
          </Box>
        )}
      </Box>
      <Box mt={8} display='flex' gap={10}>
        {!isMobile && (
          <Box w='210px' flexGrow={1} flexShrink={0}>
            <DesktopSideMenu sidebarMenuArr={sidebarMenuArr} />
          </Box>
        )}
        <Box pr={{ base: 0, xl: 10 }} w='full'>
          <ProtectedComponent>{children}</ProtectedComponent>
        </Box>
      </Box>
    </Box>
  )
}

export default HostListingLayout
