import { useAppSelector } from "app/hooks"
import { selectCurrentPmc, selectUserRole } from "store/auth/authSlice"

import { RoleAccessType } from "./types"

export const RoleAccess: RoleAccessType = {
  ADMIN: {
    isAdmin: true,
    // General
    createNewListing: true,

    // Today section

    // Inbox

    // Notification

    // .... Profile
    profilePage: true,
    editProfileDetails: true,

    // ... Payout settings
    payoutSettingsPage: true,
    registerBankAcc: true,
    applyPayoutRule: true,
    deletePayoutAccount: true,

    // ... Manage User
    manageUsersPage: true,
    addUser: true,
    updateUserDetails: true,
    deleteUser: true,

    // ... PMS Logs
    pmsLogsPage: true,

    // ... Events
    eventsPage: true,

    // live properties
    livePropertiesPage: true,
    viewLiveProperties: true,
    deleteLiveProperties: true,
    unpublishLiveProperties: true,
    previewLiveProperties: true,
    listingLiveProperties: true,
    viewCalendarLiveProperties: true,
    syncLiveProperties: true,

    // unpublished properties
    unpublishedPropertiesPage: true,
    viewUnpublishedProperties: true,
    deleteUnpublishedProperties: true,
    publishUnpublishedProperties: true,
    previewUnpublishedProperties: true,
    listingUnpublishedProperties: true,
    viewCalendarUnpublishedProperties: true,
    updateNewUnpublishedProperties: true,
    syncUnpublishedProperties: true,

    // inactive properties
    inactivePropertiesPage: true,
    viewInactiveProperties: true,
    deleteInactiveProperties: true,
    publishInactiveProperties: true,
    previewInactiveProperties: true,
    // viewCalendarInactiveProperties: true,

    // Discount template
    discountTemplatePage: true,
    createDiscountTemplate: true,
    editDiscountTemplate: true,
    deleteDiscountTemplate: true,
    applyDiscountTemplate: true,

    // Availability Blocks
    availabilityBlocksPage: true,
    createAvailabilityBlocks: true,
    editAvailabilityBlocks: true,
    deleteAvailabilityBlocks: true,
    applyAvailabilityBlocks: true,

    // Contact template
    contactTemplatePage: true,
    createContactTemplate: true,
    editContactTemplate: true,
    deleteContactTemplate: true,
    applyContactTemplate: true,

    // Property Details
    propertyDetailsPage: true,
    viewPropertiesDetails: true,
    editPropertiesDetails: true,
    listingPropertiesDetails: true,

    // Property calendar
    syncCalendar: true,
    editDates: true,

    // Bookings
    bookingsPage: true,
    viewBookings: true,

    // Earning progress report
    erningReportPage: true,
    viewEarningReports: true,
    downloadEarningReports: true,

    // Reservation report
    reservationReportPage: true,
    viewReservationReports: true,
    downloadReservationReports: true,

    // Payout report
    payoutReportPage: true,
    viewPayoutReports: true,
    downloadPayoutReports: true,
  },
  HOST_OWNER: {
    // General
    createNewListing: true,

    // Today section

    // Inbox

    // Notification

    // .... Profile
    profilePage: true,
    editProfileDetails: true,

    // ... Payout settings
    payoutSettingsPage: true,
    registerBankAcc: true,
    applyPayoutRule: true,

    // ... Manage User
    manageUsersPage: true,
    addUser: true,
    updateUserDetails: true,
    deleteUser: true,

    // ... PMS Logs
    pmsLogsPage: true,

    // ... Events
    eventsPage: true,

    // live properties
    livePropertiesPage: true,
    viewLiveProperties: true,
    deleteLiveProperties: true,
    unpublishLiveProperties: true,
    previewLiveProperties: true,
    listingLiveProperties: true,
    viewCalendarLiveProperties: true,

    // unpublished properties
    unpublishedPropertiesPage: true,
    viewUnpublishedProperties: true,
    deleteUnpublishedProperties: true,
    publishUnpublishedProperties: true,
    previewUnpublishedProperties: true,
    listingUnpublishedProperties: true,
    viewCalendarUnpublishedProperties: true,
    updateNewUnpublishedProperties: true,

    // inactive properties
    inactivePropertiesPage: true,
    viewInactiveProperties: true,
    deleteInactiveProperties: true,
    publishInactiveProperties: true,
    previewInactiveProperties: true,
    // viewCalendarInactiveProperties: true,

    // Discount template
    discountTemplatePage: true,
    createDiscountTemplate: true,
    editDiscountTemplate: true,
    deleteDiscountTemplate: true,
    applyDiscountTemplate: true,

    // Availability Blocks
    availabilityBlocksPage: true,
    createAvailabilityBlocks: true,
    editAvailabilityBlocks: true,
    deleteAvailabilityBlocks: true,
    applyAvailabilityBlocks: true,

    // Contact template
    contactTemplatePage: true,
    createContactTemplate: true,
    editContactTemplate: true,
    deleteContactTemplate: true,
    applyContactTemplate: true,

    // Property Details
    propertyDetailsPage: true,
    viewPropertiesDetails: true,
    editPropertiesDetails: true,
    listingPropertiesDetails: true,

    // Property calendar
    syncCalendar: true,
    editDates: true,

    // Bookings
    bookingsPage: true,
    viewBookings: true,

    // Earning progress report
    erningReportPage: true,
    viewEarningReports: true,
    downloadEarningReports: true,

    // Reservation report
    reservationReportPage: true,
    viewReservationReports: true,
    downloadReservationReports: true,

    // Payout report
    payoutReportPage: true,
    viewPayoutReports: true,
    downloadPayoutReports: true,
  },
  HOST_ADMIN: {
    // General
    createNewListing: true,

    // Today section

    // Inbox

    // Notification

    // .... Profile
    profilePage: true,
    editProfileDetails: true,

    // ... Payout settings
    payoutSettingsPage: true,
    registerBankAcc: true,
    applyPayoutRule: true,

    // ... Manage User
    manageUsersPage: true,
    addUser: true,
    updateUserDetails: true,
    deleteUser: true,

    // ... PMS Logs
    pmsLogsPage: true,

    // ... Events
    eventsPage: true,

    // live properties
    livePropertiesPage: true,
    viewLiveProperties: true,
    deleteLiveProperties: true,
    unpublishLiveProperties: true,
    previewLiveProperties: true,
    listingLiveProperties: true,
    viewCalendarLiveProperties: true,

    // unpublished properties
    unpublishedPropertiesPage: true,
    viewUnpublishedProperties: true,
    deleteUnpublishedProperties: true,
    publishUnpublishedProperties: true,
    previewUnpublishedProperties: true,
    listingUnpublishedProperties: true,
    viewCalendarUnpublishedProperties: true,
    updateNewUnpublishedProperties: true,

    // inactive properties
    inactivePropertiesPage: true,
    viewInactiveProperties: true,
    deleteInactiveProperties: true,
    publishInactiveProperties: true,
    previewInactiveProperties: true,
    // viewCalendarInactiveProperties: true,

    // Discount template
    discountTemplatePage: true,
    createDiscountTemplate: true,
    editDiscountTemplate: true,
    deleteDiscountTemplate: true,
    applyDiscountTemplate: true,

    // Availability Blocks
    availabilityBlocksPage: true,
    createAvailabilityBlocks: true,
    editAvailabilityBlocks: true,
    deleteAvailabilityBlocks: true,
    applyAvailabilityBlocks: true,

    // Contact template
    contactTemplatePage: true,
    createContactTemplate: true,
    editContactTemplate: true,
    deleteContactTemplate: true,
    applyContactTemplate: true,

    // Property Details
    propertyDetailsPage: true,
    viewPropertiesDetails: true,
    editPropertiesDetails: true,
    listingPropertiesDetails: true,

    // Property calendar
    syncCalendar: true,
    editDates: true,

    // Bookings
    bookingsPage: true,
    viewBookings: true,

    // Earning progress report
    erningReportPage: true,
    viewEarningReports: true,
    downloadEarningReports: true,

    // Reservation report
    reservationReportPage: true,
    viewReservationReports: true,
    downloadReservationReports: true,

    // Payout report
    payoutReportPage: true,
    viewPayoutReports: true,
    downloadPayoutReports: true,
  },
  HOST_ACCOUNTANT: {
    // General
    createNewListing: false,

    // Today section

    // Inbox

    // Notification

    // .... Profile
    profilePage: true,
    editProfileDetails: true,

    // ... Payout settings
    payoutSettingsPage: true,
    registerBankAcc: true,
    applyPayoutRule: true,

    // ... Manage User
    manageUsersPage: true,
    addUser: false,
    updateUserDetails: false,
    deleteUser: false,

    // ... PMS Logs
    pmsLogsPage: true,

    // ... Events
    eventsPage: true,

    // live properties
    livePropertiesPage: true,
    viewLiveProperties: true,
    deleteLiveProperties: false,
    unpublishLiveProperties: false,
    previewLiveProperties: true,
    listingLiveProperties: true,
    viewCalendarLiveProperties: false,

    // unpublished properties
    unpublishedPropertiesPage: true,
    viewUnpublishedProperties: true,
    deleteUnpublishedProperties: false,
    publishUnpublishedProperties: false,
    previewUnpublishedProperties: true,
    listingUnpublishedProperties: true,
    viewCalendarUnpublishedProperties: false,
    updateNewUnpublishedProperties: false,

    // inactive properties
    inactivePropertiesPage: true,
    viewInactiveProperties: true,
    deleteInactiveProperties: false,
    publishInactiveProperties: true,
    previewInactiveProperties: true,
    // viewCalendarInactiveProperties: true,

    // Discount template
    discountTemplatePage: true,
    createDiscountTemplate: false,
    editDiscountTemplate: false,
    deleteDiscountTemplate: false,
    applyDiscountTemplate: false,

    // Availability Blocks
    availabilityBlocksPage: true,
    createAvailabilityBlocks: false,
    editAvailabilityBlocks: false,
    deleteAvailabilityBlocks: false,
    applyAvailabilityBlocks: false,

    // Contact template
    contactTemplatePage: true,
    createContactTemplate: false,
    editContactTemplate: false,
    deleteContactTemplate: false,
    applyContactTemplate: false,

    // Property Details
    propertyDetailsPage: true,
    viewPropertiesDetails: true,
    editPropertiesDetails: false,
    listingPropertiesDetails: true,

    // Property calendar
    syncCalendar: false,
    editDates: false,

    // Bookings
    bookingsPage: true,
    viewBookings: true,

    // Earning progress report
    erningReportPage: true,
    viewEarningReports: true,
    downloadEarningReports: true,

    // Reservation report
    reservationReportPage: true,
    viewReservationReports: true,
    downloadReservationReports: true,

    // Payout report
    payoutReportPage: true,
    viewPayoutReports: true,
    downloadPayoutReports: true,
  },
  HOST_RESERVATIONIST: {
    // General
    createNewListing: false,

    // Today section

    // Inbox

    // Notification

    // .... Profile
    profilePage: true,
    editProfileDetails: true,

    // ... Payout settings
    payoutSettingsPage: true,
    registerBankAcc: false,
    applyPayoutRule: false,

    // ... Manage User
    manageUsersPage: true,
    addUser: false,
    updateUserDetails: false,
    deleteUser: false,

    // ... PMS Logs
    pmsLogsPage: true,

    // ... Events
    eventsPage: true,

    // live properties
    livePropertiesPage: true,
    viewLiveProperties: true,
    deleteLiveProperties: false,
    unpublishLiveProperties: false,
    previewLiveProperties: true,
    listingLiveProperties: true,
    viewCalendarLiveProperties: false,

    // unpublished properties
    unpublishedPropertiesPage: true,
    viewUnpublishedProperties: true,
    deleteUnpublishedProperties: false,
    publishUnpublishedProperties: false,
    previewUnpublishedProperties: true,
    listingUnpublishedProperties: true,
    viewCalendarUnpublishedProperties: false,
    updateNewUnpublishedProperties: false,

    // inactive properties
    inactivePropertiesPage: true,
    viewInactiveProperties: true,
    deleteInactiveProperties: false,
    publishInactiveProperties: true,
    previewInactiveProperties: true,
    // viewCalendarInactiveProperties: true,

    // Discount template
    discountTemplatePage: true,
    createDiscountTemplate: false,
    editDiscountTemplate: false,
    deleteDiscountTemplate: false,
    applyDiscountTemplate: false,

    // Availability Blocks
    availabilityBlocksPage: true,
    createAvailabilityBlocks: false,
    editAvailabilityBlocks: false,
    deleteAvailabilityBlocks: false,
    applyAvailabilityBlocks: false,

    // Contact template
    contactTemplatePage: true,
    createContactTemplate: false,
    editContactTemplate: false,
    deleteContactTemplate: false,
    applyContactTemplate: false,

    // Property Details
    propertyDetailsPage: true,
    viewPropertiesDetails: true,
    editPropertiesDetails: false,
    listingPropertiesDetails: true,

    // Property calendar
    syncCalendar: false,
    editDates: false,

    // Bookings
    bookingsPage: true,
    viewBookings: true,

    // Earning progress report
    erningReportPage: true,
    viewEarningReports: true,
    downloadEarningReports: true,

    // Reservation report
    reservationReportPage: true,
    viewReservationReports: true,
    downloadReservationReports: true,

    // Payout report
    payoutReportPage: true,
    viewPayoutReports: true,
    downloadPayoutReports: true,
  },
  ADMIN_DATA_VALIDATOR: {
    // General
    createNewListing: false,

    // Other
    propertyDetailWithToken: true,

    // Today section

    // Inbox

    // Notification

    // .... Profile
    profilePage: true,
    editProfileDetails: true,

    // ... Payout settings
    payoutSettingsPage: true,
    registerBankAcc: false,
    applyPayoutRule: false,

    // ... Manage User
    manageUsersPage: true,
    addUser: false,
    updateUserDetails: false,
    deleteUser: false,

    // ... PMS Logs
    pmsLogsPage: true,

    // ... Events
    eventsPage: true,

    // live properties
    livePropertiesPage: true,
    viewLiveProperties: true,
    deleteLiveProperties: false,
    unpublishLiveProperties: false,
    previewLiveProperties: true,
    listingLiveProperties: true,
    viewCalendarLiveProperties: false,

    // unpublished properties
    unpublishedPropertiesPage: true,
    viewUnpublishedProperties: true,
    deleteUnpublishedProperties: false,
    publishUnpublishedProperties: false,
    previewUnpublishedProperties: true,
    listingUnpublishedProperties: true,
    viewCalendarUnpublishedProperties: false,
    updateNewUnpublishedProperties: false,

    // inactive properties
    inactivePropertiesPage: true,
    viewInactiveProperties: true,
    deleteInactiveProperties: false,
    publishInactiveProperties: false,
    previewInactiveProperties: false,
    // viewCalendarInactiveProperties: true,

    // archived properties
    viewArchivedPropertiesPage: true,

    // Discount template
    discountTemplatePage: true,
    createDiscountTemplate: false,
    editDiscountTemplate: false,
    deleteDiscountTemplate: false,
    applyDiscountTemplate: false,

    // Availability Blocks
    availabilityBlocksPage: true,
    createAvailabilityBlocks: false,
    editAvailabilityBlocks: false,
    deleteAvailabilityBlocks: false,
    applyAvailabilityBlocks: false,

    // Contact template
    contactTemplatePage: true,
    createContactTemplate: false,
    editContactTemplate: false,
    deleteContactTemplate: false,
    applyContactTemplate: false,

    // Property Details
    propertyDetailsPage: true,
    viewPropertiesDetails: true,
    editPropertiesDetails: false,
    listingPropertiesDetails: true,

    // Property calendar
    syncCalendar: false,
    editDates: false,

    // Bookings
    bookingsPage: true,
    viewBookings: true,

    // Earning progress report
    erningReportPage: true,
    viewEarningReports: true,
    downloadEarningReports: false,

    // Reservation report
    reservationReportPage: true,
    viewReservationReports: true,
    downloadReservationReports: false,

    // Payout report
    payoutReportPage: true,
    viewPayoutReports: true,
    downloadPayoutReports: false,
  },
}

export interface UseIsPermitted {
  hasAccessToModule: boolean
  permitedActions: string[]
}

const useIsPermitted = () => {
  const userRole = useAppSelector(selectUserRole)
  const currentPmc = useAppSelector(selectCurrentPmc)

  // if pmc status archived then ADMIN_DATA_VALIDATOR role return
  const user =
    currentPmc && currentPmc.status === "ARCHIVED"
      ? "ADMIN_DATA_VALIDATOR"
      : userRole

  if (userRole) {
    const hasAccess = RoleAccess[user as keyof typeof RoleAccess]

    if (hasAccess) return hasAccess
  }
  return {}
}

export default useIsPermitted
