import { useEffect, useState } from "react"

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"
import "./index.css"

import { FormInputProps } from "./types"

const CustomPhoneInput = ({
  id,
  value,
  placeholder,
  error,
  icon,
  country = "us",
  dialerCode = "+1",
  disabled = false,
  onChange,
  label,
  labelSize,
}: FormInputProps) => {
  // phone number should be passed as dialCode without sign + value
  const [phoneNumber, setPhoneNumber] = useState(
    `${dialerCode?.replace("+", "")}${value}`,
  )
  // set phone number value as per diral code and value change
  useEffect(() => {
    setPhoneNumber(`${dialerCode?.replace("+", "")}${value}`)
  }, [dialerCode, value])
  return (
    <FormControl
      isInvalid={!!error}
      isDisabled={disabled}
      pointerEvents={disabled ? "none" : "auto"}
    >
      <FormLabel htmlFor={id} fontSize={labelSize || "sm"} fontWeight='medium'>
        {label}
      </FormLabel>
      <InputGroup>
        <PhoneInput
          country={country}
          enableTerritories
          value={phoneNumber}
          countryCodeEditable={false}
          // onlyCountries={["us", "mx", "ca", "br"]}
          inputProps={{
            required: true,
            height: "42px",
            id,
          }}
          containerStyle={{
            height: "30px",
          }}
          buttonStyle={{
            height: "35px",
          }}
          inputStyle={{
            height: "35px",
            width: "100%",
          }}
          dropdownStyle={{
            paddingTop: 10,
            minWidth: "100%",
            height: "150px",
            overflowY: "auto",
            border: "1px solid #a0b1b2",
            bottom: "35px",
          }}
          placeholder={placeholder}
          isValid={!error}
          onChange={onChange}
          disabled={disabled}
        />
        <InputRightElement>{icon}</InputRightElement>
      </InputGroup>
      <Box pt='8px'>
        <FormErrorMessage>{error}</FormErrorMessage>
      </Box>
    </FormControl>
  )
}

export default CustomPhoneInput
