import { HamburgerIcon } from "@chakra-ui/icons"
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Button,
} from "@chakra-ui/react"
import { Link, useLocation } from "react-router-dom"

import BookingsIcon from "components/icons/BookingsIcon"
import ContactUsIcon from "components/icons/contactUsIcon"
import FaqIcon from "components/icons/FaqIcon"
import ListingsIcon from "components/icons/ListingsIcon"
import PayoutSettingIcon from "components/icons/payoutSettingIcon"
import ProfileIcon from "components/icons/profileIcon"
import ReportsIcon from "components/icons/ReportsIcon"
import SignOutIcon from "components/icons/signOutIcon"
import CustomMenuItem from "components/menudropdown/dropdown-menu-item"

type ProfileProps = {
  onLogout: () => void
  username: string | null | undefined
}

const ProfileMenu = ({ onLogout, username }: ProfileProps) => {
  const location = useLocation()
  return (
    <Menu autoSelect={false}>
      <MenuButton
        px='11px'
        py='8px'
        variant='primary'
        as={Button}
        colorScheme='black'
        _focus={{}}
        _hover={{ bg: "secondaryColor" }}
        _expanded={{ bg: "secondaryColor" }}
      >
        <Flex justifyContent='space-between' alignItems='center'>
          <HamburgerIcon fontSize='20px' fontWeight='600' />
          <Text
            fontWeight='500'
            fontSize='14px'
            lineHeight='17px'
            marginLeft='10px'
            color='white'
          >
            {username}
          </Text>
        </Flex>
      </MenuButton>

      <MenuList minWidth='189px'>
        <Link to='/profile/profile-details'>
          <CustomMenuItem
            className={`profileIcon ${
              location.pathname === "/profile/profile-details"
                ? "activeItem"
                : ""
            }`}
            fontWeight='500'
            fontSize='13px'
            lineHeight='17px'
            isSelected={location.pathname === "/profile/profile-details"}
          >
            <ProfileIcon /> Profile
          </CustomMenuItem>
        </Link>
        <Link to='/listings/properties/live-properties'>
          <CustomMenuItem
            className={`svgIcon ${
              location.pathname === "/listings/properties/live-properties"
                ? "activeItem"
                : ""
            }`}
            fontWeight='500'
            fontSize='13px'
            lineHeight='17px'
            isSelected={
              location.pathname === "/listings/properties/live-properties"
            }
          >
            <ListingsIcon />
            Listings
          </CustomMenuItem>
        </Link>
        <Link to='/listings/bookings/all'>
          <CustomMenuItem
            className={`profileIcon ${
              location.pathname === "/listings/bookings/all" ? "activeItem" : ""
            }`}
            fontWeight='500'
            fontSize='13px'
            lineHeight='17px'
            isSelected={location.pathname === "/listings/bookings/all"}
          >
            <BookingsIcon /> Bookings
          </CustomMenuItem>
        </Link>
        <Link to='/listings/reports/earningProgress'>
          <CustomMenuItem
            className={`svgIcon ${
              location.pathname === "/listings/reports/earningProgress"
                ? "activeItem"
                : ""
            }`}
            fontWeight='500'
            fontSize='13px'
            lineHeight='17px'
            isSelected={
              location.pathname === "/listings/reports/earningProgress"
            }
          >
            <ReportsIcon /> Reports
          </CustomMenuItem>
        </Link>
        <Link to='/profile/payout-settings'>
          <CustomMenuItem
            className={`svgIcon ${
              location.pathname === "/profile/payout-settings"
                ? "activeItem"
                : ""
            }`}
            fontWeight='500'
            fontSize='13px'
            lineHeight='17px'
            isSelected={location.pathname === "/profile/payout-settings"}
          >
            <PayoutSettingIcon /> Payout Settings
          </CustomMenuItem>
        </Link>
        <Link to='/contact-us'>
          <CustomMenuItem
            className={`svgIcon ${
              location.pathname === "/contact-us" ? "activeItem" : ""
            }`}
            fontWeight='500'
            fontSize='13px'
            lineHeight='17px'
            isSelected={location.pathname === "/contact-us"}
          >
            <ContactUsIcon /> Contact Us
          </CustomMenuItem>
        </Link>
        <Link to='/faq'>
          <CustomMenuItem
            className={`svgIcon ${
              location.pathname === "/faq" ? "activeItem" : ""
            }`}
            fontWeight='500'
            fontSize='13px'
            lineHeight='17px'
            isSelected={location.pathname === "/faq"}
          >
            <FaqIcon />
            &nbsp; FAQs
          </CustomMenuItem>
        </Link>
        <CustomMenuItem
          className='profileIcon'
          fontWeight='500'
          fontSize='13px'
          lineHeight='17px'
          onClick={onLogout}
        >
          <SignOutIcon /> Sign Out
        </CustomMenuItem>
      </MenuList>
    </Menu>
  )
}

export default ProfileMenu
