import { Icon, IconProps } from "@chakra-ui/react"

const SortingIcon = (props: IconProps) => (
  // style="mask-type:alpha"
  <Icon width='22px' height='22px' viewBox='0 0 22 22' fill='none' {...props}>
    <mask
      id='mask0_2593_2668'
      maskUnits='userSpaceOnUse'
      x='5'
      y='1'
      width='12'
      height='20'
    >
      <path
        d='M11 19.25L6.875 13.2917H15.125L11 19.25ZM11 2.75L6.875 8.70833H15.125L11 2.75Z'
        fill='white'
        stroke='white'
        strokeWidth='1.83333'
        strokeLinejoin='round'
      />
    </mask>
    <g mask='url(#mask0_2593_2668)'>
      <path d='M0 0H22V22H0V0Z' fill='#E1E1E1' />
    </g>
  </Icon>
)

export default SortingIcon
