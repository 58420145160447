import { useState } from "react"

import { Box, Text, useMediaQuery } from "@chakra-ui/react"

import { useAppSelector } from "app/hooks"
import { CalendarDateListProps, CalendarProps } from "pages/properties/types"
import { CalendarDateData } from "services/common"
import {
  getBlockedDates,
  getBookedDates,
  getBookedDatesList,
  getCalendarCommonData,
  getCalendarDate,
} from "store/calendar/calendarSlice"
import { BookedDatesProps } from "store/calendar/types"

import BookedDateDetailsModal from "./BookedDateDetailsModal"
import DateDetailsModal from "./DateDetailsModal"
import { Day } from "./day"
import {
  compareTwoDatesWithGreater,
  compareTwoDatesWithLesser,
  daysNames,
  generateRangeWithoutBookedDates,
  getFormatedDate,
  isCheckBookedDate,
} from "./utils"

const dayColor = {
  booked: "#0D6F81",
  blocked: "#F4CE10",
}
// calendar component
export const Calendar = ({
  days,
  isWhimstay,
  canEditDates,
  calendarData,
}: CalendarProps) => {
  const [isMobile] = useMediaQuery("(max-width: 1024px)")
  const [openDateModal, setOpenDateModal] = useState<boolean>(false)
  const [openBookedDateModal, setOpenBookedDateModal] = useState<boolean>(false)
  const [multiples, setMultiples] = useState<boolean>(false)
  const calendarCommonData = useAppSelector(getCalendarCommonData)
  const calendarDateDetails = useAppSelector(getCalendarDate)
  const blockedDates = useAppSelector(getBlockedDates)
  const bookedDates = useAppSelector(getBookedDates)
  const bookedDatesList = useAppSelector(getBookedDatesList)
  const [selectedCurrentDate, setSelectedDate] = useState("")

  const [selectedDateData, setSelectedDateData] = useState<
    Array<CalendarDateListProps>
  >([])
  const [selectedBookedDateData, setSelectedBookedDateData] =
    useState<BookedDatesProps>([])

  const [selectedDatesList, setSelectedDatesList] = useState<string[]>([])
  const [dateRange, setDateRange] = useState<{
    startDate: string
    endDate: string
  }>({ startDate: "", endDate: "" })

  const getBackGroundColor = (prevDay: Date, currDay: Date, nextDay: Date) => {
    const formatedPrevDay = getFormatedDate(prevDay)
    const formatedCurrDay = getFormatedDate(currDay)
    const formatedNextDay = getFormatedDate(nextDay)
    const data = isCheckBookedDate(
      formatedPrevDay,
      formatedCurrDay,
      formatedNextDay,
      bookedDates,
    )

    if (data?.isBooked) {
      return {
        bg: dayColor.booked,
        type: "Booked",
        leftJoin: data?.leftJoin,
        rightJoin: data?.rightJoin,
        isBooked: data?.isBooked,
      }
    }
    // check for date is blocked or not
    if (blockedDates.includes(formatedCurrDay))
      return {
        bg: dayColor.blocked,
        type: "Blocked",
      }

    return { bg: "", type: "" }
  }

  const isCheck = (date: string) => {
    if (selectedCurrentDate === date) {
      return true
    }
    if (dateRange.startDate === date || dateRange.endDate === date) {
      return true
    }
    if (
      compareTwoDatesWithLesser(dateRange.startDate, date) &&
      compareTwoDatesWithLesser(date, dateRange.endDate)
    ) {
      return true
    }
    if (
      compareTwoDatesWithGreater(dateRange.startDate, date) &&
      compareTwoDatesWithGreater(date, dateRange.endDate)
    ) {
      return true
    }
    return false
  }

  const handleMouse = (d: string) => {
    if (dateRange.startDate.length === 0 && dateRange.endDate.length === 0) {
      setDateRange({ ...dateRange, startDate: d, endDate: d })
    } else if (compareTwoDatesWithLesser(d, dateRange.startDate)) {
      setDateRange({ ...dateRange, endDate: d })
    } else if (compareTwoDatesWithGreater(d, dateRange.startDate)) {
      setDateRange({ ...dateRange, endDate: d })
    } else {
      setDateRange({ ...dateRange, startDate: d, endDate: d })
    }
  }

  const handleMultipleDateClick = (endDate: string, isMulitple: boolean) => {
    const fromD = compareTwoDatesWithGreater(dateRange.startDate, endDate)
      ? endDate
      : dateRange.startDate
    const toD = compareTwoDatesWithGreater(dateRange.startDate, endDate)
      ? dateRange.startDate
      : endDate
    const selectedRange = generateRangeWithoutBookedDates(
      fromD,
      toD,
      days,
      bookedDatesList.flat(),
    )
    const tempDates = [fromD, ...selectedRange]

    const temp = tempDates.map((item) => ({
      ...calendarCommonData,
      date: item,
    }))

    if (isMulitple) {
      const [data] = calendarDateDetails.filter(
        (item: CalendarDateListProps) => item?.date === temp[0]?.date,
      )
      if (data) {
        temp.shift()
      }
      const dataRange = data ? [data, ...temp] : temp
      if (dataRange?.length > 0) {
        setSelectedDateData(dataRange)
      }
      setOpenDateModal(true)
    }
  }

  const handleBookedDateClick = (d: string) => {
    const bookedDatesDetails = bookedDates.filter(
      (item) =>
        item.fromDate === d ||
        item.toDate === d ||
        (compareTwoDatesWithLesser(item.fromDate, d) &&
          compareTwoDatesWithLesser(d, item.toDate)),
    )
    setSelectedBookedDateData(bookedDatesDetails)
    setOpenBookedDateModal(true)
  }

  const onClose = () => {
    setMultiples(false)
    setSelectedDateData([])
    setOpenDateModal(false)
    setSelectedDatesList([])
    setDateRange({ startDate: "", endDate: "" })
    setOpenBookedDateModal(false)
  }

  const handleSingleDateClick = (selectedDate: string) => {
    setSelectedDate(selectedDate)
    const data = calendarDateDetails.filter(
      (item: CalendarDateListProps) => item.date === selectedDate,
    )
    if (data.length > 0) {
      setSelectedDateData(data)
    } else {
      //  if data is null for any particular date
      const temp = [{ ...calendarCommonData, date: selectedDate }]
      setSelectedDateData(temp)
    }
    if (isMobile) {
      setOpenDateModal(true)
    }
  }

  return (
    <Box>
      {openBookedDateModal && (
        <BookedDateDetailsModal
          onClose={onClose}
          openBookedDateModal={openBookedDateModal}
          selectedBookedDateData={selectedBookedDateData}
        />
      )}
      {openDateModal && (
        <DateDetailsModal
          multiples={multiples}
          selectedDateData={selectedDateData}
          onClose={onClose}
          openDateModal={openDateModal}
          // days={days}
        />
      )}
      <Box>
        <Box m='30px 0 20px 0'>
          <Box
            display='grid'
            gridTemplateColumns='repeat(7, 1fr)'
            textAlign='center'
          >
            {daysNames.map((day) => (
              <Text key={day} size='sm' fontWeight='500'>
                {day}
              </Text>
            ))}
          </Box>
        </Box>
        <Box>
          <Box
            display='grid'
            gridTemplateColumns=' repeat(7, 1fr)'
            gridColumnGap='0px'
            gridAutoFlow='row'
            borderLeft='1px solid #E1E1E1'
            borderTop='1px solid #E1E1E1'
          >
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */}
            {calendarData?.map((day: CalendarDateData, index: number) => {
              const data = getBackGroundColor(
                days[index - 1],
                days[index],
                days[index + 1],
              )

              return (
                <Day
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  dayData={day}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                  day={new Date(day?.date)}
                  backgroundColor={data.bg}
                  isLeftJoin={data.leftJoin}
                  isRightJoin={data.rightJoin}
                  text={day?.base_price}
                  handleMultipleDateClick={handleMultipleDateClick}
                  handleSingleDateClick={handleSingleDateClick}
                  handleBookedDateClick={handleBookedDateClick}
                  multiples={multiples}
                  setMultiples={setMultiples}
                  selectedDatesList={selectedDatesList}
                  setSelectedDatesList={setSelectedDatesList}
                  handleMouse={handleMouse}
                  bgColor={
                    isCheck(getFormatedDate(new Date(day?.date))) &&
                    !data?.isBooked
                      ? "rgba(182, 227, 136,0.5)"
                      : "transparent"
                  }
                  isBooked={data?.isBooked}
                  isWhimstay={isWhimstay}
                  canEditDates={canEditDates}
                />
              )
            })}
          </Box>
          {/* {isMobile && (
            <Box display='flex' flexDir='row' mt='20px' flexWrap='wrap'>
              <Box mr='20px' mb='10px'>
                <ColorBox backgroundColor={dayColor.booked} text='Booked' />
              </Box>
              <Box mr='20px' mb='10px'>
                <ColorBox
                  backgroundColor={dayColor.booked}
                  text='Past Booking'
                  opacity='0.5'
                />
              </Box>
              <Box mr='20px' mb='10px'>
                <ColorBox backgroundColor={dayColor.blocked} text='Blocked' />
              </Box>
              <Box>
                <ColorBox
                  backgroundColor={dayColor.blocked}
                  text='Past Blocked'
                  opacity='0.5'
                />
              </Box>
            </Box>
          )} */}
        </Box>
      </Box>
    </Box>
  )
}
