import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "app/store"
import { apiClient } from "services/client"
import { HouseRulesRequest } from "services/features"

import { InitialState, Rule } from "./types"

export const addHouseRules = createAsyncThunk(
  "houseRules/addHouseRules",
  async (params: HouseRulesRequest) => {
    const response = await apiClient.addHouseRules(params)

    return response.data
  },
)

export const feeMappingCategories = createAsyncThunk(
  "feeMapping/categories",
  async () => {
    const response = await apiClient.getFeeCategories()
    return response.data
  },
)

const initialState: InitialState = {
  loading: false,
  houseRules: {
    id: 0,
    checkOutTime: "",
    addHouseRule: "",
    chaeckInTime: "",
    checkInInstruction: "",
    checkOutInstruction: "",
    partyAllowed: false,
    smokeAllowed: false,
    suitableChild: false,
    suitableInfant: false,
    suitablePet: false,
    wheelchairAccessible: false,
  },
  rules: [],
  feeCategories: [],
  error: null,
}

export const featureSlice = createSlice({
  name: "houseRules",
  initialState,
  reducers: {
    addRules: (state, action: PayloadAction<Rule>) => {
      state.rules = [...state.rules, action.payload]
    },
    removeRules: (state, action: PayloadAction<Rule>) => {
      const removeItem = (arr: Rule[], value: Rule) => {
        let i = 0
        while (i < arr.length) {
          if (arr[i].id === value.id) {
            arr.splice(i, 1)
          } else {
            // eslint-disable-next-line no-plusplus
            ++i
          }
        }
        return arr
      }
      state.rules = removeItem(state.rules, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addHouseRules.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addHouseRules.fulfilled, (state, action) => {
      state.loading = false
      state.houseRules = { ...state.houseRules, ...action.payload.data }
      state.error = null
    })
    builder.addCase(addHouseRules.rejected, (state, action) => {
      state.loading = false
      state.houseRules = null
      state.error = action.error.message as string
    })
    builder.addCase(feeMappingCategories.pending, (state) => {
      state.loading = true
    })
    builder.addCase(feeMappingCategories.fulfilled, (state, action) => {
      state.loading = false
      state.feeCategories = action.payload.data
      state.error = null
    })
    builder.addCase(feeMappingCategories.rejected, (state, action) => {
      state.loading = false
      state.feeCategories = []
      state.error = action.error.message as string
    })
  },
})

export const { addRules, removeRules } = featureSlice.actions

export const selectHouseRules = (state: RootState) =>
  state.houseRulesReducer.houseRules
export const selectRules = (state: RootState) => state.houseRulesReducer.rules

export const feeCategories = (state: RootState) =>
  state.houseRulesReducer.feeCategories

export default featureSlice.reducer
