import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "services/client"
import {
  ApplyTemplateOnPropertiesRequestData,
  GetTemplatePropertiesRequestData,
  ResponseData,
} from "services/common"

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/templates/availability-block`

export interface AvailabilityBlockType {
  fromDate: string
  toDate: string
  templateId: string | undefined
  pmcId: string
  active: boolean
}

export interface GetAvailabilityBlocksByPmcIdData {
  data: AvailabilityBlockType[]
}

export interface GetAvailabilityBlocksByPmcIdResponse extends AxiosResponse {
  data: GetAvailabilityBlocksByPmcIdData
}
export interface GetAvailabilityBlocksByPmcIdRequestData {
  pmcId: string
}

export interface GetAvailabilityBlocksByPmcIdRequest
  extends Partial<AxiosRequestConfig> {
  params: GetAvailabilityBlocksByPmcIdRequestData
}

export function getAvailabilityBlocksByPmcId(
  {
    params,
    method = "get",
    url = defaultUrl,
    ...config
  }: GetAvailabilityBlocksByPmcIdRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetAvailabilityBlocksByPmcIdResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetAvailabilityBlocksByPmcIdResponse>
}

export interface AddEditAvailabilityBlocksData extends ResponseData {
  data: AvailabilityBlockType
}

export interface AddEditAvailabilityBlocksResponse extends AxiosResponse {
  data: AddEditAvailabilityBlocksData
}

export function addEditAvailabilityBlocks(
  data: AvailabilityBlockType,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AddEditAvailabilityBlocksResponse> {
  return axiosInstance({
    method: data?.templateId ? "put" : "post",
    data,
    url: defaultUrl,
  }) as Promise<AddEditAvailabilityBlocksResponse>
}

export interface AvailabilityBlocksDeleteResponseData extends ResponseData {
  data: unknown
}

export interface AvailabilityBlocksDeleteResponse extends AxiosResponse {
  data: AvailabilityBlocksDeleteResponseData
}

export interface DeleteAvailabilityBlocksRequestType {
  templateId: string
  pmcId: string
}

export function deleteAvailabilityBlocks(
  data: DeleteAvailabilityBlocksRequestType,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<AvailabilityBlocksDeleteResponse> {
  return axiosInstance({
    method: "delete",
    params: { templateId: data?.templateId, pmcId: data.pmcId },
    url: defaultUrl,
  }) as Promise<AvailabilityBlocksDeleteResponse>
}

export interface TemplatePropertyResponseData extends ResponseData {
  data: {
    content: {
      pmcId: string
      pmsUnitId: string
      propertyName: string
      address: string
      templateId: number
      propertyId: number
    }[]
    pageable: {
      sort: {
        empty: boolean
        sorted: boolean
        unsorted: boolean
      }
      offset: number
      pageNumber: number
      pageSize: number
      paged: boolean
      unpaged: boolean
    }
    last: boolean
    totalPages: number
    totalElements: number
    number: number
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    size: number
    first: boolean
    numberOfElements: number
    empty: boolean
  }
}

export interface TemplatePropertyResponse extends AxiosResponse {
  data: TemplatePropertyResponseData
}

export interface TemplatePropertiesRequest extends Partial<AxiosRequestConfig> {
  params: GetTemplatePropertiesRequestData
}

export function getTemplateProperties(
  {
    params,
    method = "post",
    url = "availability-block",
    ...config
  }: TemplatePropertiesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<TemplatePropertyResponse> {
  return axiosInstance({
    data: params,
    method,
    url: `${
      process.env.REACT_APP_BASE_API_URL || ""
    }/templates/${url}/properties/by-pmcId`,
    ...config,
  }) as Promise<TemplatePropertyResponse>
}

export interface ApplyTemplateOnPropertiesResponseData extends ResponseData {
  data: {
    apiStatus: string
    message: string
    time: string
  }
}

export interface ApplyTemplateOnPropertiesResponse extends AxiosResponse {
  data: ApplyTemplateOnPropertiesResponseData
}

export interface ApplyTemplateOnPropertiesRequest
  extends Partial<AxiosRequestConfig> {
  data: ApplyTemplateOnPropertiesRequestData
}

export function applyTemplateOnProperties(
  {
    data,
    method = "put",
    url = "availability-block",
    ...config
  }: ApplyTemplateOnPropertiesRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ApplyTemplateOnPropertiesResponse> {
  return axiosInstance({
    data,
    method,
    url: `${
      process.env.REACT_APP_BASE_API_URL || ""
    }/templates/${url}/property/by-templateId`,
    ...config,
  }) as Promise<ApplyTemplateOnPropertiesResponse>
}
