import { Icon, IconProps } from "@chakra-ui/react"

const ContactUsIcon = (props: IconProps) => (
  <Icon width='24px' height='20px' viewBox='0 0 24 20' fill='none' {...props}>
    <g clipPath='url(#clip0_1319_5116)'>
      <path
        d='M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z'
        stroke='#414141'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 5.00348C6.24196 4.33548 6.71954 3.7722 7.34815 3.4134C7.97676 3.05461 8.71584 2.92345 9.43448 3.04317C10.1531 3.16288 10.805 3.52574 11.2745 4.06747C11.7441 4.60921 12.0011 5.29486 12 6.00298C12 8.00199 8.99476 9.00149 8.99476 9.00149V12'
        stroke='#414141'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='9' cy='14' r='0.5' fill='#414141' />
    </g>
    <defs>
      <clipPath id='clip0_1319_5116'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </Icon>
)

export default ContactUsIcon
