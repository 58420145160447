import { ReactNode } from "react"

import { Box, Divider, Heading, useMediaQuery } from "@chakra-ui/react"

import ProtectedComponent from "components/PleaseLoginComp"
import DesktopSideMenu from "components/sidemenu/sidemenu"
import { MenuItem as MenuItemType } from "components/sidemenu/types"
import Tabs from "components/tabs/Tabs"
import { HOST_TAB_LIST } from "layouts/host-properties-layout"

type HostBookingLayoutProps = {
  children: ReactNode
  active?: string
}
export const BookingSidebarMenuArr: MenuItemType[] = [
  {
    name: "All",
    id: "all",
    depth: 1,
    to: "/listings/bookings/all",
    childMenu: [],
  },
  {
    name: "Currently Hosting",
    id: "currentlyHosting",
    depth: 1,
    to: "/listings/bookings/currently-hosting",
    childMenu: [],
  },
  {
    name: "Arriving Soon",
    id: "arrivingSoon",
    depth: 1,
    to: "/listings/bookings/arriving-soon",
    childMenu: [],
  },
  {
    name: "Completed",
    id: "completed",
    depth: 1,
    to: "/listings/bookings/completed",
    childMenu: [],
  },
  {
    name: "Canceled",
    id: "canceled",
    depth: 1,
    to: "/listings/bookings/canceled",
    childMenu: [],
  },
]
const HostBookingLayout = (props: HostBookingLayoutProps) => {
  const { children, active } = props
  const [isMobile] = useMediaQuery("(max-width: 1023px)")

  return (
    <Box maxW='100%' px={{ base: "20px", lg: "30px", xl: "70px" }} minW='100%'>
      <Box py={{ base: "0", md: "16px" }} mx={{ base: "-20px", md: "0" }}>
        <Tabs tabsArr={HOST_TAB_LIST} />
      </Box>
      <Divider borderColor='#e1e1e1' />
      <Box mt={{ base: 4, md: 8 }} display='flex' gap={{ base: 7, xl: 10 }}>
        {!isMobile && (
          <Box w='210px' flexGrow={1} flexShrink={0}>
            <Heading size='sm'>Bookings</Heading>
            <DesktopSideMenu
              sidebarMenuArr={BookingSidebarMenuArr.map(
                (x): MenuItemType => ({
                  ...x,
                  isActive: x.id === active,
                }),
              )}
            />
          </Box>
        )}
        <Box w='full'>
          <ProtectedComponent>{children}</ProtectedComponent>
        </Box>
      </Box>
    </Box>
  )
}

export default HostBookingLayout
