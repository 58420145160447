import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { ResponseData } from "./common"

export interface ContactUsRequestData {
  companyName: string
  email: string
  mobileNumber: string
  message: string
  pmcId: string
  subject: string
}

export interface ContactUsRequest extends Partial<AxiosRequestConfig> {
  params: ContactUsRequestData
}

export interface ContactUsResponseData extends ResponseData {
  data: unknown
}

export interface ContactUsResponse extends AxiosResponse {
  data: ContactUsResponseData
}

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/host/contact-us`

export function contactUsSubmit(
  {
    params,
    method = "post",
    url = `${defaultUrl}?subject=${params.subject}`,
    ...config
  }: ContactUsRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ContactUsResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<ContactUsResponse>
}
