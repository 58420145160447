import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import {
  ExportCalendarLinkData,
  ExportCalendarRequestData,
  GetCalendarBookedDatesRequestData,
  GetCalendarData,
  GetCalendarDatesRequestData,
  GetCalendarRequestData,
  ImportCalendarRequestData,
  ResponeCalendarBookedData,
  ResponeCalendarData,
  ResponeCalendarExportData,
  ResponeCalendarImportData,
  SetCalendarImportLinkRequestData,
  UpdateCalendarDataRequestData,
  UpdateCalendarDatesRequestData,
  UpdateCalendarWeekendPriceRequestData,
} from "./common"

export interface GetCalendarDatesRequest extends Partial<AxiosRequestConfig> {
  data: GetCalendarDatesRequestData
}
export interface GetCalendarDatesResponse extends AxiosResponse {
  data: ResponeCalendarData
}
export interface UpdateCalendarDatesRequest
  extends Partial<AxiosRequestConfig> {
  data: UpdateCalendarDatesRequestData
}

export interface UpdateCalendarWeekendPriceRequest
  extends Partial<AxiosRequestConfig> {
  params: UpdateCalendarWeekendPriceRequestData
}

export interface UpdateCalendarDataRequest extends Partial<AxiosRequestConfig> {
  params: UpdateCalendarDataRequestData
}
export interface UpdateCalendarDatesResponse extends AxiosResponse {
  data: ResponeCalendarData
}

export interface GetCalendarBookedDatesRequest
  extends Partial<AxiosRequestConfig> {
  data: GetCalendarBookedDatesRequestData
}

export interface GetCalendarBookedDatesResponse extends AxiosResponse {
  data: ResponeCalendarBookedData
}

export interface ImportCalendarRequest extends Partial<AxiosRequestConfig> {
  data: ImportCalendarRequestData
}
export interface ImportCalendarResponse extends AxiosResponse {
  data: ResponeCalendarImportData
}
export interface ExportCalendarRequest extends Partial<AxiosRequestConfig> {
  params: ExportCalendarRequestData
}

export interface GetCalendarRequest extends Partial<AxiosRequestConfig> {
  params: GetCalendarRequestData
}

export interface SetCalendarImportLinkRequest
  extends Partial<AxiosRequestConfig> {
  params: SetCalendarImportLinkRequestData
}
export interface ExportCalendarResponse extends AxiosResponse {
  data: ResponeCalendarExportData
}

export interface GetCalendarResponse extends AxiosResponse {
  data: GetCalendarData
}

export interface GetCalendarExportLinkResponse extends AxiosResponse {
  data: {
    data: ExportCalendarLinkData
  }
}

const defaultGetDatesUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/calendar/search`

export function getCalendarDates(
  { method = "post", url = defaultGetDatesUrl, ...config },
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetCalendarDatesResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<GetCalendarDatesResponse>
}

const defaultUpdateDatesUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/calendar/save-update`

export function updateCalendarDates(
  { method = "post", url = defaultUpdateDatesUrl, ...config },
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<UpdateCalendarDatesResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<UpdateCalendarDatesResponse>
}

const defaultGetBookedDatesUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/calendar/bookings`

export function getCalendarBookedDates(
  { method = "post", url = defaultGetBookedDatesUrl, ...config },
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetCalendarBookedDatesResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<GetCalendarBookedDatesResponse>
}

const defaultImportCalendarUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/calendar/import`

export function importCalendar(
  { method = "post", url = defaultImportCalendarUrl, ...config },
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ImportCalendarResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<ImportCalendarResponse>
}

const defaultExportCalendarUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/calendar/export`

export function exportCalendar(
  {
    method = "get",
    url = defaultExportCalendarUrl,
    ...config
  }: ExportCalendarRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ExportCalendarResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<ExportCalendarResponse>
}

const defaultCalendarUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/properties/calendar`

export function getCalendarData(
  {
    params,
    method = "get",
    url = defaultCalendarUrl,
    ...config
  }: GetCalendarRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetCalendarResponse> {
  return axiosInstance({
    method,
    url: `${url}?pmcId=${params?.pmcId}&pmsUnitId=${params?.pmsUnitId}`,
    ...config,
  }) as Promise<GetCalendarResponse>
}

const defaultCalendarUpdateUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/calendar/update`

export function updateCalendarData(
  {
    params,
    data,
    method = "post",
    url = defaultCalendarUpdateUrl,
    ...config
  }: UpdateCalendarDataRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<UpdateCalendarDatesResponse> {
  return axiosInstance({
    method,
    url: `${url}?pmcId=${params.pmcId}&pmsUnitId=${params?.pmsUnitId}`,
    data: params,
    ...config,
  }) as Promise<UpdateCalendarDatesResponse>
}

const defaultCalendarWeekendPriceUpdateUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/calendar/update-weekend-price`

export function updateCalendarWeekendPrice(
  {
    params,
    data,
    method = "post",
    url = defaultCalendarWeekendPriceUpdateUrl,
    ...config
  }: UpdateCalendarWeekendPriceRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<UpdateCalendarDatesResponse> {
  return axiosInstance({
    method,
    url: `${url}`,
    data: params,
    ...config,
  }) as Promise<UpdateCalendarDatesResponse>
}

const defaultExportCalendarLinkUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/calendar/export`

export function getExportCalendarLink(
  {
    params,
    method = "get",
    url = defaultExportCalendarLinkUrl,
    ...config
  }: GetCalendarRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetCalendarExportLinkResponse> {
  return axiosInstance({
    method,
    url: `${url}?pmcId=${params?.pmcId}&pmsUnitId=${params?.pmsUnitId}`,
    ...config,
  }) as Promise<GetCalendarExportLinkResponse>
}

const defaultImportCalendarLinkUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/calendar/import`

export function setImportCalendarLink(
  {
    params,
    method = "post",
    url = defaultImportCalendarLinkUrl,
    ...config
  }: SetCalendarImportLinkRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetCalendarExportLinkResponse> {
  return axiosInstance({
    method,
    url: `${url}`,
    data: params,
    ...config,
  }) as Promise<GetCalendarExportLinkResponse>
}
