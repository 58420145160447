import { UserBasicDetails } from "services/common"

import { getCookie, setCookie } from "./cookies"

export const setAuthToken = (value: string) =>
  setCookie(process.env.REACT_APP_TOKEN_NAME || "wAT", value, 365 * 2)

export const getAuthToken = () =>
  getCookie(process.env.REACT_APP_TOKEN_NAME || "wAT")

export const removeAuthToken = () =>
  setCookie(process.env.REACT_APP_TOKEN_NAME || "wAT", "", 365 * 2)

export const setTempAuthToken = (value: string) =>
  setCookie(process.env.REACT_APP_TEMP_TOKEN_NAME || "whTAT", value, 365 * 2)

export const getTempAuthToken = () =>
  getCookie(process.env.REACT_APP_TEMP_TOKEN_NAME || "whTAT")

export const removeTempAuthToken = () =>
  setCookie(process.env.REACT_APP_TEMP_TOKEN_NAME || "whTAT", "", 365 * 2)

export const setReCaptchaToken = (value: string) =>
  setCookie("rCT", value || "amish", 365 * 2)

export const getReCaptchaToken = () => getCookie("rCT")

export const removeReCaptchaToken = () => setCookie("rCT", "", 365 * 2)

export const setTableRowCount = (value: string) =>
  localStorage.setItem("tableRowsCount", value)

export const getTableRowCount = () =>
  localStorage.getItem("tableRowsCount") || "5"

export const setPageCount = (value: string) =>
  sessionStorage.setItem("pageCount", value)

export const getsetPageCount = () => sessionStorage.getItem("pageCount") || "0"

export const setSelectedPMCId = (value: string) =>
  localStorage.setItem("selectedPMC", value)

export const getSelectedPMCId = () => localStorage.getItem("selectedPMC") || ""

export const setSelectedPMCIdC = (value: string) =>
  localStorage.setItem("selectedPMC", value)

export const setInviteAuth = (value: string) =>
  localStorage.setItem("inviteAuth", value)

export const getInviteAuth = () => localStorage.getItem("inviteAuth")

export const removeInviteAuth = () => localStorage.removeItem("inviteAuth")

export const setUserCookie = (value: UserBasicDetails | undefined) =>
  setCookie("ubD", (value && JSON.stringify(value)) || "", 365 * 2)
