import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { downloadFileFromBlobData } from "utils/common-functions"

import { defaultAxiosInstance } from "./client"
import {
  ActivePropertyReportData,
  GetPayoutReportData,
  GetReportsData,
  ResponseData,
} from "./common"

export const defaultUrl = `${process.env.REACT_APP_BASE_API_URL || ""}/report`

export interface GetReportsRequestData {
  reportName: string
  format: string
  pmcId: string
  bookingStatus: string
  keyword?: string
  pageNo?: string
  noOfRecords?: string
  sortDirection?: string
  sortBy?: string
  locale: string
  fromDate?: Date
  toDate?: Date
}

export interface GetReportsRequest extends Partial<AxiosRequestConfig> {
  params: GetReportsRequestData
}

export interface ReportsResponseData extends ResponseData {
  data: GetReportsData
}

export interface ReportsResponse extends AxiosResponse {
  data: ReportsResponseData | BlobPart
}

export async function getReports(
  { params, method = "get", url = defaultUrl, ...config }: GetReportsRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ReportsResponse> {
  const resp = axiosInstance({
    params,
    method,
    url,
    responseType: params.format === "JSON" ? "json" : "blob",
    ...config,
  }) as Promise<ReportsResponse>
  const { format, reportName } = params
  if (!(format === "JSON")) {
    const data = (await resp).data as BlobPart
    downloadFileFromBlobData({
      fileName: reportName,
      format,
      data,
    })
  }
  return resp
}

export interface EarningReportsResponseData extends ResponseData {
  data: {
    earning: number
    generationDate: number
    expected: number
    paidout: number
  }[]
}

export interface EarningReportsResponse extends AxiosResponse {
  data: EarningReportsResponseData | BlobPart
}

export async function getEarningReportData(
  { params, method = "get", url = defaultUrl, ...config }: GetReportsRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<EarningReportsResponse> {
  const resp = axiosInstance({
    params,
    method,
    url,
    responseType: params.format === "JSON" ? "json" : "blob",
    ...config,
  }) as Promise<EarningReportsResponse>
  const { format, reportName } = params
  if (!(format === "JSON")) {
    const data = (await resp).data as BlobPart
    downloadFileFromBlobData({
      fileName: reportName,
      format,
      data,
    })
  }
  return resp
}

export interface GetPayoutReportRequestData {
  reportName: string
  format: string
  pmcId: string
  fromDate: Date
  toDate: Date
  locale: string
  bookingStatus: string
  keyword?: string
  pageNo?: number
  noOfRecords?: number
  sortDirection?: string
  sortBy?: string
}

export interface GetPayoutReportRequest extends Partial<AxiosRequestConfig> {
  params: GetPayoutReportRequestData
}

export interface PayoutReportResponseData extends ResponseData {
  data: GetPayoutReportData
}

export interface PayoutReportResponse extends AxiosResponse {
  data: PayoutReportResponseData | BlobPart
}

export async function getPayoutReportData(
  {
    params,
    method = "get",
    url = defaultUrl,
    ...config
  }: GetPayoutReportRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<PayoutReportResponse> {
  const resp = axiosInstance({
    params,
    method,
    url,
    responseType: params.format === "JSON" ? "json" : "blob",
    ...config,
  }) as Promise<PayoutReportResponse>
  const { format, reportName } = params
  if (!(format === "JSON")) {
    const data = (await resp).data as BlobPart
    downloadFileFromBlobData({
      fileName: reportName,
      format,
      data,
    })
  }
  return resp
}

export interface ActivePropertiesReportRequestData {
  reportName: string
  format: string
  pmcId: string
  // fromDate: Date
  // toDate: Date
  locale: string
  bookingStatus: string
  keyword?: string
  pageNo?: number
  noOfRecords?: number
  sortDirection?: string
  sortBy?: string
}

export interface ActivePropertiesReportRequest
  extends Partial<AxiosRequestConfig> {
  params: ActivePropertiesReportRequestData
}
export interface ActivePropertiesReportResponseData extends ResponseData {
  data: ActivePropertyReportData
}

export interface ActivePropertiesReportResponse extends AxiosResponse {
  data: ActivePropertiesReportResponseData | BlobPart
}
export async function getActivePropertiesReport(
  {
    params,
    method = "get",
    url = defaultUrl,
    ...config
  }: ActivePropertiesReportRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<ActivePropertiesReportResponse> {
  const resp = axiosInstance({
    params,
    method,
    url,
    responseType: params.format === "JSON" ? "json" : "blob",
    ...config,
  }) as Promise<ActivePropertiesReportResponse>
  const { format, reportName } = params
  if (!(format === "JSON")) {
    const data = (await resp).data as BlobPart
    downloadFileFromBlobData({
      fileName: reportName,
      format,
      data,
    })
  }
  return resp
}
