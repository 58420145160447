import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { VerifyOtpRequestData, ResponseData } from "./common"

export interface VerifyOtpRequest extends Partial<AxiosRequestConfig> {
  params: VerifyOtpRequestData
}

export interface VerifyOtpResponseData extends ResponseData {
  data: {
    verified: boolean
    view: number
  }
}
export interface VerifyOtpResponse extends AxiosResponse {
  data: VerifyOtpResponseData
}

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/otp/verify`

export function verifyOtp(
  { params, method = "get", url = defaultUrl, ...config }: VerifyOtpRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<VerifyOtpResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<VerifyOtpResponse>
}

export interface VerifyPasswordRequest extends Partial<AxiosRequestConfig> {
  data:
    | {
        password: string
      }
    | string
}

export interface VerifyPasswordResponseData extends ResponseData {
  data: {
    message: string
    userId: number
    pmcId: string
    firstName: string
    headers: never
    subject: string
    isEmailVerify: boolean
  }
}
export interface VerifyPasswordResponse extends AxiosResponse {
  data: VerifyPasswordResponseData
}

export const defaultUrlPassword = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/auth/password/verify`

export function verifyPassword(
  {
    params,
    method = "post",
    url = defaultUrlPassword,
    ...config
  }: VerifyPasswordRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<VerifyPasswordResponse> {
  return axiosInstance({
    method,
    url,
    ...config,
  }) as Promise<VerifyPasswordResponse>
}
