import { useCallback, useEffect, useState } from "react"

import {
  getProjectWindow,
  injectScript,
  injectStyle,
  removeScript,
} from "utils/html-dom"

const ERROR_SCRIPT_NOT_AVAILABLE = "Google recaptcha is not available"
const ERROR_NOT_A_WEB_BROWSER = "Running outside a web browser"

const GOOGLE_RECAPTCHA_V3_SCRIPT = "https://www.google.com/recaptcha/api.js"
const SCRIPT_ID = "google-recaptcha-v3"
const GOOGLE_RECAPTCHA_BADGE_CLASSNAME = ".grecaptcha-badge"

interface IGoogleReCaptchaOptions {
  siteKey: string
  language?: string
  loadOnStart?: boolean
  action?: string
}

interface IGoogleReCaptchaV3HookReturn {
  reCaptchaResponseToken?: string
  executeReCaptcha: (action?: string) => Promise<string>
}

type TGoogleReCaptchaV3Hook = (
  options: IGoogleReCaptchaOptions,
) => IGoogleReCaptchaV3HookReturn

export const useGoogleReCaptchaV3: TGoogleReCaptchaV3Hook = ({
  siteKey,
  language,
  loadOnStart = false,
  action,
}) => {
  const [responseToken, setResponseToken] = useState<string>()

  const executeReCaptcha = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (action?: string): Promise<string> => {
      const window = getProjectWindow()
      if (!window) {
        throw new Error(ERROR_NOT_A_WEB_BROWSER)
      }

      const { grecaptcha } = window
      if (!grecaptcha) {
        throw new Error(ERROR_SCRIPT_NOT_AVAILABLE)
      }

      return new Promise((resolve) => {
        if (action) {
          grecaptcha.ready(() => {
            grecaptcha
              .execute(siteKey, { action })
              .then((token) => resolve(token))
              // eslint-disable-next-line no-console
              .catch((e) => console.warn(e))
          })
        }
      })
    },
    [siteKey],
  )

  const removeGReCaptchaDivElement = () => {
    const window = getProjectWindow()
    if (!window) {
      return
    }

    const element = window.document.querySelector(
      GOOGLE_RECAPTCHA_BADGE_CLASSNAME,
    )
    if (element && element.parentElement) {
      element.parentElement.remove()
    }
  }

  const onLoadInjectedScript = async () => {
    if (!loadOnStart) {
      return
    }

    try {
      const token = await executeReCaptcha(action)
      setResponseToken(token)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e)
    }
  }

  useEffect(() => {
    if (!siteKey) {
      return
    }

    const window = getProjectWindow()
    if (window) {
      const scriptTag = window.document.getElementById(SCRIPT_ID)
      if (!scriptTag) {
        injectScript(
          SCRIPT_ID,
          `${GOOGLE_RECAPTCHA_V3_SCRIPT}?render=${siteKey}${
            language ? `&hl=${language}` : ""
          }`,
          onLoadInjectedScript,
        )
        injectStyle(".grecaptcha-badge { visibility: hidden; }")
      }
    }

    // eslint-disable-next-line consistent-return
    return () => {
      removeScript(SCRIPT_ID)
      removeGReCaptchaDivElement()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteKey, language])

  return { executeReCaptcha, reCaptchaResponseToken: responseToken }
}
