import { Icon, IconProps } from "@chakra-ui/react"

const PayoutSettingIcon = (props: IconProps) => (
  <Icon width='24px' height='20px' viewBox='0 0 24 20' fill='none' {...props}>
    <path
      d='M1.44995 15.67V10.63C1.44995 10.5306 1.53054 10.45 1.62995 10.45H3.96995C4.06936 10.45 4.14995 10.5306 4.14995 10.63V15.67C4.14995 15.7694 4.06936 15.85 3.96995 15.85H1.62995C1.53054 15.85 1.44995 15.7694 1.44995 15.67Z'
      stroke='#414141'
    />
    <path
      d='M4.1499 14.95H5.9499L9.35987 16.655C9.48484 16.7175 9.62264 16.75 9.76236 16.75H12.8241C13.0346 16.75 13.2385 16.6762 13.4002 16.5414L18.2261 12.5199C18.4313 12.3489 18.5499 12.0956 18.5499 11.8285V11.35C18.5499 10.8529 18.147 10.45 17.6499 10.45V10.45L14.0499 13.15M14.0499 13.15H10.4499M14.0499 13.15V12.25C14.0499 11.7529 13.647 11.35 13.1499 11.35H9.5499L8.91351 10.7136C8.74472 10.5448 8.5158 10.45 8.27711 10.45H6.16236C6.02264 10.45 5.88484 10.4825 5.75987 10.545L4.1499 11.35'
      stroke='#414141'
    />
    <circle cx='12.25' cy='5.95' r='3.6' stroke='#414141' strokeWidth='0.9' />
    <path
      d='M13.5999 4.60001H11.5749C11.3959 4.60001 11.2242 4.67112 11.0976 4.79771C10.971 4.9243 10.8999 5.09599 10.8999 5.27501C10.8999 5.45403 10.971 5.62572 11.0976 5.7523C11.2242 5.87889 11.3959 5.95001 11.5749 5.95001H12.9249C13.1039 5.95001 13.2756 6.02112 13.4022 6.14771C13.5288 6.2743 13.5999 6.44599 13.5999 6.62501C13.5999 6.80403 13.5288 6.97572 13.4022 7.10231C13.2756 7.22889 13.1039 7.30001 12.9249 7.30001H10.8999'
      stroke='#414141'
      strokeWidth='0.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.25 8.20001V3.7'
      stroke='#414141'
      strokeWidth='0.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
)

export default PayoutSettingIcon
