import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import {
  GetPropertyData,
  GetPropertyRequestData,
  PropertyData,
  ResponseData,
  PropertyUpdateData,
  HidePropertyImageData,
  PMCDescriptionData,
  GetPmcDescrriptionRequestData,
} from "./common"

export interface PropertyRequest extends Partial<AxiosRequestConfig> {
  params: Partial<PropertyData>
}

export interface PropertyResponseData extends ResponseData {
  data: PropertyData
}

export interface PropertyResponse extends AxiosResponse {
  data: PropertyResponseData
}

export interface PropertUpdateRequest extends Partial<AxiosRequestConfig> {
  params: PropertyUpdateData
}

// export interface PropertyUpdateResponseData extends ResponseData {
//   data: PropertyUpdateData
// }

export interface PropertyUpdateResponse extends AxiosResponse {
  data: ResponseData
}

export interface HidePropertyImageRequest extends Partial<AxiosRequestConfig> {
  params: HidePropertyImageData
}

export interface HidePropertyImageResponseData extends ResponseData {
  data: HidePropertyImageData
}

export interface HidePropertyImageResponse extends AxiosResponse {
  data: HidePropertyImageResponseData
}

export interface GetPropertyRequest extends Partial<AxiosRequestConfig> {
  params: GetPropertyRequestData
}

export interface GetPmcDescriptionRequest extends Partial<AxiosRequestConfig> {
  params: GetPmcDescrriptionRequestData
}

export interface GetPropertyResponseData extends ResponseData {
  data: GetPropertyData
}

export interface GetPropertyResponse extends AxiosResponse {
  data: GetPropertyResponseData
}

export interface GetPMCDescriptionData extends ResponseData {
  data: PMCDescriptionData
}

export interface GetPMCDescriptionResponse extends AxiosResponse {
  data: GetPMCDescriptionData
}

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/properties`

export function createProperty(
  { params, method = "post", url = defaultUrl, ...config }: PropertyRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<PropertyResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<PropertyResponse>
}

export function updateProperty(
  { params, method = "put", url = defaultUrl, ...config }: PropertyRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<PropertyResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<PropertyResponse>
}

export function getProperty(
  { params, method = "get", url = defaultUrl, ...config }: PropertyRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetPropertyResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetPropertyResponse>
}

export function updatePropertyDescription(
  {
    params,
    method = "post",
    url = `${defaultUrl}/edit-description`,
    ...config
  }: PropertUpdateRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<PropertyUpdateResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<PropertyUpdateResponse>
}

export function hidePropertyImage(
  {
    params,
    method = "post",
    url = `${defaultUrl}/remove-image`,
    ...config
  }: HidePropertyImageRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<HidePropertyImageResponse> {
  return axiosInstance({
    data: params,
    method,
    url,
    ...config,
  }) as Promise<HidePropertyImageResponse>
}

export function getPMCDescription(
  {
    params,
    method = "get",
    url = `${defaultUrl}/original-desc`,
    ...config
  }: GetPmcDescriptionRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetPMCDescriptionResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetPMCDescriptionResponse>
}
