import { CompanyForRequest, Persons } from "store/payout/types"

export interface ResponseData {
  time: string
  apiStatus: ApiStatus
  status?: number
  message?: string
  errorCode?: number
  errorMessage?: string
}

export interface ResponeCalendarData {
  time: string
  apiStatus: ApiStatus
  status?: number
  message?: string
  errorCode?: number
  errorMessage?: string
  data: CalendarData
}

export interface ResponeCalendarImportData {
  time: string
  apiStatus: ApiStatus
  status?: number
  message?: string
  errorCode?: number
  errorMessage?: string
  data: {
    propertyId: string
    pmcId: string
    pmsUnitId: string
    eventsCreated: [
      {
        description: string
        summary: string
        startDate: string
        endDate: string
        uid: string
      },
    ]
  }
}

export interface ResponeCalendarExportData {
  time: string
  apiStatus: ApiStatus
  status?: number
  message?: string
  errorCode?: number
  errorMessage?: string
  data: {
    propertyId: string
    pmcId: string
    pmsUnitId: string
    calendarLink: string
  }
}

export interface ExportCalendarLinkData {
  pmcId: string
  pmsUnitId: string
  importCalendarLink: string
  exportCalendarLink: string
  icalLinkPlatFormName: string
}

export interface CalendarDateData {
  updateDt: number
  valid_from: string
  min_nights: number
  max_nights: number | null
  base_price: number
  pm_base_price: number | null
  weekly_base_price: number | null
  max_rent: number
  available: boolean
  whimstay_availability: boolean
  djs_discount: number
  arrival: boolean
  departure: boolean
  weekly_arrival: boolean | null
  weekly_departure: boolean | null
  priceTotal: number
  isAvailableForCheckIn: boolean
  other_fee_Data: any | null // Adjust type based on actual structure of `other_fee_Data`
  los: any | null // Adjust type based on actual structure of `los`
  date: number
}

export interface GetCalendarData {
  time: string
  apiStatus: ApiStatus
  status?: number
  message?: string
  errorCode?: number
  errorMessage?: string
  data: CalendarDateData[]
}

export interface CalendarData {
  averagePrice: number | string
  considerArrDpt: boolean
  maximumPrice: number
  calendarList: [
    {
      date: string
      basePrice: number
      discountPrice: number
      arrival: boolean
      available: boolean
      departure: boolean
      minStayDays: number
    },
  ]
}

export interface ResponeCalendarBookedData {
  time: string
  apiStatus: ApiStatus
  status?: number
  message?: string
  errorCode?: number
  errorMessage?: string
  data: [
    {
      depositId: number
      claimNo: string
      refundAmount: number
      travelerId: number
      propertyId: number
      nightCount: number
      pmcId: string
      pmsUnitId: string
      bookedDate: string
      bookingNo: string
      statusId: number
      checkInDate: string
      checkOutDate: string
      adultNumber: number
      noOfChild: number
      noOfPet: number
      rentAmount: number
      optionalFeeAmount: number
      requiredFeeAmount: number
      taxAmount: number
      depositAmount: number
      totalPaidAmt: number
      hostFee: number
      propertyName: string
      travMobileNo: string
      guestName: string
      travelerEmail: string
      travelerImage: string
      bookingStatus: string
      fullAddress: string
      hostTransferAmount: number
      status: string
    },
  ]
}

export enum ApiStatus {
  SUCCESS = "SUCCESS",
  ACCEPTED = "ACCEPTED",
  ERROR = "ERROR",
  FAILED = "FAILED",
}

export enum Status {
  OK = "OK",
  INVALID_REQUEST = "INVALID_REQUEST",
  REQUEST_DENIED = "REQUEST_DENIED",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  ZERO_RESULTS = "ZERO_RESULTS",
  NOT_FOUND = "NOT_FOUND",
}

export enum PropertyStatus {
  IN_PROGRESS = "IN_PROGRESS",
  LISTED = "LISTED",
  UNLISTED = "UNLISTED",
}

export interface PropertyUnitCalendarRate {
  calendarId: number
  date: string
  basePrice: number
  discPrice: number
  isAvailable: boolean
  minStayDays: number
  pmsUnitId: string
  pmcId: string
  checkin: string
  checkout: string
}

export interface RoomsDetail {
  name: string
  description: string
  imageURL: string
}

export interface PropertyFeaturesKeyWords {
  id: number
  categoryName: string
  keyName: string
  keyIcon: string
  keyIndex: string
  isPopular: boolean
  mappedId: string
}

export interface PropertyFeaturesNewAmenities {
  propertyFeatureDescription: string
  propertyFeatureId: string
}

export interface PropDataType {
  pmc_id: string
  pms_system: string
  unit_id: string
  property_name: string
  property_desc: string
  addHouse_rule: string
  property_rating_star_sum: number
  property_rating_user_count: number
  property_type_desc: string
  property_sub_type_desc: string
  pulled_property: boolean
  max_no_guests: number
  no_of_bathrooms: number
  no_of_bedrooms: number
  no_of_beds: number
  no_of_units: number
  party_allowed: boolean
  smoke_allowed: boolean
  suitable_child: boolean
  suitable_infant: boolean
  suitable_pet: boolean
  unit_number: string
  wheelchair_accessable: boolean
  pg_host_acc_id: number
  discount_template_id: number
  contact_email_id: string
  contact_mobile_no: string
  contact_template_id: number
  address_full: string
  addressline1: string
  addressline2: string
  country: string
  city: string
  state: string
  postal_code: string
  latitude: string
  longitude: string
  square_ft: null
  checkInInstruction: string
  check_in_time: string
  checkOutInstruction: string
  check_out_time: string
  currency: string
  disc_per: number
  other_fee: number
  max_price: number
  min_price: number
  nightlyTaxPer: number
  cleaning_fee: number
  service_tax_per: number
  room_details: RoomsDetail[]
  reviews: null
  other_details: null
}
export interface PropImages {
  file_description: string
  file_url: string
  pmc_id: string
  unit_d: string
  image_order: number
  photo_id: number
  file_name: string
  is_cover_img: boolean
  upload_status: string
  height: number
  width: number
  is_hide: boolean
  is_video: boolean
  image_full_url: string
}
export interface KeywordsType {
  id: number
  category_name: string
  key_name: string
  key_icon: string
  key_index: string | number | null
  is_popular: boolean
  condition: string
}
export interface PropFeatures {
  category_name: string
  keywords: KeywordsType[]
}
export interface PropertyDataNew {
  id: string
  addedDesc: string | undefined | null
  pmcId: string
  unitId: string
  pmsSystem: string
  active: boolean
  isHide: boolean
  status: string
  propHashCode: number
  propData: PropDataType
  propImages: PropImages[]
  propFeatures: PropFeatures[]
  calendar: unknown[]
  rateInfo: unknown
  location: {
    x: number
    y: number
    type: string
    coordinates: [number, number]
  }
  manualUpdated: string
  minAdvanceBookingOffset: number
  maxAdvanceBookingOffset: number
  payoutType: string
  days: number
  exportCalendarLink: string | null
  importCalendarLink: string | null
  reviewComments: null
  errors: [string]
  createdDt: number
  updatedDt: number
  effMinPrice: number
  averagePrice: number
  calendarRate: number
  new: boolean
  saveAndExit: boolean
}
export interface PropertyData {
  id: string
  propertyId: string | number
  active: boolean
  isHide: boolean
  featureProperty: boolean
  noOfUnits: number
  propertyDesc: string
  propertyName: string
  pmsType: string
  pulledProperty: boolean
  pmcId: string
  pmsUnitId: string
  propertyTypeDesc: string
  propertySubTypeDesc: string
  propertyAccessType: string
  addressFull: string
  addressline1: string
  addressline2: string
  city: string
  state: string
  countryCode: string
  postalCode: string
  latitude: string
  longitude: string
  distance: number
  maxNoGuest: number
  noOfBathroom: number
  noOfBedroom: number
  partyAllowed: boolean
  smokeAllowed: boolean
  suitableChild: boolean
  suitableInfant: boolean
  suitablePet: boolean
  wheelchairAccessable: boolean
  maxPrice: number
  addHouseRule: string
  propertyRatingUserCount: number
  propertyRatingStarSum: number
  checkInTime: string
  checkInInstruction: string
  checkOutTime: string
  checkOutInstruction: string
  cleaningFee: number
  depositAmount: number
  depositType: string
  depositeBasicType: number
  discPer: number
  minPrice: number
  nightlyTaxPer: number
  otherFee: number
  otherFeeName: string
  petFee: number
  serviceTaxCleaning: boolean
  serviceTaxDeposite: boolean
  serviceTaxOther: boolean
  serviceTaxPet: boolean
  serviceTaxPer: number
  currency: string
  contactMobileNo: string
  contactEmailId: string
  contactTemplateId: number
  discountTemplateId: number
  taxTemplateId: number
  unitNumber: string
  pgHostAccId: number
  superhogListingId: number
  propertyUnitCalendarRate: PropertyUnitCalendarRate
  roomsDetail: RoomsDetail[]
  isManual: boolean
  securityCamera: boolean
  weapons: boolean
  dangerousAnimals: boolean
  propertyStatus: PropertyStatus
  status: string
  saveAndExit: boolean
}

export interface PropertyUpdateData {
  id: string
  description: string
  remove: boolean
}

export interface HidePropertyImageData {
  id: string
  description: string
  remove: boolean
}

export interface GetPropertyData {
  property: PropertyDataNew
  propertyFeaturesKeyWords: PropertyFeaturesKeyWords[]
  propertyFeaturesNewAmenites: PropertyFeaturesNewAmenities[]
  // propertyUnitCalendarRate: PropertyUnitCalendarRate
  // propertyUnitCalendarRates: PropertyUnitCalendarRate[]
  // hostPropertyAttachment: AttachmentData[]
  // isFavourite: boolean
  // hostservicefeePercentage: number
  // depositprocessfeePercentage: number
  // hostName: string
  // aboutHost: string
  // city: string
  // averagePrice: number
}

export interface PMCDescriptionData {
  description: string
}

export type TagDetails = {
  [key: string]: string
}

export interface LivePropertiesData {
  propertyId: string
  pmcId: string
  pmsUnitId: string
  propertyName: string
  urlPropertyName: string
  addressFull: string
  city: string
  photosCount: number
  propertyStatus: PropertyStatus | string
  fileName: string | null
  imageLink: string
  isNewProperty?: boolean
  updatedDt: "2022-08-17 09:44"
  propertyTypeDesc: string
  stepStatus: string
  contactTemplateApplied: boolean
  discountTemplateApplied: boolean
  accountApplied: boolean
  isDeleted: boolean
  isHide: boolean
  createdAt: number
  pmId?: number | string
  propertyCustomTags: {
    snooze: {
      startDate: number | Date | null
      endDate: number | Date | null
    }
    tempHold: boolean
    engineeringFixingDataIssues: boolean
    inDataValidation: boolean
  } | null
  weight: number
}

export interface GetLivePropertiesData {
  content: LivePropertiesData[]
  pageable: {
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  last: boolean
  totalPages: number
  totalElements: number
  number: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  size: number
  first: boolean
  numberOfElements: number
  empty: boolean
}

export interface MinimumAgePropertiesData {
  propertyId: string
  pmcId: string
  pmsUnitId: string
  propertyName: string
  urlPropertyName: string
  addressFull: string
  city: string
  photosCount: number
  propertyStatus: PropertyStatus | string
  imageLink: string
  stepStatus: string
  propertyTypeDesc: string
  discountTemplateApplied: boolean
  contactTemplateApplied: boolean
  accountApplied: boolean
  isHide: boolean
  isDeleted: boolean
  createdAt: number
  weight: number
  minimumAgeLimit: string | null
  pmId?: number | string
  fileName: string | null
  isNewProperty?: boolean
  updatedDt: "2022-08-17 09:44"
  propertyCustomTags: {
    snooze: {
      startDate: number | Date | null
      endDate: number | Date | null
    }
    tempHold: boolean
    engineeringFixingDataIssues: boolean
    inDataValidation: boolean
  } | null
}

export interface UpdateCategoryDataRequestType {
  feeName: string
  feeCategory: string
  blocked: boolean
  feeAmount: number
}

export interface GetMinimumAgePropertiesData {
  content: MinimumAgePropertiesData[]
  pageable: {
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  last: boolean
  totalPages: number
  totalElements: number
  number: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  size: number
  first: boolean
  numberOfElements: number
  empty: boolean
}

export interface CheckPublishPropertiesStatusData {
  pmcId: string
  eventType: string
  totalPmsUnitIdsCount: number
  processedPmsUnitIdsCount: number
  notProcessedPmsUnitIdsCount: number
}

export interface EventsData {
  hostEventLogId: number
  userId: number
  pmcId: string
  action: string
  details: string
  updateDate: string | number | null
  firstName: string
  lastName: string
  emailId: string
  mobileno1: string
  mobileno2: string
}

export interface PMSLogsData {
  hostEventLogId: number
  userId: number
  pmcId: string
  action: string
  details: string
  updateDate: string
  firstName: string
  lastName: string
  emailId: string
  mobileno1: string
  mobileno2: string
}

export interface ErrorLogsData {
  content: {
    propertyId: string
    pmcId: string
    pmsUnitId: string
    errors: string[]
  }[]
  pageable: {
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  last: boolean
  totalPages: number
  totalElements: number
  number: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  size: number
  first: boolean
  numberOfElements: number
  empty: boolean
}

export interface GetPropertyRequestData {
  pmcId: string
  pmsUnitId: string
}

export interface GetPmcDescrriptionRequestData {
  pmcId: string
  unitId: string
}
export interface GetLivePropertiesRequestData {
  pmcId: string
  keyword: string
  pageNo: number
  noOfRecords: number
  sortDirection: string
  sortBy: string
  active: boolean
  isHide: boolean
  status: string
}

export interface GetMinimumAgePropertiesRequestData {
  pmcId: string
  noOfRecords: number
  pageNo: number
  keyword: string
  sortBy: string
  sortDirection: string
  state: string
}

export interface GetEventsRequestData {
  pmcId: string
}
export interface GetPMSLogsRequestData {
  pmcId: string
}

export interface GetErrorLogsRequestData {
  pmcId: string
  keyword: string
  pageNo: number
  noOfRecords: number
  active: boolean
  isHide: boolean
}
export interface PublishPropertiesRequestData {
  eventType: string
  allUpdate: boolean
  pmcId: string
  pmsUnitIds: string[]
}
export interface CompanyStatusRequestData {
  pmcId: string
}
export interface InactivePropertiesRequestData {
  isHide: boolean
  pmcId: string
  pmsUnitIds: string[]
}
export interface AddingTagRequestData {
  pmcId: string
  ids: string[]
  tagName: string
  propertyCustomTags: {
    snooze: {
      startDate: string
      endDate: string
    }
    tempHold: boolean
    inDataValidation: boolean
    engineeringFixingDataIssues: boolean
  }
}

export interface AddingCustomTagRequestData {
  pmcId: string | undefined
  companyCustomTags: CompanyTags
}

export interface CheckPublishPropertiesStatusRequestData {
  eventType: string
  pmcId: string
}

export interface DeletePropertiesRequestData {
  propertyFor: "PUBLISH" | "UN_PUBLISH"
  allDelete: boolean
  pmcId: string
  pmsUnitIds: string[]
}

export interface AmenityData {
  id: number
  keyName: string
  categoryName: string
  keyIcon: string
}

export interface NewAmenities {
  propertyFeatureId: string
  propertyFeatureDescription: string
}

export interface Keywords {
  id: number
  isPopular: boolean
  keyIcon: string
  keyIndex: string
  keyName: string
  mappedId: string
}

export interface PropertyFeature {
  categoryName: string
  keywords: Keywords[]
}

export interface GetCustomAmenityData {
  newAmenities: NewAmenities[]
  propertyFeatures: PropertyFeature[]
}

export interface CustomAmenityData {
  pmsFeatureDesc: string
  pmsFeatureDescCategory: string
}

interface SupportedBankAccountCurrencies {
  additionalProp1: []
  additionalProp2: []
  additionalProp3: []
}

export interface CountryData {
  id: string
  defaultCurrency: string
  supportedBankAccountCurrencies: SupportedBankAccountCurrencies
  supportedPaymentCurrencies: string[]
  supportedPaymentMethods: string[]
  supportedTransferCountries: []
  companyFieldsMinimum: string[]
  companyFieldsAdditional: string[]
  individualFieldsMinimum: string[]
  individualFieldsAdditional: string[]
}

export interface AmenityRequestData {
  pmcId: string
  pmsUnitId: string
  propertyAmenities: AmenityData[]
}

export interface CustomAmenityRequestData {
  pmcId: string
  pmsUnitId: string
  customAmenities: CustomAmenityData[]
}

export interface GetCustomAmenityRequestData {
  pmcId: string
  pmsUnitId: string
}

export interface GetCountryByCodeRequestData {
  countryCode: string
}

export interface AttachmentRequestData {
  pmcId: string
  pmsUnitId: string
  image: File
  imageOrder: number
  fileDescription: string
}

export interface UpdateAttachmentRequestData {
  propertyAttachmentId: number | string
  image: File
}

export interface RemoveAttachmentRequestData {
  attachmentId: number | string
}

export interface ReorderAttachmentRequestData {
  pmcId: string
  pmsUnitId: string
  hostPopertyAttachmentIds: (number | string)[]
}

export interface GetAllAttachmentsRequestData {
  pmcId: string
  pmsUnitId: string
}

export interface AttachmentData {
  hostPopertyAttachmentId: number | string
  fileDescription: string
  fileName: string
  imgOrder: number
  isCoverImg: number | boolean
  pmsUnitId: string
  pmcId: string
  imageFullUrl: string
  imageThumbnailUrl: string
  video: boolean
  isHide: boolean
}

export interface HouseRulesData {
  pmcId: string
  pmsUnitId: string
  id: number
  checkOutTime: string
  addHouseRule: string
  chaeckInTime: string
  checkInInstruction: string
  checkOutInstruction: string
  partyAllowed: boolean
  smokeAllowed: boolean
  suitableChild: boolean
  suitableInfant: boolean
  suitablePet: boolean
  wheelchairAccessible: boolean
}

export interface AuthRequestData {
  emailOption: boolean
  code: string
  mobileNum: string
  email: string
}

export interface AuthData {
  userExists: boolean
  isTermAccepted: boolean
}

export interface VerifyOtpRequestData {
  otp: string
  isTermAccepted: boolean
}

export interface SignUpVerifyOtpRequestData {
  otp: string
}

export interface UserData {
  userId: number
  password: string
  active: boolean
  userType: number
  isAdmin: boolean
  isHost: boolean
  isTraveler: boolean
  emailVerified: boolean
  mobileno1: string
  mobileno2: string
  phNo: string
  deactiveDt: string
  dialerCode: string
  dobDt: string
  emailId: string
  firstName: string
  middleName: string
  lastName: string
  fullName: string
  gender: string
  about: string
  city: string
  country: string
  currency: string
  identificationId: string
  language: string
  location: string
  profileImg: string
  state: string
  zipcode: string
  appVersion: string
  browserType: string
  ipAddress: string
  isTrmsncndAccepted: boolean
  macId: string
  trmsncndAcceptedDate: string
  trmsncndAcceptedVersion: string
  signinFrom: string
  travelerRatingUserCount: number
  travelerRatingStarSum: number
  notificationEmail: boolean
  notificationApp: boolean
  notificationSms: boolean
  hostWebsite: string
  profileImageFullPath: string
  hostFeePercentage: number
  servicefeeforhostId: number
  pmsType: string
  channel: string
  signinProvider: string
  signinProviderId: string
  socialProfileImageURL: string
  superhogUserId: string
  invCode: string
  listingStatus: boolean
  userRole: string
}

export interface PmcData {
  name: string
  pmc_id: string
  pms_system: string
}

export interface IndividualAccountRequestData {
  email: string
  type: string
  accountHolderType: string | null
  timeStamp: number
  ipAddress: string
  pmcId: string | undefined
  url: string | null
  country: string
  // status: string
  bankAccount: {
    accountHolderName: string
    accountHolderType: string
    country: string
    currency: string
  } | null
  persons: Persons[]
  company?: CompanyForRequest
  isDefault: boolean
  pgHostAccId: number | undefined
  attachedProperties: number
  totalProperties: number
  mor?: boolean
}
export interface FeesType {
  applied: null
  currency: null
  description: string
  feeId: null
  id: number
  optional: null
  value: number
}
export interface TaxesType {
  currency: null
  description: string
  id: number
  percent: number
  taxId: null
  type: null
  value: number
}

export interface BookingsData {
  depositId: number
  claimNo: string
  currencySymbol: string | null
  refundAmount: number
  travelerId: number
  propertyId: number
  nightCount: number
  pmcId: string
  pmsUnitId: string
  bookedDate: string
  bookingNo: string
  statusId: number
  checkInDate: string
  checkOutDate: string
  adultNumber: number
  noOfChild: number
  noOfPet: number
  rentAmount: number
  optionalFeeAmount: number
  requiredFeeAmount: number
  taxAmount: number
  depositAmount: number
  totalPaidAmt: number
  hostFee: number
  propertyName: string
  travMobileNo: string
  guestName: string
  travelerEmail: string
  travelerImage: string
  bookingStatus: string
  fullAddress: string
  hostTransferAmount: number
  status: string
  fees: FeesType[]
  taxes: TaxesType[]
  cancelReason: string | undefined | null
  reasonType: string | undefined | null
}

export interface GetBookingsData {
  content: BookingsData[]
  pageable: {
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  last: boolean
  totalPages: number
  totalElements: number
  number: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  size: number
  first: boolean
  numberOfElements: number
  empty: boolean
}

type BankAccountType = {
  bankName: string
  routingNumber: string
  accountNumber: string
  accountHolderType: string
  accountHolderName: string
  country: string
  currency: string
}

type CardType = {
  brand: string
  country: string
  currency: string
  funding: string
  last4digit: string
  object: string
}

export interface PayoutBankResponseData {
  pgHostAccId: number
  persons: Persons[]
  isDefault: boolean
  bankAccount: BankAccountType
  card: CardType
  attachedProperties: number
  totalProperties: number
  accountHolderType: string
  pmcId: string
  country: string
  accountHolderName: string
  internalStatus: string
  status: string
  type: string
}

export interface GetPayoutPropertiesRequestData {
  pmcId: string
  pgHostAccId: number
  keyword: string
  pageNo: number
  noOfRecords: number
}

export type ApplyPayoutRulesOnPropertiesRequestData = {
  pmcId: string
  pmsUnitId: string
  pgHostAccId: number
  active: boolean
}[]

export interface GetTemplatePropertiesRequestData {
  pmcId: string
  templateId: number | string
  keyword: string
  pageNo: number
  noOfRecords: number
}

export type ApplyTemplateOnPropertiesRequestData = {
  pmcId: string
  templateId: number | string
  isAllUpdate: boolean
  removeAll: boolean
  propertyDetails: {
    propertyId: number
    active: boolean
  }[]
}

export type ReportsData = {
  status: string
  bookingid: string
  bookingDate: string
  guestName: string
  adultNumber: number
  noOfChild: number
  checkInDate: string
  checkOutDate: string
  nightsNo: number
  address: string
  propertyName: string
  earnings: number
  genrationDate: number
  pmsUnitId: string
}

export type GetReportsData = {
  content: ReportsData[]
  pageable: {
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  last: boolean
  totalPages: number
  totalElements: number
  number: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  size: number
  first: boolean
  numberOfElements: number
  empty: boolean
}

export type CurrencyType =
  | "USD"
  | "EUR"
  | "CRC"
  | "GBP"
  | "ILS"
  | "INR"
  | "JPY"
  | "KRW"
  | "NGN"
  | "PHP"
  | "PLN"
  | "PYG"
  | "THB"
  | "UAH"
  | "VND"

export type PayoutReportData = {
  address: string
  bookingNumber: string
  checkInDate: number
  checkOutDate: number
  currency: CurrencyType
  description: string
  currencySymbol: string | null
  estarrival: number
  generationDate: number
  initiated: number
  pmsReservationNumber: string
  propertyName: string
  transferAmount: number
}

export type GetPayoutReportData = {
  content: PayoutReportData[]
  pageable: {
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  last: boolean
  totalPages: number
  totalElements: number
  number: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  size: number
  first: boolean
  numberOfElements: number
  empty: boolean
}
export interface UploadVideoRequestData {
  pmcId: string
  pmsUnitId: string
  image: File
  imageOrder: number
  fileDescription: string
}
export interface ReviewsAndRatings {
  id: number
  userName: string | null
  userProfile: string | null
  publishDate: string
  rating: number
  reviewText: string
  userReviewRepliesEntity: []
}

export interface CategoryData {
  id: string
  feeName: string
  feeCategory: string
  blocked: boolean
  modifiedDate: number
  feeAmount: number
  feeType: string
}

export interface FeeCategoryData {
  data: CategoryData
}
export interface ReviewsAndRatingsData {
  content: ReviewsAndRatings[]
  pageable: {
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  last: boolean
  totalPages: number
  totalElements: number
  number: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  size: number
  first: boolean
  numberOfElements: number
  empty: boolean
}
export interface AddedReview {
  id: number
  propertyId: string | number | null
  userId: string | number | null
  reviewerName: string
  pmcId: string
  pmsUnitId: string
  rating: number
  reviewTitle: string
  reviewText: string
  replies: string | number | null
  publishDate: string
  pmsReviewerId: string | number | null
  updateDate: number[]
}
export interface AddedReply {
  id: number
  replyMessage: string
  userId: number | string | null
  isOwner: boolean
  isHide: boolean
  updateAt: number[]
  createdAt: number[]
}
export interface IndividualBankAccountRequestData {
  bankAccount: {
    bankName: string
    accountNumber: string
    routingNumber: string
    accountHolderType: string
    country: string
    currency: string
    accountHolderName?: string
  }
  pmcId: string | undefined
  pgHostAccId: number | undefined
  timeStamp: number
  accountHolderType: string
}

export interface GetCalendarDatesRequestData {
  pmsUnitId: string | undefined
  pmcId: string
  propertyId: string | undefined
  fromDate: string
  toDate: string
}

export interface UpdateCalendarDatesRequestData {
  pmsUnitId: string | undefined
  pmcId: string
  propertyId: string | undefined
  fromDate: string
  toDate: string
  calendarList: {
    date: string
    basePrice: number
    discountPrice: number
    available: boolean
    arrival: boolean
    departure: boolean
    minStayDays: number
  }[]
}

export interface UpdateCalendarWeekendPriceRequestData {
  pmsUnitId: string | undefined
  pmcId: string
  weekendPrice: number
}

export interface UpdateCalendarDataRequestData {
  pmsUnitId: string
  pmcId: string
  date: string
  basePrice: number
  discountPrice: number
  available: boolean
  arrival: boolean
  departure: boolean
  minStayDays: number
}

export type Iptypes = {
  data: {
    IPv4: string
    country_name: string
  }
}

export interface GetCalendarBookedDatesRequestData {
  pmsUnitId: string | undefined
  pmcId: string
  propertyId: string | undefined
  fromDate: string
  toDate: string
}

export interface ImportCalendarRequestData {
  pmsUnitId: string | undefined
  pmcId: string
  propertyId: string | undefined
  calendarLink: string
}

export interface ExportCalendarRequestData {
  pmsUnitId: number | string
}

export interface GetCalendarRequestData {
  pmsUnitId: number | string
  pmcId: number | string
}

export interface SetCalendarImportLinkRequestData {
  pmsUnitId: number | string
  pmcId: number | string
  calendarLink: string
  icalLinkPlatformName: string
}
export interface Notifications {
  detailType: string
  details: string
  notificationStatus: string
  pmcId: string
  subject: string
  userId: 103375
  notificationId: number
  createdAt: number
  title: string
  read: boolean
}
export interface NotificationData {
  content: Notifications[]
  pageable: {
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  last: boolean
  totalPages: number
  totalElements: number
  number: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  size: number
  first: boolean
  numberOfElements: number
  empty: boolean
}

export interface AddBankAccountError {
  message?: string | null
  apiStatus: string
  data: {
    message: string
  }
}

export interface UserBasicDetails {
  userId: number
  firstName: string
  pmcId: string
}

export interface ActivePropertiesData {
  address: string
  propertyId: string
  propertyLink: string
  propertyName: string
  bookingPalId: string | null | undefined
}
export interface ActivePropertyReportData {
  content: ActivePropertiesData[]
  pageable: {
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  last: boolean
  totalPages: number
  totalElements: number
  number: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  size: number
  first: boolean
  numberOfElements: number
  empty: boolean
}

export interface PmcDefaults {
  isDefaultContactTemplate: boolean
  isDefaultPaymentAccount: boolean
}

export interface CompanyTags {
  awaitingPMTestBookingApproval: boolean
  inDataValidation: boolean
  engineeringFixingDataIssues: boolean
  awaitingPMSSupport: boolean
  awaitingPMCSupport: boolean
  readyToPublish: boolean
  testBookingPending: boolean
  onboardingPending: boolean
}

export interface CompanyTagResponse {
  active: boolean
  addressLine1: null | string
  addressLine2: string
  calendarDays: string
  checkArrivalDeparture: boolean
  city: null | string
  companyCustomTags: null | CompanyTags
  contactName: string
  contactNo: string
  country: null | string
  createdDt: number
  currency: string
  day1bookable: boolean
  email: string
  id: string
  invCode: string
  invCodeExp: number
  isTrmsncndAccepted: boolean
  liveDt: number
  maxProfit: boolean
  name: string
  new: boolean
  note: null | string
  pmcId: string
  pmsSystem: string
  postalCode: null | string
  priceMarkup: number
  stateProvince: null | string
  minimumAgeTemplate: null | string
  isAutoPublished: null | string
  status: string
  trmsncndAcceptedDate: number
  trmsncndAcceptedVersion: string
  updatedDt: number
  websiteAddress: null | string
  whimstayAdditionalLastMinuteDiscountPercent: number
  whimstayHostServiceFeePercent: number
  whimstayTravelerRegularServiceFeePercent: number
  whimstayTravelerServiceFeePercent: number
}

export enum PDPType {
  Existing = "Existing",
  Whimstay = "Whimstay",
}

export enum MinimumAgeType {
  Existing = "PMS",
  Whimstay = "HOST_SITE",
}
