import { Dispatch, SetStateAction } from "react"

import CustomMenuItem from "components/menudropdown/dropdown-menu-item"
import MenuDropDown from "components/menudropdown/menudropdown"
import { setTableRowCount } from "utils/local-storage"

type PageSelectorProps = {
  pageSize: number
  setPageSize: Dispatch<SetStateAction<number>>
  setPageStartIndex?: (n: number) => void
  pageSizes?: number[]
}

const PageSelector = ({
  pageSize,
  setPageSize,
  setPageStartIndex,
  pageSizes,
}: PageSelectorProps) => {
  const handlePageSizeChange = (count: number) => {
    setTableRowCount(`${count}`)
    setPageSize(count)
    if (setPageStartIndex) {
      setPageStartIndex(0)
    }
  }
  return (
    <MenuDropDown title={`${pageSize} Rows`}>
      {(pageSizes || [5, 10, 20, 50, 100]).map((i) => (
        <CustomMenuItem
          h='40px'
          isSelected={i === pageSize}
          key={i}
          value={i}
          onClick={() => handlePageSizeChange(i)}
          whiteSpace='nowrap'
        >
          {i} Rows
        </CustomMenuItem>
      ))}
    </MenuDropDown>
  )
}
export default PageSelector
