import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "services/client"
import { ResponseData } from "services/common"

export interface VerifyResetLinkRequest extends Partial<AxiosRequestConfig> {
  token: string | undefined
}

export interface VerifyResetLinkResponse extends AxiosResponse {
  payload: {
    message: string
    apiStatus: string
  }
}

export const defaultUrlPassword = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/auth/verify/reset-link`

export function verifyResetLink(
  { token, method = "post", url = defaultUrlPassword }: VerifyResetLinkRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<VerifyResetLinkResponse> {
  return axiosInstance({
    method,
    url,
    headers: {
      authorization: `Bearer ${token || ""}`,
    },
  }) as Promise<VerifyResetLinkResponse>
}

// new password set code
export interface NewPassWordRequestData extends Partial<AxiosRequestConfig> {
  password: string
  token: string | undefined
}

export interface NewPasswordSetupRequest extends Partial<AxiosRequestConfig> {
  data: NewPassWordRequestData
}

export interface NewPasswordSetupResponseData extends ResponseData {
  payload: {
    message: string
    apiStatus: string
  }
}
export interface NewPasswordSetupResponse extends AxiosResponse {
  data: NewPasswordSetupResponseData
}

export const defaultUrl = `${
  process.env.REACT_APP_BASE_API_URL || ""
}/auth/password/change`

export function newPasswordSetup(
  { data, method = "post", url = defaultUrl }: NewPasswordSetupRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<NewPasswordSetupResponse> {
  return axiosInstance({
    data: {
      password: data.password,
    },
    method,
    url,
    headers: {
      authorization: `Bearer ${data.token || ""}`,
    },
  }) as Promise<NewPasswordSetupResponse>
}
