import { lazy, Suspense } from "react"

import { Center, Spinner } from "@chakra-ui/react"
import { Routes, Route, Navigate } from "react-router-dom"

import { useAppSelector } from "app/hooks"
import ProtectedComponent from "components/PleaseLoginComp"
import HostBookingLayout from "layouts/host-booking-layout"
import HostListingLayout from "layouts/host-listing-layout"
// import HostNotificationLayout from "layouts/host-notification-layout"
import HostProfileLayout from "layouts/host-profile-layout"
import HostPropertiesLayout from "layouts/host-properties-layout"
import HostReportsLayout from "layouts/host-reports-layout"
import HostLayout from "layouts/hostLayout"
import StepLayout from "layouts/stepLayout"
import DummyCalendar from "pages/dummy/property-calendar"
import FeeMapping from "pages/fee-mapping/FeeMapping"
import PropertyListingCalendar from "pages/listing/Calendar"
import PropertyDamageProtection from "pages/property-damage-protection/PropertyDamageProtection"
import SignUp from "pages/signup"
import { selectUserLoggedIn } from "store/auth/authSlice"

// profile pages
const Events = lazy(() => import("pages/profile/events"))
const ManageUsers = lazy(() => import("pages/profile/manage-users"))
const ManageUsersByCode = lazy(
  () => import("pages/profile/manage-users/manage-users-by-code"),
)
const PayoutSettings = lazy(() => import("pages/profile/payout-settings"))
// const PmsLogs = lazy(() => import("pages/profile/pms-logs"))
const ProfileDetails = lazy(() => import("pages/profile/profile-details"))
// const Inbox = lazy(() => import("pages/inbox/Inbox"))
const ErrorLogs = lazy(() => import("pages/profile/error-logs"))

// Report pages
const EarningProgress = lazy(() => import("pages/reports/earning-progress"))
const PayoutReports = lazy(() => import("pages/reports/payout"))
const AllReports = lazy(() => import("pages/reports/reservation/all-reports"))
// const ArrivingSoonReports = lazy(
//   () => import("pages/reports/reservation/arriving-soon-reports"),
// )
// const CompletedReports = lazy(
//   () => import("pages/reports/reservation/completed-reports"),
// )
// const CurrentlyHostingReports = lazy(
//   () => import("pages/reports/reservation/currently-hosting-reports"),
// )
const ActivePropertisReports = lazy(
  () => import("pages/reports/active-properties"),
)

// Booking pages
const AllBookings = lazy(() => import("pages/bookings/all-bookings"))
const ArrivingSoonBookings = lazy(
  () => import("pages/bookings/arriving-soon-bookings"),
)

const CompletedBookings = lazy(() => import("pages/bookings/completed"))
const CurrentlyHostingBookings = lazy(
  () => import("pages/bookings/currently-hosting-bookings"),
)
const CanceledBookings = lazy(() => import("pages/bookings/canceled"))

// Properties pages
const LiveProperties = lazy(() => import("pages/properties/live-properties"))
const UnpublishedProperties = lazy(
  () => import("pages/properties/unpublished-properties"),
)
const PMCInactiveProperties = lazy(
  () => import("pages/properties/pmc-inactive-properties"),
)

const ArchivedProperties = lazy(
  () => import("pages/properties/archived-properties"),
)
const ContactTemplates = lazy(
  () => import("pages/contact-templates/ContactTemplates"),
)
const DiscountTemplates = lazy(
  () => import("pages/discount-templates/DiscountTemplates"),
)
const MinimumAgeTemplate = lazy(
  () => import("pages/minimum-age-template/MinimumAgeTemplate"),
)
const AvailabilityBlocks = lazy(
  () => import("pages/availability-blocks/AvailabilityBlocks"),
)
const Listing = lazy(() => import("pages/listing/Listing"))
// const PropertyCalendar = lazy(
//   () => import("pages/properties/property-calendar"),
// )

// Steps pages
const HomePage = lazy(() => import("pages/home"))
const HostIntroPage = lazy(() => import("pages/host-intro"))
const Step1 = lazy(() => import("pages/step1"))
const Step2Page = lazy(() => import("pages/step2/step2"))
const Step3 = lazy(() => import("pages/Step3"))
const AmenitiesStepPage = lazy(() => import("pages/Step5/index"))
const PhotosStepPage = lazy(() => import("pages/step6"))
const Step7 = lazy(() => import("pages/step7"))
const Step8 = lazy(() => import("pages/step8"))
const Step9 = lazy(() => import("pages/step9"))
const Step11page = lazy(() => import("pages/Step11"))
const Step12page = lazy(() => import("pages/Step12"))
const ThankYou = lazy(() => import("pages/thank-you"))
const SelectPmsSoftware = lazy(() => import("pages/select-pms-software"))
const SalesTeam = lazy(() => import("pages/sales-team"))

const Payout = lazy(() => import("pages/payout"))

// Dashboard pages
// const Dashboard = lazy(() => import("pages/dashboard"))
const TryHost = lazy(() => import("pages/try-host"))
const GetReady = lazy(() => import("pages/get-ready"))
const CutomizeCalendar = lazy(() => import("pages/get-ready/customizeCalendar"))
const FAQ = lazy(() => import("pages/faq"))
const ContactUs = lazy(() => import("pages/contact-us/ContactUs"))
const ContactUsConfirmation = lazy(
  () => import("pages/contact-us/ContactUsConfirmation"),
)

const ResetPassword = lazy(
  () => import("components/reset-password/reset-password"),
)
const HostFully = lazy(() => import("pages/link-hostfully"))
const AuthorizeCode = lazy(() => import("pages/authorize-code"))
const CreatePassword = lazy(() => import("pages/create-password"))

const Loader = () => (
  <Center p={4} h='90vh'>
    <Spinner />
  </Center>
)

const Router = () => {
  const isLoggedIn = useAppSelector(selectUserLoggedIn)

  return (
    <Routes>
      <Route path='/dummy' element={<DummyCalendar />} />

      <Route
        path='/reset_password/:token'
        element={
          <HostLayout>
            <Suspense fallback={<Loader />}>
              <ResetPassword />
            </Suspense>
          </HostLayout>
        }
      />

      <Route
        path='/create-password/:token'
        element={
          <HostLayout>
            <Suspense fallback={<Loader />}>
              <CreatePassword />
            </Suspense>
          </HostLayout>
        }
      />

      {/* <Route
        path='/'
        element={
          <HostLayout>
            {!isLoggedIn ? (
              <Suspense fallback={<Loader />}>
                <TryHost />
              </Suspense>
            ) : (
              <ProtectedComponent>
                <Suspense fallback={<Loader />}>
                  <Dashboard />
                </Suspense>
              </ProtectedComponent>
            )}
          </HostLayout>
        }
      /> */}
      <Route
        path='/'
        element={
          <HostLayout>
            {!isLoggedIn ? (
              <Suspense fallback={<Loader />}>
                <TryHost />
              </Suspense>
            ) : (
              <HostPropertiesLayout>
                <Suspense fallback={<Loader />}>
                  <LiveProperties />
                </Suspense>
              </HostPropertiesLayout>
            )}
          </HostLayout>
        }
      />

      <Route
        path='/host-signup'
        element={
          <HostLayout>
            <Suspense fallback={<Loader />}>
              <SignUp />
            </Suspense>
          </HostLayout>
        }
      />

      <Route
        path='/get-started/first-guest'
        element={
          <HostLayout>
            <ProtectedComponent>
              <Suspense fallback={<Loader />}>
                <GetReady />
              </Suspense>
            </ProtectedComponent>
          </HostLayout>
        }
      />
      <Route
        path='/get-started/guest-use-area'
        element={
          <HostLayout>
            <ProtectedComponent>
              <Suspense fallback={<Loader />}>
                <CutomizeCalendar />
              </Suspense>
            </ProtectedComponent>
          </HostLayout>
        }
      />
      <Route
        path='/faq'
        element={
          <HostLayout>
            <ProtectedComponent>
              <Suspense fallback={<div>Loading...</div>}>
                <FAQ />
              </Suspense>
            </ProtectedComponent>
          </HostLayout>
        }
      />
      <Route
        path='/contact-us'
        element={
          <HostLayout>
            <ProtectedComponent>
              <Suspense fallback={<div>Loading...</div>}>
                <ContactUs />
              </Suspense>
            </ProtectedComponent>
          </HostLayout>
        }
      />
      <Route
        path='/contact-us-confirmation'
        element={
          <HostLayout>
            <ProtectedComponent>
              <Suspense fallback={<div>Loading...</div>}>
                <ContactUsConfirmation />
              </Suspense>
            </ProtectedComponent>
          </HostLayout>
        }
      />
      <Route
        path='/listings/properties/discount-template'
        element={
          <HostLayout>
            <HostPropertiesLayout>
              <Suspense fallback={<Loader />}>
                <DiscountTemplates />
              </Suspense>
            </HostPropertiesLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/properties/contact-template'
        element={
          <HostLayout>
            <HostPropertiesLayout>
              <Suspense fallback={<Loader />}>
                <ContactTemplates />
              </Suspense>
            </HostPropertiesLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/properties/property-damage-protection'
        element={
          <HostLayout>
            <HostPropertiesLayout>
              <Suspense fallback={<Loader />}>
                <PropertyDamageProtection />
              </Suspense>
            </HostPropertiesLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/properties/minimum-age-template'
        element={
          <HostLayout>
            <HostPropertiesLayout>
              <Suspense fallback={<Loader />}>
                <MinimumAgeTemplate />
              </Suspense>
            </HostPropertiesLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/properties/availability-blocks'
        element={
          <HostLayout>
            <HostPropertiesLayout>
              <Suspense fallback={<Loader />}>
                <AvailabilityBlocks />
              </Suspense>
            </HostPropertiesLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/properties/fee-mapping'
        element={
          <HostLayout>
            <HostPropertiesLayout>
              <Suspense fallback={<Loader />}>
                <FeeMapping />
              </Suspense>
            </HostPropertiesLayout>
          </HostLayout>
        }
      />

      <Route
        path='/listings/properties/unpublished-properties'
        element={
          <HostLayout>
            <HostPropertiesLayout>
              <Suspense fallback={<Loader />}>
                <UnpublishedProperties />
              </Suspense>
            </HostPropertiesLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/properties/live-properties'
        element={
          <HostLayout>
            <HostPropertiesLayout>
              <Suspense fallback={<Loader />}>
                <LiveProperties />
              </Suspense>
            </HostPropertiesLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/properties/pmc-inactive-properties'
        element={
          <HostLayout>
            <HostPropertiesLayout>
              <Suspense fallback={<Loader />}>
                <PMCInactiveProperties />
              </Suspense>
            </HostPropertiesLayout>
          </HostLayout>
        }
      />
      {/* <Route
        path='/listings/properties/whimstay-inactive-properties'
        element={
          <HostLayout>
            <HostPropertiesLayout>
              <Suspense fallback={<Loader />}>
                <WhimstayInactiveProperties />
              </Suspense>
            </HostPropertiesLayout>
          </HostLayout>
        }
      /> */}
      <Route
        path='/listings/properties/archived-properties'
        element={
          <HostLayout>
            <HostPropertiesLayout>
              <Suspense fallback={<Loader />}>
                <ArchivedProperties />
              </Suspense>
            </HostPropertiesLayout>
          </HostLayout>
        }
      />

      {/** Note: Uncomments this to unable calendar. */}
      <Route
        path='/listings/properties/calendar/:properyId/*'
        element={
          <HostLayout>
            <HostListingLayout>
              <Suspense fallback={<Loader />}>
                {/* <PropertyCalendar /> */}
                <PropertyListingCalendar />
              </Suspense>
            </HostListingLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/properties/details/:properyId/*'
        element={
          <HostLayout>
            <HostListingLayout>
              <Suspense fallback={<Loader />}>
                <Listing />
              </Suspense>
            </HostListingLayout>
          </HostLayout>
        }
      />

      {/* bookings  */}
      <Route
        path='/listings/bookings/all'
        element={
          <HostLayout>
            <HostBookingLayout active='all'>
              <Suspense fallback={<Loader />}>
                <AllBookings />
              </Suspense>
            </HostBookingLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/bookings/currently-hosting'
        element={
          <HostLayout>
            <HostBookingLayout active='currentlyHosting'>
              <Suspense fallback={<Loader />}>
                <CurrentlyHostingBookings />
              </Suspense>
            </HostBookingLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/bookings/arriving-soon'
        element={
          <HostLayout>
            <HostBookingLayout active='arrivingSoon'>
              <Suspense fallback={<Loader />}>
                <ArrivingSoonBookings />
              </Suspense>
            </HostBookingLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/bookings/completed'
        element={
          <HostLayout>
            <HostBookingLayout active='completed'>
              <Suspense fallback={<Loader />}>
                <CompletedBookings />
              </Suspense>
            </HostBookingLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/bookings/canceled'
        element={
          <HostLayout>
            <HostBookingLayout active='canceled'>
              <Suspense fallback={<Loader />}>
                <CanceledBookings />
              </Suspense>
            </HostBookingLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/reports/earningProgress'
        element={
          <HostLayout>
            <HostReportsLayout>
              <Suspense fallback={<Loader />}>
                <EarningProgress />
              </Suspense>
            </HostReportsLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/reports/reservation'
        element={
          <HostLayout>
            <HostReportsLayout>
              <Suspense fallback={<Loader />}>
                <AllReports />
              </Suspense>
            </HostReportsLayout>
          </HostLayout>
        }
      />
      {/** TODO : after client approves the single reservation report screen remove other report pages */}

      {/* <Route
        path='/listings/reports/reservation/currentlyHosting'
        element={
          <HostLayout>
            <HostReportsLayout>
              <Suspense fallback={<Loader />}>
                <CurrentlyHostingReports />
              </Suspense>
            </HostReportsLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/reports/reservation/arrivingSoon'
        element={
          <HostLayout>
            <HostReportsLayout>
              <Suspense fallback={<Loader />}>
                <ArrivingSoonReports />
              </Suspense>
            </HostReportsLayout>
          </HostLayout>
        }
      />
      <Route
        path='/listings/reports/reservation/completed'
        element={
          <HostLayout>
            <HostReportsLayout>
              <Suspense fallback={<Loader />}>
                <CompletedReports />
              </Suspense>
            </HostReportsLayout>
          </HostLayout>
        }
      /> */}
      <Route
        path='/listings/reports/payout'
        element={
          <HostLayout>
            <HostReportsLayout>
              <Suspense fallback={<Loader />}>
                <PayoutReports />
              </Suspense>
            </HostReportsLayout>
          </HostLayout>
        }
      />

      <Route
        path='/listings/reports/active-properties'
        element={
          <HostLayout>
            <HostReportsLayout>
              <Suspense fallback={<Loader />}>
                <ActivePropertisReports />
              </Suspense>
            </HostReportsLayout>
          </HostLayout>
        }
      />

      {/* Profile */}
      <Route
        path='/profile/profile-details'
        element={
          <HostLayout>
            <HostProfileLayout>
              <Suspense fallback={<Loader />}>
                <ProfileDetails />
              </Suspense>
            </HostProfileLayout>
          </HostLayout>
        }
      />
      <Route
        path='/profile/payout-settings'
        element={
          <HostLayout>
            <HostProfileLayout>
              <Suspense fallback={<Loader />}>
                <PayoutSettings />
              </Suspense>
            </HostProfileLayout>
          </HostLayout>
        }
      />
      <Route
        path='/profile/manage-users'
        element={
          <HostLayout>
            <HostProfileLayout>
              <Suspense fallback={<Loader />}>
                <ManageUsers />
              </Suspense>
            </HostProfileLayout>
          </HostLayout>
        }
      />
      <Route
        path='/invite/:invCode'
        element={
          <HostLayout>
            <Suspense fallback={<Loader />}>
              <ManageUsersByCode />
            </Suspense>
          </HostLayout>
        }
      />
      {/* <Route
        path='/profile/pms-logs'
        element={
          <HostLayout>
            <HostProfileLayout>
              <Suspense fallback={<Loader />}>
                <PmsLogs />
              </Suspense>
            </HostProfileLayout>
          </HostLayout>
        }
      /> */}
      <Route
        path='/profile/error-logs'
        element={
          <HostLayout>
            <HostProfileLayout>
              <Suspense fallback={<Loader />}>
                <ErrorLogs />
              </Suspense>
            </HostProfileLayout>
          </HostLayout>
        }
      />
      <Route
        path='/profile/events'
        element={
          <HostLayout>
            <HostProfileLayout>
              <Suspense fallback={<Loader />}>
                <Events />
              </Suspense>
            </HostProfileLayout>
          </HostLayout>
        }
      />

      <Route
        path='/become-a-host'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <HomePage />
            </Suspense>
          </StepLayout>
        }
      />
      <Route
        path='/become-a-host/intro'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <HostIntroPage index={0} />
            </Suspense>
          </StepLayout>
        }
      />

      {/** Note: Uncomments this to unable inbox. */}
      {/* <Route
        path='/inbox/all'
        element={
          <HostLayout>
            <HostNotificationLayout>
              <Suspense fallback={<Loader />}>
                <Inbox />
              </Suspense>
            </HostNotificationLayout>
          </HostLayout>
        }
      />
      <Route
        path='/inbox/archive'
        element={
          <HostLayout>
            <HostNotificationLayout>
              <Suspense fallback={<Loader />}>
                <Inbox />
              </Suspense>
            </HostNotificationLayout>
          </HostLayout>
        }
      /> */}
      <Route
        path='/become-a-host/property-type-group'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <Step1 index={1} />
            </Suspense>
          </StepLayout>
        }
      />
      <Route
        path='/become-a-host/location'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <Step2Page index={2} />
            </Suspense>
          </StepLayout>
        }
      />
      <Route
        path='/become-a-host/floor-plan'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <Step3 index={3} />
            </Suspense>
          </StepLayout>
        }
      />
      <Route
        path='/become-a-host/amenities'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <AmenitiesStepPage index={4} />
            </Suspense>
          </StepLayout>
        }
      />
      <Route
        path='/become-a-host/photos'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <PhotosStepPage index={5} />
            </Suspense>
          </StepLayout>
        }
      />
      <Route
        path='/become-a-host/title'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <Step7 index={6} />
            </Suspense>
          </StepLayout>
        }
      />
      <Route
        path='/become-a-host/description'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <Step8 index={7} />
            </Suspense>
          </StepLayout>
        }
      />
      <Route
        path='/become-a-host/price'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <Step9 index={8} />
            </Suspense>
          </StepLayout>
        }
      />
      <Route
        path='/become-a-host/terms-and-condition'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <Step11page index={9} />
            </Suspense>
          </StepLayout>
        }
      />
      <Route
        path='/become-a-host/preview'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <Step12page index={10} />
            </Suspense>
          </StepLayout>
        }
      />

      <Route
        path='/congratulation'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <ThankYou />
            </Suspense>
          </StepLayout>
        }
      />
      <Route
        path='/selectpmssoftware'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <SelectPmsSoftware />
            </Suspense>
          </StepLayout>
        }
      />
      <Route
        path='/salesteam'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <SalesTeam />
            </Suspense>
          </StepLayout>
        }
      />

      <Route
        path='/payout'
        element={
          <StepLayout>
            <Suspense fallback={<Loader />}>
              <Payout />
            </Suspense>
          </StepLayout>
        }
      />
      <Route
        path='/link/hostfully'
        element={
          <HostLayout>
            <Suspense fallback={<Loader />}>
              <HostFully />
            </Suspense>
          </HostLayout>
        }
      />

      <Route
        path='/authorize/code'
        element={
          <HostLayout>
            <Suspense fallback={<Loader />}>
              <AuthorizeCode />
            </Suspense>
          </HostLayout>
        }
      />

      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  )
}

export default Router
