import React from "react"

import { Modal, ModalOverlay } from "@chakra-ui/react"

import { PopupProps } from "./types"

const Popup: React.FC<PopupProps> = ({
  children,
  isOpen,
  onClose,
  isCloseOnOverlayClick = false,
  preserveScrollBarGap = false,
}) => (
  <Modal
    isCentered
    onClose={onClose}
    isOpen={isOpen}
    size='xl'
    motionPreset='slideInBottom'
    closeOnOverlayClick={isCloseOnOverlayClick}
    preserveScrollBarGap={preserveScrollBarGap}
  >
    <ModalOverlay />
    {children}
  </Modal>
)

export default Popup
