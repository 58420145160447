import { Tag, Text } from "@chakra-ui/react"

type RoundedBadgeProps = {
  text: string
  variant?: "primary" | "secondary" | "error"
  marginRight?: string
}

// Rounded badge component which can be use to show badge inside table component
const RoundedBadge = ({
  text,
  variant = "primary",
  marginRight = "10px",
}: RoundedBadgeProps) => {
  let bg
  if (variant === "error") {
    bg = "#e74c4c"
  } else if (variant === "primary") {
    bg = "#0d6f8177"
  } else {
    bg = "#E1E1E1"
  }
  return (
    <Tag
      bg={bg}
      minH={{ base: "16px", md: "24px" }}
      height={{ base: "16px", md: "24px" }}
      rounded='full'
      mr={marginRight}
    >
      <Text
        variant={
          variant === "primary" || variant === "error" ? "white" : "black"
        }
        fontSize={{ base: "8px", md: "10px" }}
        fontWeight={400}
        whiteSpace='nowrap'
      >
        {text}
      </Text>
    </Tag>
  )
}

export default RoundedBadge
