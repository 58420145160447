import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { defaultAxiosInstance } from "./client"
import { PMSLogsData, GetPMSLogsRequestData, ResponseData } from "./common"

export interface GetPMSLogsRequest extends Partial<AxiosRequestConfig> {
  params: GetPMSLogsRequestData
}
export interface GetPMSLogsResponseData extends ResponseData {
  data: PMSLogsData[]
}
export interface GetPMSLogsResponse extends AxiosResponse {
  data: GetPMSLogsResponseData
}

const defaultUrl = `${process.env.REACT_APP_BASE_API_URL || ""}/pms/log`

export function getPMSLogs(
  { params, method = "get", url = defaultUrl, ...config }: GetPMSLogsRequest,
  axiosInstance: AxiosInstance = defaultAxiosInstance,
): Promise<GetPMSLogsResponse> {
  return axiosInstance({
    params,
    method,
    url,
    ...config,
  }) as Promise<GetPMSLogsResponse>
}
